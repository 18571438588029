import { IClientBonCommandeModel, IClientBonLivraisonModel, IClientPropalPeriodeModel } from "@common-models/*"
import React, { useState } from "react"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyTooltip from "@common-utils/MyTooltip"
import PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsLivraison from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsLivraison"
import PtfPeriodeAddBonCommandeForm from "../actions/_PtfPeriodeAddBonCommandeForm"
import MyModal from "@common-utils/MyModal"
import { IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import { usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext } from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import { useQueryClient } from "react-query"
import PtfPeriodeBonCommandeAddBonLivraisonForm from "../actions/_PtfPeriodeBonCommandeAddBonLivraisonForm"
import PtfPeriodeBonCommandeAddLivrableForm from "../actions/_PtfPeriodeBonCommandeAddLivrableForm"
import { deleteBonCommandeRequest } from "../core/_requests"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import PtfPeriodeBonCommandeEditForm from "../actions/_PtfPeriodeBonCommandeEditForm"
import MyGoogleDriveFileViewerInModal from "../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"

const PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommande = ({ periode, ptf_type }: IPtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommandePropsModel) => {
     const context = usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext()
     const queryClient = useQueryClient()
     const { swal } = useSwal()

     const [currentBonCommandeInModal, setCurrentBonCommandeInModal] = useState<{
          bc: IClientBonCommandeModel
          loading: boolean
     }>()
     const [editBonCommandeInModal, setEditBonCommandeInModal] = useState<IClientBonCommandeModel>()

     const [isAddBonCommandeModalShown, setIsAddBonCommandeModalShown] = useState<boolean>(false)
     const [addBonLivraisonToBonCommandeModal, setAddBonLivraisonToBonCommandeModal] = useState<{ bc: IClientBonCommandeModel }>()

     function handleSubmitAddBonCommandeCallback(val: IClientBonCommandeModel) {
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              item.clientPropalPeriodes?.map(item_ => {
                                   if (item_.id === periode.id) {
                                        item_.clientBonCommandes = [val, ...(item_.clientBonCommandes || [])]
                                        return item_
                                   }
                              })
                         })
                         return prev
                    }
                    return prev
               }
          )

          setIsAddBonCommandeModalShown(false)
     }

     function handleSubmitAddBonLivraisonCallback(val: IClientBonLivraisonModel, livraisonTotale?: boolean) {
          // Add BL among BL's in Périodes, BC, BL list
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              item.clientPropalPeriodes?.map(item_ => {
                                   item_.clientBonCommandes?.map(item__ => {
                                        if (item__.id === addBonLivraisonToBonCommandeModal?.bc.id) {
                                             item__.clientBonLivraisons = [...(item__.clientBonLivraisons || []), val]
                                             return item__
                                        }
                                   })
                              })
                         })
                         return prev
                    }
                    return prev
               }
          )

          // Callback
          context.handleSubmitAddBonLivraisonCallback(val, livraisonTotale)

          // Close modal
          setAddBonLivraisonToBonCommandeModal(undefined)
     }

     function handleRemoveBonCommande(id: number) {
          swal.fire({
               icon: "warning",
               text: `Vous-vous vraiment supprimer le bon de commande?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteBonCommandeRequest(id)
                         .then(() => {
                              queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
                                   (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                                        if (prev?.data) {
                                             prev.data.map(item => {
                                                  item.clientPropalPeriodes?.map(item_ => {
                                                       item_.clientBonCommandes?.map(item__ => {
                                                            if (item__.id === id) {
                                                                 item_.clientBonCommandes = item_.clientBonCommandes?.filter(item___ => item___.id !== id)
                                                            }
                                                       })
                                                       return item_
                                                  })
                                             })
                                             return prev
                                        }
                                        return prev
                                   }
                              )
                              toast.info("Le bon de commande a bien été supprimé")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function handleSubmitEditBonCommandeCallback(val: IClientBonCommandeModel) {
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              item.clientPropalPeriodes?.map(item_ => {
                                   item_.clientBonCommandes!.map((item__, index__) => {
                                        if (item__.id === editBonCommandeInModal?.id) {
                                             item_.clientBonCommandes![index__] = {
                                                  ...item_.clientBonCommandes![index__],
                                                  ...val,
                                             }
                                        }
                                   })
                                   return item_
                              })
                         })
                    }
                    return prev
               }
          )

          setEditBonCommandeInModal(undefined)
     }

     return (
          <div className={"p-4 border border-dashed border-primary mb-4"}>
               <div className="d-flex justify-content-between">
                    <div className={"mb-2"}>
                         <div className={"fs-5 text-primary fw-bold"}>Bons de commande</div>
                    </div>
                    <div>
                         <MyTooltip title={"Ajouter un bon de commande / accord client"}>
                              <span className={"fas fa-plus-circle text-primary fs-3 cursor-pointer"} onClick={() => setIsAddBonCommandeModalShown(true)} />
                         </MyTooltip>
                    </div>
               </div>
               <div className="separator separator-dashed border-primary mb-6" />
               {periode.clientBonCommandes?.map((bonCommande, bc_key) => (
                    <div className={"p-4 border border-dashed border-primary mb-4"} key={bc_key}>
                         <div className="d-flex justify-content-between">
                              <div className={"mb-2"}>
                                   <div
                                        className={"fs-6 fw-bold text-primary d-flex align-items-center cursor-pointer text-hover-dark"}
                                        onClick={() =>
                                             setCurrentBonCommandeInModal({
                                                  bc: bonCommande,
                                                  loading: true,
                                             })
                                        }
                                   >
                                        {bonCommande.typeLabel} <span className={"fas fa-file-pdf ms-2 fs-2 text-hover-dark cursor-pointer"} />
                                   </div>
                                   <div className={"text-muted fs-7 fw-normal fst-italic"}>
                                        {GlobalHelper.firstLetterUppercase(bonCommande.dateDebutValidite?.format("D MMM YYYY"))} -{" "}
                                        {bonCommande.dateFinValidite?.format("D MMM YYYY")}
                                   </div>
                              </div>
                              <div>
                                   <MyTooltip title={"Supprimer"}>
                                        <span className={"fas fa-trash text-primary fs-3 cursor-pointer me-2"} onClick={() => handleRemoveBonCommande(bonCommande.id as number)} />
                                   </MyTooltip>
                                   <MyTooltip title={"Modifier"}>
                                        <span className={"fas fa-edit text-primary fs-3 cursor-pointer"} onClick={() => setEditBonCommandeInModal(bonCommande)} />
                                   </MyTooltip>
                                   <MyTooltip title={ptf_type === "TYPE_FORFAIT" ? "Ajouter un livrable" : "Ajouter un bon de livraison"}>
                                        <span
                                             className={"fas fa-plus-circle text-warning fs-3 cursor-pointer ms-3"}
                                             onClick={() => setAddBonLivraisonToBonCommandeModal({ bc: bonCommande })}
                                        />
                                   </MyTooltip>
                              </div>
                         </div>
                         <div className="separator separator-dashed border-primary mb-6" />

                         {/* BONS DE LIVRAISON */}
                         <PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsLivraison bonCommande={bonCommande} ptf_type={ptf_type} />
                    </div>
               ))}

               {/* Modal add BC*/}
               {isAddBonCommandeModalShown && (
                    <MyModal title={<span>{periode.reference} - Ajout d'un bon de commande</span>} show={true} handleClose={() => setIsAddBonCommandeModalShown(false)} size={"lg"}>
                         <PtfPeriodeAddBonCommandeForm ptf_periode_ID={periode.id as number} handleSubmitCallback={handleSubmitAddBonCommandeCallback} />
                    </MyModal>
               )}

               {/* Modal add BL*/}
               {addBonLivraisonToBonCommandeModal && (
                    <MyModal
                         title={
                              <span>
                                   {periode.reference} - Ajout d'un {ptf_type === "TYPE_ASSISTANCE_TECHNIQUE" ? "bon de livraison" : "livrable"}
                              </span>
                         }
                         show={true}
                         handleClose={() => setAddBonLivraisonToBonCommandeModal(undefined)}
                         size={"lg"}
                    >
                         {ptf_type === "TYPE_ASSISTANCE_TECHNIQUE" ? (
                              <PtfPeriodeBonCommandeAddBonLivraisonForm
                                   bc_ID={addBonLivraisonToBonCommandeModal.bc!.id as number}
                                   handleSubmitCallback={handleSubmitAddBonLivraisonCallback}
                              />
                         ) : (
                              <PtfPeriodeBonCommandeAddLivrableForm
                                   bc_ID={addBonLivraisonToBonCommandeModal.bc!.id as number}
                                   handleSubmitCallback={handleSubmitAddBonLivraisonCallback}
                              />
                         )}
                    </MyModal>
               )}

               {/* Modal view Bon commande */}
               {currentBonCommandeInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={currentBonCommandeInModal.bc.googleDriveID as string}
                         googleDriveMimetype={currentBonCommandeInModal.bc.googleDriveMimeType as string}
                         modalTitle={<>{currentBonCommandeInModal.bc.typeLabel}</>}
                         handleCloseModal={() => setCurrentBonCommandeInModal(undefined)}
                         modalSize={"xl"}
                    />
               )}

               {/* Modal edit BC */}
               {editBonCommandeInModal && (
                    <MyModal
                         title={
                              <>
                                   Bon de commande du {editBonCommandeInModal.dateDebutValidite?.format("D MMM YYYY")} au{" "}
                                   {editBonCommandeInModal.dateFinValidite?.format("D MMM YYYY")}
                              </>
                         }
                         show={true}
                         handleClose={() => setEditBonCommandeInModal(undefined)}
                         size={"lg"}
                    >
                         <PtfPeriodeBonCommandeEditForm bc={editBonCommandeInModal} handleSubmitCallback={handleSubmitEditBonCommandeCallback} />
                    </MyModal>
               )}
          </div>
     )
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommandePropsModel {
     periode: IClientPropalPeriodeModel
     ptf_type: "TYPE_FORFAIT" | "TYPE_ASSISTANCE_TECHNIQUE" | string
}

export default PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommande
