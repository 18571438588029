import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IAddVersionDocumentPropalRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { IClientPropalPeriodeModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { addVersionDocumentPropalRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"

interface IPtfPeriodeAjoutVersionDocumentFormPropsModel {
     ptf_periode_ID: number
     handleSubmitCallback?: (periode: IClientPropalPeriodeModel) => void
     nextReference: string
     currentVersionRef: string
}

const PtfPeriodeAjoutVersionDocumentForm = ({
     ptf_periode_ID,
     handleSubmitCallback = () => {},
     nextReference,
     currentVersionRef,
}: IPtfPeriodeAjoutVersionDocumentFormPropsModel) => {
     const [uploadedOrGeneratedVersion, setUploadedOrGeneratedVersion] = useState<{
          googleDriveID: string
          loading: boolean
     }>()

     // Form initial values
     const initialValues: IAddVersionDocumentPropalRequestModel = {
          nextReference,
          currentVersionRef,
     }

     // Formik handle submit
     function handleSubmit(values: IAddVersionDocumentPropalRequestModel, helpers: FormikHelpers<IAddVersionDocumentPropalRequestModel>) {
          helpers.setStatus(null)

          addVersionDocumentPropalRequest(ptf_periode_ID, values)
               .then(r => {
                    setUploadedOrGeneratedVersion({ googleDriveID: r.data.googleDriveId as string, loading: true })
                    toast.success(
                         values.documentGeneratedOrSaisieManuelle === "GENERATION"
                              ? "La nouvelle version a bien été générée, vous pouvez commencer à l'éditer en ligne"
                              : "La nouvelle version a bien envoyée. Vous pouvez continuer à l'éditer dans l'encadré ou fermer si vous avez terminé."
                    )
                    handleSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Once form submited. The next version of ODM appears and can be edited
     if (uploadedOrGeneratedVersion) {
          return (
               <MyLoadingOverlay isActive={uploadedOrGeneratedVersion.loading}>
                    <iframe
                         onLoad={() => setUploadedOrGeneratedVersion(prev => (prev ? { ...prev, loading: false } : undefined))}
                         src={FileAndDriveHelper.getUrlOfDriveFileEditMode(uploadedOrGeneratedVersion.googleDriveID)}
                         className={"vh-100 w-100"}
                    />
               </MyLoadingOverlay>
          )
     }

     // Form
     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <h5 className={"mb-4"}>Choisissez parmi les deux options</h5>
                                   <div className="mb-2">
                                        <MyRadioWithLabelAndDescriptionField
                                             name={"documentGeneratedOrSaisieManuelle"}
                                             value={"SAISIE"}
                                             isChecked={helpers.values.documentGeneratedOrSaisieManuelle === "SAISIE"}
                                             label={"Saisie manuelle"}
                                             description={"Joignez votre propre version depuis votre ordinateur"}
                                             iconFontAwesomeClassName={"upload"}
                                             color={"primary"}
                                             isInvalid={!!helpers.errors.documentGeneratedOrSaisieManuelle}
                                             onChange={val => helpers.setFieldValue("documentGeneratedOrSaisieManuelle", val)}
                                        />
                                   </div>
                                   <MyRadioWithLabelAndDescriptionField
                                        name={"documentGeneratedOrSaisieManuelle"}
                                        value={"GENERATION"}
                                        isChecked={helpers.values.documentGeneratedOrSaisieManuelle === "GENERATION"}
                                        label={"Génération automatique (recommandé)"}
                                        description={"Générer la nouvelle version à partir de la dernière version du document"}
                                        iconFontAwesomeClassName={"bolt"}
                                        color={"success"}
                                        isInvalid={!!helpers.errors.documentGeneratedOrSaisieManuelle}
                                        onChange={val => helpers.setFieldValue("documentGeneratedOrSaisieManuelle", val)}
                                   />

                                   <div className="separator mb-5"></div>

                                   {helpers.values.documentGeneratedOrSaisieManuelle && (
                                        <>
                                             {/* Document */}
                                             {helpers.values.documentGeneratedOrSaisieManuelle === "SAISIE" && (
                                                  <>
                                                       <MyAlert type={"primary"} classNames={"mb-4"}>
                                                            <div>
                                                                 Assurer vous de bien utiliser la référence <span className={"fw-bold text-dark"}>{nextReference}</span> dans le
                                                                 document que vous allez joindre.
                                                            </div>
                                                       </MyAlert>
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez un document</label>

                                                            <div className="col-lg-8">
                                                                 <MyFileField
                                                                      name={"document"}
                                                                      isInvalid={!!helpers.errors.document}
                                                                      onChange={val => helpers.setFieldValue("document", val)}
                                                                 />
                                                                 {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                            </div>
                                                       </div>
                                                  </>
                                             )}

                                             {helpers.values.documentGeneratedOrSaisieManuelle === "GENERATION" && (
                                                  <MyAlert type={"primary"} classNames={"mb-4"}>
                                                       <div>
                                                            Après avoir cliqué sur le bouton "Générer", un document word s'affichera, vous permettant ainsi de le modifier. Assurez
                                                            tout de même d'utiliser la référence: <span className={"fw-bold text-dark"}>{nextReference}</span>.
                                                       </div>
                                                  </MyAlert>
                                             )}
                                             <div className="row mb-5">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6">Commentaire</label>
                                                  <div className="col-lg-8">
                                                       <MyTextareaField
                                                            name={"commentaire"}
                                                            value={helpers.values.commentaire}
                                                            isInvalid={!!helpers.errors.commentaire}
                                                            placeholder={"Commentaire (optionnel)"}
                                                            onChange={value => helpers.setFieldValue("commentaire", value)}
                                                       />
                                                       {helpers.errors.commentaire && <div className="text-danger">{helpers.errors.commentaire}</div>}
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        {helpers.values.documentGeneratedOrSaisieManuelle && (
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? (
                                                       <>
                                                            {helpers.values.documentGeneratedOrSaisieManuelle === "GENERATION" ? (
                                                                 <>
                                                                      Générer <i className={"fas fa-bolt fs-4 ms-1"} />
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      Envoyer <i className={"fas fa-upload fs-4 ms-1"} />
                                                                 </>
                                                            )}
                                                       </>
                                                  ) : (
                                                       <MySimpleSpinner size={"sm"} />
                                                  )}
                                             </button>
                                        )}
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

export default PtfPeriodeAjoutVersionDocumentForm
