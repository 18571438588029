import React from "react"
import "./styles.scss"
import MyModal from "@common-utils/MyModal"
import MyGoogleDriveFileViewer from "./MyGoogleDriveFileViewer"

const MyGoogleDriveFileViewerInModal = ({
     googleDriveID,
     googleDriveMimetype,
     modalTitle,
     modalSize = "lg",
     modalFullScreen,
     handleCloseModal,
     onDocumentLoadCallback = () => {},
}: IMyDocumentViewerInModalPropsModel) => {
     return (
          <MyModal title={modalTitle} show={true} handleClose={() => handleCloseModal()} noPadding size={modalSize} fullscreen={modalFullScreen}>
               <MyGoogleDriveFileViewer googleDriveID={googleDriveID} googleDriveMimetype={googleDriveMimetype} onDocumentLoadCallback={onDocumentLoadCallback} />
          </MyModal>
     )
}

interface IMyDocumentViewerInModalPropsModel {
     onDocumentLoadCallback?: () => void
     googleDriveID: string
     googleDriveMimetype: string
     modalTitle: JSX.Element
     modalSize?: "sm" | "lg" | "xl"
     modalFullScreen?: true
     handleCloseModal: () => void
}

export default MyGoogleDriveFileViewerInModal
