import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ReactNode } from "react"
import { ObjectSchema } from "yup"
import useFormik from "./useFormik"

const MyCheckboxFormField = ({ label, name, text, formikProps, classNameLayout, classNameLabel, classNameField, validationSchema, onChangeCallback = () => {} }: IPropsModel) => {
     const { isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                         "form-check-danger": isFieldOnError(formikProps, name),
                    })}
               >
                    <div className={"form-check form-check-custom form-check-solid"}>
                         <input
                              {...formikProps.getFieldProps(name)}
                              type="checkbox"
                              className={clsx("form-check-input cursor-pointer border border-secondary", {
                                   "border-danger": isFieldOnError(formikProps, name),
                              })}
                              checked={formikProps.getFieldProps(name).value}
                              onChange={val => {
                                   formikProps.setFieldValue(name, val.target.checked)
                                   onChangeCallback(val.target.checked)
                              }}
                              id={name}
                         />
                         {text && (
                              <label className="form-check-label" htmlFor={name}>
                                   {text}
                              </label>
                         )}
                    </div>
                    {isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     text?: ReactNode
     formikProps: FormikProps<any>
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     validationSchema?: ObjectSchema<any>
     onChangeCallback?: (val: boolean) => void
}

export default MyCheckboxFormField
