import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ReactNode } from "react"
import { ObjectSchema } from "yup"
import useFormik from "./useFormik"

const MyInputFormField = ({
     label,
     name,
     formikProps,
     placeholder,
     autoComplete = "off",
     inputMode = "text",
     classNameLayout,
     classNameLabel,
     classNameField,
     type = "text",
     autoFocus,
     validationSchema,
     footerRightSide,
     inputRightSideIcon,
     onChangeCallback = () => {},
}: IPropsModel) => {
     const { isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900 d-flex align-items-center", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <div className={"input-group"}>
                         <input
                              {...formikProps?.getFieldProps(name)}
                              type={type}
                              inputMode={inputMode}
                              placeholder={placeholder}
                              autoComplete={autoComplete}
                              className={clsx("form-control bg-transparent", { "border-danger": formikProps && isFieldOnError(formikProps, name) })}
                              autoFocus={autoFocus}
                              value={
                                   formikProps?.getFieldProps(name).value === undefined || formikProps.getFieldProps(name).value === null
                                        ? ""
                                        : formikProps.getFieldProps(name).value
                              }
                              onChange={val => {
                                   if (type === "number" && /[0-9.,]/.test(val.target.value)) {
                                        const parsedFloat = parseFloat(val.target.value)
                                        formikProps?.setFieldValue(name, parsedFloat)
                                        onChangeCallback(parsedFloat)
                                   } else {
                                        formikProps?.setFieldValue(name, val.target.value)
                                   }
                              }}
                         />
                         {inputRightSideIcon && (
                              <span className={clsx("input-group-text", { "border-danger": formikProps && isFieldOnError(formikProps, name) })}>
                                   <i className={`fas fa-${inputRightSideIcon}`} />
                              </span>
                         )}
                    </div>
                    {(formikProps || footerRightSide) && (
                         <div className="row">
                              <div className="col text-start">
                                   {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
                              </div>
                              {footerRightSide && <div className="col text-end">{footerRightSide}</div>}
                         </div>
                    )}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     placeholder: string
     type?: "text" | "number" | "email" | "password"
     inputMode?: "text" | "numeric" | "tel" | "email" | "decimal"
     autoComplete?: "on" | "off"
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     validationSchema?: ObjectSchema<any>
     footerRightSide?: ReactNode
     inputRightSideIcon?: string
     onChangeCallback?: (val: any) => void
}

export default MyInputFormField
