import { toast } from "react-toastify"

import axios, { AxiosError } from "axios"
import React, { useEffect, useRef, useState } from "react"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import { useQueryClient } from "react-query"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import * as Yup from "yup"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import { IEditConnectedUserNdfDepenseGroupedRequestModel } from "../../../../mes-activites/ndf/core/_models"
import { IHttpErrorResponseModel, IPersonnelNdfDetailModel, IPersonnelNdfDocumentModel } from "@common-models/*"
import {
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MONTANT_INDEMNITE_PAR_KM,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import MyGoogleDriveFileViewerInModal from "../../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import {
     addPersonnelNdfJustificatifRequest,
     deletePersonnelNdfJustificatifRequest,
     editPersonnelDepenseGroupedRequest,
     replacePersonnelNdfJustificatifRequest,
} from "../../core/_requests"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyDateDayMonthYearFormField from "@common-components/fields/MyDateDayMonthYearFormField"
import MyInputFormField from "@common-components/fields/MyInputFormField"
import MySelectFormField from "@common-components/fields/MySelectFormField"
import MySelectCreatableFormField from "@common-components/fields/MySelectCreatableFormField"
import MyTextareaFormField from "@common-components/fields/MyTextareaFormField"
import MySelectSearchDataFromServerFormField from "@common-components/fields/MySelectSearchDataFromServerFormField"
import {
     CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT,
     CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT,
} from "../../../../../../endpoints"
import MyCheckboxFormField from "@common-components/fields/MyCheckboxFormField"

const ConnectedUserNdfDepenseGroupedEdit = ({ detail }: IConnectedUserNdfDepenseGroupedEditPropsModel) => {
     const queryClient = useQueryClient()
     const context = useConnectedUserNdfDepensesContext()
     const [docToBeReplaced, setDocToBeReplaced] = useState<IPersonnelNdfDocumentModel>()
     const refReplaceDocInput = useRef<any>(null)
     const refAddDocInput = useRef<any>(null)
     const MySwal = withReactContent(Swal)

     const [justificatifInModal, setJustificatifInModal] = useState<IPersonnelNdfDocumentModel>()
     const [detailsIndemniteKm, setDetailsIndemniteKm] = useState<
          Record<
               number,
               {
                    distanceEnKmEntreDepartEtDestination: number
                    distanceTotal: number
                    montantTTC: number
               }
          >
     >({})

     const validationSchema = Yup.object().shape({
          depenses: Yup.array().of(
               Yup.object().shape({
                    date: Yup.string().required("Champ requis").nullable(),
                    numeroTicket: Yup.number().required("Champ requis").typeError("Format incorrect.").nullable(),
                    categorie: Yup.string().required("Champ requis").nullable(),
                    description: Yup.string().required().label(`"Description"`),
                    montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect.").nullable(),
                    repasSeulOuEnGroupe: Yup.string()
                         .label(`"Seul ou en groupe"`)
                         .when("categorie", {
                              is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                              then: schema => schema.required(),
                         })
                         .nullable(),
                    invites: Yup.array()
                         .label(`"Invités"`)
                         .when("repasSeulOuEnGroupe", {
                              is: "REPAS_MIDI_EN_GROUPE",
                              then: schema => schema.required(),
                         })
                         .nullable(),
               })
          ),
     })

     const initialValues: IEditConnectedUserNdfDepenseGroupedRequestModel = {
          depenses: detail.enfants!.map(item => ({
               id: item.id as number,
               date: item.date!.format("YYYY-MM-DD") as string,
               categorie: item.categorie as string,
               montantTTC: Number(item.montantTtc!.toFixed(2)),
               description: item.description || "",
               numeroTicket: item.numeroTicket as string,
               repasSeulOuEnGroupe: item.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI ? (item.invites ? "REPAS_MIDI_EN_GROUPE" : "REPAS_MIDI_SEUL") : undefined,
               invites: item.invites || undefined,
               adresseDepart: item.indemniteKmDetails?.addresseDepart || "",
               adresseArrive: item.indemniteKmDetails?.addresseArrive || "",
               nbTrajets: item.indemniteKmDetails?.nbTrajets,
               isAllerRetour: item.indemniteKmDetails?.isAllerRetour,
          })),
     }

     function handleAddDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Ajout en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               addPersonnelNdfJustificatifRequest(detail.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                              [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                              (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                   if (prev) {
                                        prev.map(item => {
                                             if (item.id === detail.id) {
                                                  item.personnelNdfDocuments = [...(item.personnelNdfDocuments || []), r.data]
                                                  return item
                                             }
                                        })
                                   }

                                   return prev
                              }
                         )

                         toast.dismiss()
                         toast.success("Le justificatif a bien été ajouté")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleReplaceDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               FileAndDriveHelper.processFiles([file_binary]).then(processedFiles => {
                    replacePersonnelNdfJustificatifRequest(docToBeReplaced?.id as number, processedFiles[0])
                         .then(r => {
                              queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                                   [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                                   (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                        if (prev) {
                                             prev.map(item => {
                                                  if (item.id === detail.id) {
                                                       item.personnelNdfDocuments?.map(item_ => {
                                                            if (item_.id === docToBeReplaced?.id) {
                                                                 item_.googleDriveID = r.data.googleDriveID
                                                                 item_.googleDriveMimeType = r.data.googleDriveMimeType
                                                                 return item_
                                                            }
                                                       })
                                                  }
                                             })
                                        }

                                        return prev
                                   }
                              )

                              toast.dismiss()
                              toast.success("Le justificatif a bien été remplacé")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e?.response?.data.detail, { autoClose: false })
                         })
               })
          }
     }

     function handleRemoveDoc(id: number, index: number) {
          MySwal.fire({
               icon: "warning",
               title: `Confirmez-vous la suppression du justificatif n°${index}?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deletePersonnelNdfJustificatifRequest(id)
                         .then(() => {
                              queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                                   [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                                   (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                        if (prev) {
                                             prev.map(item => {
                                                  if (item.id === detail.id) {
                                                       item.personnelNdfDocuments = item.personnelNdfDocuments?.filter(item_ => item_.id !== id)
                                                  }
                                             })
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Le justificatif a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !MySwal.isLoading(),
          }).then()
     }

     function handleSubmit(values: IEditConnectedUserNdfDepenseGroupedRequestModel, helpers: FormikHelpers<IEditConnectedUserNdfDepenseGroupedRequestModel>) {
          helpers.setStatus(null)
          editPersonnelDepenseGroupedRequest(detail.id as number, values)
               .then(r => {
                    queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                         [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                         (prev: IPersonnelNdfDetailModel[] | undefined) => {
                              if (prev) {
                                   const index = prev.findIndex(item => item.id === detail.id)
                                   if (index >= 0) {
                                        prev[index] = {
                                             ...prev[index],
                                             ...r.data,
                                        }
                                   }
                              }
                              context.computeTotalExpenses(prev || [])

                              return prev
                         }
                    )

                    helpers.setSubmitting(false)
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setCurrentDepenseInEdition(undefined)
               })
               .catch(e => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)

                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     useEffect(() => {
          detail.enfants!.map((item, index) => {
               if (item.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && item.indemniteKmDetails) {
                    setDetailsIndemniteKm(prev => ({
                         ...prev,
                         [index]: {
                              distanceEnKmEntreDepartEtDestination: item.indemniteKmDetails?.distanceKmEntreDepartEtArrive!,
                              distanceTotal: item.indemniteKmDetails?.distanceKmTotal!,
                              montantTTC: Number((item.indemniteKmDetails?.distanceKmTotal! * CONST_MONTANT_INDEMNITE_PAR_KM).toFixed(2)),
                         },
                    }))
               }
          })
     }, [])

     return (
          <>
               <div className={"p-4 border border-dashed mb-10"}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                         <h4>Justificatifs</h4>
                         <MyTooltip title={"Ajouter un justificatif"}>
                              <div className="btn btn-icon btn-sm btn-active-light-success" onClick={() => refAddDocInput.current.click()}>
                                   <i className={"fas fa-plus-circle fs-2 text-success"} />
                                   <input type="file" className={"d-none"} ref={refAddDocInput} onChange={handleAddDoc} />
                              </div>
                         </MyTooltip>
                    </div>
                    <div className="separator separator-dashed mb-6" />

                    {detail.personnelNdfDocuments?.map((item, key) => (
                         <div className={`d-flex align-items-center ${key > 0 && "mt-4"}`} key={key}>
                              <span className={"me-2"}>Justificatif n° {key + 1}: </span>
                              <button className={"btn btn-sm btn-primary p-0 px-2 py-1 d-flex align-items-center me-2"} onClick={() => setJustificatifInModal(item)}>
                                   Consulter <i className={"fas fa-eye ms-1"} />
                              </button>
                              <button
                                   className={"btn btn-sm btn-warning p-0 px-2 py-1 d-flex align-items-center me-2"}
                                   onClick={() => {
                                        setDocToBeReplaced(item)
                                        refReplaceDocInput.current.click()
                                   }}
                              >
                                   Remplacer <i className={"fas fa-sync ms-1"} />
                              </button>
                              <input type="file" className={"d-none"} ref={refReplaceDocInput} onChange={handleReplaceDoc} />
                              <button className={"btn btn-sm btn-danger p-0 px-2 py-1 d-flex align-items-center me-2"} onClick={() => handleRemoveDoc(item.id as number, key + 1)}>
                                   Supprimer <i className={"fas fa-trash ms-1"} />
                              </button>
                         </div>
                    ))}
               </div>

               <div className={"p-4 border border-dashed mb-10"}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                         <h4>Détails des dépenses</h4>
                    </div>
                    <div className="separator separator-dashed mb-6" />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                         {helpers => {
                              async function calculateMontantIndemniteKmEntreDepartEtDestination(index: number, departure?: string, arrival?: string) {
                                   if (departure && arrival) {
                                        return axios
                                             .post<{ distance: number | null }>(CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT, {
                                                  departure,
                                                  arrival,
                                             })
                                             .then(({ data: { distance } }) => {
                                                  if (distance) {
                                                       setDetailsIndemniteKm(prev => ({
                                                            ...prev,
                                                            [index]: {
                                                                 ...prev[index], // Preserve existing data for this index
                                                                 distanceEnKmEntreDepartEtDestination: distance,
                                                            },
                                                       }))
                                                  }

                                                  return distance || 0
                                             })
                                   }

                                   return 0
                              }

                              function calculateMontantIndemniteKmTotal(
                                   index: number,
                                   distanceEnKmEntreDepartEtDestination: number,
                                   isAllerRetour: boolean,
                                   nbTrajets: number = 0
                              ) {
                                   if (distanceEnKmEntreDepartEtDestination > 0) {
                                        const dt = Number((distanceEnKmEntreDepartEtDestination * (isAllerRetour ? nbTrajets * 2 : nbTrajets)).toFixed(2))
                                        setDetailsIndemniteKm(prev => ({
                                             ...prev,
                                             [index]: {
                                                  ...prev[index], // Keep existing properties
                                                  distanceTotal: dt,
                                                  montantTTC: Number((dt * CONST_MONTANT_INDEMNITE_PAR_KM).toFixed(2)),
                                             },
                                        }))
                                   }
                              }

                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}
                                        <FieldArray name={"depenses"}>
                                             {({ push, remove }) => (
                                                  <>
                                                       <div className={"row"}>
                                                            {helpers.values.depenses
                                                                 .slice(0)
                                                                 .reverse()
                                                                 .map((_, index) => {
                                                                      return (
                                                                           <div className={"col-lg-6 col-xl-4 mb-4 d-flex flex-column gap-4"} key={index}>
                                                                                <div className="fw-bolder fs-7 text-gray-500">Dépense n°{index + 1}</div>

                                                                                <MyDateDayMonthYearFormField
                                                                                     formikProps={helpers}
                                                                                     validationSchema={validationSchema}
                                                                                     name={`depenses[${index}].date`}
                                                                                     placeholder={"Date"}
                                                                                />
                                                                                <MyInputFormField
                                                                                     formikProps={helpers}
                                                                                     validationSchema={validationSchema}
                                                                                     name={`depenses[${index}].numeroTicket`}
                                                                                     placeholder={"Numéro que vous avez inscrit"}
                                                                                     type={"number"}
                                                                                     autoComplete={"off"}
                                                                                     inputMode={"numeric"}
                                                                                />
                                                                                <MySelectFormField
                                                                                     formikProps={helpers}
                                                                                     validationSchema={validationSchema}
                                                                                     options={CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                                                     name={`depenses[${index}].categorie`}
                                                                                     isSearchable={false}
                                                                                     isClearable={false}
                                                                                     placeholder={"Catégorie"}
                                                                                />

                                                                                {helpers.values.depenses[index].categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                                                                     <>
                                                                                          <MySelectSearchDataFromServerFormField
                                                                                               formikProps={helpers}
                                                                                               validationSchema={validationSchema}
                                                                                               name={`depenses[${index}].adresseDepart`}
                                                                                               endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                                                               method={"GET"}
                                                                                               minimumLengthSearch={8}
                                                                                               placeholder={"Saisissez une adresse"}
                                                                                               defaultOptions={[
                                                                                                    {
                                                                                                         label: helpers.values.depenses[index].adresseDepart!,
                                                                                                         value: helpers.values.depenses[index].adresseDepart!,
                                                                                                    },
                                                                                               ]}
                                                                                               onChangeCallback={val => {
                                                                                                    calculateMontantIndemniteKmEntreDepartEtDestination(
                                                                                                         index,
                                                                                                         val as string,
                                                                                                         helpers.values.depenses[index].adresseArrive
                                                                                                    ).then(dist => {
                                                                                                         calculateMontantIndemniteKmTotal(
                                                                                                              index,
                                                                                                              dist,
                                                                                                              helpers.values.depenses[index].isAllerRetour!,
                                                                                                              helpers.values.depenses[index].nbTrajets
                                                                                                         )
                                                                                                    })
                                                                                               }}
                                                                                          />

                                                                                          <MySelectSearchDataFromServerFormField
                                                                                               formikProps={helpers}
                                                                                               validationSchema={validationSchema}
                                                                                               name={`depenses[${index}].adresseArrive`}
                                                                                               endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                                                               defaultOptions={[
                                                                                                    {
                                                                                                         label: helpers.values.depenses[index].adresseArrive!,
                                                                                                         value: helpers.values.depenses[index].adresseArrive!,
                                                                                                    },
                                                                                               ]}
                                                                                               method={"GET"}
                                                                                               minimumLengthSearch={8}
                                                                                               placeholder={"Saisissez une adresse"}
                                                                                               onChangeCallback={val => {
                                                                                                    calculateMontantIndemniteKmEntreDepartEtDestination(
                                                                                                         index,
                                                                                                         helpers.values.depenses[index].adresseDepart,
                                                                                                         val as string
                                                                                                    ).then(dist => {
                                                                                                         calculateMontantIndemniteKmTotal(
                                                                                                              index,
                                                                                                              dist,
                                                                                                              helpers.values.depenses[index].isAllerRetour!,
                                                                                                              helpers.values.depenses[index].nbTrajets
                                                                                                         )
                                                                                                    })
                                                                                               }}
                                                                                          />

                                                                                          <div className={"d-flex gap-2"}>
                                                                                               <MyInputFormField
                                                                                                    formikProps={helpers}
                                                                                                    validationSchema={validationSchema}
                                                                                                    name={`depenses[${index}].nbTrajets`}
                                                                                                    type={"number"}
                                                                                                    placeholder={"Nombre de trajets"}
                                                                                                    classNameLayout={"me-2"}
                                                                                                    onChangeCallback={val =>
                                                                                                         calculateMontantIndemniteKmTotal(
                                                                                                              index,
                                                                                                              detailsIndemniteKm[index].distanceEnKmEntreDepartEtDestination || 0,
                                                                                                              helpers.values.depenses[index].isAllerRetour!,
                                                                                                              val
                                                                                                         )
                                                                                                    }
                                                                                               />
                                                                                               <div className={"d-flex align-items-center"}>
                                                                                                    <MyCheckboxFormField
                                                                                                         formikProps={helpers}
                                                                                                         validationSchema={validationSchema}
                                                                                                         name={`depenses[${index}].isAllerRetour`}
                                                                                                         onChangeCallback={val => {
                                                                                                              calculateMontantIndemniteKmTotal(
                                                                                                                   index,
                                                                                                                   detailsIndemniteKm[index].distanceEnKmEntreDepartEtDestination ||
                                                                                                                        0,
                                                                                                                   val,
                                                                                                                   helpers.values.depenses[index].nbTrajets
                                                                                                              )
                                                                                                         }}
                                                                                                    />
                                                                                                    <div style={{ marginLeft: "-1rem" }}>Aller-retour</div>
                                                                                               </div>
                                                                                          </div>

                                                                                          <div className={"p-4 border border-dashed text-gray-600"}>
                                                                                               <h4 className={"mb-4 text-gray-600"}>
                                                                                                    Détails de l'indemnité kilométrique
                                                                                                    <i className="fas fa-info-circle fs-3 float-end" />
                                                                                               </h4>
                                                                                               <div className="separator separator-dashed mb-6" />

                                                                                               <p>Distance totale: {detailsIndemniteKm[index]?.distanceTotal.toFixed(2) || 0} Km</p>
                                                                                               <p>Montant TTC: {detailsIndemniteKm[index]?.montantTTC.toFixed(2) || 0}€</p>
                                                                                          </div>
                                                                                     </>
                                                                                )}

                                                                                {helpers.values.depenses[index].categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                                     <>
                                                                                          <MySelectFormField
                                                                                               formikProps={helpers}
                                                                                               validationSchema={validationSchema}
                                                                                               name={`depenses[${index}].repasSeulOuEnGroupe`}
                                                                                               options={[
                                                                                                    {
                                                                                                         value: "REPAS_MIDI_SEUL",
                                                                                                         label: "Repas seul",
                                                                                                    },
                                                                                                    {
                                                                                                         value: "REPAS_MIDI_EN_GROUPE",
                                                                                                         label: "Repas en groupe",
                                                                                                    },
                                                                                               ]}
                                                                                               placeholder={"Choisissez si seul ou en groupe"}
                                                                                          />

                                                                                          {helpers.values.depenses[index].repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                                                               <>
                                                                                                    <MyAlert type={"primary"}>
                                                                                                         Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un
                                                                                                         client, ajoutez le nom de la société en plus.
                                                                                                    </MyAlert>
                                                                                                    <MySelectCreatableFormField
                                                                                                         formikProps={helpers}
                                                                                                         validationSchema={validationSchema}
                                                                                                         name={`depenses[${index}].invites`}
                                                                                                         placeholder={"Saisissez les invités"}
                                                                                                    />
                                                                                               </>
                                                                                          )}
                                                                                     </>
                                                                                )}

                                                                                {helpers.values.depenses[index].categorie !== CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                                                                     <MyInputFormField
                                                                                          formikProps={helpers}
                                                                                          validationSchema={validationSchema}
                                                                                          name={`depenses[${index}].montantTTC`}
                                                                                          type={"number"}
                                                                                          inputMode={"decimal"}
                                                                                          placeholder={"Montant TTC"}
                                                                                     />
                                                                                )}

                                                                                <MyTextareaFormField
                                                                                     formikProps={helpers}
                                                                                     validationSchema={validationSchema}
                                                                                     name={`depenses[${index}].description`}
                                                                                     minRows={4}
                                                                                     placeholder={"Description"}
                                                                                />

                                                                                <div className={"d-flex justify-md-content-between flex-column flex-md-row"}>
                                                                                     {index > 0 && (
                                                                                          <button
                                                                                               className={`btn btn-light-danger btn-sm ${index > 0 && "me-2"}`}
                                                                                               type={"button"}
                                                                                               onClick={() => remove(index)}
                                                                                          >
                                                                                               Supprimer <i className={"fas fa-minus-circle"} />
                                                                                          </button>
                                                                                     )}

                                                                                     {index === helpers.values.depenses.length - 1 && (
                                                                                          <button
                                                                                               className={`btn btn-light-primary btn-sm mt-2 mt-md-0`}
                                                                                               type={"button"}
                                                                                               onClick={() =>
                                                                                                    push({
                                                                                                         date: undefined,
                                                                                                         numeroTicket: "",
                                                                                                         categorie: "",
                                                                                                         montantTTC: "",
                                                                                                         invites: undefined,
                                                                                                         description: undefined,
                                                                                                         dfsfdsf: "fdsf",
                                                                                                    })
                                                                                               }
                                                                                          >
                                                                                               Ajouter un autre détail <i className={"fas fa-plus-circle"} />
                                                                                          </button>
                                                                                     )}
                                                                                </div>
                                                                           </div>
                                                                      )
                                                                 })}
                                                       </div>
                                                  </>
                                             )}
                                        </FieldArray>

                                        <div className="separator separator-dashed border-primary mb-4" />

                                        <div className="text-center align-items-center">
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </div>

               {justificatifInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={justificatifInModal.googleDriveID as string}
                         googleDriveMimetype={justificatifInModal.googleDriveMimeType as string}
                         modalTitle={<span>Justificatif</span>}
                         handleCloseModal={() => setJustificatifInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConnectedUserNdfDepenseGroupedEditPropsModel {
     detail: IPersonnelNdfDetailModel
}

export default ConnectedUserNdfDepenseGroupedEdit
