import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../common/components/MyLoadingOverlay"
import { useDashboardAlertesPeriodesEssaiExpirationListingListContext } from "./List"
import { useFournisseurListingContext } from "./FournisseurListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { IItemResponseModel } from "./core/_models"
import GlobalHelper from "@common-helpers/GlobalHelper"

const Item = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useFournisseurListingContext()
     const listContext = useDashboardAlertesPeriodesEssaiExpirationListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.fournisseur?.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.fournisseur?.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.fournisseur?.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.fournisseur?.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.fournisseur?.logoGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item.fournisseur.logoGoogleDriveId)
                                                                 : toAbsoluteUrl("/media/svg/files/blank-image.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"fw-bold fs-5"}>{item.fournisseur.raisonSociale}</span>
                                             </div>
                                             <div>
                                                  <span className={"text-muted"}>{item.fournisseur.typeLabel}</span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        <span className={"badge badge-dark"}>{item.fournisseur.formeJuridiqueLabel}</span>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* SIRET */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-id-card fs-5 w-25px" />
                                                  {item.fournisseur.siret ? item.fournisseur.siret : <span className={"text-muted"}>Non renseigné</span>}
                                             </div>

                                             {/* Capital Social */}
                                             <div className={`d-flex align-items-center`}>
                                                  <span className="fas fa-coins fs-5 w-25px" />
                                                  {item.fournisseur.capitalSociale ? (
                                                       <>{GlobalHelper.separateNumber(item.fournisseur.capitalSociale)} €</>
                                                  ) : (
                                                       <span className={"text-muted"}>Non renseigné</span>
                                                  )}
                                             </div>
                                        </div>
                                        <div className="col text-end"></div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const ListItem = ({ item, index }: { item: IItemResponseModel; index: number }) => {
     const context = useFournisseurListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.fournisseur?.id === item.fournisseur?.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.fournisseur?.id === item.fournisseur?.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.fournisseur?.id !== item.fournisseur?.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <Item item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default ListItem
