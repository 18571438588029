import { usePersonnelDetailsContext } from "../PersonnelDetails"
import { Form, Formik, FormikHelpers } from "formik"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_PERSONNEL_CONTRATS_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_DEFAULT,
     CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ECOLE,
     CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT,
     CONST_PERSONNEL_STATUTS_OPTIONS_FOR_SELECT2,
} from "@common-constants/*"
import { IGetPersonnelDetailsResponseModel, ISetPersonnelInfoProRequestModel } from "../../core/_models"
import { setInfoPersoProRequest } from "../../core/_requests"
import { useQueryClient } from "react-query"
import * as Yup from "yup"
import MyAlert from "@common-utils/MyAlert"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import {
     CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT,
     CONST_API_UTILS_GET_AGENCES_ENDPOINT,
     CONST_API_UTILS_GET_ENTITES_ENDPOINT,
     CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT,
     CONST_API_UTILS_GET_METIERS_PERSONNEL_INTERNE_URL_ENDPOINT,
} from "../../../../endpoints"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { Dispatch, SetStateAction } from "react"
import MySelectField from "@common-utils/fields/MySelectField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"

const InfoProEdit = ({ setIsInfoProOnEdition }: IPropsModel) => {
     const queryClient = useQueryClient()
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data

     const schema = Yup.object().shape({
          statut: Yup.string().label(`"Statut"`).nullable().required(),
          contrat: Yup.string().label(`"Contrat"`).nullable().required(),
          emailPro: Yup.string().email().label(`"Email"`).nullable().required(),
          telPro: Yup.string().label(`"Tél."`).nullable().required(),
          telProIndicatif: Yup.number().label(`"Indicatif tél."`).nullable().required(),
          poste: Yup.number().label(`"Poste"`).nullable().required(),
          entite: Yup.number().label(`"Entité"`).nullable().required(),
          responsable: Yup.number().label(`"Responsable"`).nullable().required(),
          linkedinUrl: Yup.string().label(`"LinkedIn"`).nullable(),
          heuresTravail: Yup.number().label(`"Heures travail"`).required().nullable(),
          heuresSupp: Yup.number().label(`"Heures supp."`).required().nullable(),
          dateEntree: Yup.string().label(`"Date entrée"`).required().nullable(),
          optionLignesCra: Yup.array().label(`"Lignes CRA"`),
     })

     const initialValues: ISetPersonnelInfoProRequestModel = {
          statut: details?.personnel?.statut,
          agence: details?.personnel?.agence?.id,
          contrat: details?.personnel?.contrat,
          emailPro: details?.personnel?.mailProfessionnel,
          telPro: details?.personnel?.telProfessionnel,
          telProIndicatif: details?.personnel?.telProfessionnelIndicatif?.id,
          poste: details?.personnel?.personnelPostes && details?.personnel?.personnelPostes.length > 0 ? details?.personnel?.personnelPostes[0].poste?.id : undefined,
          entite: details?.personnel?.mainEntite?.id,
          responsable: details?.responsable?.id,
          linkedinUrl: details?.personnel?.linkedinUrl,
          heuresTravail: details?.personnel?.heuresTravail,
          heuresSupp: details?.personnel?.heuresTravailSupp,
          dateEntree: details?.personnel?.dateEntree?.format("YYYY-MM-DD"),
          optionLignesCra: details?.personnel?.typesLigneActiviteCraAutorises?.filter(item => item !== CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_DEFAULT),
     }

     function handleSubmit(values: ISetPersonnelInfoProRequestModel, helpers: FormikHelpers<ISetPersonnelInfoProRequestModel>) {
          helpers.setStatus(null)

          setInfoPersoProRequest(details?.personnel.id as number, values)
               .then(r => {
                    queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                         context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                         (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                              if (prev) {
                                   prev = {
                                        ...prev,
                                        ...r.data,
                                   }
                              }
                              return prev
                         }
                    )
                    setIsInfoProOnEdition(false)
                    toast.success("Les informations pro. ont bien été mises à jour.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })
               })
               .finally(() => {
                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         console.log(helpers.errors)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Statut</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"statut"}
                                                  value={helpers.values.statut}
                                                  options={CONST_PERSONNEL_STATUTS_OPTIONS_FOR_SELECT2}
                                                  isInvalid={!!helpers.errors.statut}
                                                  onChange={val => helpers.setFieldValue("statut", val)}
                                             />
                                             {helpers.errors.statut && <div className={"text-danger"}>{helpers.errors.statut}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Contrat</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"contrat"}
                                                  value={helpers.values.contrat}
                                                  options={CONST_PERSONNEL_CONTRATS_OPTIONS_FOR_SELECT2}
                                                  isInvalid={!!helpers.errors.contrat}
                                                  onChange={val => helpers.setFieldValue("contrat", val)}
                                             />
                                             {helpers.errors.contrat && <div className={"text-danger"}>{helpers.errors.contrat}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email pro.</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"emailPro"}
                                                  value={helpers.values.emailPro}
                                                  isInvalid={!!helpers.errors.emailPro}
                                                  onChange={val => helpers.setFieldValue("emailPro", val)}
                                                  placeholder={"Email"}
                                             />
                                             {helpers.errors.emailPro && <div className={"text-danger"}>{helpers.errors.emailPro}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MySelectGetAllDataFromServer
                                                            name={"telProIndicatif"}
                                                            value={helpers.values.telProIndicatif}
                                                            url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                            defaultOptions={
                                                                 details?.personnel?.telProfessionnelIndicatif
                                                                      ? [
                                                                             {
                                                                                  value: details?.personnel.telProfessionnelIndicatif.id as number,
                                                                                  label: details?.personnel.telProfessionnelIndicatif.phoneCodeWithPlusPrefixAndCountry as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            placeholder={"Choisissez un indicatif"}
                                                            isInvalid={!!helpers.errors.telProIndicatif}
                                                            onChange={val => helpers.setFieldValue("telProIndicatif", val)}
                                                       />
                                                       {helpers.errors.telProIndicatif && <div className={"text-danger"}>{helpers.errors.telProIndicatif}</div>}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInptuMaskField
                                                            name={"telPro"}
                                                            mask={"99 9999999999999999999999999"}
                                                            value={helpers.values.telPro}
                                                            isInvalid={!!helpers.errors.telPro}
                                                            placeholder={"Téléphone"}
                                                            onChange={e => helpers.setFieldValue("telPro", e.target.value)}
                                                       />
                                                       {helpers.errors.telPro && <div className={"text-danger"}>{helpers.errors.telPro}</div>}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Métier */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Métier</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"poste"}
                                                  value={helpers.values.poste}
                                                  defaultOptions={
                                                       details?.personnel.personnelPostes && details?.personnel.personnelPostes.length > 0
                                                            ? [
                                                                   {
                                                                        value: details?.personnel.personnelPostes[0].poste?.id as number,
                                                                        label: details?.personnel.personnelPostes[0].poste?.nom as string,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.poste}
                                                  onChange={val => helpers.setFieldValue("poste", val)}
                                                  method={"GET"}
                                                  url={CONST_API_UTILS_GET_METIERS_PERSONNEL_INTERNE_URL_ENDPOINT}
                                             />
                                             {helpers.errors.poste && <div className={"text-danger"}>{helpers.errors.poste}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Agence</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"agence"}
                                                  value={helpers.values.agence}
                                                  defaultOptions={
                                                       details?.personnel?.agence
                                                            ? [{ value: details?.personnel?.agence.id as number, label: details?.personnel?.agence.nom as string }]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.agence}
                                                  onChange={val => helpers.setFieldValue("agence", val)}
                                                  url={CONST_API_UTILS_GET_AGENCES_ENDPOINT}
                                             />
                                             {helpers.errors.agence && <div className={"text-danger"}>{helpers.errors.agence}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"entite"}
                                                  value={helpers.values.entite}
                                                  defaultOptions={
                                                       details?.personnel?.mainEntite
                                                            ? [{ value: details?.personnel?.mainEntite.id as number, label: details?.personnel?.mainEntite.nom as string }]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.entite}
                                                  onChange={val => helpers.setFieldValue("entite", val)}
                                                  url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                             />
                                             {helpers.errors.entite && <div className={"text-danger"}>{helpers.errors.entite}</div>}
                                        </div>
                                   </div>

                                   {/*Date entrée*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Date entrée</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateEntree"}
                                                  onChange={date => helpers.setFieldValue("dateEntree", date)}
                                                  value={helpers.values.dateEntree}
                                                  isInvalid={!!helpers.errors.dateEntree}
                                                  placeholder={"Date d'entrée"}
                                             />
                                             {helpers.errors.dateEntree && <div className={"text-danger"}>{helpers.errors.dateEntree}</div>}
                                        </div>
                                   </div>

                                   {/* Heures travail */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Heures travail / jour</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"heuresTravail"}
                                                  onChange={date => helpers.setFieldValue("heuresTravail", date)}
                                                  value={helpers.values.heuresTravail}
                                                  isInvalid={!!helpers.errors.heuresTravail}
                                                  placeholder={"Heures travail"}
                                                  type={"number"}
                                                  inputMode={"numeric"}
                                             />
                                             {helpers.errors.heuresTravail && <div className={"text-danger"}>{helpers.errors.heuresTravail}</div>}
                                        </div>
                                   </div>

                                   {/* Heures travail */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Heures supp. / jour</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"heuresSupp"}
                                                  onChange={date => helpers.setFieldValue("heuresSupp", date)}
                                                  value={helpers.values.heuresSupp}
                                                  isInvalid={!!helpers.errors.heuresSupp}
                                                  placeholder={"Heures supp."}
                                                  type={"number"}
                                                  inputMode={"numeric"}
                                             />
                                             {helpers.errors.heuresSupp && <div className={"text-danger"}>{helpers.errors.heuresSupp}</div>}
                                        </div>
                                   </div>

                                   {details?.responsable && (
                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Responsable direct</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"responsable"}
                                                       value={helpers.values.responsable}
                                                       defaultOptions={
                                                            details?.responsable
                                                                 ? [{ value: details?.responsable?.id as number, label: details?.responsable.prenomNom as string }]
                                                                 : []
                                                       }
                                                       method={"GET"}
                                                       payload={{ except_ids: [details?.personnel.id as number] }}
                                                       isInvalid={!!helpers.errors.responsable}
                                                       onChange={val => helpers.setFieldValue("responsable", val)}
                                                       url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                  />
                                                  {helpers.errors.responsable && <div className={"text-danger"}>{helpers.errors.responsable}</div>}
                                             </div>
                                        </div>
                                   )}

                                   <div className="separator separator-dashed my-4"></div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">LinkedIn URL</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"linkedinUrl"}
                                                  value={helpers.values.linkedinUrl}
                                                  isInvalid={!!helpers.errors.linkedinUrl}
                                                  onChange={val => helpers.setFieldValue("linkedinUrl", val)}
                                                  placeholder={"LinkedIn"}
                                             />
                                             {helpers.errors.linkedinUrl && <div className={"text-danger"}>{helpers.errors.linkedinUrl}</div>}
                                        </div>
                                   </div>

                                   {/* Options CRA */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Options lignes CRA</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"optionLignesCra"}
                                                  options={[
                                                       {
                                                            label: "TT",
                                                            value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT,
                                                       },
                                                       {
                                                            label: "École",
                                                            value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ECOLE,
                                                       },
                                                  ]}
                                                  value={helpers.values.optionLignesCra}
                                                  isInvalid={!!helpers.errors.optionLignesCra}
                                                  onChange={val => helpers.setFieldValue("optionLignesCra", val)}
                                                  isMulti
                                                  isSearchable
                                                  isClearable
                                             />
                                             {helpers.errors.optionLignesCra && <div className={"text-danger"}>{helpers.errors.optionLignesCra}</div>}
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5"></div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-active-secondary d-inline-flex align-items-center me-2"}
                                             type={"button"}
                                             onClick={() => setIsInfoProOnEdition(false)}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     setIsInfoProOnEdition: Dispatch<SetStateAction<boolean>>
}

export default InfoProEdit
