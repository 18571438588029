import React from "react"
import MyCard from "@common-utils/MyCard"
import { useQuery } from "react-query"
import { useAnnoncesListingContext } from "./AnnoncesListing"
import { OffreModel } from "@common-models/*"
import { AxiosError } from "axios"
import { detailsAnnonceRequest, generateTalentplugUrlEditionAnnonceRequest } from "../core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import moment from "moment/moment"
import { CONST_ANNONCE_ETAT_ARCHIVED } from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import { toast } from "react-toastify"
import { IGetAnnoncesResponseItemModel } from "./core/_models"
import { useNavigate } from "react-router-dom"

const DetailsAnnonce = () => {
     const globalContext = useAnnoncesListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.annonce.id === selectedItemInPreview?.annonce.id) as IGetAnnoncesResponseItemModel

     // Query
     const detailsQuery = useQuery<OffreModel, AxiosError>(["REACT_QUERY_KEY_GET_OFFER_DETAILS", itemInPreview], async () => {
          return detailsAnnonceRequest(itemInPreview.annonce.id as number)
               .then(r => r.data)
               .finally(() => {
                    globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : null))
               })
     })

     return (
          <>
               {/* loader */}
               {detailsQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!detailsQuery.isFetching && detailsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* With data */}
               {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                    <>
                         <div className="row mb-4">
                              <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Titre</label>

                              <div className="col-sm-8">
                                   <input
                                        className={"form-control form-control-sm form-control-solid"}
                                        value={detailsQuery.data.talentPlugOfferDTO?.general_informations?.job_title}
                                        readOnly
                                   />
                              </div>
                         </div>

                         <div className="row mb-4">
                              <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Réf. interne</label>

                              <div className="col-sm-8">
                                   <input className={"form-control form-control-sm form-control-solid"} value={itemInPreview.annonce.reference} readOnly />
                              </div>
                         </div>

                         <div className="row mb-4">
                              <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Réf. externe</label>

                              <div className="col-sm-8">
                                   <input className={"form-control form-control-sm form-control-solid"} value={itemInPreview.annonce.reference_talentplug} readOnly />
                              </div>
                         </div>

                         <div className="row mb-4">
                              <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Description du poste</label>

                              <div className="col-sm-8">
                                   <textarea
                                        className={"form-control form-control-sm form-control-solid"}
                                        rows={8}
                                        value={detailsQuery.data.talentPlugOfferDTO?.job?.job_description}
                                        readOnly
                                   />
                              </div>
                         </div>

                         <div className="row">
                              <label className="col-sm-4 col-form-label fw-bold fs-6 text-muted">Profil recherché</label>

                              <div className="col-sm-8">
                                   <textarea
                                        className={"form-control form-control-sm form-control-solid"}
                                        rows={8}
                                        value={detailsQuery.data.talentPlugOfferDTO?.profil?.applicant_profile}
                                        readOnly
                                   />
                              </div>
                         </div>
                    </>
               )}
          </>
     )
}

const ActionsIcons = () => {
     const navigate = useNavigate()
     const globalContext = useAnnoncesListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.annonce.id === selectedItemInPreview?.annonce.id) as IGetAnnoncesResponseItemModel

     const publishedAt = moment(itemInPreview.annonce.publishedAt)
     const dateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

     function handleGenerateUrlEditionTalentplug(e: any) {
          e.target.disabled = true
          toast.info("Redirection en cours ...", { autoClose: false })
          generateTalentplugUrlEditionAnnonceRequest(itemInPreview.annonce.id as number)
               .then(r => {
                    if (r.data.redirectUrl) {
                         window.location.href = r.data.redirectUrl
                    } else {
                         toast.error("Impossible d'éditer cette offre.")
                    }
               })
               .catch(e => {
                    toast.dismiss()
                    toast.error(e?.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {/* Si non différé */}
               {publishedAt.diff(dateToday, "days") <= 0 && itemInPreview.annonce.etat !== CONST_ANNONCE_ETAT_ARCHIVED && (
                    <>
                         <MyTooltip title={"Republier"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle me-2`}
                                   onClick={() => globalContext.setAnnoncesToRepublish([itemInPreview.annonce])}
                              >
                                   <i className="fas fa-globe fs-4" />
                              </button>
                         </MyTooltip>
                         <MyTooltip title={"Archiver"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle me-2`}
                                   onClick={() => globalContext.setAnnoncesToArchiver([itemInPreview.annonce])}
                              >
                                   <i className="fas fa-box-archive fs-4" />
                              </button>
                         </MyTooltip>
                    </>
               )}
               <MyTooltip title={"Changer de responsable"}>
                    <button
                         type={"button"}
                         className={`btn btn-outline btn-outline-primary btn-sm btn-icon rounded-circle me-2`}
                         onClick={() => globalContext.setAnnoncesChangementResponsable([itemInPreview.annonce])}
                    >
                         <i className="fas fa-user fs-4" />
                    </button>
               </MyTooltip>
               <MyTooltip title={"Editer sur le site partenaire"}>
                    <button type={"button"} className={`btn btn-outline btn-outline-warning btn-sm btn-icon rounded-circle me-2`} onClick={handleGenerateUrlEditionTalentplug}>
                         <i className="fas fa-edit fs-4" />
                    </button>
               </MyTooltip>
               <MyTooltip title={"Cloner"}>
                    <button
                         type={"button"}
                         className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle`}
                         onClick={() => navigate(`/recrutement/annonces/${itemInPreview.annonce.id}/clone`)}
                    >
                         <i className="fas fa-clone fs-4" />
                    </button>
               </MyTooltip>
          </>
     )
}

/* Wrapper */
const AnnoncesListingPreview = () => {
     const globalContext = useAnnoncesListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.annonce.id === selectedItemInPreview?.annonce.id)

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <div className="d-flex align-items-center">
                         <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                         <span className={"me-2"}>{itemInPreview?.annonce.intitule}</span>
                    </div>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <>
                         <div className={"d-flex justify-content-center p-5"}>
                              <div className={"w-100 w-md-75 w-xl-100 w-xxl-75"}>
                                   <DetailsAnnonce />
                              </div>
                         </div>
                         <div className="separator my-2" />
                         <div className={"d-flex justify-content-center mb-2"}>
                              <ActionsIcons />
                         </div>
                    </>
               </MyCard.Body>
          </MyCard>
     )
}

export default AnnoncesListingPreview
