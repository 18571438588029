import { IConsultantSuiviProlongementMissionNonSalarieRequestModel, IGetPrefillFormDataProlongementMissionRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { consultantSuiviProlongementMissionNonSalarieRequestModel, getPrefillFormDataProlongementMissionRequest } from "../core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { useQuery } from "react-query"
import * as Yup from "yup"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"

const ConsultantSuiviProlongementMissionNonSalarie = ({
     consultant_ID,
     onSubmitCallback = () => {},
     annulerCallback = () => {},
}: IConsultantSuiviProlongementMissionNonSalariePropsModel) => {
     const [generatedOrderMission, setGeneratedOrderMission] = useState<{
          loading: boolean
          googleDriveID: string
     }>()
     const prefillInfoQuery = useQuery<IGetPrefillFormDataProlongementMissionRequestModel, AxiosError>("REACT_QUERY_KEY_GET_PRE_FILL_INFO", () =>
          getPrefillFormDataProlongementMissionRequest(consultant_ID).then(r => r.data)
     )

     // Schema
     const schema = Yup.object().shape({
          dateDebut: Yup.string().required().nullable().label(`"Date de début"`),
          dateFin: Yup.string().required().nullable().label(`"Date de fin"`),
     })

     // Init values
     const initialValues: IConsultantSuiviProlongementMissionNonSalarieRequestModel = {
          dateDebut: prefillInfoQuery.data?.dateDebut?.format("YYYY-MM-DD"),
          dateFin: prefillInfoQuery.data?.dateFin?.format("YYYY-MM-DD"),
     }

     // Handle submit
     function handleSubmit(values: IConsultantSuiviProlongementMissionNonSalarieRequestModel, helpers: FormikHelpers<IConsultantSuiviProlongementMissionNonSalarieRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviProlongementMissionNonSalarieRequestModel(consultant_ID, values)
               .then(() => {
                    toast.success("Votre action a bien été prise en compte.")

                    onSubmitCallback()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     if (generatedOrderMission) {
          return (
               <MyLoadingOverlay isActive={generatedOrderMission.loading}>
                    <iframe
                         onLoad={() => setGeneratedOrderMission(prev => prev && { ...prev, loading: false })}
                         src={FileAndDriveHelper.getUrlOfDriveFileEditMode(generatedOrderMission.googleDriveID)}
                         className={"vh-100 w-100"}
                    />
               </MyLoadingOverlay>
          )
     } else {
          return (
               <>
                    {prefillInfoQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!prefillInfoQuery.isFetching && prefillInfoQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{prefillInfoQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => prefillInfoQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!prefillInfoQuery.isFetching && !prefillInfoQuery.isError && prefillInfoQuery.data && (
                         <>
                              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                   {helpers => {
                                        console.log(helpers.errors)
                                        return (
                                             <Form noValidate autoComplete="off">
                                                  {helpers.status && (
                                                       <MyAlert type={"danger"} classNames={"mb-4"}>
                                                            {helpers.status}
                                                       </MyAlert>
                                                  )}

                                                  {/* Période */}
                                                  <div className="row mb-5">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nouvelle période</label>

                                                       <div className="col-lg-8">
                                                            <div className="d-flex">
                                                                 <MyDateDayMonthYearField
                                                                      name={"dateDebut"}
                                                                      value={helpers.values.dateDebut}
                                                                      isInvalid={!!helpers.errors.dateDebut}
                                                                      maxDate={helpers.values.dateFin}
                                                                      onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                                      placeholder={"Date de début"}
                                                                      classNames={"rounded-0 rounded-start"}
                                                                 />
                                                                 <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                 <MyDateDayMonthYearField
                                                                      name={"dateFin"}
                                                                      value={helpers.values.dateFin}
                                                                      minDate={helpers.values.dateDebut}
                                                                      isInvalid={!!helpers.errors.dateFin}
                                                                      onChange={val => helpers.setFieldValue("dateFin", val)}
                                                                      placeholder={"Date de fin"}
                                                                      classNames={"rounded-0 rounded-end"}
                                                                 />
                                                            </div>
                                                            {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                                            {helpers.errors.dateFin && <div className={"text-danger"}>{helpers.errors.dateFin}</div>}
                                                       </div>
                                                  </div>

                                                  {/* Submit button */}
                                                  <div className={"d-flex justify-content-end"}>
                                                       <button
                                                            className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                            type={"button"}
                                                            onClick={annulerCallback}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            Annuler
                                                       </button>
                                                       <button
                                                            className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                            type={"submit"}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            {!helpers.isSubmitting ? <>Prolonger</> : <MySimpleSpinner size={"sm"} />}
                                                       </button>
                                                  </div>
                                             </Form>
                                        )
                                   }}
                              </Formik>
                         </>
                    )}
               </>
          )
     }
}

interface IConsultantSuiviProlongementMissionNonSalariePropsModel {
     consultant_ID: number
     onSubmitCallback?: () => void
     annulerCallback?: () => void
}

export default ConsultantSuiviProlongementMissionNonSalarie
