import React from "react"
import MyCard from "@common-utils/MyCard"
import { IConsultantMissionModel } from "@common-models/*"
import { useDashboardAlertesMissionsExpirationListingContext } from "./DashboardAlertesMissionsExpirationListing"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import "./styles.scss"
import MyTooltip from "@common-utils/MyTooltip"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"

/* Wrapper */
const DashboardAlertesMissionsExpirationListingPreview = () => {
     const globalContext = useDashboardAlertesMissionsExpirationListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.id === selectedItemInPreview.id) as IConsultantMissionModel

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <Link to={`/business/consultants/${itemInPreview.consultant?.id}/details`} target={"_blank"}>
                         {itemInPreview.consultant?.prenomNom}
                    </Link>{" "}
                    - {itemInPreview?.reference}
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyLoadingOverlay isActive={globalContext.itemInPreview?.isSelectedItemLoading as boolean}>
                         <>
                              <iframe
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(itemInPreview.googleDriveID as string)}
                                   style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                                   onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                              />
                              <div className={"vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                                   <MyTooltip title={"Prolonger la mission"} placement={"right"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-5 mb-sm-2"}
                                             onClick={() => {
                                                  itemInPreview.consultant?.contrat === "CONTRAT_ST"
                                                       ? globalContext.setItemEnProlongementMissionNonSalarieInModal(itemInPreview)
                                                       : globalContext.setItemEnProlongementMissionSalarieInModal({
                                                              item: itemInPreview,
                                                              modalNoPadding: false,
                                                              modalSize: "lg",
                                                         })
                                             }}
                                        >
                                             <i className={"fas fa-clock"} />
                                        </button>
                                   </MyTooltip>

                                   <MyTooltip title={"Changer de mission"} placement={"right"}>
                                        <span>
                                             <input type="file" className={"d-none"} />
                                             <button className="btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle" onClick={() => toast.info("En maintenance ...")}>
                                                  <i className="fas fa-sync" />
                                             </button>
                                        </span>
                                   </MyTooltip>
                              </div>
                         </>
                    </MyLoadingOverlay>
               </MyCard.Body>
          </MyCard>
     )
}

export default DashboardAlertesMissionsExpirationListingPreview
