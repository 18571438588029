import { IGetDetailsConsultant, ISetInfoPersoSalarieRequestModel } from "../../core/_models"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import * as Yup from "yup"
import {
     CONST_CIVILITE_MR,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
     CONST_PAYS_CODE_FRANCE,
     CONST_PAYS_ID_FRANCE,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyLoadingOverlay from "../../../../../../../common/components/MyLoadingOverlay"
import MySelectField from "@common-utils/fields/MySelectField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT, CONST_API_UTILS_GET_PAYS_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../../../endpoints"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { setConsultantInfoPersoSalarie } from "../../core/_requests"
import { useThemeMode } from "@common-metronic/partials"
import { useConsultantDetailsContext } from "../../ConsultantDetails"

const ConsultantDetailsInfoBaseFormInfoPersoSalarie = ({ consultantInfoPerso }: IConsultantDetailsInfoBaseFormInfoPersoPropsModel) => {
     const { mode } = useThemeMode()

     const context = useConsultantDetailsContext()

     const user = context.detailsQuery.data?.consultant.candidat?.user

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [avatarPreview, setAvatarPreview] = useState<any>(
          user?.avatarGoogleDriveId
               ? FileAndDriveHelper.getThumbnailDriveUrl(user.avatarGoogleDriveId)
               : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
     )

     const schema = Yup.object().shape({
          civilite: Yup.string().label(`"Civilité"`).nullable().required(),
          nom: Yup.string().label(`"Nom"`).nullable().required(),
          prenom: Yup.string().label(`"Prénom"`).nullable().required(),
          telIndicatif: Yup.number().label(`"Indicatif tél."`).nullable().required(),
          tel: Yup.number().label(`"Téléphone"`).nullable().required(),
          email: Yup.string().email().label(`"Email"`).nullable().required(),
          naissanceDate: Yup.string().label(`"Date de naissance"`).nullable().required(),
          naissancePays: Yup.number().label(`"Pays de naissance"`).nullable().required(),
          naissanceVilleFrance: Yup.number()
               .label(`"Ville de naissance"`)
               .nullable()
               .when("naissancePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          naissanceVille: Yup.string()
               .label(`"Ville de naissance"`)
               .nullable()
               .when("naissancePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    otherwise: schema => schema.required(),
               }),
          isNationaliteEtrangere: Yup.boolean().required(),
          nationalite: Yup.number()
               .label(`"Nationalité"`)
               .nullable()
               .when("isNationaliteEtrangere", {
                    is: true,
                    then: schema => schema.required(),
               }),
          nSecuriteSociale: Yup.string().label(`"N° de sécurité sociale"`).nullable().required().min(21, "Ce champ doit contenir 15 chiffres"),
          residenceAdresse: Yup.string().label(`"Adresse de résidence"`).nullable().required(),
          residencePays: Yup.number().label(`"Pays de résidence"`).nullable().required(),
          residenceVille: Yup.string()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("residencePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    otherwise: schema => schema.required(),
               }),
          residenceVilleFrance: Yup.number()
               .label(`"Ville de résidence"`)
               .nullable()
               .when("residencePays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          residenceCodePostal: Yup.number().label(`"Code postal de résidence"`).nullable().required(),
          personneToContacterCasUrgenceNom: Yup.string().label(`"Nom"`).nullable(),
          personneToContacterCasUrgencePrenom: Yup.string().label(`"Prénom"`).nullable(),
          personneToContacterCasUrgenceTelIndicatif: Yup.number().label(`"Indicatif tél."`).nullable(),
          personneToContacterCasUrgenceTel: Yup.string().label(`"Téléphone"`).nullable(),
          avatar: Yup.mixed()
               .nullable()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Avatar"`),
     })

     const initialValues: ISetInfoPersoSalarieRequestModel = {
          civilite: consultantInfoPerso.consultant?.civilite,
          nom: consultantInfoPerso.consultant?.nom,
          prenom: consultantInfoPerso.consultant?.prenom,
          telIndicatif: consultantInfoPerso.consultant?.telProPays?.id,
          tel: consultantInfoPerso.consultant?.telPro,
          email: consultantInfoPerso.consultant?.mailPro,
          naissanceDate: consultantInfoPerso.consultant?.naissanceDate?.format("YYYY-MM-DD"),
          naissancePays: consultantInfoPerso.consultant?.naissancePays?.id,
          naissanceVilleFrance: consultantInfoPerso.consultant?.naissanceVilleFrance?.id,
          naissanceVille: consultantInfoPerso.consultant?.naissanceVille,
          isNationaliteEtrangere: !!(consultantInfoPerso.consultant?.nationalite && consultantInfoPerso.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE),
          nationalite:
               consultantInfoPerso.consultant?.nationalite && consultantInfoPerso.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE
                    ? consultantInfoPerso.consultant?.nationalite?.id
                    : undefined,
          nSecuriteSociale: consultantInfoPerso.consultant.numeroSecuriteSociale,
          residenceAdresse: consultantInfoPerso.consultant?.domicileAdresse,
          residencePays: consultantInfoPerso.consultant?.domicilePays?.id,
          residenceVille: consultantInfoPerso.consultant?.domicileVille,
          residenceVilleFrance: consultantInfoPerso.consultant?.domocileVilleFrance?.id,
          residenceCodePostal: consultantInfoPerso.consultant?.domicileCodePostal,
          personneToContacterCasUrgenceNom: consultantInfoPerso.consultant?.contactUrgenceNom,
          personneToContacterCasUrgencePrenom: consultantInfoPerso.consultant?.contactUrgencePrenom,
          personneToContacterCasUrgenceTelIndicatif: consultantInfoPerso.consultant?.contactUrgenceTelPays?.id,
          personneToContacterCasUrgenceTel: consultantInfoPerso.consultant?.contactUrgenceTel,
     }

     function handleSubmit(values: ISetInfoPersoSalarieRequestModel, helpers: FormikHelpers<ISetInfoPersoSalarieRequestModel>) {
          helpers.setStatus(null)

          setConsultantInfoPersoSalarie(consultantInfoPerso.consultant.id as number, values)
               .then(() => {
                    toast.success("Vos modifications ont bien été prises en compte.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Field Avatar */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6">Photo</label>
                                   <div className="col-lg-8">
                                        <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${avatarPreview})` }}>
                                             <MyLoadingOverlay isActive={isAvatarLoading}>
                                                  <img
                                                       className="image-input-wrapper w-125px h-125px"
                                                       src={avatarPreview}
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       alt={"Avatar"}
                                                  ></img>
                                             </MyLoadingOverlay>

                                             <label
                                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                  data-kt-image-input-action="change"
                                                  data-bs-original-title="Change avatar"
                                             >
                                                  <i className="bi bi-pencil-fill fs-7"></i>
                                                  <input
                                                       type="file"
                                                       name="avatar"
                                                       accept=".png, .jpg, .jpeg"
                                                       onChange={e => {
                                                            const file = e.target.files![0]
                                                            helpers.setFieldValue("avatar", file).then()
                                                            const reader = new FileReader()
                                                            reader.onloadend = () => {
                                                                 // Set the image preview URL
                                                                 setAvatarPreview(reader.result as string)
                                                            }
                                                            reader.readAsDataURL(file)
                                                       }}
                                                  />
                                             </label>
                                        </div>
                                   </div>
                              </div>

                              {/* Field Civilité */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             name={"civilite"}
                                             options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                             value={helpers.values.civilite}
                                             isInvalid={!!helpers.errors.civilite}
                                             onChange={val => helpers.setFieldValue("civilite", val)}
                                        />
                                        {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                   </div>
                              </div>

                              {/* Nom & prénom */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Prénom NOM</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col-lg-6 mb-2 mb-lg-0">
                                                  <MyInputField
                                                       name={"prenom"}
                                                       value={helpers.values.prenom}
                                                       isInvalid={!!helpers.errors.prenom}
                                                       placeholder={"Prénom"}
                                                       onChange={val => helpers.setFieldValue("prenom", val)}
                                                  />
                                                  {helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}
                                             </div>
                                             <div className="col-lg-6">
                                                  <MyInputField
                                                       name={"nom"}
                                                       value={helpers.values.nom}
                                                       isInvalid={!!helpers.errors.nom}
                                                       placeholder={"Nom"}
                                                       onChange={val => helpers.setFieldValue("nom", val)}
                                                  />
                                                  {helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {/* Email */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"email"}
                                             value={helpers.values.email}
                                             isInvalid={!!helpers.errors.email}
                                             placeholder={"Email"}
                                             onChange={val => helpers.setFieldValue("email", val)}
                                        />
                                        {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                   </div>
                              </div>

                              {/* Field Telindicatif + Tél*/}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col-lg-6 mb-2 mb-lg-0">
                                                  <MySelectGetAllDataFromServer
                                                       name={"telIndicatif"}
                                                       value={helpers.values.telIndicatif}
                                                       url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                       defaultOptions={
                                                            consultantInfoPerso.consultant.telProPays
                                                                 ? [
                                                                        {
                                                                             value: consultantInfoPerso.consultant.telProPays.id as number,
                                                                             label: consultantInfoPerso.consultant.telProPays.phoneCodeWithPlusPrefixAndCountry as string,
                                                                        },
                                                                   ]
                                                                 : []
                                                       }
                                                       placeholder={"Choisissez un indicatif"}
                                                       isInvalid={!!helpers.errors.telIndicatif}
                                                       onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                  />
                                                  {helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}
                                             </div>
                                             <div className="col-lg-6">
                                                  <MyInptuMaskField
                                                       name={"tel"}
                                                       value={helpers.values.tel}
                                                       isInvalid={!!helpers.errors.tel}
                                                       mask={"99 9999999999999999999999999"}
                                                       placeholder={"Téléphone"}
                                                       inputMode={"numeric"}
                                                       onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                                  />
                                                  {helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {/* Numéro de sécurité sociale */}
                              <div className="row mb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                        <span>Numéro de sécurité sociale</span>
                                   </label>

                                   <div className="col-lg-8">
                                        <MyInptuMaskField
                                             name={"nSecuriteSociale"}
                                             mask={"9 99 99 99 999 999 99"}
                                             value={helpers.values.nSecuriteSociale}
                                             placeholder={"N° de sécurité sociale"}
                                             inputMode={"numeric"}
                                             onChange={e => helpers.setFieldValue("nSecuriteSociale", e.target.value)}
                                             isInvalid={!!helpers.errors.nSecuriteSociale}
                                        />
                                        {helpers.errors.nSecuriteSociale && <div className={"text-danger"}>{helpers.errors.nSecuriteSociale}</div>}
                                   </div>
                              </div>

                              {/*NAISSANCE*/}
                              <div className={"pb-6"}>
                                   <h3 className={"mt-8 mb-4"}>Naissance</h3>
                                   <div className="separator separator-dashed mb-6" />

                                   {/*Date de naissance*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Date de naissance</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"naissanceDate"}
                                                  onChange={date => helpers.setFieldValue("naissanceDate", date)}
                                                  value={helpers.values.naissanceDate}
                                                  isInvalid={!!helpers.errors.naissanceDate}
                                                  placeholder={"Date de naissance"}
                                             />
                                             {helpers.errors.naissanceDate && <div className={"text-danger"}>{helpers.errors.naissanceDate}</div>}
                                        </div>
                                   </div>

                                   {/*Pays et ville de naissance*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays et ville de naissance</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"naissancePays"}
                                                  value={helpers.values.naissancePays}
                                                  url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                  defaultOptions={
                                                       consultantInfoPerso.consultant.naissancePays
                                                            ? [
                                                                   {
                                                                        value: consultantInfoPerso.consultant.naissancePays.id as number,
                                                                        label: consultantInfoPerso.consultant.naissancePays.nomFr as string,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.naissancePays}
                                                  onChange={val => helpers.setFieldValue("naissancePays", val)}
                                             />
                                             {helpers.errors.naissancePays && <div className={"text-danger"}>{helpers.errors.naissancePays}</div>}

                                             {helpers.values.naissancePays && (
                                                  <div className={"mt-4"}>
                                                       {helpers.values.naissancePays === CONST_PAYS_ID_FRANCE ? (
                                                            <>
                                                                 <MySelectSearchDataFromServer
                                                                      name={"naissanceVilleFrance"}
                                                                      value={helpers.values.naissanceVilleFrance}
                                                                      url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                      method={"GET"}
                                                                      minimumLengthSearch={2}
                                                                      defaultOptions={
                                                                           consultantInfoPerso.consultant.naissanceVilleFrance
                                                                                ? [
                                                                                       {
                                                                                            value: consultantInfoPerso.consultant.naissanceVilleFrance.id as number,
                                                                                            label: consultantInfoPerso.consultant.naissanceVilleFrance.nom as string,
                                                                                       },
                                                                                  ]
                                                                                : []
                                                                      }
                                                                      isInvalid={!!helpers.errors.naissanceVilleFrance}
                                                                      onChange={val => helpers.setFieldValue("naissanceVilleFrance", val)}
                                                                 />
                                                                 {helpers.errors.naissanceVilleFrance && <div className={"text-danger"}>{helpers.errors.naissanceVilleFrance}</div>}
                                                            </>
                                                       ) : (
                                                            <>
                                                                 <MyInputField
                                                                      name={"naissanceVille"}
                                                                      value={helpers.values.naissanceVille}
                                                                      isInvalid={!!helpers.errors.naissanceVille}
                                                                      placeholder={"Ville"}
                                                                      onChange={val => helpers.setFieldValue("naissanceVille", val)}
                                                                 />
                                                                 {helpers.errors.naissanceVille && <div className={"text-danger"}>{helpers.errors.naissanceVille}</div>}
                                                            </>
                                                       )}
                                                  </div>
                                             )}
                                        </div>
                                   </div>

                                   {/*French?*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                                             Est-{helpers.values.civilite === CONST_CIVILITE_MR ? "il" : "elle"} de nationalité étrangère?
                                        </label>

                                        <div className="col-lg-8 d-flex align-items-center">
                                             <MyCheckBoxField
                                                  name={"isNationaliteEtrangere"}
                                                  value={helpers.values.isNationaliteEtrangere}
                                                  isInvalid={!!helpers.errors.isNationaliteEtrangere}
                                                  onChange={val => helpers.setFieldValue("isNationaliteEtrangere", val)}
                                             />
                                             {helpers.errors.isNationaliteEtrangere && <div className={"text-danger"}>{helpers.errors.isNationaliteEtrangere}</div>}
                                        </div>
                                   </div>

                                   {helpers.values.isNationaliteEtrangere && (
                                        <div className="row">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez sa nationalité</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"document"}
                                                       value={helpers.values.nationalite}
                                                       defaultOptions={
                                                            consultantInfoPerso.consultant.nationalite
                                                                 ? [
                                                                        {
                                                                             value: consultantInfoPerso.consultant.nationalite.id as number,
                                                                             label: consultantInfoPerso.consultant.nationalite.nomFr as string,
                                                                        },
                                                                   ]
                                                                 : []
                                                       }
                                                       url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                       payload={{ excluded_country_codes: [CONST_PAYS_CODE_FRANCE] }}
                                                       isInvalid={!!helpers.errors.nationalite}
                                                       onChange={val => helpers.setFieldValue("nationalite", val)}
                                                  />
                                                  {helpers.errors.nationalite && <div className={"text-danger"}>{helpers.errors.nationalite}</div>}
                                             </div>
                                        </div>
                                   )}
                              </div>

                              {/*RÉSIDENCE*/}
                              <div className={"pb-6"}>
                                   <h3 className={"mt-8 mb-4"}>Résidence</h3>
                                   <div className="separator separator-dashed mb-6" />

                                   {/*Adresse*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Adresse</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"residenceAdresse"}
                                                  onChange={date => helpers.setFieldValue("residenceAdresse", date)}
                                                  value={helpers.values.residenceAdresse}
                                                  isInvalid={!!helpers.errors.residenceAdresse}
                                                  placeholder={"Adresse"}
                                             />
                                             {helpers.errors.residenceAdresse && <div className={"text-danger"}>{helpers.errors.residenceAdresse}</div>}
                                        </div>
                                   </div>

                                   {/* Pays*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"residencePays"}
                                                  value={helpers.values.residencePays}
                                                  url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                  defaultOptions={
                                                       consultantInfoPerso.consultant.domicilePays
                                                            ? [
                                                                   {
                                                                        value: consultantInfoPerso.consultant.domicilePays.id as number,
                                                                        label: consultantInfoPerso.consultant.domicilePays.nomFr as string,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  isInvalid={!!helpers.errors.residencePays}
                                                  onChange={val => helpers.setFieldValue("residencePays", val)}
                                             />
                                             {helpers.errors.residencePays && <div className={"text-danger"}>{helpers.errors.residencePays}</div>}
                                        </div>
                                   </div>

                                   {/*Ville et code postal*/}
                                   {helpers.values.residencePays && (
                                        <div className="row">
                                             <label className="col-lg-4 col-form-label required fw-bold fs-6">Ville et code postal</label>

                                             <div className="col-lg-8">
                                                  <div className="row">
                                                       <div className="col-6">
                                                            {helpers.values.residencePays === CONST_PAYS_ID_FRANCE ? (
                                                                 <>
                                                                      <MySelectSearchDataFromServer
                                                                           name={"residenceVilleFrance"}
                                                                           value={helpers.values.residenceVilleFrance}
                                                                           url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                           method={"GET"}
                                                                           minimumLengthSearch={2}
                                                                           placeholder={"Choisissez la ville"}
                                                                           defaultOptions={
                                                                                consultantInfoPerso.consultant.domocileVilleFrance
                                                                                     ? [
                                                                                            {
                                                                                                 value: consultantInfoPerso.consultant.domocileVilleFrance.id as number,
                                                                                                 label: consultantInfoPerso.consultant.domocileVilleFrance.nom as string,
                                                                                            },
                                                                                       ]
                                                                                     : []
                                                                           }
                                                                           isInvalid={!!helpers.errors.residenceVilleFrance}
                                                                           onChange={val => helpers.setFieldValue("residenceVilleFrance", val)}
                                                                      />
                                                                      {helpers.errors.residenceVilleFrance && (
                                                                           <div className={"text-danger"}>{helpers.errors.residenceVilleFrance}</div>
                                                                      )}
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      <MyInputField
                                                                           name={"residenceVille"}
                                                                           value={helpers.values.residenceVille}
                                                                           isInvalid={!!helpers.errors.residenceVille}
                                                                           placeholder={"Ville"}
                                                                           onChange={val => helpers.setFieldValue("residenceVille", val)}
                                                                      />
                                                                      {helpers.errors.residenceVille && <div className={"text-danger"}>{helpers.errors.residenceVille}</div>}
                                                                 </>
                                                            )}
                                                       </div>
                                                       <div className="col-6">
                                                            <MyInputField
                                                                 name={"residenceCodePostal"}
                                                                 value={helpers.values.residenceCodePostal}
                                                                 isInvalid={!!helpers.errors.residenceCodePostal}
                                                                 placeholder={"Code postal"}
                                                                 inputMode={"numeric"}
                                                                 onChange={val => helpers.setFieldValue("residenceCodePostal", val)}
                                                            />
                                                            {helpers.errors.residenceCodePostal && <div className={"text-danger"}>{helpers.errors.residenceCodePostal}</div>}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   )}
                              </div>

                              {/*PERSONNE A CONTACTER CAS URGENCE*/}
                              <div className={"pb-6"}>
                                   <h3 className={"mt-8 mb-4"}>Personne à contacter en cas d'urgence</h3>
                                   <div className="separator separator-dashed mb-6" />

                                   {/* Field Prénom & nom */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Prénom NOM</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MyInputField
                                                            name={"personneToContacterCasUrgencePrenom"}
                                                            value={helpers.values.personneToContacterCasUrgencePrenom}
                                                            isInvalid={!!helpers.errors.personneToContacterCasUrgencePrenom}
                                                            placeholder={"Prénom"}
                                                            onChange={val => helpers.setFieldValue("personneToContacterCasUrgencePrenom", val)}
                                                       />
                                                       {helpers.errors.personneToContacterCasUrgencePrenom && (
                                                            <div className={"text-danger"}>{helpers.errors.personneToContacterCasUrgencePrenom}</div>
                                                       )}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInputField
                                                            name={"personneToContacterCasUrgenceNom"}
                                                            value={helpers.values.personneToContacterCasUrgenceNom}
                                                            isInvalid={!!helpers.errors.personneToContacterCasUrgenceNom}
                                                            placeholder={"Nom"}
                                                            onChange={val => helpers.setFieldValue("personneToContacterCasUrgenceNom", val)}
                                                       />
                                                       {helpers.errors.personneToContacterCasUrgenceNom && (
                                                            <div className={"text-danger"}>{helpers.errors.personneToContacterCasUrgenceNom}</div>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Field contactUrgenceTelPays + personneToContacterCasUrgenceTel*/}
                                   <div className="row">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Téléphone</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MySelectGetAllDataFromServer
                                                            name={"personneToContacterCasUrgenceTelIndicatif"}
                                                            value={helpers.values.personneToContacterCasUrgenceTelIndicatif}
                                                            url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                            defaultOptions={
                                                                 consultantInfoPerso.consultant.contactUrgenceTelPays
                                                                      ? [
                                                                             {
                                                                                  value: consultantInfoPerso.consultant.contactUrgenceTelPays.id as number,
                                                                                  label: consultantInfoPerso.consultant.contactUrgenceTelPays
                                                                                       .phoneCodeWithPlusPrefixAndCountry as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            placeholder={"Choisissez un indicatif"}
                                                            isInvalid={!!helpers.errors.personneToContacterCasUrgenceTelIndicatif}
                                                            onChange={val => helpers.setFieldValue("personneToContacterCasUrgenceTelIndicatif", val)}
                                                       />
                                                       {helpers.errors.personneToContacterCasUrgenceTelIndicatif && (
                                                            <div className={"text-danger"}>{helpers.errors.personneToContacterCasUrgenceTelIndicatif}</div>
                                                       )}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInptuMaskField
                                                            name={"personneToContacterCasUrgenceTel"}
                                                            mask={"99 9999999999999999999999999"}
                                                            value={helpers.values.personneToContacterCasUrgenceTel}
                                                            isInvalid={!!helpers.errors.personneToContacterCasUrgenceTel}
                                                            placeholder={"Téléphone"}
                                                            onChange={e => helpers.setFieldValue("personneToContacterCasUrgenceTel", e.target.value)}
                                                       />
                                                       {helpers.errors.personneToContacterCasUrgenceTel && (
                                                            <div className={"text-danger"}>{helpers.errors.personneToContacterCasUrgenceTel}</div>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="separator bg-primary my-5"></div>

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? (
                                             <>
                                                  Sauvegarder et suivant <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                             </>
                                        ) : (
                                             <MySimpleSpinner size={"sm"} />
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

interface IConsultantDetailsInfoBaseFormInfoPersoPropsModel {
     consultantInfoPerso: IGetDetailsConsultant
}

export default ConsultantDetailsInfoBaseFormInfoPersoSalarie
