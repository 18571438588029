import { FormikProps } from "formik/dist/types"
import { ArraySchema, ObjectSchema, Schema } from "yup"

interface ISelectReactHookReturn {
     isFieldOnError: (formikProps: FormikProps<any>, name: string) => boolean
     getFieldErrorMessage: (formikProps: FormikProps<any>, name: string) => string | null
     isFieldValid: (formikProps: FormikProps<any>, name: string) => boolean
     isFieldRequired: (validationSchema: ObjectSchema<any>, name: string) => boolean
}

const useFormik = (): ISelectReactHookReturn => {
     function _getNestedProperty(obj: any, path: string): any {
          return path.split(".").reduce((acc, part) => {
               const match = part.match(/(\w+)\[(\d+)\]/)
               if (match) {
                    const [, arrayName, index] = match
                    return acc && acc[arrayName] && acc[arrayName][index]
               }
               return acc && acc[part]
          }, obj)
     }

     function _parseFieldName(fieldName: string): { name: string; index: number | null; field: string | null } {
          const arrayRegex = /^(\w+)\[(\d+)\]\.(\w+)$/
          const match = fieldName.match(arrayRegex)

          if (match) {
               const [, name, index, field] = match
               return {
                    name,
                    index: parseInt(index, 10),
                    field,
               }
          }

          return {
               name: fieldName,
               index: null,
               field: null,
          }
     }

     // Checks if there is an error on the field
     function isFieldOnError(formikProps: FormikProps<any>, name: string): boolean {
          const touched = _getNestedProperty(formikProps.touched, name)
          const error = _getNestedProperty(formikProps.errors, name)

          if ((touched && error) || (error && formikProps.submitCount > 0)) {
               return true
          }

          return false
     }

     // Gets the error
     function getFieldErrorMessage(formikProps: FormikProps<any>, name: string): string | null {
          if (isFieldOnError(formikProps, name)) {
               return _getNestedProperty(formikProps.errors, name)
          }

          return null
     }

     // Checks if field is valid
     function isFieldValid(formikProps: FormikProps<any>, name: string): boolean {
          const touched = _getNestedProperty(formikProps.touched, name)
          const error = _getNestedProperty(formikProps.errors, name)

          if (touched && !error) {
               return true
          }

          return false
     }

     // Checks if field is required
     function isFieldRequired(validationSchema: ObjectSchema<any>, name: string): boolean {
          const parseFieldName = _parseFieldName(name)
          let field = validationSchema.fields[parseFieldName.name] as Schema

          if (!field?.type) return false

          if (field.type === "array") {
               const arrayField = field as ArraySchema<any, any>
               if (arrayField.innerType && (arrayField.innerType as ObjectSchema<any>).fields) {
                    const field: Schema = (arrayField.innerType as ObjectSchema<any>).fields[parseFieldName.field as string] as Schema
                    return !field.spec.optional
               }

               return false
          } else {
               if (field) {
                    return !field.spec.optional
               }

               return false
          }
     }

     return {
          isFieldOnError,
          getFieldErrorMessage,
          isFieldValid,
          isFieldRequired,
     }
}

export default useFormik
