import { useParams } from "react-router-dom"
import { useQuery, useQueryClient } from "react-query"
import { IConsultantMissionModel, IHttpErrorResponseModel } from "@common-models/*"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDemandeEmbaucheOrdreMission, setDemandeEmbaucheOrdreMission } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import * as Yup from "yup"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_APPLICATION_DOCX } from "@common-constants/*"
import React, { useState } from "react"
import { Form, Formik, FormikHelpers } from "formik"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import { ISetDemandeEmbaucheOrdreMissionFormikInitValuesModel, ISetDemandeEmbaucheOrdreMissionRequestModel } from "./core/_models"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"

const REACT_QUERY_KEY_GET_DE_ODM_QUERY = "REACT_QUERY_KEY_GET_DE_ODM_QUERY"
const DemandeEmbaucheDetailsOrdreMission = () => {
     const context = useDemandeEmbaucheDetailsContext()
     const queryClient = useQueryClient()
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)

     const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(true)

     // Query
     const ordreMissionQuery = useQuery<IConsultantMissionModel, AxiosError>(REACT_QUERY_KEY_GET_DE_ODM_QUERY, () => {
          return getDemandeEmbaucheOrdreMission(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Form schema
     const schema = Yup.object().shape({
          saisieManuelleOuGenerationAutomatique: Yup.string().required(),
          document: Yup.mixed()
               .when("saisieManuelleOuGenerationAutomatique", {
                    is: true,
                    then: schema => schema.required(),
               })
               .test("fileFormat", "Seul le format Word (.docx uniquement) est accepté.", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Document"`),
     })

     // Formik init values
     const initialValues: ISetDemandeEmbaucheOrdreMissionFormikInitValuesModel = {}

     // Formik handle submit
     function handleSubmit(values: ISetDemandeEmbaucheOrdreMissionFormikInitValuesModel, helpers: FormikHelpers<ISetDemandeEmbaucheOrdreMissionFormikInitValuesModel>) {
          helpers.setStatus(null)

          const payload: ISetDemandeEmbaucheOrdreMissionRequestModel = {
               saisieManuelleOuGenerationAutomatique: values.saisieManuelleOuGenerationAutomatique as "SAISIE_MANUELLE" | "GENERATION",
               document: values.document,
          }

          setDemandeEmbaucheOrdreMission(consultant_ID, payload)
               .then(r => {
                    queryClient.setQueryData<IConsultantMissionModel | undefined>(REACT_QUERY_KEY_GET_DE_ODM_QUERY, (prev: IConsultantMissionModel | undefined) => {
                         return r.data
                    })
                    toast.success(
                         values.saisieManuelleOuGenerationAutomatique === "SAISIE_MANUELLE"
                              ? "Votre ODM a bien été envoyé. Vous pouvez l'éditer en ligne si vous le souhaitez."
                              : "L'ODM a bien été généré. Merci de le compléter avant de passer à l'étape suivante."
                    )
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Query Is fetching
     if (ordreMissionQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // Query on Error
     if (!ordreMissionQuery.isFetching && ordreMissionQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{ordreMissionQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger"} onClick={() => ordreMissionQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Query with data
     if (!ordreMissionQuery.isFetching && ordreMissionQuery.data) {
          if (ordreMissionQuery.data.googleDriveID) {
               return (
                    <div className={"w-100 w-xl-75"}>
                         <MyCard>
                              <MyCard.Header
                                   isFullScreenIconShown
                                   rightSideContent={
                                        <>
                                             <button type={"button"} className={"btn btn-sm btn-warning p-0 px-2 py-1 me-2"} onClick={() => context.setNavigationStep("client")}>
                                                  <i className={"fas fa-arrow-left me-1"} /> Précédant
                                             </button>
                                             <button type={"button"} className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2"} onClick={() => context.setNavigationStep("doc")}>
                                                  Suivant <i className={"fas fa-arrow-right ms-1"} />
                                             </button>
                                        </>
                                   }
                              >
                                   Ordre de mission - {ordreMissionQuery.data.reference}
                              </MyCard.Header>
                              <MyCard.Body noPadding styles={{ height: "65vh", overflowY: "scroll", width: "100%" }}>
                                   <div>
                                        {/* CASE: un document est déjà présent */}
                                        <MyLoadingOverlay isActive={isDocumentLoading}>
                                             <iframe
                                                  onLoad={() => setIsDocumentLoading(false)}
                                                  src={FileAndDriveHelper.getUrlOfDriveFileEditMode(ordreMissionQuery.data.googleDriveID)}
                                                  className={"vh-100 w-100"}
                                             />
                                        </MyLoadingOverlay>
                                   </div>
                              </MyCard.Body>
                         </MyCard>
                    </div>
               )
          } else {
               return (
                    <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                         <MyCard.Body>
                              <div>
                                   {/* Case: Aucun document est présent */}
                                   <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                                        {helpers => {
                                             return (
                                                  <Form noValidate autoComplete="off">
                                                       {helpers.status && (
                                                            <MyAlert type={"danger"} classNames={"mb-4"}>
                                                                 {helpers.status}
                                                            </MyAlert>
                                                       )}
                                                       <div className="row">
                                                            <div className="col-md-6 mb-2">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"saisieManuelleOuGenerationAutomatique"}
                                                                      value={"SAISIE_MANUELLE"}
                                                                      isChecked={helpers.values.saisieManuelleOuGenerationAutomatique === "SAISIE_MANUELLE"}
                                                                      label={"Saisie manuelle"}
                                                                      description={"Joignez votre propre document"}
                                                                      iconFontAwesomeClassName={"upload"}
                                                                      color={"primary"}
                                                                      isInvalid={!!helpers.errors.saisieManuelleOuGenerationAutomatique}
                                                                      onChange={val => helpers.setFieldValue("saisieManuelleOuGenerationAutomatique", val)}
                                                                 />
                                                            </div>
                                                            <div className="col-md-6 mb-2">
                                                                 <MyRadioWithLabelAndDescriptionField
                                                                      name={"saisieManuelleOuGenerationAutomatique"}
                                                                      value={"GENERATION"}
                                                                      isChecked={helpers.values.saisieManuelleOuGenerationAutomatique === "GENERATION"}
                                                                      label={"Génération automatique (recommandé)"}
                                                                      description={"Générer un ODM pré-rempli éditable en ligne"}
                                                                      iconFontAwesomeClassName={"bolt"}
                                                                      color={"success"}
                                                                      isInvalid={!!helpers.errors.saisieManuelleOuGenerationAutomatique}
                                                                      onChange={val => helpers.setFieldValue("saisieManuelleOuGenerationAutomatique", val)}
                                                                 />
                                                            </div>
                                                       </div>

                                                       <div className="separator mb-5"></div>

                                                       {/* Document */}
                                                       {helpers.values.saisieManuelleOuGenerationAutomatique === "SAISIE_MANUELLE" && (
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez un document</label>

                                                                 <div className="col-lg-8">
                                                                      <MyFileField
                                                                           name={"document"}
                                                                           isInvalid={!!helpers.errors.document}
                                                                           onChange={val => helpers.setFieldValue("document", val)}
                                                                      />
                                                                      {helpers.errors.document && <div className={"text-danger"}>{helpers.errors.document}</div>}
                                                                 </div>
                                                            </div>
                                                       )}

                                                       {/* Submit button */}
                                                       <div className={"d-flex justify-content-between"}>
                                                            <button
                                                                 className={"btn btn-sm btn-warning d-inline-flex align-items-center"}
                                                                 type={"button"}
                                                                 disabled={helpers.isSubmitting}
                                                                 onClick={() => context.setNavigationStep("client")}
                                                            >
                                                                 <span className={"fas fa-arrow-left fs-4 me-1"}></span> Précédant
                                                            </button>
                                                            {helpers.values.saisieManuelleOuGenerationAutomatique && (
                                                                 <button
                                                                      className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                                      type={"submit"}
                                                                      disabled={helpers.isSubmitting}
                                                                 >
                                                                      {!helpers.isSubmitting ? (
                                                                           <>
                                                                                {helpers.values.saisieManuelleOuGenerationAutomatique === "GENERATION" ? (
                                                                                     <>
                                                                                          Générer <i className={"fas fa-bolt fs-4 ms-1"} />
                                                                                     </>
                                                                                ) : (
                                                                                     <>
                                                                                          Envoyer <i className={"fas fa-upload fs-4 ms-1"} />
                                                                                     </>
                                                                                )}
                                                                           </>
                                                                      ) : (
                                                                           <MySimpleSpinner size={"sm"} />
                                                                      )}
                                                                 </button>
                                                            )}
                                                       </div>
                                                  </Form>
                                             )
                                        }}
                                   </Formik>
                              </div>
                         </MyCard.Body>
                    </MyCard>
               )
          }
     }

     return <></>
}

export default DemandeEmbaucheDetailsOrdreMission
