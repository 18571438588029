import React from "react"
import MyCard from "@common-utils/MyCard"
import { useDashboardAlertesPeriodesEssaiExpirationListingContext } from "./DashboardAlertesPeriodesEssaiExpirationListing"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import "./styles.scss"
import MyTooltip from "@common-utils/MyTooltip"
import { toast } from "react-toastify"
import { IGetAlertesPeriodesEssaiExpirationItemDashboardResponseModel } from "./core/_models"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

/* Wrapper */
const DashboardAlertesPeriodesEssaiExpirationListingPreview = () => {
     const globalContext = useDashboardAlertesPeriodesEssaiExpirationListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(
          item => item.consultantPeriodeEssai.consultant?.id === selectedItemInPreview.consultantPeriodeEssai.consultant?.id
     ) as IGetAlertesPeriodesEssaiExpirationItemDashboardResponseModel
     const contrat = itemInPreview.contratDeTravailEtAvenants[0]

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>Contrat et avenants</MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyLoadingOverlay isActive={globalContext.itemInPreview?.isSelectedItemLoading as boolean}>
                         <>
                              <iframe
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(contrat.googleDriveID as string)}
                                   style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                                   onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                              />
                              {/* Curriculum utils: download/replace CV, send mail, vivier, traitement, partage */}
                              <div className={"vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                                   {/* Download CV */}
                                   <MyTooltip title={"Prolonger la mission"} placement={"right"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-5 mb-sm-2"}
                                             onClick={() => toast.info("En maintenance ...")}
                                        >
                                             <i className={"fas fa-clock"} />
                                        </button>
                                   </MyTooltip>

                                   {/* Replace CV */}
                                   <MyTooltip title={"Changer de mission"} placement={"right"}>
                                        <span>
                                             <input type="file" className={"d-none"} />
                                             <button className="btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle" onClick={() => toast.info("En maintenance ...")}>
                                                  <i className="fas fa-sync" />
                                             </button>
                                        </span>
                                   </MyTooltip>
                              </div>
                         </>
                    </MyLoadingOverlay>
               </MyCard.Body>
          </MyCard>
     )
}

export default DashboardAlertesPeriodesEssaiExpirationListingPreview
