import React, { CSSProperties, ReactElement } from "react"
import { StylesConfig } from "react-select"
import { useThemeMode } from "@common-metronic/partials"

const useSelectReact = (): ISelectReactHookReturn => {
     const { mode } = useThemeMode()

     const styles = (isInvalid: boolean): StylesConfig => ({
          control: (baseStyles, state) => ({
               ...baseStyles,
               border: `${
                    mode == "light"
                         ? `1px solid ${isInvalid ? "var(--kt-danger)" : "var(--kt-gray-600)"}`
                         : `1px solid ${isInvalid ? "var(--kt-danger)" : "var(--kt-input-border-color)"}`
               }`,
               boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               borderRadius: "0.625rem!important",
               padding: "2px 0px",
               backgroundColor: mode == "light" ? "white" : "var(--kt-input-solid-bg)",
               color: "var(--kt-input-solid-bg)",
               ":hover": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
               ":active": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
          }),
          placeholder: (baseStyles, state) => ({
               ...baseStyles,
               fontSize: "1.1rem",
               fontWeight: 500,
               color: "var(--kt-input-placeholder-color)",
          }),
          indicatorSeparator: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: "var(--kt-input-placeholder-color)",
          }),
          dropdownIndicator: (baseStyles, state) => ({
               ...baseStyles,
               color: "var(--kt-input-placeholder-color)",
          }),
          clearIndicator: (baseStyles, state) => ({
               ...baseStyles,
               color: "var(--kt-input-placeholder-color)",
               ":hover": {
                    color: "var(--kt-danger)",
                    cursor: "pointer",
               },
          }),
          input: (baseStyles, state) => ({
               ...baseStyles,
               fontSize: "1.15rem",
               fontWeight: 500,
               color: "var(--kt-input-color)",
          }),
          option: (baseStyles, state) => ({
               ...baseStyles,
               color: state.isSelected ? "white" : "var(--kt-input-color)",
               ":hover": {
                    backgroundColor: "var(--kt-primary)",
                    color: "white",
               },
               ":active": {
                    backgroundColor: "var(--kt-primary)",
                    color: "white",
               },
          }),
          menu: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: "var(--kt-input-bg)",
          }),
          singleValue: (baseStyles, state) => ({
               ...baseStyles,
               marginLeft: "5px!important",
               fontSize: "1.15rem",
               fontWeight: "500",
               color: "var(--kt-input-color)",
          }),
          multiValueRemove: baseStyles => ({
               ...baseStyles,
               color: "var(--kt-input-color)",
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
     })

     /* Used for custom group label */
     const formatGroupLabel = data => {
          const groupStyles: CSSProperties = {
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               fontSize: 13,
               color: mode === "light" ? "black" : "white",
          }
          const groupBadgeStyles: CSSProperties = {
               backgroundColor: mode === "light" ? "white" : "black",
               borderRadius: "2em",
               display: "inline-block",
               fontSize: 13,
               fontWeight: "bold",
               lineHeight: "1",
               minWidth: 1,
               padding: "0.16666666666667em 0.5em",
               textAlign: "center",
          }
          return (
               <div>
                    <div style={groupStyles}>
                         <span>{data.label}</span>
                         <span style={groupBadgeStyles}>{data.options.length}</span>
                    </div>
               </div>
          )
     }

     return {
          styles,
          formatGroupLabel,
     }
}

interface ISelectReactHookReturn {
     styles: (isError: boolean, isValid: boolean) => StylesConfig
     formatGroupLabel: (data: any) => ReactElement
}

export type SelectOptionsType = Array<
     | { value?: SelectOptionValueType; label: SelectOptionLabelType; options: Array<{ label: SelectOptionLabelType; value: SelectOptionValueType }> }
     | { label: SelectOptionLabelType; value: SelectOptionValueType }
>

export type SelectOptionType = { value?: SelectOptionValueType; label: SelectOptionLabelType }

export type SelectOptionValueType = string | number | boolean
export type SelectOptionLabelType = any

export default useSelectReact
