import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import React, { ReactNode } from "react"
import { ObjectSchema } from "yup"
import useFormik from "./useFormik"
import InputMask from "react-input-mask"
import DatePicker, { registerLocale } from "react-datepicker"
import moment from "moment/moment"
import fr from "date-fns/locale/fr"

registerLocale("fr", fr)

const MyDateDayMonthYearFormField = ({
     label,
     name,
     formikProps,
     placeholder,
     classNameLayout,
     classNameLabel,
     classNameField,
     autoFocus,
     validationSchema,
     footerRightSide,
     inputRightSideIcon,
     areWeekendsDisabled = true,
     minDate,
     maxDate,
     isClearable = true,
     isDisabled = false,
     defaultOpenMonth = { month: moment().month(), year: moment().year() }, // Default: February of the current year
}: IPropsModel) => {
     const isWeekday = (date: any) => {
          const day = date.getDay()
          return day !== 0 && day !== 6
     }
     const { isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900 d-flex align-items-center", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div className={clsx("col-12", classNameField, { "mt-1": label })}>
                    <div className={"input-group"}>
                         <DatePicker
                              dateFormat="dd/MM/yyyy"
                              locale="fr"
                              placeholderText={placeholder}
                              openToDate={moment().year(defaultOpenMonth.year).month(defaultOpenMonth.month).toDate()}
                              customInput={
                                   <InputMask className={"form-control"} mask="99/99/9999">
                                        {() => (
                                             <input
                                                  type="text"
                                                  inputMode={"numeric"}
                                                  placeholder={placeholder}
                                                  className={clsx("form-control bg-transparent", { "border-danger": formikProps && isFieldOnError(formikProps, name) })}
                                                  autoFocus={autoFocus}
                                                  onBlurCapture={() => formikProps?.setFieldTouched(name, true)}
                                             />
                                        )}
                                   </InputMask>
                              }
                              name={name}
                              minDate={minDate ? moment(minDate).toDate() : null}
                              maxDate={maxDate ? moment(maxDate).toDate() : null}
                              isClearable={isClearable}
                              disabled={isDisabled}
                              selected={
                                   formikProps?.getFieldProps(name).value === undefined || formikProps.getFieldProps(name).value === null
                                        ? null
                                        : moment(formikProps.getFieldProps(name).value).toDate()
                              }
                              value={
                                   formikProps?.getFieldProps(name).value === undefined || formikProps.getFieldProps(name).value === null
                                        ? null
                                        : moment(formikProps.getFieldProps(name).value).toDate()
                              }
                              filterDate={areWeekendsDisabled && isWeekday}
                              onChange={(val: any) => {
                                   formikProps?.setFieldTouched(name, true)
                                   if (val) {
                                        const d = moment(val).format("YYYY-MM-DD")
                                        formikProps?.setFieldValue(name, d)
                                   } else {
                                        formikProps?.setFieldValue(name, null)
                                   }
                              }}
                         />

                         {inputRightSideIcon && (
                              <span className={clsx("input-group-text", { "border-danger": formikProps && isFieldOnError(formikProps, name) })}>
                                   <i className={`fas fa-${inputRightSideIcon}`} />
                              </span>
                         )}
                    </div>
                    {(formikProps || footerRightSide) && (
                         <div className="row">
                              <div className="col text-start">
                                   {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
                              </div>
                              {footerRightSide && <div className="col text-end">{footerRightSide}</div>}
                         </div>
                    )}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     placeholder: string
     type?: "text" | "number" | "email" | "password"
     inputMode?: "text" | "numeric" | "tel" | "email"
     autoComplete?: "on" | "off"
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     validationSchema?: ObjectSchema<any>
     footerRightSide?: ReactNode
     inputRightSideIcon?: string
     areWeekendsDisabled?: boolean
     minDate?: string
     maxDate?: string
     isClearable?: boolean
     isDisabled?: boolean
     defaultOpenMonth?: { month: number; year: number } // New prop to control the default open month/year
}

export default MyDateDayMonthYearFormField
