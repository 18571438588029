import axios from "axios"
import {
     CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CONTRACT_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT,
     CONST_API_UTILS_POST_CALCULATE_DATE_FIN_PE_ENDPOINT,
} from "../../../../../../endpoints"
import {
     ICalculateFinPeriodeEssaiRequestModel,
     IGetDemandeEmbaucheInfoContractResponseModel,
     ISetDemandeEmbaucheInfoContractNonSalarieRequestModel,
     ISetDemandeEmbaucheInfoProSalarieRequestModel,
} from "./_models"
import moment from "moment/moment"

export function getDemandeEmbaucheInfoContract(consultant_id: number) {
     return axios.get<IGetDemandeEmbaucheInfoContractResponseModel>(CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CONTRACT_ENDPOINT(consultant_id))
}

export function setDemandeEmbaucheInfoContractSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoProSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT(consultant_id), payload)
}

export function setDemandeEmbaucheInfoContractNonSalarie(consultant_id: number, payload: ISetDemandeEmbaucheInfoContractNonSalarieRequestModel) {
     return axios.post(CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT(consultant_id), payload)
}

export function calculateFinPeriodeEssai(payload: ICalculateFinPeriodeEssaiRequestModel) {
     return axios.post<{ dateFin: moment.Moment }>(CONST_API_UTILS_POST_CALCULATE_DATE_FIN_PE_ENDPOINT, payload)
}
