import MyLoadingOverlay from "@common-components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyTooltip from "@common-utils/MyTooltip"
import React, { useState } from "react"
import { CONST_DEFAULT_TOAST_OPTIONS, CONST_MIME_APPLICATION_DOC, CONST_MIME_APPLICATION_DOCX, CONST_MIME_APPLICATION_GOOGLE_DOC } from "@common-constants/*"
import "./styles.scss"
import { toast } from "react-toastify"
import axios from "axios"
import { CONST_API_UTILS_GET_DOWNLOAD_DRIVE_FILE_ENDPOINT } from "../../endpoints"

const MyGoogleDriveFileViewer = ({ googleDriveID, googleDriveMimetype, onDocumentLoadCallback = () => {} }: IMyDocumentViewerPropsModel) => {
     const [isLoading, setIsLoading] = useState<boolean>(true)
     const [mode, setMode] = useState<"view" | "edit">("view")
     const isEditable =
          googleDriveMimetype === CONST_MIME_APPLICATION_GOOGLE_DOC || googleDriveMimetype === CONST_MIME_APPLICATION_DOCX || googleDriveMimetype === CONST_MIME_APPLICATION_DOC

     // Handles download curriculum
     function handleDownloadFile() {
          toast.info("Téléchargement en cours ...", {
               ...CONST_DEFAULT_TOAST_OPTIONS,
               autoClose: false,
          })
          axios.get(CONST_API_UTILS_GET_DOWNLOAD_DRIVE_FILE_ENDPOINT, {
               params: { reference: googleDriveID },
               responseType: "blob",
          })
               .then(r => {
                    // Get content disposition from axios response
                    const contentDisposition = r.headers["content-disposition"]

                    let filename = "file"
                    if (contentDisposition) {
                         const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                         const matches = filenameRegex.exec(contentDisposition)
                         if (matches != null && matches[1]) {
                              filename = matches[1].replace(/['"]/g, "")
                         }
                    }

                    // const fileName = `talent-download`
                    FileAndDriveHelper.downloadFile(r.data, filename)

                    toast.dismiss()
                    toast.success("Le fichier a bien été téléchargé")
               })
               .catch(() => {
                    toast.dismiss()
                    toast.error("Un problème est survenu lors du téléchargement du fichier.", { autoClose: false })
               })
     }

     return (
          <div className={"position-relative"}>
               {/* Iframe utils */}
               <div className={"mgdfv-vertical-container-of-button-on-iframe bg-secondary rounded text-center"}>
                    <MyTooltip title={"Télécharger"} placement={"right"}>
                         <button type={"button"} className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle"} onClick={() => handleDownloadFile()}>
                              <i className={"fas fa-download"} />
                         </button>
                    </MyTooltip>

                    {/* Edit mode */}
                    {isEditable && (
                         <MyTooltip title={mode === "view" ? "Passer en mode édition" : "Passer en mode visualisation"} placement={"right"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mt-2"}
                                   onClick={() => {
                                        setIsLoading(true)
                                        setMode(prev => (prev === "view" ? "edit" : "view"))
                                   }}
                              >
                                   {mode === "view" ? <i className={"fas fa-edit"} /> : <i className={"fas fa-eye"} />}
                              </button>
                         </MyTooltip>
                    )}
               </div>

               <MyLoadingOverlay isActive={isLoading}>
                    <iframe
                         onLoad={() => {
                              setIsLoading(false)
                         }}
                         src={mode === "view" ? FileAndDriveHelper.getUrlOfDriveFilePreview(googleDriveID) : FileAndDriveHelper.getUrlOfDriveFileEditMode(googleDriveID)}
                         style={{ height: "100vh", width: "100%" }}
                    />
               </MyLoadingOverlay>
          </div>
     )
}

interface IMyDocumentViewerPropsModel {
     onDocumentLoadCallback?: () => void
     googleDriveID: string
     googleDriveMimetype: string
}

export default MyGoogleDriveFileViewer
