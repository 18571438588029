import { IGetDocumentsConsultant } from "../core/_models"
import React, { useState } from "react"
import { IConsultantCraNdfDcSignesModel } from "@common-models/*"
import MyTooltip from "@common-utils/MyTooltip"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import MyAlert from "@common-utils/MyAlert"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyGoogleDriveFileViewerInModal from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"

const ConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024 = ({ documents }: IConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024PropsModel) => {
     const context = useConsultantDetailsContext()

     const [documentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMime: string
          modalTitle: string
          isLoading: boolean
     }>()

     return (
          <>
               <MyAlert type={"primary"} classNames={"mb-2"}>
                    Vous trouverez ci-dessous les CRA, NDF et DDC avant la mise en place du système de déclaration en ligne. Pour accéder aux déclarations du nouveau système,
                    veuillez les consulter en cliquant sur l'onglet "Déclarations".
               </MyAlert>

               <table className={`table table-rounded  gy-7 gs-7 m-0 p-0`}>
                    <thead className={`bg-light border-bottom border-secondary`}>
                         <tr>
                              <td>Mois</td>
                              <td>
                                   <span className={"text-primary"}>{context.detailsQuery.data?.consultant.contrat === "CONTRAT_ST" ? "Factures" : "CRA"}</span> -{" "}
                                   <span className={"text-warning"}>NDF</span>
                                   {context.detailsQuery.data?.consultant.contrat !== "CONTRAT_ST" && (
                                        <>
                                             {" "}
                                             - <span className={"text-danger"}>DDC</span>
                                        </>
                                   )}
                              </td>
                         </tr>
                    </thead>
                    <tbody>
                         {documents.documentsCraNdfDdcBeforeFevrier2024.map((item: IConsultantCraNdfDcSignesModel, key) => (
                              <tr className={`bg-hover-light-primary`} key={key}>
                                   <td>{GlobalHelper.firstLetterUppercase(item.mois?.format("MMMM YYYY"))}</td>
                                   <td className={"d-flex align-items-center"}>
                                        {item.consultantCraNdfDcSignesDetails?.map((item_, key_) => (
                                             <div key={key_}>
                                                  {item_.type === "DOC_CRA_FACTURES" && (
                                                       <MyTooltip title={"Consulter facture"}>
                                                            <i
                                                                 className={"fas fa-file text-primary fs-2 me-4"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           modalTitle: "Facture",
                                                                           driveID: item_.googleDriveId as string,
                                                                           driveMime: item_.googleDriveMimeType as string,
                                                                           isLoading: true,
                                                                      })
                                                                 }
                                                            />
                                                       </MyTooltip>
                                                  )}
                                                  {item_.type === "TYPE_CRA" && (
                                                       <MyTooltip title={"Consulter CRA"}>
                                                            <i
                                                                 className={"fas fa-file text-primary fs-2 me-4"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           modalTitle: "CRA",
                                                                           driveID: item_.googleDriveId as string,
                                                                           driveMime: item_.googleDriveMimeType as string,
                                                                           isLoading: true,
                                                                      })
                                                                 }
                                                            />
                                                       </MyTooltip>
                                                  )}
                                                  {item_.type === "TYPE_NDF" && (
                                                       <MyTooltip title={"Consulter NDF"}>
                                                            <i
                                                                 className={"fas fa-file text-warning fs-2 me-4"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           modalTitle: "NDF",
                                                                           driveID: item_.googleDriveId as string,
                                                                           driveMime: item_.googleDriveMimeType as string,
                                                                           isLoading: true,
                                                                      })
                                                                 }
                                                            />
                                                       </MyTooltip>
                                                  )}
                                                  {item_.type === "DOC_DC" && (
                                                       <MyTooltip title={"Consulter DDC"}>
                                                            <i
                                                                 className={"fas fa-file text-danger fs-2 me-4"}
                                                                 onClick={() =>
                                                                      setActiveDriveDocumentInModal({
                                                                           modalTitle: "DDC",
                                                                           driveID: item_.googleDriveId as string,
                                                                           driveMime: item_.googleDriveMimeType as string,
                                                                           isLoading: true,
                                                                      })
                                                                 }
                                                            />
                                                       </MyTooltip>
                                                  )}
                                             </div>
                                        ))}
                                   </td>
                              </tr>
                         ))}
                    </tbody>
               </table>

               {/* Document in modal */}
               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<span>{documentInModal.modalTitle}</span>}
                         handleCloseModal={() => setActiveDriveDocumentInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024PropsModel {
     documents: IGetDocumentsConsultant
}

export default ConsultantDetails_DocumentsCraDdcNdfAvantFevrier2024
