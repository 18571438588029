// ABSOLUTE PATHS (used in project)
export const ROUTING_ABSOLUTE_PATH_GESTION = "/gestion"

export const ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION = `${ROUTING_ABSOLUTE_PATH_GESTION}/production`
export const ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS = `${ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION}/declarations-voyants`
export const ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION}/declarations-listing`
export const ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DDC_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION}/ddc-listing`
export const ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_MALADIES_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION}/maladies-listing`

export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE = `${ROUTING_ABSOLUTE_PATH_GESTION}/structure`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_ORGANIGRAMME = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/organigramme`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/staff`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_DETAILS = (id: number) => `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF}/${id}/details`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_CREATE = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF}/create`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/declarations-voyants`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/declarations-listing`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DDC_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/ddc-listing`
export const ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_MALADIES_LISTING = `${ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE}/maladies-listing`

// RELATIVE PATHS (used in Routing.tsx only)
export const ROUTING_RELATIVE_PATH_GESTION_PRODUCTION = `production`
export const ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS = `declarations-voyants`
export const ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DECLARATIONS_LISTING = `declarations-listing`
export const ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_DDC_LISTING = `ddc-listing`
export const ROUTING_RELATIVE_PATH_GESTION_PRODUCTION_MALADIES_LISTING = `maladies-listing`

export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE = `structure`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS = `declarations-voyants`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_ORGANIGRAMME = `organigramme`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF = `staff`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF_DETAILS = `staff/:id/details`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_STAFF_CREATE = `staff/create`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DECLARATIONS_LISTING = `declarations-listing`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_DDC_LISTING = `ddc-listing`
export const ROUTING_RELATIVE_PATH_GESTION_STRUCTURE_MALADIES_LISTING = `maladies-listing`
