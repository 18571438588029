import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ReactNode } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { ObjectSchema } from "yup"
import useFormik from "./useFormik"

const MyTextareaFormField = ({
     label,
     name,
     formikProps,
     placeholder,
     classNameLayout,
     classNameLabel,
     classNameField,
     autoFocus,
     minRows,
     maxRows,
     maxLength,
     validationSchema,
}: IPropsModel) => {
     const { isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()
     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <TextareaAutosize
                         {...formikProps?.getFieldProps(name)}
                         minRows={minRows}
                         maxRows={maxRows}
                         placeholder={placeholder}
                         maxLength={maxLength}
                         value={formikProps?.getFieldProps(name).value === undefined || formikProps.getFieldProps(name).value === null ? "" : formikProps.getFieldProps(name).value}
                         className={clsx("form-control bg-transparent", { "border-danger": formikProps && isFieldOnError(formikProps, name) })}
                         autoFocus={autoFocus}
                    />
                    {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     placeholder: string
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     minRows?: number
     maxRows?: number
     maxLength?: number
     validationSchema?: ObjectSchema<any>
}

export default MyTextareaFormField
