// ENDPOINTS

export const CONST_API_BASE_URL_ENDPOINT = process.env.REACT_APP_API_URL

// -- Tableau de bord
export const CONST_API_DASHBOARD = CONST_API_BASE_URL_ENDPOINT + `/dashboard`
export const CONST_API_DASHBOARD_POST_ALERTES_COUNTER_ENDPOINT = CONST_API_DASHBOARD + `/alertes-counter`
export const CONST_API_DASHBOARD_POST_ALERTES_MISSION_EXPIRATION_ENDPOINT = CONST_API_DASHBOARD + `/alertes-mission-expiration`
export const CONST_API_DASHBOARD_POST_ALERTES_CONSULTANTS_AVEC_DOCS_EMBAUCHE_NON_ENVOYES_ENDPOINT = CONST_API_DASHBOARD + `/consultants-avec-docs-embauche-non-envoyes`
export const CONST_API_DASHBOARD_POST_ALERTES_PERIODES_ESSAI_EXPIRATION_ENDPOINT = CONST_API_DASHBOARD + `/alertes-pe-expiration`

// -- Viviers
export const CONST_API_RECRUITMENT_VIVIERS = CONST_API_BASE_URL_ENDPOINT + "/recruitment/viviers"
export const CONST_API_RECRUITMENT_VIVIERS_POST_LISTING_ENDPOINT = CONST_API_RECRUITMENT_VIVIERS
export const CONST_API_RECRUITMENT_VIVIERS_POST_CREATE_ENDPOINT = CONST_API_RECRUITMENT_VIVIERS + "/create"
export const CONST_API_RECRUITMENT_VIVIERS_POST_EDIT_ENDPOINT = id => `${CONST_API_RECRUITMENT_VIVIERS}/${id}/edit`

// -- Annonces
export const CONST_API_RECRUITMENT_OFFERS_GET_LISTING_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + "/recruitment/offers"
export const CONST_API_RECRUITMENT_OFFERS_GET_DETAILS_ENDPOINT = id => CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/${id}/details`
export const CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_EDITION_ENDPOINT = id =>
     CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/${id}/generate-talentplug-url-for-edition`
export const CONST_API_RECRUITMENT_OFFERS_PUT_ENABLE_ENDPOINT = id => CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/${id}/enable`
export const CONST_API_RECRUITMENT_OFFERS_POST_CLONE_ENDPOINT = id => CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/${id}/clone`
export const CONST_API_RECRUITMENT_OFFERS_PUT_REPUBLISH_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/republish`
export const CONST_API_RECRUITMENT_OFFERS_PUT_ARCHIVE_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/archive`
export const CONST_API_RECRUITMENT_OFFERS_PUT_CHANGER_RESP_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + `/recruitment/offers/changer-responsable`
export const CONST_API_RECRUITMENT_OFFERS_GET_GENERATE_TALENTPLUG_URL_CREATION_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + "/recruitment/offers/generate-talentplug-url-for-creation"

// -- Candidates
export const CONST_API_RECRUITMENT_CANDIDATES = CONST_API_BASE_URL_ENDPOINT + "/recruitment/candidates"
export const CONST_API_RECRUITMENT_CANDIDATES_POST_LISTING = CONST_API_RECRUITMENT_CANDIDATES
export const CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE = CONST_API_RECRUITMENT_CANDIDATES + "/create"
export const CONST_API_RECRUITMENT_CANDIDATES_PUT_CHECK_AS_SEEN = (id: number) => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/check-as-seen`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_LIST_TRAITEMENT = CONST_API_RECRUITMENT_CANDIDATES + `/add-to-liste-traitement`
export const CONST_API_RECRUITMENT_CANDIDATES_post_REMOVE_FROM_LIST_TRAITEMENT = CONST_API_RECRUITMENT_CANDIDATES + `/remove-from-liste-traitement`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_TO_VIVIERS = CONST_API_RECRUITMENT_CANDIDATES + `/add-to-viviers`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_DOCUMENT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-document`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_PARTAGER_PROFIL_PAR_MAIL = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/partager-profil-par-mail`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_PROGRAMMER_ENTRETIEN_MEET_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/programmer-entretien-meet`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_DOWNLOAD_CURRICULUM_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/download-cv`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_REPLACE_CURRICULUM_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/replace-cv`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_STATUT_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-statut`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_REMOVE_STATUT_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/remove-statut`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_CLIENT_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-suivi-entretien-client`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_AGENCE_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-suivi-entretien-agence`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_TEL_DMS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-suivi-entretien-tel`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ADD_ENTRETIEN_MESSAGE_REPONDEUR_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-suivi-message-repondeur`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_DETAILS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/details`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_SET_DETAILS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/set-details`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_DOCOS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/docos`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_SUIVIS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/suivis`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_COMMENTS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/comments`
export const CONST_API_RECRUITMENT_CANDIDATES_GET_CANDIDATURES_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/candidatures`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_CREATE_COMMENT_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/add-comment`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDATES + `/${id}/entamer-demande-embauche`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_PAR_MAIL_ENDPOINT = id =>
     CONST_API_RECRUITMENT_CANDIDATES + `/${id}/demander-info-pre-embauche-to-candidat-existant`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_DEMANDER_INFO_PRE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT =
     CONST_API_RECRUITMENT_CANDIDATES + `/demander-info-pre-embauche-sans-passer-par-candidat-existant`
export const CONST_API_RECRUITMENT_CANDIDATES_POST_ENTAMER_DEMANDE_EMBAUCHE_SANS_PASSER_PAR_CANDIDAT_EXISTANT_PAR_MAIL_ENDPOINT =
     CONST_API_RECRUITMENT_CANDIDATES + `/entamer-demande-embauche-sans-passer-par-candidat-existant`
export const CONST_API_RECRUITMENT_CANDIDAT_GENERER_DOCO_ET_NOTIFIER_CANDIDAT_PAR_MAIL_ENDPOINT = id =>
     CONST_API_RECRUITMENT_CANDIDATES + `/${id}/generate-doco-vierge-and-notify-par-mail`

// -- Candidat document
const CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT = CONST_API_RECRUITMENT_CANDIDATES + "/documents"
export const CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_DELETE_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT + `/${id}/delete`
export const CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT_REPLACE_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCUMENT + `/${id}/replace`

// -- Candidat suivi
export const CONST_API_RECRUITMENT_CANDIDAT_SUIVI = CONST_API_BASE_URL_ENDPOINT + "/recruitment/candidatSuivi"
export const CONST_API_RECRUITMENT_CANDIDAT_SUIVI_GET_DETAILS_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_SUIVI + `/${id}/details`
export const CONST_API_RECRUITMENT_CANDIDAT_SUIVI_PUT_EDIT_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_SUIVI + `/${id}/edit`

// -- Candidat Doco
export const CONST_API_RECRUITMENT_CANDIDAT_DOCO = CONST_API_BASE_URL_ENDPOINT + "/recruitment/candidatDoco"
export const CONST_API_RECRUITMENT_CANDIDAT_DOCO_DELETE_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCO + `/${id}/delete`
export const CONST_API_RECRUITMENT_CANDIDAT_DOCO_LOCK_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCO + `/${id}/lock`
export const CONST_API_RECRUITMENT_CANDIDAT_DOCO_UNLOCK_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCO + `/${id}/unlock`
export const CONST_API_RECRUITMENT_CANDIDAT_DOCO_PARTAGER_PAR_MAIL_ENDPOINT = id => CONST_API_RECRUITMENT_CANDIDAT_DOCO + `/${id}/partager-par-mail`

// -- Demandes embauche
export const CONST_API_RECRUITMENT_DEMANDES_EMBAUCHE = CONST_API_BASE_URL_ENDPOINT + "/recruitment/demandes-embauche"
export const CONST_API_RECRUITMENT_DEMANDES_EMBAUCHE_LISTING_ENDPOINT = CONST_API_RECRUITMENT_DEMANDES_EMBAUCHE

// -- Personnel
const CONST_API_PERSONNEL = CONST_API_BASE_URL_ENDPOINT + "/personnel"
export const CONST_API_PERSONNEL_ORGANIGRAMME_ENDPOINT = CONST_API_PERSONNEL + `/organigramme`
export const CONST_API_PERSONNEL_LISTING_DETAILS_ENDPOINT = id => CONST_API_PERSONNEL + `/${id}/details`
export const CONST_API_PERSONNEL_LISTING_ENDPOINT = CONST_API_PERSONNEL + `/listing`
export const CONST_API_PERSONNEL_CREATE_ENDPOINT = CONST_API_PERSONNEL + `/create`
export const CONST_API_PERSONNEL_POST_GET_ANNUAIRE_ENDPOINT = CONST_API_PERSONNEL + `/get-annuaire`
export const CONST_API_PERSONNEL_POST_GET_DECLARATIONS_ENDPOINT = id => CONST_API_PERSONNEL + `/${id}/declarations`
export const CONST_API_PERSONNEL_GET_DDC_ENDPOINT = id => CONST_API_PERSONNEL + `/${id}/ddc`
export const CONST_API_PERSONNEL_GET_MALADIES_ENDPOINT = id => CONST_API_PERSONNEL + `/${id}/maladies`
export const CONST_API_PERSONNEL_RELANCE_DECLARATION_ENDPOINT = CONST_API_PERSONNEL + `/relance-declaration-activite`
export const CONST_API_PERSONNEL_GET_DROITS_OF_CONSULTANTS_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/get-droits-of-consultants`
export const CONST_API_PERSONNEL_SET_INFO_PERSO_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/set-info-perso`
export const CONST_API_PERSONNEL_SET_INFO_PRO_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/set-info-pro`
export const CONST_API_PERSONNEL_ADD_PERSONNELS_TO_TEAM_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/add-personnels-to-team`
export const CONST_API_PERSONNEL_REMOVE_PERSONNELS_FROM_TEAM_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/remove-personnels-from-team`
export const CONST_API_PERSONNEL_SET_NFC_ENDPOINT = (id: number) => CONST_API_PERSONNEL + `/${id}/set-nfc`

// ---- Personnel compte
const CONST_API_PERSONNEL_COMPTE = CONST_API_PERSONNEL + "/compte"
export const CONST_API_PERSONNEL_COMPTE_GET_INFO_PERSO_ENDPOINT = CONST_API_PERSONNEL_COMPTE + "/info-perso"
export const CONST_API_PERSONNEL_COMPTE_POST_SET_INFO_PERSO_ENDPOINT = CONST_API_PERSONNEL_COMPTE + "/set-info-perso"
export const CONST_API_PERSONNEL_COMPTE_ENABLE_ACCOUNT_ENDPOINT = (id: number) => CONST_API_PERSONNEL_COMPTE + `/${id}/enable-account`
export const CONST_API_PERSONNEL_COMPTE_DISABLE_ACCOUNT_ENDPOINT = (id: number) => CONST_API_PERSONNEL_COMPTE + `/${id}/disable-account`

// ---- Personnel Declaration
const CONST_API_PERSONNEL_DECLARATION = CONST_API_PERSONNEL + "/declaration"
export const CONST_API_PERSONNEL_DECLARATION_GET_BY_DATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION + `/get-declaration-by-date`
export const CONST_API_PERSONNEL_DECLARATION_GET_BY_ID_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/details`
export const CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_CRA_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/active-cra`
export const CONST_API_PERSONNEL_DECLARATION_GET_ACTIVE_NDF_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/active-ndf`
export const CONST_API_PERSONNEL_DECLARATION_CREATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION + `/create`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_FINALISER_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-envoyer-code-signature`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_REENVOYER_MAIL_FINALISATION_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-reevoyer-mail-finalisation`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_RECTIF_SANS_VALIDATION_RESP_OU_SIEGE_ENDPOINT = id =>
     CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-rectification-sans-validation-resp-siege`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_RESP_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-demande-rectification-par-resp`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-demande-rectification-par-siege`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_SIGNER_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-signer`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_RESP_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-validation-resp`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_PAR_SIEGE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-validation-siege`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_DEMANDE_REGUL_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-demande-regul`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_VALIDATION_DEMANDE_REGUL_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-validation-regul-par-resp`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_REJET_DEMANDE_REGUL_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi-rejet-regul-par-resp`
export const CONST_API_PERSONNEL_DECLARATION_VOYANTS_ENDPOINT = CONST_API_PERSONNEL_DECLARATION + `/voyants`
export const CONST_API_PERSONNEL_DECLARATION_LISTING_ENDPOINT = CONST_API_PERSONNEL_DECLARATION + `/listing`
export const CONST_API_PERSONNEL_DECLARATION_DOWNLOAD_ENDPOINT = CONST_API_PERSONNEL_DECLARATION + `/download`
export const CONST_API_PERSONNEL_DECLARATION_SUIVI_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION + `/${id}/suivi`

// ------ Personnel Declaration cra
const CONST_API_PERSONNEL_DECLARATION_CRA = CONST_API_PERSONNEL_DECLARATION + "/cra"
export const CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_TRAVAIL_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA + `/${id}/set-heures-travail`
export const CONST_API_PERSONNEL_DECLARATION_CRA_SET_HEURES_ABSENCE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA + `/${id}/set-heures-absence`
export const CONST_API_PERSONNEL_DECLARATION_CRA_AUTOCOMPLETE_HEURES_TRAVAIL_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA + `/${id}/autocomplete-heures-travail`
export const CONST_API_PERSONNEL_DECLARATION_CRA_DELETE_LIGNE_ACTIVITE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA + `/${id}/delete-ligne-activite`
export const CONST_API_PERSONNEL_DECLARATION_CRA_CREATE_LIGNE_ACTIVITE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA + `/${id}/create-ligne-activite`

// ------ Personnel Declaration cra-details
const CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS = CONST_API_PERSONNEL_DECLARATION + "/cra-details"
export const CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_TR_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS + `/${id}/toggle-tr`
export const CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS_TOGGLE_ALL_TR_MONTH_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_CRA_DETAILS + `/toggle-all-tr-month`

// ------ Personnel Declaration DDC
const CONST_API_PERSONNEL_DECLARATION_DDC = CONST_API_PERSONNEL_DECLARATION + "/ddc"
export const CONST_API_PERSONNEL_DECLARATION_DDC_CREATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_DDC + `/create`
export const CONST_API_PERSONNEL_DECLARATION_DDC_EDIT_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_DDC + `/${id}/edit`
export const CONST_API_PERSONNEL_DECLARATION_DDC_ANNULER_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_DDC + `/${id}/annuler`
export const CONST_API_PERSONNEL_DECLARATION_DDC_SUIVI_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_DDC + `/${id}/suivi`
export const CONST_API_PERSONNEL_DECLARATION_DDC_LISTING_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_DDC + `/listing`
export const CONST_API_PERSONNEL_DECLARATION_DDC_VALIDATION_PAR_RESPONSABLE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_DDC + `/${id}/validation-par-resp`
export const CONST_API_PERSONNEL_DECLARATION_DDC_REJET_PAR_RESPONSABLE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_DDC + `/${id}/rejet-par-resp`

// ------ Personnel Declaration Maladie
const CONST_API_PERSONNEL_DECLARATION_MALADIE = CONST_API_PERSONNEL_DECLARATION + "/maladie"
export const CONST_API_PERSONNEL_DECLARATION_MALADIE_CREATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_MALADIE + `/create`
export const CONST_API_PERSONNEL_DECLARATION_MALADIE_EDIT_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_MALADIE + `/${id}/edit`
export const CONST_API_PERSONNEL_DECLARATION_MALADIE_ANNULER_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_MALADIE + `/${id}/annuler`
export const CONST_API_PERSONNEL_DECLARATION_MALADIE_LISTING_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_MALADIE + `/listing`

// ------ Personnel Declaration NDF
const CONST_API_PERSONNEL_DECLARATION_NDF = CONST_API_PERSONNEL_DECLARATION + "/ndf"
export const CONST_API_PERSONNEL_DECLARATION_NDF_GET_LISTING_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_NDF + `/listing`
export const CONST_API_PERSONNEL_DECLARATION_NDF_CREATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_NDF + `/create`
export const CONST_API_PERSONNEL_DECLARATION_NDF_DELETE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF + `/${id}/delete`
export const CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_DATE_ENDPOINT = CONST_API_PERSONNEL_DECLARATION_NDF + `/get-ndf-by-date`
export const CONST_API_PERSONNEL_DECLARATION_GET_NDF_BY_ID_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF + `/${id}/info`
export const CONST_API_PERSONNEL_DECLARATION_GET_NDF_DEPENSES_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF + `/${id}/depenses`
export const CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_GROUPED_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF + `/${id}/add-depense-grouped`
export const CONST_API_PERSONNEL_DECLARATION_NDF_ADD_DEPENSE_SIMPLE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF + `/${id}/add-depense-simple`

// -------- Personnel Declaration NDF détails
const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE = CONST_API_PERSONNEL_DECLARATION_NDF + "/depense"
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_SIMPLE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE + `/${id}/edit-depense-simple`
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_EDIT_DEPENSE_GROUPED_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE + `/${id}/edit-depense-grouped`
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_ADD_JUSTIFICATIF_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE + `/${id}/add-justificatif`
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_DELETE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE + `/${id}/delete`

// -------- Personnel Declaration NDF détails
const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF = CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE + "/justificatif"
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_DELETE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF + `/${id}/delete`
export const CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF_REPLACE_ENDPOINT = id => CONST_API_PERSONNEL_DECLARATION_NDF_DEPENSE_JUSTIFICATIF + `/${id}/replace`

// ---- Templates
const CONST_API_PERSONNEL_TEMPLATES_ENDPOINT = CONST_API_PERSONNEL + "/templates"
export const CONST_API_PERSONNEL_TEMPLATES_POST_CREATE_ENDPOINT = CONST_API_PERSONNEL_TEMPLATES_ENDPOINT + "/create"
export const CONST_API_PERSONNEL_TEMPLATES_GET_DETAILS_ENDPOINT = id => CONST_API_PERSONNEL_TEMPLATES_ENDPOINT + `/${id}/details`
export const CONST_API_PERSONNEL_TEMPLATES_PUT_EDIT_ENDPOINT = id => CONST_API_PERSONNEL_TEMPLATES_ENDPOINT + `/${id}/edit`
export const CONST_API_PERSONNEL_TEMPLATES_DELETE_ENDPOINT = id => CONST_API_PERSONNEL_TEMPLATES_ENDPOINT + `/${id}/delete`

// ---- Saved filters & logs search candidat
const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + "/personnel/saved-filters-and-logs-search-candidat"
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_POST_CREATE_NEW_SAVED_FILTERS_OR_NEW_LOG_ENDPOINT =
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + "/create-new-saved-filters-or-new-log"
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_SOME_SAVED_FILTERS_AND_LOGS_ENDPOINT =
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + "/get-some-saved-filters-and-logs"
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_UPDATE_SAVED_FILTERS_ENDPOINT = id =>
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/saved-filters/${id}/update`
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_PUT_DUPLICATE_SAVED_FILTERS_ENDPOINT = id =>
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/saved-filters/${id}/duplicate`
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_DELETE_SAVED_FILTERS_ENDPOINT =
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/saved-filters/delete`
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_ONE_SAVED_FILTERS_OR_ONE_LOG_ENDPOINT = id =>
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/get-one-saved-filters-or-one-log/${id}`
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_POST_GET_LOGS_ENDPOINT = CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/get-logs`
export const CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_GET_SAVED_FILTERS_ENDPOINT =
     CONST_API_PERSONNEL_SAVED_FILTERS_AND_LOGS_SEARCH_CANDIDAT_ENDPOINT + `/get-saved-filters`

// -- Client
const CONST_API_CLIENT = CONST_API_BASE_URL_ENDPOINT + "/client"
// ---- PTF
const CONST_API_CLIENT_PTF = CONST_API_CLIENT + "/ptf"
export const CONST_API_CLIENT_PTF_GET_PERIODES_BCs_BLs_ENDPOINT = CONST_API_CLIENT_PTF + `/get-periodes-bc-bl`
export const CONST_API_CLIENT_PTF_GET_SIMPLE_DETAILS_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/simple-details`
export const CONST_API_CLIENT_PTF_GET_DETAILS_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/details`
export const CONST_API_CLIENT_PTF_GET_CDCs_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/cdc`
export const CONST_API_CLIENT_PTF_POST_ADD_CDC_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/add-cdc`
export const CONST_API_CLIENT_PTF_POST_CREATE_ENDPOINT = CONST_API_CLIENT_PTF + `/create`
export const CONST_API_CLIENT_PTF_GET_GENERATE_NEXT_REF_ENDPOINT = CONST_API_CLIENT_PTF + `/generate-next-ref`
export const CONST_API_CLIENT_PTF_GENERATE_CURRENT_PERIODE_NEXT_VERSION_REF_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/generate-current-periode-next-version-ref`
export const CONST_API_CLIENT_PTF_GET_GENERATE_REF_DATE_DEBUT_NEXT_PERIODE_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/reference-and-dateDebut-of-next-periode`
export const CONST_API_CLIENT_PTF_GET_LISTING_ENDPOINT = CONST_API_CLIENT_PTF + `/listing`
export const CONST_API_CLIENT_PTF_POST_SET_STATE_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/set-etat`
export const CONST_API_CLIENT_PTF_POST_SET_RESPONSABLE_ENDPOINT = CONST_API_CLIENT_PTF + `/set-responsable`
export const CONST_API_CLIENT_PTF_POST_SET_DETAILS_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/set-details`
export const CONST_API_CLIENT_PTF_POST_PROLONGEMENT_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/prolongement`
export const CONST_API_CLIENT_PTF_POST_VALIDATION_PAR_CLIENT_ENDPOINT = id => CONST_API_CLIENT_PTF + `/${id}/validation-client`

// ------ PTF CDC
const CONST_API_CLIENT_PTF_CDC = CONST_API_CLIENT_PTF + "/cdc"
export const CONST_API_CLIENT_PTF_CDC_POST_REPLACE_ENDPOINT = id => CONST_API_CLIENT_PTF_CDC + `/${id}/replace`
export const CONST_API_CLIENT_PTF_CDC_DELETE_ENDPOINT = id => CONST_API_CLIENT_PTF_CDC + `/${id}/delete`
export const CONST_API_CLIENT_PTF_CDC_GET_DOWNLOAD_CDC_ENDPOINT = id => CONST_API_CLIENT_PTF_CDC + `/${id}/download`

// ------ PTF période
const CONST_API_CLIENT_PTF_PERIODE = CONST_API_CLIENT_PTF + "/periode"
export const CONST_API_CLIENT_PTF_PERIODE_POST_ADD_VERSION_DOC_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE + `/${id}/add-version-doc-ptf`
export const CONST_API_CLIENT_PTF_PERIODE_POST_ADD_BC_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE + `/${id}/add-bon-commande`
export const CONST_API_CLIENT_PTF_PERIODE_DELETE_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE + `/${id}/delete`
export const CONST_API_CLIENT_PTF_PERIODE_POST_EDIT_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE + `/${id}/edit`

// ------ Bon de commande
const CONST_API_CLIENT_PTF_PERIODE_BC = CONST_API_CLIENT_PTF_PERIODE + "/bc"
export const CONST_API_CLIENT_PTF_PERIODE_BC_POST_ADD_BL_OR_LIVRABLE_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE_BC + `/${id}/add-bl-ou-livrable`
export const CONST_API_CLIENT_PTF_PERIODE_BC_DELETE_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE_BC + `/${id}/delete`
export const CONST_API_CLIENT_PTF_PERIODE_BC_EDIT_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE_BC + `/${id}/edit`

// -------- Bon de livraison
const CONST_API_CLIENT_PTF_PERIODE_BC_BL = CONST_API_CLIENT_PTF_PERIODE_BC + "/bl"
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_DELETE_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/${id}/delete`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_EDIT_ENDPOINT = id => CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/${id}/edit`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_MANQUANTS_OF_GIVEN_YEAR_ENDPOINT = CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/get-total-bl-manquants-of-given-year`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_BL_MANQUANTS_OF_GIVEN_MONTH_ENDPOINT = CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/get-bl-manquants-of-given-month`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_TOTAL_BL_NON_FACTURES_OF_GIVEN_YEAR_ENDPOINT =
     CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/get-total-bl-non-factures-of-given-year`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_GET_BL_NON_FACTURES_OF_GIVEN_MONTH_ENDPOINT = CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/get-bl-non-factures-of-given-month`
export const CONST_API_CLIENT_PTF_PERIODE_BC_BL_POST_CHECK_AS_FACTURE_ENDPOINT = CONST_API_CLIENT_PTF_PERIODE_BC_BL + `/check-as-facture`

// -- Fournisseur
const CONST_API_FOURNISSEUR = CONST_API_BASE_URL_ENDPOINT + "/fournisseur"
export const CONST_API_FOURNISSEUR_GET_LISTING_ENDPOINT = CONST_API_FOURNISSEUR + `/listing`
export const CONST_API_FOURNISSEUR_SET_SIMPLE_DETAILS_ENDPOINT = id => CONST_API_FOURNISSEUR + `/${id}/set-basic-info`
export const CONST_API_FOURNISSEUR_GET_SIMPLE_DETAILS_ENDPOINT = id => CONST_API_FOURNISSEUR + `/${id}/simple-details`
export const CONST_API_FOURNISSEUR_GET_DOCUMENTS_ENDPOINT = id => CONST_API_FOURNISSEUR + `/${id}/documents`
export const CONST_API_FOURNISSEUR_POST_ADD_DOCUMENT_ENDPOINT = id => CONST_API_FOURNISSEUR + `/${id}/add-document`

// --- Fournisseur resp
const CONST_API_FOURNISSEUR_RESPONSABLE_ENDPOINT = CONST_API_FOURNISSEUR + `/responsable`
export const CONST_API_FOURNISSEUR_RESPONSABLE_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_TALENT_ENDPOINT = id =>
     CONST_API_FOURNISSEUR_RESPONSABLE_ENDPOINT + `/${id}/envoyer-instruction-connexion-espace-talent`
export const CONST_API_FOURNISSEUR_RESPONSABLE_GET_DETAILS_ENDPOINT = id => CONST_API_FOURNISSEUR_RESPONSABLE_ENDPOINT + `/${id}/details`
export const CONST_API_FOURNISSEUR_RESPONSABLE_SET_DETAILS_ENDPOINT = id => CONST_API_FOURNISSEUR_RESPONSABLE_ENDPOINT + `/${id}/edit-details`

// ---- Fournisseur document
const CONST_API_FOURNISSEUR_DOCUMENT = CONST_API_FOURNISSEUR + "/document"
export const CONST_API_FOURNISSEUR_DOCUMENT_DELETE_ENDPOINT = id => CONST_API_FOURNISSEUR_DOCUMENT + `/${id}/delete`
export const CONST_API_FOURNISSEUR_DOCUMENT_REPLACE_ENDPOINT = id => CONST_API_FOURNISSEUR_DOCUMENT + `/${id}/replace`

// -- Business
const CONST_API_BUSINESS = CONST_API_BASE_URL_ENDPOINT + "/business"
// ---- Consultant
const CONST_API_BUSINESS_CONSULTANT = CONST_API_BUSINESS + "/consultant"
export const CONST_API_BUSINESS_CONSULTANT_SUPPRIMER_DELEGATION_ADMINISTRATIVE_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/supprimerDelegationAdministrative`
export const CONST_API_BUSINESS_CONSULTANTS_SUIVIS_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivis`
export const CONST_API_BUSINESS_CONSULTANT_GET_CURRENT_PTF_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/current-ptf`
export const CONST_API_BUSINESS_CONSULTANTS_PERSONNELS_DELEGATION_DROITS_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/get-personnels-delegation-droits`
export const CONST_API_BUSINESS_CONSULTANT_POST_LISTING_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/listing`
export const CONST_API_BUSINESS_CONSULTANT_POST_SIMULATION_RENTABILITE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/simulation-rentabilite-avant-apres`
export const CONST_API_BUSINESS_CONSULTANT_GET_DDC_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/ddc`
export const CONST_API_BUSINESS_CONSULTANT_GET_ARRETS_MALADIE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/arrets-maladie`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_CONTRAT_TRAVAIL_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/contrat-travail`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_SUIVI_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/suivi`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_GENERATE_OR_UPLOAD_CONTRAT_TRAVAIL_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/generate-upload-contrat-travail`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_FOR_NAVIGATION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-info-for-navigation`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_PERSO_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-info-perso`
export const CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_PERSO_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/set-info-perso`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CONTRACT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-info-contract`
export const CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CONTRACT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/set-info-contract`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_INFO_CLIENT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-info-client`
export const CONST_API_BUSINESS_CONSULTANT_SET_DE_INFO_CLIENT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/set-info-client`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_ODM_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-odm`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_SET_ODM_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/set-odm`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCUMENTS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/get-documents`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_DOCS_TO_SEND_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/docs-embauche-to-send`
export const CONST_API_BUSINESS_CONSULTANT_IGNORER_ENVOI_DOCS_EMBAUCHE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/ignorer-envoi-docs-embauche`
export const CONST_API_BUSINESS_CONSULTANT_ENVOYER_DOCS_EMBAUCHE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/envoyer-docs-embauche`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_SOUMISSION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/soumission`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_RESPONSABLE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/demande-rectif-par-responsable`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_DIRECTION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/demande-rectif-par-direction`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_DEMANDE_RECTIF_SIEGE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/demande-rectif-par-siege`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_RESPONSABLE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/validation-responsable`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_DIRECTION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/validation-direction`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_VALIDATION_SIEGE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/validation-siege`
export const CONST_API_BUSINESS_CONSULTANT_POST_ADD_DOCUMENT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/add-document`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_DIRECTION_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/rejet-definitif-par-direction`
export const CONST_API_BUSINESS_CONSULTANT_POST_DE_REJET_DEFINITIF_PAR_RESPONSABLE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/rejet-definitif-par-responsable`
export const CONST_API_BUSINESS_CONSULTANT_DELETE_DE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/delete`
export const CONST_API_BUSINESS_CONSULTANT_GET_DE_DOWNLOAD_DOCS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/demande-embauche/download-docs`
export const CONST_API_BUSINESS_CONSULTANT_GET_DETAILS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/details`
export const CONST_API_BUSINESS_CONSULTANT_GET_DOCUMENTS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/documents`
export const CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/get-declarations-activite`
export const CONST_API_BUSINESS_CONSULTANT_POST_GET_DECLARATIONS_ACTIVITE_OLD_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/get-declarations-activite-old`
export const CONST_API_BUSINESS_CONSULTANT_POST_PUT_ENABLE_ESPACE_CONSULTANT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/activer-espace-consultant`
export const CONST_API_BUSINESS_CONSULTANT_ENVOYER_INSTRUCTIONS_CONNEXION_ESPACE_COLLAB_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/envoyer-instructions-connexion-talent`
export const CONST_API_BUSINESS_CONSULTANT_POST_PUT_DISABLE_ESPACE_CONSULTANT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/desactiver-espace-consultant`
export const CONST_API_BUSINESS_CONSULTANT_POST_SET_INFO_PERSO_DETAILS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/set-info-perso`
export const CONST_API_BUSINESS_CONSULTANT_RELANCE_DECLARATION_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/relance-declaration-activite`

// ---- Consultant ddc
const CONST_API_BUSINESS_CONSULTANT_DDC = CONST_API_BUSINESS_CONSULTANT + "/ddc"
export const CONST_API_BUSINESS_CONSULTANT_DDC_POST_LISTING_ENDPOINT = CONST_API_BUSINESS_CONSULTANT_DDC + `/listing`
export const CONST_API_BUSINESS_CONSULTANT_DDC_POST_VALIDATION_PAR_RESPONSABLE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DDC + `/${id}/validation`
export const CONST_API_BUSINESS_CONSULTANT_DDC_POST_REJET_PAR_RESPONSABLE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DDC + `/${id}/rejet`

// ---- Consultant suivi
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_RATTACHEMENT_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-entite-rattachement`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_ENTITE_FACTURATION_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-entite-facturation`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_COEFF_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-coeff`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_POSITION_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-position`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_AJOUT_MACHINE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/changement-ou-ajout-machine`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SUPPRESSION_MACHINE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/suppression-machine`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_HEURES_SUPP_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-heures-supp`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_STATUT_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-statut`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MANAGER_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/changement-manager`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SET_PERSONNEL_DELEGATION_DROITS_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/set-personnel-delegation-droits`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_AJOUT_PERSONNEL_DELEGATION_DROITS_ENDPOINT = CONST_API_BUSINESS_CONSULTANT + `/suivi/ajout-personnel-delegation-droits`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_EVOLUTION_TAUX_VENTE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/evolution-taux-vente`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_AFFECTER_MISSION_TO_SALARIE_EMBAUCHE_SUR_PROFIL_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/affecter-mission-to-salarie-embauche-sur-profil`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPRISE_MISSION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/reprise-mission`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_INTERCONTRAT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/intercontrat`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_SORTIE_EFFECTIFS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/sortie-des-effectifs`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_SALARIE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/prolongement-mission-salarie`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PROLONGEMENT_MISSION_NON_SALARIE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/prolongement-mission-non-salarie`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_SALARIE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/changement-mission-salarie`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_MISSION_NON_SALARIE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/changement-mission-non-salarie`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_REPORTING_MISSION_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/reporting-mission`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_PRIME_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/prime`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_COMMENTAIRE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/commentaire`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_SALAIRE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/changement-salaire`
export const CONST_API_BUSINESS_CONSULTANT_POST_SUIVI_CHANGEMENT_TAUX_ACHAT_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT + `/${id}/suivi/changement-taux-achat`

// ---- Consultant Document
const CONST_API_BUSINESS_CONSULTANT_DOCUMENT = CONST_API_BUSINESS_CONSULTANT + "/document"
export const CONST_API_BUSINESS_CONSULTANT_DOCUMENT_DELETE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DOCUMENT + `/${id}/delete`
export const CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_REPLACE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DOCUMENT + `/${id}/replace`
export const CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_ADD_VERSION_SIGNEE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DOCUMENT + `/${id}/add-version-signee`
export const CONST_API_BUSINESS_CONSULTANT_DOCUMENT_POST_SET_DETAILS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DOCUMENT + `/${id}/set-details`

// ---- Consultant Mission
const CONST_API_BUSINESS_CONSULTANT_MISSION = CONST_API_BUSINESS_CONSULTANT + "/mission"
export const CONST_API_BUSINESS_CONSULTANT_MISSION_DELETE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_MISSION + `/${id}/delete`
export const CONST_API_BUSINESS_CONSULTANT_MISSION_POST_REPLACE_DOC_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_MISSION + `/${id}/replace-doc`
export const CONST_API_BUSINESS_CONSULTANT_MISSION_POST_ADD_DOC_SIGNEE_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_MISSION + `/${id}/add-doc-signe`
export const CONST_API_BUSINESS_CONSULTANT_MISSION_POST_SET_DETAILS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_MISSION + `/${id}/set-details`

// ---- Consultant Declaration
const CONST_API_BUSINESS_CONSULTANT_DECLARATION = CONST_API_BUSINESS_CONSULTANT + "/declaration"
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_VOYANTS_ENDPOINT = CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/voyants`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_LISTING_ENDPOINT = CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/listing`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_CRA_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/active-cra`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_NDF_DETAILS_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/active-ndf-details`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_ACTIVE_FACTURES_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/active-factures`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_RESPONSABLE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-validation-responsable`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_VALIDATION_SIEGE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-validation-siege`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_RESPONSABLE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-demander-rectification-par-responsable`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_POST_ADD_SUIVI_DEMANDE_RECTIF_PAR_SIEGE_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-demander-rectification-par-siege`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_REJET_DEMANDE_REGUL_PAR_RESP_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-rejet-demande-regul-par-resp`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_SUIVI_VALIDATION_DEMANDE_REGUL_PAR_RESP_ENDPOINT = id =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-suivi-validation-demande-regul-par-resp`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_GET_SUIVI_ENDPOINT = id => CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/suivi`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_DOWNLOAD_ENDPOINT = CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/download`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_ADD_REVIEW_ENDPOINT = (id: number) => CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${id}/add-review`
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_DELETE_REVIEW_ENDPOINT = (declaration_id: number, review_id: number) =>
     CONST_API_BUSINESS_CONSULTANT_DECLARATION + `/${declaration_id}/reviews/${review_id}/delete`

// ------ Declaration MALADIE
const CONST_API_BUSINESS_CONSULTANT_DECLARATION_MALADIE = CONST_API_BUSINESS_CONSULTANT_DECLARATION + "/maladie"
export const CONST_API_BUSINESS_CONSULTANT_DECLARATION_MALADIES_LISTING_ENDPOINT = CONST_API_BUSINESS_CONSULTANT_DECLARATION_MALADIE + "/listing"

// -- Administratif
const CONST_API_ADMINISTRATIF = CONST_API_BASE_URL_ENDPOINT + "/administratif"

// ---- Gestion declarations
const CONST_API_ADMINISTRATIF_GESTION_DECLARATIONS = CONST_API_ADMINISTRATIF + "/gestion-declarations"
export const CONST_API_ADMINISTRATIF_GESTION_DECLARATIONS_GET_VOYANTS_ENDPOINT = CONST_API_ADMINISTRATIF_GESTION_DECLARATIONS + "/voyants"

// -- Utils
const CONST_API_UTILS_ENDPOINT = CONST_API_BASE_URL_ENDPOINT + "/utils"
export const CONST_API_UTILS_GET_CONSULTANT_ALLOWED_SUIVIS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/get-consultants-allowed-suivis"
export const CONST_API_UTILS_GET_CONSULTANT_ALL_SUIVIS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/get-consultants-all-suivis"
export const CONST_API_UTILS_GET_ENTITES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/entites"
export const CONST_API_UTILS_GET_METIER_CATEGORIES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/categories-metier"
export const CONST_API_UTILS_GET_CANDIDATES_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/candidates"
export const CONST_API_UTILS_GET_OFFERS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/offers"
export const CONST_API_UTILS_GET_PAYS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/pays"
export const CONST_API_UTILS_GET_AVAILABLE_NFC_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/available-nfc"
export const CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/get-google-maps-address-autocompletion"
export const CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/get-google-maps-distance-between-two-points-in-km"
export const CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/indicatif-tel"
export const CONST_API_UTILS_GET_DEPARTEMENTS_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/departements-france"
export const CONST_API_UTILS_GET_VILLES_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/villes-france"
export const CONST_API_UTILS_GET_REGIONS_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/regions-france"
export const CONST_API_UTILS_GET_BUSINESS_UNITS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/business-inits"
export const CONST_API_UTILS_GET_METIERS_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/metiers"
export const CONST_API_UTILS_GET_METIERS_PERSONNEL_INTERNE_URL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/metiers-personnel-interne"
export const CONST_API_UTILS_GET_VIVIERS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/viviers"
export const CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/active-personnels"
export const CONST_API_UTILS_GET_ACTIVE_USERNAMES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/active-usernames"
export const CONST_API_UTILS_GET_AGENCES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/agences"
export const CONST_API_UTILS_GET_TEMPLATES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/templates"
export const CONST_API_UTILS_GET_CLIENTS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/clients"
export const CONST_API_UTILS_GET_CLIENT_RESPONSABLES_ENDPOINT = id => CONST_API_UTILS_ENDPOINT + `/client/${id}/responsables`
export const CONST_API_UTILS_GET_CLIENT_ADRESSES_ENDPOINT = id => CONST_API_UTILS_ENDPOINT + `/client/${id}/adresses`
export const CONST_API_UTILS_POST_CALCULATE_DATE_FIN_PE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/calculate-date-fin-pe"
export const CONST_API_UTILS_GET_MACHINES_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/machines"
export const CONST_API_UTILS_GET_BASES_FACTU_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/bases-facturation"
export const CONST_API_UTILS_GET_FOURNISSEURS_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/fournisseurs"
export const CONST_API_UTILS_GET_FOURNISSEURS_RESP_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/fournisseurs-resp"
export const CONST_API_UTILS_GET_PTFS_MERE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/ptfs-mere"
export const CONST_API_UTILS_GET_TYPES_LIGNE_CRA_PERSONNEL_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/types-ligne-cra-personnel"
export const CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/ptfs-non-mere"
export const CONST_API_UTILS_POST_SIMULATION_RENTABILITE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/simulation-rentabilite"
export const CONST_API_UTILS_GET_DOWNLOAD_DRIVE_FILE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/download-drive-file"
export const CONST_API_UTILS_POST_ADD_METIER_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-metier"
export const CONST_API_UTILS_POST_ADD_CLIENT_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-client"
export const CONST_API_UTILS_POST_ADD_RESPONSABLE_CLIENT_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-responsable-client"
export const CONST_API_UTILS_POST_ADD_ADRESSE_CLIENT_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-adresse-client"
export const CONST_API_UTILS_POST_ADD_MACHINE_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-machine"
export const CONST_API_UTILS_POST_ADD_FOURNISSEUR_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-fournisseur"
export const CONST_API_UTILS_POST_ADD_FOURNISSEUR_RESP_ENDPOINT = CONST_API_UTILS_ENDPOINT + "/add-fournisseur-resp"

const CONST_API_UTILS_PRE_FILL_FORM_DATA = CONST_API_UTILS_ENDPOINT + "/get-pre-fill-form-data"
export const CONST_API_UTILS_GET_PRE_FILL_FORM_DATA_POST_PROLONGEMENT_MISSION_ENDPOINT = CONST_API_UTILS_PRE_FILL_FORM_DATA + "/prolongement-mission"
