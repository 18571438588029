import React from "react"
import { SidebarMenuItem } from "./SidebarMenuItem"
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub"
import { useAuth } from "../../../AppContext"
import MyTooltip from "@common-utils/MyTooltip"
import {
     ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DDC_LISTING,
     ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS,
     ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_MALADIES_LISTING,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DDC_LISTING,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_MALADIES_LISTING,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_ORGANIGRAMME,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF,
     ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_CREATE,
} from "../../../modules/gestion/routing/routing-endpoints"
import { ROUTING_ABSOLUTE_PATH_FOURNISSEUR_CREATE, ROUTING_ABSOLUTE_PATH_FOURNISSEUR_LISTING } from "../../../modules/fournisseur/routing/routing-endpoints"

const SidebarMenuMain = () => {
     const { currentUser } = useAuth()

     const hasAccessToGestionProductionDeclarations = (() => {
          return (
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_IA", "ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) ||
               currentUser!.consultantsSousGestion.filter(item => item.type === "DROIT_GESTION_DECLARATIONS" && item.total > 0).length > 0
          )
     })()

     const hasAccessToGestionProductionBonsLivraison = (() => {
          return (
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_IA", "ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) ||
               currentUser!.nbPtfSousGestion > 0
          )
     })()

     const hasAccessToGestionProductionDdc = (() => {
          return (
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_IA", "ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) ||
               currentUser!.consultantsSousGestion.filter(item => ["DROIT_CONSULTATION_DDC", "DROIT_VALIDATION_DDC", "DROIT_REJET_DDC"].includes(item.type) && item.total > 0)
                    .length > 0
          )
     })()

     const hasAccessToGestionProductionArretsMaldie = (() => {
          return (
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_IA", "ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) ||
               currentUser!.consultantsSousGestion.filter(item => ["DROIT_CONSULTATION_DDC", "DROIT_VALIDATION_DDC", "DROIT_REJET_DDC"].includes(item.type) && item.total > 0)
                    .length > 0
          )
     })()

     const hasAccessToGestionProduction = (() => {
          return hasAccessToGestionProductionDeclarations || hasAccessToGestionProductionBonsLivraison
     })()

     const hasAccessToGestionStructureDeclarations = (() => {
          return !!(
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string) ||
               (currentUser?.personnelHierarchyFromUpToDown && currentUser?.personnelHierarchyFromUpToDown.length > 0)
          )
     })()

     const hasAccesToGestionStructureStaff = (() => {
          return (
               ["ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string) ||
               currentUser?.authorizedAppModules?.includes("PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_GESTION_STRUCTURE_LISTE_PERSONNEL")
          )
     })()

     const hasAccessToGestionStructureDdc = (() => {
          return !!(
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string) ||
               (currentUser?.personnelHierarchyFromUpToDown && currentUser?.personnelHierarchyFromUpToDown.length > 0)
          )
     })()

     const hasAccessToGestionStructureArretsMaladie = (() => {
          return !!(
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string) ||
               (currentUser?.personnelHierarchyFromUpToDown && currentUser?.personnelHierarchyFromUpToDown.length > 0)
          )
     })()

     const hasAccessToGestionStructure = (() => {
          return hasAccessToGestionStructureDeclarations
     })()

     const hasAccesToFournisseurs = (() => {
          return ["ROLE_GROUP_APP_ADMIN", "ROLE_GROUP_DIRECTION"].includes(currentUser?.roleGroup as string)
     })()

     return (
          <>
               <SidebarMenuItem to="/dashboard" fontIcon={"fas fa-home"} title={"Tableau de bord"} />

               <SidebarMenuItem to="/personnel/mon-compte/profil" fontIcon={"fas fa-user"} title={"Mon profil"} />

               <SidebarMenuItemWithSub title={"Mes activités"} to={"#"} fontIcon={"fas fa-calendar-days"}>
                    <SidebarMenuItem to="personnel/mes-activites/declarations" title={"Déclarations mensuelles"} fontIcon={"fas fa-list-check"} />

                    <MyTooltip title={"Mes Demandes De Congés"} placement={"right"}>
                         <span>
                              <SidebarMenuItem to="personnel/mes-activites/ddc" fontIcon={"fas fa-umbrella-beach"} title={"Mes DDC"} />
                         </span>
                    </MyTooltip>

                    <SidebarMenuItem to="personnel/mes-activites/maladies" fontIcon={"fas fa-file-medical"} title={"Mes arrêts maladie"} />
                    <MyTooltip title={"Mes notes de frais"} placement={"right"}>
                         <span>
                              <SidebarMenuItem to="personnel/mes-activites/ndf" fontIcon={"fas fa-file-invoice-dollar"} title={"Mes NDF"} />
                         </span>
                    </MyTooltip>
               </SidebarMenuItemWithSub>

               {/* BEGIN:: RECRUTEMENT */}
               {currentUser?.authorizedAppModules?.find(item =>
                    [
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_ANNONCE",
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_CANDIDAT",
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_VIVIER",
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_DE",
                    ].includes(item)
               ) && (
                    <>
                         <div className="menu-item">
                              <div className="menu-content">
                                   <span className="menu-heading fw-bold text-uppercase fs-7">Recrutement</span>
                              </div>
                         </div>

                         {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_ANNONCE") && (
                              <SidebarMenuItemWithSub title={"Annonces"} to="/recrutement/annonces" fontIcon={"fas fa-file-alt"}>
                                   <SidebarMenuItem to="/recrutement/annonces/listing" hasBullet title={"Listing"} />
                                   <SidebarMenuItem to="/recrutement/annonces/create" hasBullet title={"Diffuser"} />
                              </SidebarMenuItemWithSub>
                         )}

                         {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_CANDIDAT") && (
                              <SidebarMenuItemWithSub title={"Candidats"} to={"recrutement/candidats"} fontIcon={"fas fa-users-viewfinder"}>
                                   <SidebarMenuItem to="/recrutement/candidats/search" hasBullet title={"Recherche"} />
                                   <SidebarMenuItem to="/recrutement/candidats/create" hasBullet title={"Ajouter"} />
                              </SidebarMenuItemWithSub>
                         )}

                         {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_VIVIER") && (
                              <SidebarMenuItemWithSub title={"Viviers"} to="/recrutement/viviers" fontIcon={"fas fa-heart"}>
                                   <SidebarMenuItem to="/recrutement/viviers/listing" hasBullet title={"Listing"} />
                                   <SidebarMenuItem to="/recrutement/viviers/create" hasBullet title={"Ajouter"} />
                              </SidebarMenuItemWithSub>
                         )}

                         {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_DE") && (
                              <SidebarMenuItemWithSub to="#" title={"Demandes d'embauche"} fontIcon={"fas fa-door-open"}>
                                   <SidebarMenuItem to={"recrutement/demandes-embauche/listing"} title={"Liste"} hasBullet />
                                   <SidebarMenuItem to={"recrutement/demandes-embauche/start"} title={"Créer"} hasBullet />
                              </SidebarMenuItemWithSub>
                         )}
                    </>
               )}

               {/* END:: RECRUTEMENT */}

               {/* BEGIN:: BUSINESS */}
               {currentUser?.authorizedAppModules?.find(item =>
                    [
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_COLLAB",
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_FOURNISSEUR",
                         "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_PTF",
                    ].includes(item)
               ) && (
                    <>
                         <div className="menu-item">
                              <div className="menu-content">
                                   <span className="menu-heading fw-bold text-uppercase fs-7">Business</span>
                              </div>

                              {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_PTF") && (
                                   <SidebarMenuItemWithSub title={"PTF"} to="/client/ptf" fontIcon={"fas fa-lightbulb"}>
                                        <SidebarMenuItem to="/client/ptf/create" hasBullet title={"Ajouter"} />
                                        <SidebarMenuItem to="/client/ptf/listing" hasBullet title={"Listing"} />
                                   </SidebarMenuItemWithSub>
                              )}

                              {currentUser?.authorizedAppModules?.find(item => item === "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_COLLAB") && (
                                   <SidebarMenuItemWithSub title={"Collaborateurs"} to="/business/consultants" fontIcon={"fas fa-user-astronaut"}>
                                        <SidebarMenuItem to="/business/consultants/listing" hasBullet title={"Mon périmètre"} />
                                        <SidebarMenuItem to="/business/consultants/suivis" hasBullet title={"Suivis"} />
                                   </SidebarMenuItemWithSub>
                              )}

                              {hasAccesToFournisseurs && (
                                   <SidebarMenuItemWithSub title={"Fournisseur"} to="/fournisseur" fontIcon={"fas fa-building"}>
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_FOURNISSEUR_LISTING} hasBullet title={"Listing"} />
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_FOURNISSEUR_CREATE} hasBullet title={"Ajouter"} />
                                   </SidebarMenuItemWithSub>
                              )}
                         </div>
                    </>
               )}

               {/* END:: Business */}

               {/* BEGIN:: Gestion */}
               {(hasAccessToGestionStructure || hasAccessToGestionProduction) && (
                    <>
                         <div className="menu-item">
                              <div className="menu-content">
                                   <span className="menu-heading fw-bold text-uppercase fs-7">Gestion</span>
                              </div>
                         </div>

                         {hasAccessToGestionProduction && (
                              <SidebarMenuItemWithSub title={"Production"} to="#" fontIcon={"fas fa-building-columns"}>
                                   {hasAccessToGestionProductionDeclarations && (
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DECLARATIONS_VOYANTS} hasBullet title={"Déclarations d'activité"} />
                                   )}
                                   {hasAccessToGestionProductionDdc && <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_DDC_LISTING} hasBullet title={"DDC"} />}
                                   {hasAccessToGestionProductionArretsMaldie && (
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_PRODUCTION_MALADIES_LISTING} hasBullet title={"Arrêts maladie"} />
                                   )}
                                   {hasAccessToGestionProductionBonsLivraison && <SidebarMenuItem to="/client/bl/dashboard" hasBullet title={"Bons de livraison"} />}
                              </SidebarMenuItemWithSub>
                         )}

                         {/* Seuls le siège + direction + app admin */}
                         {hasAccessToGestionStructure && (
                              <SidebarMenuItemWithSub title={"Structure"} to="#" fontIcon={"fas fa-vault"}>
                                   {hasAccesToGestionStructureStaff && (
                                        <>
                                             <SidebarMenuItemWithSub title={"Équipe"} to="#" fontIcon={"fas fa-users"}>
                                                  <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF} hasBullet title={"Liste"} />
                                                  <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_ORGANIGRAMME} hasBullet title={"Organigramme"} />
                                                  <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF_CREATE} hasBullet title={"Ajouter"} />
                                             </SidebarMenuItemWithSub>
                                        </>
                                   )}
                                   {hasAccessToGestionStructureDeclarations && (
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DECLARATIONS_VOYANTS} hasBullet title={"Déclarations d'activité"} />
                                   )}
                                   {hasAccessToGestionStructureDdc && <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_DDC_LISTING} hasBullet title={"DDC"} />}
                                   {hasAccessToGestionStructureArretsMaladie && (
                                        <SidebarMenuItem to={ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_MALADIES_LISTING} hasBullet title={"Arrêts maladie"} />
                                   )}
                              </SidebarMenuItemWithSub>
                         )}
                    </>
               )}
               {/* END:: Gestion */}

               {/* BEGIN:: Bibliothèque */}
               <div className="menu-item">
                    <div className="menu-content">
                         <span className="menu-heading fw-bold text-uppercase fs-7">Bibliothèque</span>
                    </div>
               </div>

               <SidebarMenuItemWithSub title={"Drive"} to="/client/ptf" fontIcon={"fas fa-folder-tree"}>
                    <SidebarMenuItem to="https://drive.google.com/drive/folders/1lZ1saimlvmZfkkV2M4IDx7jBpsqlj0bQ" hasBullet title={"Qualité"} target={"_blank"} />
                    <SidebarMenuItem to="https://drive.google.com/drive/folders/1ZB4iJQgiIwirgaflXpLWiTURp_-xS1XD" hasBullet title={"Communication"} target={"_blank"} />
               </SidebarMenuItemWithSub>

               {/* END:: Bibliothèque */}
          </>

          // <SidebarMenuItem to="/business/consultants/declarations/voyants" hasBullet title={"Déclarations mensuelles"} />
          //
          // <SidebarMenuItem to="/administratif/declarations/voyants" fontIcon={"fas fa-calendar-alt"} title={"Déclarations mensuelles"} />
     )
}

export { SidebarMenuMain }
