import React, { ReactNode, useCallback } from "react"
import Select from "react-select"
import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ObjectSchema } from "yup"
import useSelectReact, { SelectOptionsType } from "./useSelectReact"
import useFormik from "./useFormik"

const MySelectFormField = ({
     label,
     formikProps,
     options,
     name,
     isClearable = true,
     isSearchable = false,
     placeholder,
     isMulti = false,
     classNameLayout,
     classNameLabel,
     classNameField,
     autoFocus,
     validationSchema,
}: IPropsModel) => {
     const { styles, formatGroupLabel } = useSelectReact()
     const { isFieldValid, isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     // selectValue is based on formik "value". It will get all options object matching with value.
     // Example: if value = [1, 2] it will get 2 objects [{value: 1, label: A}, {value: 2, label B}]
     // It handles <select> with one selectable option, <select> with multiple selectable options and optgroups
     const selectValue = useCallback(() => {
          if (!formikProps?.getFieldProps(name).value) return []
          if (Array.isArray(formikProps?.getFieldProps(name).value)) {
               if (formikProps?.getFieldProps(name).value.length == 0) return []

               let selectedOptions: any[] = []
               options.map(opt => {
                    // if opt has options it means that it's an optgroup
                    if ("options" in opt) {
                         opt.options?.map(item => {
                              if (formikProps?.getFieldProps(name).value.includes(item.value)) selectedOptions.push(item)
                         })
                    } else {
                         if (formikProps?.getFieldProps(name).value.includes(opt.value)) {
                              selectedOptions.push(opt)
                         }
                    }
               })
               return selectedOptions
          } else {
               return options.find(opt => opt.value === formikProps?.getFieldProps(name).value)
          }
     }, [formikProps?.getFieldProps(name).value])

     // onChange: sets the field's value of the targeted field
     function onChange(val: any) {
          if (val === null) {
               formikProps?.setFieldValue(name, null) // Form only needs the value
          } else {
               if (Array.isArray(val)) {
                    formikProps?.setFieldValue(
                         name,
                         val.map(i => i?.value)
                    )
               } else {
                    formikProps?.setFieldValue(name, val?.value)
               }
          }
     }

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <Select
                         {...formikProps?.getFieldProps(name)}
                         name={name}
                         menuPortalTarget={document.body}
                         options={options}
                         value={selectValue()}
                         onChange={onChange}
                         isMulti={isMulti}
                         isClearable={isClearable}
                         isSearchable={isSearchable}
                         placeholder={placeholder}
                         formatGroupLabel={formatGroupLabel}
                         styles={styles(!!(formikProps && isFieldOnError(formikProps, name)), !!(formikProps && isFieldValid(formikProps, name)))}
                         autoFocus={autoFocus}
                         onMenuClose={() => formikProps?.getFieldHelpers(name).setTouched(true)}
                         noOptionsMessage={() => "Aucun résultat"}
                    />
                    {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     options: SelectOptionsType
     isClearable?: boolean
     isSearchable?: boolean
     placeholder: string
     isMulti?: boolean
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     validationSchema?: ObjectSchema<any>
}

export default MySelectFormField
