import React, { ReactNode, useCallback, useEffect, useState } from "react"
import Select from "react-select"
import axios, { AxiosResponse } from "axios"
import { useIntl } from "react-intl"
import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ObjectSchema } from "yup"
import useSelectReact, { SelectOptionsType, SelectOptionType, SelectOptionValueType } from "./useSelectReact"
import useFormik from "./useFormik"

const MySelectGetAllDataFromServerFormField = ({
     name,
     endpoint,
     isClearable = true,
     isSearchable = true,
     placeholder,
     method,
     payload = undefined,
     isMulti = false,
     defaultOptions = [],
     label,
     classNameLabel,
     classNameField,
     formikProps,
     autoFocus,
     classNameLayout,
     onChangeCallback = () => {},
     validationSchema,
     footerRightSide,
}: IPropsModel) => {
     const { formatGroupLabel, styles } = useSelectReact()
     const { isFieldValid, isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()
     const intl = useIntl()

     const [isLoading, setIsLoading] = useState<boolean>(false)
     const [options, setOptions] = useState<SelectOptionsType>(defaultOptions)
     const [noOptionsMessage, setNoOptionsMessage] = useState<string>("Aucun résultat")

     function getOptions() {
          setIsLoading(true)

          let result
          if (method === "GET") {
               /* Convert true to 1 and false to 0 for simplifying backend operations. */
               for (const key in payload) {
                    if (payload[key] === true) payload[key] = 1
                    if (payload[key] === false) payload[key] = 0
               }
               result = axios.get<SelectOptionsType>(endpoint, { params: payload })
          } else {
               result = axios.post<SelectOptionsType>(endpoint, payload)
          }

          result
               .then((r: AxiosResponse) => {
                    setOptions(r.data)
               })
               .catch(() => {
                    setOptions([])
                    setNoOptionsMessage(intl.formatMessage({ id: "GLOBAL.UNEXPECTED_ERROR_MESSAGE" }))
               })
               .finally(() => {
                    setIsLoading(false)
               })
     }

     // selectValue is based on formik "value". It will get all options object matching with value.
     // Example: if value = [1, 2] it will get 2 objects [{value: 1, label: A}, {value: 2, label B}]
     // It handles <select> with one selectable option, <select> with multiple selectable options and optgroups
     const selectValue = useCallback(() => {
          if (!formikProps?.getFieldProps(name).value) return []

          if (Array.isArray(formikProps?.getFieldProps(name).value)) {
               if (formikProps?.getFieldProps(name).value.length == 0) return []

               let selectedOptions: any[] = []
               options.map(opt => {
                    // if opt has options it means that it's an optgroup
                    if ("options" in opt) {
                         opt.options?.map(item => {
                              if (formikProps?.getFieldProps(name).value.includes(item.value)) selectedOptions.push(item)
                         })
                    } else {
                         if (formikProps?.getFieldProps(name).value.includes(opt.value)) {
                              selectedOptions.push(opt)
                         }
                    }
               })
               return selectedOptions
          } else {
               return options.find(opt => opt.value === formikProps?.getFieldProps(name).value)
          }
     }, [formikProps?.getFieldProps(name).value, options])

     // onChange: sets the field's value of the targeted field
     function onChange(val: any) {
          if (val === null) {
               formikProps?.setFieldValue(name, null) // Form only needs the value
          } else {
               if (Array.isArray(val)) {
                    formikProps?.setFieldValue(
                         name,
                         val.map(i => i.value)
                    )
                    onChangeCallback(
                         val.map(i => i.value),
                         val.map(i => i)
                    )
               } else {
                    formikProps?.setFieldValue(name, val?.value)
                    onChangeCallback(val?.value, val)
               }
          }
     }

     useEffect(() => {
          getOptions()
     }, [])

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <Select
                         {...formikProps?.getFieldProps(name)}
                         name={name}
                         isClearable={isClearable}
                         isSearchable={isSearchable}
                         placeholder={placeholder}
                         onFocus={getOptions}
                         options={options}
                         isLoading={isLoading}
                         formatGroupLabel={formatGroupLabel}
                         styles={styles(!!(formikProps && isFieldOnError(formikProps, name)), !!(formikProps && isFieldValid(formikProps, name)))}
                         isMulti={isMulti}
                         loadingMessage={() => intl.formatMessage({ id: "REACT_SELECT.LOADING" })}
                         value={selectValue()}
                         menuPortalTarget={document.body}
                         onMenuClose={() => formikProps?.getFieldHelpers(name).setTouched(true)}
                         onChange={onChange}
                         noOptionsMessage={() => (options.length > 0 ? intl.formatMessage({ id: "REACT_SELECT.NO_RESULTS" }) : noOptionsMessage)}
                         autoFocus={autoFocus}
                    />
                    {(formikProps || footerRightSide) && (
                         <div className="row">
                              {formikProps && isFieldOnError(formikProps, name) && (
                                   <div className="col text-start">
                                        <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>
                                   </div>
                              )}

                              {footerRightSide && <div className="col text-end">{footerRightSide}</div>}
                         </div>
                    )}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     endpoint: string
     placeholder: string
     method: "POST" | "GET"
     defaultOptions?: SelectOptionsType
     payload?: Record<string, any>
     isMulti?: boolean
     isClearable?: boolean
     isSearchable?: boolean
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     onChangeCallback?: (val: SelectOptionValueType | Array<SelectOptionValueType>, option: SelectOptionType | Array<SelectOptionType>) => void
     validationSchema?: ObjectSchema<any>
     footerRightSide?: ReactNode
}

export default MySelectGetAllDataFromServerFormField
