import React, { useState } from "react"
import { IConsultantArretMaladieModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MyTooltip from "@common-utils/MyTooltip"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useConsultantDeclarationDetailsContext } from "./_ConsultantDeclarationDetails"
import { getConsultantArretsRequest } from "../../core/_requests"
import { IGetConsultantDdcRequestModel } from "../../core/_models"
import MyModal from "@common-utils/MyModal"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const REACT_QUERY_KEY_GET_CONSULTANT_ARRETS = "REACT_QUERY_KEY_GET_CONSULTANT_ARRETS"

export function ConsultantDeclarationDetailsArrets({ consultant_id, filtres }: { consultant_id: number; filtres: IGetConsultantDdcRequestModel }) {
     const context = useConsultantDeclarationDetailsContext()
     const [documentInModal, setDocumentInModal] = useState<{
          driveID: string
          modalTitle: string
          isLoading: boolean
     }>()

     const getMaladieListingQuery = useQuery<IConsultantArretMaladieModel[], AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_ARRETS, () =>
          getConsultantArretsRequest(consultant_id, filtres).then(r => r.data)
     )

     const NavigationFooter = () => {
          return (
               <div className="d-flex justify-content-end gap-4">
                    {context.isActionDemandeRectifAvailable && (
                         <button type="button" className="btn btn-sm btn-warning d-flex align-items-center" onClick={() => context.handleDemandeRectification()}>
                              <span className={"d-none d-sm-flex"}>Demander une rectification</span>
                              <span className={"fas fa-thumbs-down fs-3 ms-1 align-middle"} />
                         </button>
                    )}
                    {context.isActionValiderOuRejeterDemandeRegulAvailable && (
                         <>
                              <button type="button" className="btn btn-sm btn-success d-flex align-items-center" onClick={() => context.handleValidationDemandeRegul()}>
                                   <span className={"d-none d-sm-flex"}>Valider la demande de régul.</span>
                                   <span className={"fas fa-thumbs-up fs-3 ms-1 align-middle"} />
                              </button>
                              <button type="button" className="btn btn-sm btn-danger d-flex align-items-center" onClick={() => context.handleRejetDemandeRegul()}>
                                   <span className={"fas fa-thumbs-down fs-3 align-middle"} />
                              </button>
                         </>
                    )}
                    <button type="button" className="btn btn-sm btn-primary d-flex align-items-center" onClick={() => context.setNavigationStep("ddc")}>
                         DDC <span className={"fas fa-arrow-right fs-3 align-middle"} />
                    </button>
               </div>
          )
     }

     return (
          <div>
               {/* loader */}
               {getMaladieListingQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!getMaladieListingQuery.isFetching && getMaladieListingQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{getMaladieListingQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => getMaladieListingQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* no data found */}
               {!getMaladieListingQuery.isFetching && !getMaladieListingQuery.isError && getMaladieListingQuery.data?.length === 0 && (
                    <div className={"text-center p-4"}>
                         <p style={{ fontWeight: 500 }}>Aucun arrêt n'a été ajouté ...</p>
                    </div>
               )}

               {/* with DDC*/}
               {!getMaladieListingQuery.isFetching && !getMaladieListingQuery.isError && getMaladieListingQuery.data && getMaladieListingQuery.data.length > 0 && (
                    <>
                         <div className={"mb-4"}>
                              <div className={"row"}>
                                   {getMaladieListingQuery.data.map((item, key) => (
                                        <div className="col-md-6 mb-9" key={key}>
                                             <div className="d-flex align-items-center">
                                                  <MyTooltip title={"Consulter le justificatif"}>
                                                       <div className={"w-65px d-flex justify-content-center"}>
                                                            <i
                                                                 className={"fas fa-file-medical text-hover-primary cursor-pointer"}
                                                                 style={{ fontSize: "4rem" }}
                                                                 onClick={() =>
                                                                      setDocumentInModal({
                                                                           isLoading: true,
                                                                           modalTitle: "Justificatif",
                                                                           driveID: item.justificatifGoogleDriveID as string,
                                                                      })
                                                                 }
                                                            />
                                                       </div>
                                                  </MyTooltip>
                                                  <div className={"border-start ps-3 ms-2"}>
                                                       <span className={"text-dark fw-bolder fs-6 d-block py-2"}>Arrêt maladie</span>
                                                       <span className="text-muted d-block fw-bold mb-4">
                                                            <MyTooltip
                                                                 title={
                                                                      <span>
                                                                           Du {item.du?.parseZone().format("dddd D MMMM YYYY")} au {item.au?.parseZone().format("dddd D MMMM YYYY")}
                                                                      </span>
                                                                 }
                                                            >
                                                                 <span className="badge badge-light-primary me-1 mb-1">
                                                                      {item.du?.parseZone().format("DD/MM/YYYY")} - {item.au?.parseZone().format("DD/MM/YYYY")}
                                                                 </span>
                                                            </MyTooltip>
                                                       </span>
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>
                         </div>
                    </>
               )}
               <NavigationFooter />

               {/* Document in modal */}
               {documentInModal && (
                    <MyModal title={<span>{documentInModal.modalTitle}</span>} show={true} handleClose={() => setDocumentInModal(undefined)} noPadding size={"lg"}>
                         <MyLoadingOverlay isActive={documentInModal.isLoading}>
                              <iframe
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(documentInModal.driveID)}
                                   onLoad={() => setDocumentInModal(prev => (prev ? { ...prev, isLoading: false } : prev))}
                                   style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                              />
                         </MyLoadingOverlay>
                    </MyModal>
               )}
          </div>
     )
}

export default ConsultantDeclarationDetailsArrets
