import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { IHttpErrorResponseModel, IPersonnelNdfDetailModel, IPersonnelNdfDocumentModel } from "@common-models/*"
import { IEditConnectedUserNdfDepenseSimpleRequestModel } from "../../../../mes-activites/ndf/core/_models"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MONTANT_INDEMNITE_PAR_KM,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
} from "@common-constants/*"
import MyGoogleDriveFileViewerInModal from "../../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"
import MyTooltip from "@common-utils/MyTooltip"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import {
     addPersonnelNdfJustificatifRequest,
     deletePersonnelNdfJustificatifRequest,
     editPersonnelNdfDepenseSimpleRequest,
     replacePersonnelNdfJustificatifRequest,
} from "../../core/_requests"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import {
     CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT,
     CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT,
} from "../../../../../../endpoints"
import MySelectSearchDataFromServerFormField from "@common-components/fields/MySelectSearchDataFromServerFormField"
import MyInputFormField from "@common-components/fields/MyInputFormField"
import MyCheckboxFormField from "@common-components/fields/MyCheckboxFormField"
import MySelectFormField from "@common-components/fields/MySelectFormField"
import MyDateDayMonthYearFormField from "@common-components/fields/MyDateDayMonthYearFormField"
import MyTextareaFormField from "@common-components/fields/MyTextareaFormField"
import MySelectCreatableFormField from "@common-components/fields/MySelectCreatableFormField"

const ConnectedUserNdfDepenseSimpleEdit = ({ detail }: IConnectedUserNdfDepenseGroupedEditPropsModel) => {
     const queryClient = useQueryClient()
     const context = useConnectedUserNdfDepensesContext()
     const [docToBeReplaced, setDocToBeReplaced] = useState<IPersonnelNdfDocumentModel>()
     const refReplaceDocInput = useRef<any>(null)
     const refAddDocInput = useRef<any>(null)
     const MySwal = withReactContent(Swal)
     const [justificatifInModal, setJustificatifInModal] = useState<IPersonnelNdfDocumentModel>()
     const [detailsIndemniteKm, setDetailsIndemniteKm] = useState<{
          distanceEnKmEntreDepartEtDestination: number
          distanceTotal: number
          montantTTC: number
     }>({ distanceEnKmEntreDepartEtDestination: 0, distanceTotal: 0, montantTTC: 0 })

     // Schema
     const validationSchema = Yup.object().shape({
          date: Yup.string().required("Champ requis"),
          categorie: Yup.string().required("Champ requis"),
          montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
          tva: Yup.number(),
          description: Yup.string().required().label(`"Description"`),
          documents: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          repasSeulOuEnGroupe: Yup.string()
               .label(`"Seul ou en groupe"`)
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                    then: schema => schema.required(),
               })
               .nullable(),
          invites: Yup.array()
               .label(`"Invités"`)
               .when("repasSeulOuEnGroupe", {
                    is: "REPAS_MIDI_EN_GROUPE",
                    then: schema => schema.required(),
               })
               .nullable(),
          adresseDepart: Yup.string()
               .nullable()
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
                    then: schema => schema.required(),
               }),
          adresseArrive: Yup.string()
               .nullable()
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
                    then: schema => schema.required(),
               }),
          nbTrajets: Yup.number()
               .nullable()
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
                    then: schema => schema.required(),
               }),
          isAllerRetour: Yup.boolean(),
     })

     const initialValues: IEditConnectedUserNdfDepenseSimpleRequestModel = {
          date: detail.date?.format("YYYY-MM-DD"),
          categorie: detail.categorie as string,
          montantTTC: Number(detail.montantTtc!.toFixed(2)),
          description: detail.description || "",
          repasSeulOuEnGroupe: detail.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI ? (detail.invites ? "REPAS_MIDI_EN_GROUPE" : "REPAS_MIDI_SEUL") : undefined,
          invites: detail.invites || undefined,
          adresseDepart: detail.indemniteKmDetails?.addresseDepart,
          adresseArrive: detail.indemniteKmDetails?.addresseArrive,
          nbTrajets: detail.indemniteKmDetails?.nbTrajets,
          isAllerRetour: detail.indemniteKmDetails?.isAllerRetour || false,
     }

     useEffect(() => {
          if (detail.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && detail.indemniteKmDetails) {
               setDetailsIndemniteKm(prev => ({
                    distanceEnKmEntreDepartEtDestination: detail.indemniteKmDetails?.distanceKmEntreDepartEtArrive!,
                    distanceTotal: detail.indemniteKmDetails?.distanceKmTotal!,
                    montantTTC: Number((detail.indemniteKmDetails?.distanceKmTotal! * CONST_MONTANT_INDEMNITE_PAR_KM).toFixed(2)),
               }))
          }
     }, [])

     function handleSubmit(values: IEditConnectedUserNdfDepenseSimpleRequestModel, helpers: FormikHelpers<IEditConnectedUserNdfDepenseSimpleRequestModel>) {
          helpers.setStatus(null)
          editPersonnelNdfDepenseSimpleRequest(detail.id as number, values)
               .then(r => {
                    queryClient.setQueryData<IPersonnelNdfDocumentModel[] | undefined>(
                         [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                         (prev: IPersonnelNdfDocumentModel[] | undefined) => {
                              if (prev) {
                                   const index = prev.findIndex(item => item.id === detail.id)
                                   if (index >= 0) {
                                        prev[index] = {
                                             ...prev[index],
                                             ...r.data,
                                        }
                                   }
                              }

                              context.computeTotalExpenses(prev || [])

                              return prev
                         }
                    )

                    helpers.setSubmitting(false)
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setCurrentDepenseInEdition(undefined)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)

                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     function handleAddDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Ajout en cours ...", {
                    autoClose: false,
               })
               addPersonnelNdfJustificatifRequest(detail.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                              [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                              (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                   if (prev) {
                                        prev.map(item => {
                                             if (item.id === detail.id) {
                                                  item.personnelNdfDocuments = [...(item.personnelNdfDocuments || []), r.data]
                                                  return item
                                             }
                                        })
                                   }

                                   return prev
                              }
                         )

                         toast.dismiss()
                         toast.success("Le justificatif a bien été ajouté")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleReplaceDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    autoClose: false,
               })

               FileAndDriveHelper.processFiles([file_binary]).then(processedFiles => {
                    replacePersonnelNdfJustificatifRequest(docToBeReplaced?.id as number, processedFiles[0])
                         .then(r => {
                              queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                                   [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                                   (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                        if (prev) {
                                             prev.map(item => {
                                                  if (item.id === detail.id) {
                                                       item.personnelNdfDocuments?.map(item_ => {
                                                            if (item_.id === docToBeReplaced?.id) {
                                                                 item_.googleDriveID = r.data.googleDriveID
                                                                 item_.googleDriveMimeType = r.data.googleDriveMimeType
                                                                 return item_
                                                            }
                                                       })
                                                  }
                                             })
                                        }

                                        return prev
                                   }
                              )

                              toast.dismiss()
                              toast.success("Le justificatif a bien été remplacé")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e?.response?.data.detail, { autoClose: false })
                         })
               })
          }
     }

     function handleRemoveDoc(id: number, index: number) {
          MySwal.fire({
               icon: "warning",
               title: `Confirmez-vous la suppression du justificatif n°${index}?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deletePersonnelNdfJustificatifRequest(id)
                         .then(() => {
                              queryClient.setQueryData<IPersonnelNdfDetailModel[] | undefined>(
                                   [context.REACT_QUERY_KEY_GET_LISTING, context.ndfId],
                                   (prev: IPersonnelNdfDetailModel[] | undefined) => {
                                        if (prev) {
                                             prev.map(item => {
                                                  if (item.id === detail.id) {
                                                       item.personnelNdfDocuments = item.personnelNdfDocuments?.filter(item_ => item_.id !== id)
                                                  }
                                             })
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Le justificatif a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !MySwal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className="d-flex flex-column gap-4 w-xl-65 w-xxl-50">
                    <div className={"p-4 border border-dashed mb-10"}>
                         <div className="d-flex justify-content-between align-items-center mb-2">
                              <h4>Justificatifs</h4>
                              <MyTooltip title={"Ajouter un justificatif"}>
                                   <div className="btn btn-icon btn-sm btn-active-light-success" onClick={() => refAddDocInput.current.click()}>
                                        <i className={"fas fa-plus-circle fs-2 text-success"} />
                                        <input type="file" className={"d-none"} ref={refAddDocInput} onChange={handleAddDoc} />
                                   </div>
                              </MyTooltip>
                         </div>
                         <div className="separator separator-dashed mb-6" />

                         {detail.personnelNdfDocuments?.map((item, key) => (
                              <div className={`d-flex align-items-center ${key > 0 && "mt-4"}`} key={key}>
                                   <span className={"me-2"}>Justificatif n° {key + 1}: </span>
                                   <button className={"btn btn-sm btn-primary p-0 px-2 py-1 d-flex align-items-center me-2"} onClick={() => setJustificatifInModal(item)}>
                                        Consulter <i className={"fas fa-eye ms-1"} />
                                   </button>
                                   <button
                                        className={"btn btn-sm btn-warning p-0 px-2 py-1 d-flex align-items-center me-2"}
                                        onClick={() => {
                                             setDocToBeReplaced(item)
                                             refReplaceDocInput.current.click()
                                        }}
                                   >
                                        Remplacer <i className={"fas fa-sync ms-1"} />
                                   </button>
                                   <input type="file" className={"d-none"} ref={refReplaceDocInput} onChange={handleReplaceDoc} />
                                   <button
                                        className={"btn btn-sm btn-danger p-0 px-2 py-1 d-flex align-items-center me-2"}
                                        onClick={() => handleRemoveDoc(item.id as number, key + 1)}
                                   >
                                        Supprimer <i className={"fas fa-trash ms-1"} />
                                   </button>
                              </div>
                         ))}
                    </div>
               </div>

               <div className="w-xl-65 w-xl-50">
                    <div className={"p-4 border border-dashed mb-4"}>
                         <h4 className={"mb-4"}>Détails</h4>
                         <div className="separator separator-dashed mb-6" />

                         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                              {helpers => {
                                   async function calculateMontantIndemniteKmEntreDepartEtDestination(departure?: string, arrival?: string) {
                                        if (departure && arrival) {
                                             return axios
                                                  .post<{ distance: number | null }>(CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT, {
                                                       departure,
                                                       arrival,
                                                  })
                                                  .then(({ data: { distance } }) => {
                                                       if (distance) {
                                                            setDetailsIndemniteKm(prev => ({
                                                                 ...prev,
                                                                 distanceEnKmEntreDepartEtDestination: distance,
                                                            }))
                                                       }

                                                       return distance || 0
                                                  })
                                        }

                                        return 0
                                   }

                                   function calculateMontantIndemniteKmTotal(distanceEnKmEntreDepartEtDestination: number, isAllerRetour: boolean, nbTrajets: number = 0) {
                                        if (distanceEnKmEntreDepartEtDestination > 0) {
                                             const dt = Number((distanceEnKmEntreDepartEtDestination * (isAllerRetour ? nbTrajets * 2 : nbTrajets)).toFixed(2))
                                             setDetailsIndemniteKm(prev => ({
                                                  ...prev,
                                                  distanceTotal: dt,
                                                  montantTTC: Number((dt * CONST_MONTANT_INDEMNITE_PAR_KM).toFixed(2)),
                                             }))
                                        }
                                   }

                                   return (
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}
                                             <div className="d-flex flex-column gap-4">
                                                  <MyDateDayMonthYearFormField
                                                       name={"date"}
                                                       formikProps={helpers}
                                                       validationSchema={validationSchema}
                                                       placeholder={"Date"}
                                                       label={"Date"}
                                                       classNameLabel={"col-lg-4"}
                                                       classNameField={"col-lg-8"}
                                                  />
                                                  <MySelectFormField
                                                       formikProps={helpers}
                                                       validationSchema={validationSchema}
                                                       label={"Catégorie"}
                                                       options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                       name={`categorie`}
                                                       isSearchable={false}
                                                       isClearable={false}
                                                       placeholder={"Catégorie"}
                                                       classNameLabel={"col-lg-4"}
                                                       classNameField={"col-lg-8"}
                                                  />
                                                  {helpers.values.categorie !== CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                                       <MyInputFormField
                                                            formikProps={helpers}
                                                            validationSchema={validationSchema}
                                                            name={"montantTTC"}
                                                            label={"Montant TTC"}
                                                            type={"number"}
                                                            inputMode={"decimal"}
                                                            placeholder={"Montant TTC"}
                                                            classNameLabel={"col-lg-4"}
                                                            classNameField={"col-lg-8"}
                                                       />
                                                  )}

                                                  {helpers.values.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                                       <>
                                                            <MySelectSearchDataFromServerFormField
                                                                 formikProps={helpers}
                                                                 validationSchema={validationSchema}
                                                                 label={<div className={"required"}>Adresse de départ</div>}
                                                                 name={"adresseDepart"}
                                                                 endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={8}
                                                                 placeholder={"Saisissez une adresse"}
                                                                 classNameLabel={"col-lg-4"}
                                                                 classNameField={"col-lg-8"}
                                                                 defaultOptions={[
                                                                      {
                                                                           label: helpers.values.adresseDepart!,
                                                                           value: helpers.values.adresseDepart!,
                                                                      },
                                                                 ]}
                                                                 onChangeCallback={val => {
                                                                      calculateMontantIndemniteKmEntreDepartEtDestination(val as string, helpers.values.adresseArrive).then(
                                                                           distance => {
                                                                                calculateMontantIndemniteKmTotal(distance, helpers.values.isAllerRetour!, helpers.values.nbTrajets)
                                                                           }
                                                                      )
                                                                 }}
                                                            />

                                                            <MySelectSearchDataFromServerFormField
                                                                 formikProps={helpers}
                                                                 validationSchema={validationSchema}
                                                                 label={<div className={"required"}>Adresse de destination</div>}
                                                                 name={"adresseArrive"}
                                                                 endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                                 method={"GET"}
                                                                 minimumLengthSearch={8}
                                                                 placeholder={"Saisissez une adresse"}
                                                                 classNameLabel={"col-lg-4"}
                                                                 classNameField={"col-lg-8"}
                                                                 defaultOptions={[
                                                                      {
                                                                           label: helpers.values.adresseArrive!,
                                                                           value: helpers.values.adresseArrive!,
                                                                      },
                                                                 ]}
                                                                 onChangeCallback={val => {
                                                                      calculateMontantIndemniteKmEntreDepartEtDestination(helpers.values.adresseDepart, val as string).then(
                                                                           dist => {
                                                                                calculateMontantIndemniteKmTotal(dist, helpers.values.isAllerRetour!, helpers.values.nbTrajets)
                                                                           }
                                                                      )
                                                                 }}
                                                            />

                                                            <div className="row">
                                                                 <label className="col-lg-4 col-form-label fs-6 fw-bolder text-gray-900 required">Nombre de trajets</label>

                                                                 <div className={"col-lg-8 d-flex gap-2"}>
                                                                      <MyInputFormField
                                                                           formikProps={helpers}
                                                                           validationSchema={validationSchema}
                                                                           name={"nbTrajets"}
                                                                           type={"number"}
                                                                           inputMode={"numeric"}
                                                                           placeholder={"Nombre de trajets"}
                                                                           classNameLayout={"me-2"}
                                                                           onChangeCallback={val => {
                                                                                calculateMontantIndemniteKmTotal(
                                                                                     detailsIndemniteKm.distanceEnKmEntreDepartEtDestination,
                                                                                     helpers.values.isAllerRetour!,
                                                                                     val
                                                                                )
                                                                           }}
                                                                      />
                                                                      <div className={"d-flex align-items-center"}>
                                                                           <MyCheckboxFormField
                                                                                formikProps={helpers}
                                                                                validationSchema={validationSchema}
                                                                                name={"isAllerRetour"}
                                                                                onChangeCallback={val => {
                                                                                     calculateMontantIndemniteKmTotal(
                                                                                          detailsIndemniteKm.distanceEnKmEntreDepartEtDestination,
                                                                                          val,
                                                                                          helpers.values.nbTrajets
                                                                                     )
                                                                                }}
                                                                           />
                                                                           <div style={{ marginLeft: "-1rem" }}>Aller-retour</div>
                                                                      </div>
                                                                 </div>
                                                            </div>

                                                            <div className={"p-4 border border-dashed text-gray-600"}>
                                                                 <h4 className={"mb-4 text-gray-600"}>
                                                                      Détails de l'indemnité kilométrique
                                                                      <i className="fas fa-info-circle fs-3 float-end" />
                                                                 </h4>
                                                                 <div className="separator separator-dashed mb-6" />

                                                                 <p>Distance totale: {detailsIndemniteKm.distanceTotal.toFixed(2)} Km</p>
                                                                 <p>Montant TTC: {detailsIndemniteKm.montantTTC.toFixed(2)}€</p>
                                                            </div>
                                                       </>
                                                  )}

                                                  {helpers.values.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                       <>
                                                            <MySelectFormField
                                                                 formikProps={helpers}
                                                                 validationSchema={validationSchema}
                                                                 label={"Type de repas"}
                                                                 name={"repasSeulOuEnGroupe"}
                                                                 options={[
                                                                      {
                                                                           value: "REPAS_MIDI_SEUL",
                                                                           label: "Repas seul",
                                                                      },
                                                                      {
                                                                           value: "REPAS_MIDI_EN_GROUPE",
                                                                           label: "Repas en groupe",
                                                                      },
                                                                 ]}
                                                                 placeholder={"Choisissez si seul ou en groupe"}
                                                                 classNameLabel={"col-lg-4"}
                                                                 classNameField={"col-lg-8"}
                                                            />

                                                            {helpers.values.repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                                 <>
                                                                      <div className="row d-flex justify-content-lg-end">
                                                                           <div className={"mb-2 col-lg-8 px-lg-3"}>
                                                                                <MyAlert type={"primary"}>
                                                                                     Veuillez fournir les noms et prénoms de vos invités. <br />
                                                                                     S'il s'agit d'un client, ajoutez le nom de la société en plus.
                                                                                </MyAlert>
                                                                           </div>
                                                                      </div>

                                                                      <MySelectCreatableFormField
                                                                           formikProps={helpers}
                                                                           validationSchema={validationSchema}
                                                                           name={"invites"}
                                                                           placeholder={"Saisissez les invités"}
                                                                           classNameLabel={"col-lg-4"}
                                                                           classNameField={"col-lg-8"}
                                                                      />
                                                                 </>
                                                            )}
                                                       </>
                                                  )}
                                                  <MyTextareaFormField
                                                       formikProps={helpers}
                                                       validationSchema={validationSchema}
                                                       name={"description"}
                                                       label={"Description"}
                                                       minRows={4}
                                                       placeholder={"Description"}
                                                       classNameLabel={"col-lg-4"}
                                                       classNameField={"col-lg-8"}
                                                  />

                                                  <div className="separator separator-dashed" />
                                                  <div className="text-center d-flex flex-sm-row flex-column justify-content-end">
                                                       <button
                                                            className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                            type={"submit"}
                                                            disabled={helpers.isSubmitting}
                                                       >
                                                            {!helpers.isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                                       </button>
                                                  </div>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </div>
               </div>

               {justificatifInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={justificatifInModal.googleDriveID as string}
                         googleDriveMimetype={justificatifInModal.googleDriveMimeType as string}
                         modalTitle={<span>Justificatif</span>}
                         handleCloseModal={() => setJustificatifInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConnectedUserNdfDepenseGroupedEditPropsModel {
     detail: IPersonnelNdfDetailModel
}

export default ConnectedUserNdfDepenseSimpleEdit
