import React, { ReactNode } from "react"
import CreatableSelect from "react-select/creatable"
import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ObjectSchema } from "yup"
import useSelectReact from "./useSelectReact"
import useFormik from "./useFormik"

const MySelectCreatableField = ({
     name,
     label,
     formikProps,
     isClearable = true,
     placeholder,
     isValidNewOption,
     classNameLabel,
     classNameField,
     classNameLayout,
     autoFocus,
     validationSchema,
}: IPropsModel) => {
     const { formatGroupLabel, styles } = useSelectReact()
     const { isFieldValid, isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     const value: any[] = formikProps?.getFieldProps(name).value

     function onChange(values: any) {
          formikProps?.setFieldValue(
               name,
               values.map((item: any) => item.value)
          )
     }

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <CreatableSelect
                         {...formikProps?.getFieldProps(name)}
                         menuPortalTarget={document.body}
                         options={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
                         name={name}
                         formatGroupLabel={formatGroupLabel}
                         value={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
                         onChange={onChange}
                         noOptionsMessage={() => placeholder}
                         isValidNewOption={isValidNewOption}
                         formatCreateLabel={inputValue => `Ajouter "${inputValue}"`}
                         isMulti={true}
                         isClearable={isClearable}
                         placeholder={placeholder}
                         styles={styles(!!(formikProps && isFieldOnError(formikProps, name)), !!(formikProps && isFieldValid(formikProps, name)))}
                         autoFocus={autoFocus}
                         onMenuClose={() => formikProps?.getFieldHelpers(name).setTouched(true)}
                    />
                    {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     isClearable?: boolean
     placeholder: string
     isValidNewOption?: (val: string) => boolean // Callback function: checks if the new option is valid
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     validationSchema?: ObjectSchema<any>
}

export default MySelectCreatableField
