import React from "react"
import { Form, Formik, FormikHelpers } from "formik"
import moment from "moment"
import MyAlert from "@common-utils/MyAlert"
import { ICloneAnnonceRequestModel } from "../core/_models"
import { cloneAnnonceRequest, detailsAnnonceRequest } from "../core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyCard from "@common-utils/MyCard"

import { CONST_API_UTILS_GET_ENTITES_ENDPOINT, CONST_API_UTILS_GET_METIER_CATEGORIES_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../endpoints"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel, OffreModel } from "@common-models/*"
import { toast } from "react-toastify"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import * as Yup from "yup"
import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { useQuery } from "react-query"
import { useNavigate, useParams } from "react-router-dom"

const FormClone = ({ offerDetails }: { offerDetails: OffreModel }) => {
     const navigate = useNavigate()

     const schema = Yup.object().shape({
          publishedAt: Yup.string().label(`"Date de diffusion"`).required(),
          intitule: Yup.string().label(`"Titre"`).required(),
          description: Yup.string().label(`"Description"`).required(),
          ville: Yup.number().label(`"Ville"`).required(),
          entite: Yup.number().label(`"Entité"`).required(),
          metier: Yup.number().label(`"Métier"`).required(),
     })

     const initialValues: ICloneAnnonceRequestModel = {
          publishedAt: moment().format("YYYY-MM-DD"),
          intitule: offerDetails.intitule,
          description: offerDetails.talentPlugOfferDTO?.job?.job_description,
          ville: offerDetails.ville?.id,
          metier: offerDetails.businessUnitMetier?.id,
          entite: offerDetails.entite?.id,
     }

     function handleSubmit(values: ICloneAnnonceRequestModel, helpers: FormikHelpers<ICloneAnnonceRequestModel>) {
          helpers.setStatus(null)

          cloneAnnonceRequest(offerDetails?.id as number, values)
               .then(r => {
                    toast.success("L'annonce a été clonée avec succès.")
                    navigate("/recrutement/annonces/listing")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}
                              {/* Publication Date */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de diffusion</label>
                                   <div className="col-lg-8">
                                        <MyDateDayMonthYearField
                                             name={"publishedAt"}
                                             value={helpers.values.publishedAt}
                                             isInvalid={!!helpers.errors.publishedAt}
                                             minDate={moment().format("YYYY-MM-DD")}
                                             placeholder={"Saisissez la date de publication"}
                                             onChange={val => helpers.setFieldValue("publishedAt", val)}
                                        />
                                        {helpers.errors.publishedAt && <div className="text-danger">{helpers.errors.publishedAt}</div>}
                                   </div>
                              </div>

                              {/* Intitulé */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Titre</label>
                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"intitule"}
                                             value={helpers.values.intitule}
                                             isInvalid={!!helpers.errors.intitule}
                                             placeholder={"Saisissez un titre"}
                                             onChange={value => helpers.setFieldValue("intitule", value)}
                                        />
                                        {helpers.errors.intitule && <div className="text-danger">{helpers.errors.intitule}</div>}
                                   </div>
                              </div>

                              {/* Description du poste */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Description du poste</label>
                                   <div className="col-lg-8">
                                        <MyTextareaField
                                             name={"description"}
                                             value={helpers.values.description}
                                             isInvalid={!!helpers.errors.description}
                                             placeholder={"Saisissez la description du poste"}
                                             maxLength={2300}
                                             rows={8}
                                             onChange={value => helpers.setFieldValue("description", value)}
                                        />
                                        <div className="d-flex justify-content-between">
                                             {helpers.errors.description && <div className="text-danger">{helpers.errors.description}</div>}
                                             <div className="text-muted fs-8 mt-1">{helpers.values.description?.length || 0} / 2300 caractères</div>
                                        </div>
                                   </div>
                              </div>

                              {/* Ville */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>
                                   <div className="col-lg-8">
                                        <MySelectSearchDataFromServer
                                             url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                             name={"ville"}
                                             minimumLengthSearch={3}
                                             value={helpers.values.ville}
                                             onChange={val => helpers.setFieldValue("ville", val)}
                                             defaultOptions={offerDetails.ville && [{ value: offerDetails.ville.id!, label: offerDetails.ville.nom! }]}
                                             method={"GET"}
                                             isInvalid={!!helpers.errors.ville}
                                        />
                                        {helpers.errors.ville && <div className="text-danger">{helpers.errors.ville}</div>}
                                   </div>
                              </div>

                              {/* Entité */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité</label>
                                   <div className="col-lg-8">
                                        <MySelectGetAllDataFromServer
                                             name={"entite"}
                                             value={helpers.values.entite}
                                             onChange={val => helpers.setFieldValue("entite", val)}
                                             url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                             defaultOptions={offerDetails.entite && [{ value: offerDetails.entite.id!, label: offerDetails.entite.nom! }]}
                                             isInvalid={!!helpers.errors.entite}
                                        />
                                        {helpers.errors.entite && <div className="text-danger">{helpers.errors.entite}</div>}
                                   </div>
                              </div>

                              {/* Métier */}
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Métier</label>
                                   <div className="col-lg-8">
                                        <MySelectGetAllDataFromServer
                                             name={"metier"}
                                             value={helpers.values.metier}
                                             onChange={val => helpers.setFieldValue("metier", val)}
                                             url={CONST_API_UTILS_GET_METIER_CATEGORIES_ENDPOINT}
                                             defaultOptions={
                                                  offerDetails.businessUnitMetier && [{ value: offerDetails.businessUnitMetier.id!, label: offerDetails.businessUnitMetier.nom! }]
                                             }
                                             isInvalid={!!helpers.errors.metier}
                                        />
                                        {helpers.errors.metier && <div className="text-danger">{helpers.errors.metier}</div>}
                                   </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? (
                                             <>
                                                  Commencer <i className={"fas fa-play fs-4 ms-2"} />
                                             </>
                                        ) : (
                                             <MySimpleSpinner size={"sm"} />
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

const AnnonceCloneAction = () => {
     // Constants & vars
     const breadcrumb: Array<PageLink> = [
          {
               title: "Annonces",
               path: "/recrutement/annonces/listing",
          },
     ]
     const annonce_ID = parseInt(useParams<{ annonce_id: string }>().annonce_id as string)

     // Hooks
     const detailsQuery = useQuery<OffreModel, AxiosError>("REACT_QUERY_KEY_GET_OFFER_DETAILS", async () => {
          return detailsAnnonceRequest(annonce_ID)
               .then(r => r.data)
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     return (
          <>
               <PageTitle breadcrumbs={breadcrumb}>Clone</PageTitle>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         {detailsQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {!detailsQuery.isFetching && detailsQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                              <>
                                   <MyAlert type={"primary"} classNames={"mb-5"}>
                                        <div className={"fw-bold"}>
                                             La republication s'effectuera automatiquement tous les 15 jours. Merci d'achiver l'annonce lorsqu'elle ne sera plus d'actualité.
                                        </div>
                                   </MyAlert>
                                   <MyCard>
                                        <MyCard.Header>
                                             <i className={"fas fa-globe fs-4 text-dark me-2"} /> Diffuser une annonce
                                        </MyCard.Header>
                                        <MyCard.Body>
                                             <FormClone offerDetails={detailsQuery.data} />
                                        </MyCard.Body>
                                   </MyCard>
                              </>
                         )}
                    </div>
               </div>
          </>
     )
}

export default AnnonceCloneAction
