import React from "react"
import InputMask from "react-input-mask"

import DatePicker, { registerLocale } from "react-datepicker"
import fr from "date-fns/locale/fr"
import moment from "moment"

registerLocale("fr", fr)

const MyDateDayMonthYearField: React.FC<Props> = ({
     name,
     value,
     onChange,
     placeholder,
     minDate,
     isDisabled,
     maxDate,
     autofocus = false,
     isInvalid = false,
     classNames = "",
     disabledWeekends = false,
}) => {
     const isWeekday = (date: any) => {
          const day = date.getDay()
          return day !== 0 && day !== 6
     }

     return (
          <DatePicker
               dateFormat="dd/MM/yyyy"
               locale="fr"
               placeholderText={placeholder}
               customInput={
                    <InputMask className={"form-control"} mask="99/99/9999">
                         {() => (
                              <input
                                   type="text"
                                   inputMode={"numeric"}
                                   className={`form-control ${isInvalid ? "is-invalid" : ""} ${classNames}`}
                                   placeholder={placeholder}
                                   autoFocus={autofocus}
                                   disabled={isDisabled}
                              />
                         )}
                    </InputMask>
               }
               name={name}
               minDate={minDate ? moment(minDate).toDate() : null}
               maxDate={maxDate ? moment(maxDate).toDate() : null}
               isClearable
               disabled={isDisabled}
               selected={value ? moment(value).toDate() : null}
               filterDate={disabledWeekends && isWeekday}
               onChange={(val: any) => {
                    if (val) {
                         const d = moment(val).format("YYYY-MM-DD")
                         onChange(d)
                    } else {
                         onChange("")
                    }
               }}
          />
     )
}

type Props = {
     name: string
     minDate?: string | undefined | null
     maxDate?: string | undefined | null
     value: string | undefined | null
     placeholder: string
     onChange: (value: any) => void
     autofocus?: boolean
     isInvalid?: boolean
     isDisabled?: boolean
     classNames?: string
     disabledWeekends?: boolean
}

export default MyDateDayMonthYearField
