import React, { useState } from "react"
import * as Yup from "yup"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import { useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { useConnectedUserNdfDepensesContext } from "../_PersonnelNdfDepenses"
import axios, { AxiosError } from "axios"
import { ICreateConnectedUserNdfDepenseSimpleRequestModel } from "../../../../mes-activites/ndf/core/_models"
import MyAlert from "@common-utils/MyAlert"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MONTANT_INDEMNITE_PAR_KM,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "@common-constants/*"
import { IHttpErrorResponseModel, IPersonnelNdfDetailModel } from "@common-models/*"
import { createPersonnelNdfDepenseSimpleRequest } from "../../core/_requests"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import {
     CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT,
     CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT,
} from "../../../../../../endpoints"
import MyDateDayMonthYearFormField from "@common-components/fields/MyDateDayMonthYearFormField"
import MySelectFormField from "@common-components/fields/MySelectFormField"
import MySelectCreatableFormField from "@common-components/fields/MySelectCreatableFormField"
import MyInputFormField from "@common-components/fields/MyInputFormField"
import MySelectSearchDataFromServerFormField from "@common-components/fields/MySelectSearchDataFromServerFormField"
import MyCheckboxFormField from "@common-components/fields/MyCheckboxFormField"
import MyTextareaFormField from "@common-components/fields/MyTextareaFormField"

export const ConnectedUserNdfDepenseCreateSimple = () => {
     const queryClient = useQueryClient()
     const context = useConnectedUserNdfDepensesContext()
     const [actionAfterSubmitForm, setActionAfterSubmitForm] = useState<"close" | "keepAddingDetails">("close")
     const [detailsIndemniteKm, setDetailsIndemniteKm] = useState<{
          distanceEnKmEntreDepartEtDestination: number
          distanceTotal: number
          montantTTC: number
     }>({ distanceEnKmEntreDepartEtDestination: 0, distanceTotal: 0, montantTTC: 0 })

     // Schema
     const validationSchema = Yup.object().shape({
          date: Yup.string().required("Champ requis"),
          categorie: Yup.string().required("Champ requis"),
          montantTTC: Yup.number().when("categorie", {
               is: (val: string) => val !== CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
               then: schema => schema.required(),
          }),
          documents: Yup.array()
               .of(Yup.mixed())
               .when("categorie", {
                    is: (val: string) => val !== CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
                    then: schema =>
                         schema
                              .of(
                                   Yup.mixed()
                                        .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => !value || value.size <= 10000000)
                                        .test(
                                             "fileFormat",
                                             "La PJ doit être au format JPEG, PNG ou PDF",
                                             (value: any) => !value || ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                                        )
                              )
                              .min(1, "Au moins un document est requis"),
                    otherwise: schema => schema.notRequired(),
               }),
          repasSeulOuEnGroupe: Yup.string()
               .label(`"Seul ou en groupe"`)
               .when("categorie", {
                    is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                    then: schema => schema.required(),
               }),
          invites: Yup.array()
               .label(`"Invités"`)
               .when("repasSeulOuEnGroupe", {
                    is: "REPAS_MIDI_EN_GROUPE",
                    then: schema => schema.required(),
               }),
          description: Yup.string().label(`"Description"`).required(),
          adresseDepart: Yup.string().when("categorie", {
               is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
               then: schema => schema.required(),
          }),
          adresseArrive: Yup.string().when("categorie", {
               is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
               then: schema => schema.required(),
          }),
          nbTrajets: Yup.number().when("categorie", {
               is: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
               then: schema => schema.required(),
          }),
          isAllerRetour: Yup.boolean(),
     })

     const initialValues: ICreateConnectedUserNdfDepenseSimpleRequestModel = {
          documents: [new File([new Blob()], "")],
          isAllerRetour: false,
     }

     function handleSubmit(values: ICreateConnectedUserNdfDepenseSimpleRequestModel, helpers: FormikHelpers<ICreateConnectedUserNdfDepenseSimpleRequestModel>) {
          helpers.setStatus(null)
          FileAndDriveHelper.processFiles(values.documents!).then(processedFiles => {
               createPersonnelNdfDepenseSimpleRequest(context.ndfId, { ...values, documents: processedFiles })
                    .then(r => {
                         queryClient.setQueryData([context.REACT_QUERY_KEY_GET_LISTING, context.ndfId], (details: IPersonnelNdfDetailModel[] | undefined) => {
                              if (details) {
                                   const dataWithNewDepense = [r.data, ...details]
                                   context.computeTotalExpenses(dataWithNewDepense || [])
                                   return dataWithNewDepense
                              }

                              return []
                         })

                         helpers.setSubmitting(false)

                         if (actionAfterSubmitForm === "close") {
                              toast.success("Votre justificatif a bien été ajouté.")
                              context.setIsAddDepenseModalShown(false)
                              context.setTypeAddDepense(null)
                              context.setIsCardDepenseSimpleCollapsed(false)
                         } else {
                              toast.success("Votre justificatif a bien été ajouté. Vous pouvez continuer à ajouter un autre si vous le souhaitez.")
                              helpers.resetForm()
                         }
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)

                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
               {helpers => {
                    async function calculateMontantIndemniteKmEntreDepartEtDestination(departure?: string, arrival?: string) {
                         if (departure && arrival) {
                              return axios
                                   .post<{ distance: number | null }>(CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT, {
                                        departure,
                                        arrival,
                                   })
                                   .then(({ data: { distance } }) => {
                                        if (distance) {
                                             setDetailsIndemniteKm(prev => ({
                                                  ...prev,
                                                  distanceEnKmEntreDepartEtDestination: distance,
                                             }))
                                        }
                                        return distance || 0
                                   })
                         }
                         return 0
                    }

                    function calculateMontantIndemniteKmTotal(distance: number, isAllerRetour: boolean, nbTrajets: number = 0) {
                         if (distance > 0) {
                              const dt = Number((distance * (isAllerRetour ? nbTrajets * 2 : nbTrajets)).toFixed(2))
                              setDetailsIndemniteKm(prev => ({
                                   ...prev,
                                   distanceTotal: dt,
                                   montantTTC: Number((dt * CONST_MONTANT_INDEMNITE_PAR_KM).toFixed(2)),
                              }))
                         }
                    }

                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}
                              {/* Justificatifs*/}
                              <>
                                   <label className="form-label fw-bolder  fs-6">Justificatifs</label>
                                   <FieldArray name={"documents"}>
                                        {({ push, remove }) => (
                                             <div className={"row"}>
                                                  {helpers.values.documents!.map((_, index) => {
                                                       return (
                                                            <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                                 <div className="d-flex">
                                                                      <input
                                                                           type={"file"}
                                                                           name={`documents[${index}]`}
                                                                           onChange={e => helpers.setFieldValue(`documents[${index}]`, e.target.files![0])}
                                                                           className={"form-control me-2"}
                                                                      />
                                                                      {index > 0 && (
                                                                           <button className={"btn btn-light-danger btn-sm"} type={"button"} onClick={() => remove(index)}>
                                                                                <span className={"fas fa-minus-circle"}></span>
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                                 {helpers.errors.documents && helpers.errors.documents[index] && (
                                                                      <div className={"text-danger"}>{helpers.errors.documents[index]}</div>
                                                                 )}
                                                                 <div className={"d-flex justify-content-sm-start justify-content-center mt-4"}>
                                                                      {index === helpers.values.documents!.length - 1 && (
                                                                           <button
                                                                                className={`btn btn-light-primary btn-sm ${index > 0 && "me-2"}`}
                                                                                type={"button"}
                                                                                onClick={() => push({})}
                                                                           >
                                                                                Ajouter un autre justificatif <i className={"fas fa-plus-circle ps-1 pe-0"} />
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })}
                                             </div>
                                        )}
                                   </FieldArray>
                              </>

                              <div className="my-4 separator separator-dashed" />

                              <div className="d-flex flex-column gap-4 w-lg-75 w-xl-50">
                                   {/* Date */}
                                   <MyDateDayMonthYearFormField
                                        name={"date"}
                                        formikProps={helpers}
                                        validationSchema={validationSchema}
                                        placeholder={"Date"}
                                        label={"Date"}
                                        classNameLabel={"col-lg-4"}
                                        classNameField={"col-lg-8"}
                                   />

                                   {/* Catégorie */}
                                   <MySelectFormField
                                        formikProps={helpers}
                                        validationSchema={validationSchema}
                                        options={CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                        name={"categorie"}
                                        isSearchable={false}
                                        placeholder={"Catégorie"}
                                        label={"Catégorie"}
                                        classNameLabel={"col-lg-4"}
                                        classNameField={"col-lg-8"}
                                   />

                                   {helpers.values.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                        <>
                                             <MySelectFormField
                                                  formikProps={helpers}
                                                  validationSchema={validationSchema}
                                                  label={"Type de repas"}
                                                  name={"repasSeulOuEnGroupe"}
                                                  options={[
                                                       {
                                                            value: "REPAS_MIDI_SEUL",
                                                            label: "Repas seul",
                                                       },
                                                       {
                                                            value: "REPAS_MIDI_EN_GROUPE",
                                                            label: "Repas en groupe",
                                                       },
                                                  ]}
                                                  placeholder={"Choisissez si seul ou en groupe"}
                                                  classNameLabel={"col-lg-4"}
                                                  classNameField={"col-lg-8"}
                                             />

                                             {helpers.values.repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                  <>
                                                       <div className="row d-flex justify-content-lg-end">
                                                            <div className={"mb-2 col-lg-8 px-lg-3"}>
                                                                 <MyAlert type={"primary"}>
                                                                      Veuillez fournir les noms et prénoms de vos invités. <br />
                                                                      S'il s'agit d'un client, ajoutez le nom de la société en plus.
                                                                 </MyAlert>
                                                            </div>
                                                       </div>

                                                       <MySelectCreatableFormField
                                                            formikProps={helpers}
                                                            validationSchema={validationSchema}
                                                            name={"invites"}
                                                            placeholder={"Saisissez les invités"}
                                                            classNameLabel={"col-lg-4"}
                                                            classNameField={"col-lg-8"}
                                                       />
                                                  </>
                                             )}
                                        </>
                                   )}

                                   {/* Montant TTC */}
                                   {helpers.values.categorie !== CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                        <MyInputFormField
                                             formikProps={helpers}
                                             validationSchema={validationSchema}
                                             name={"montantTTC"}
                                             label={<div className={"required"}>Montant TTC</div>}
                                             inputMode={"decimal"}
                                             type={"number"}
                                             placeholder={"Montant TTC"}
                                             classNameLabel={"col-lg-4"}
                                             classNameField={"col-lg-8"}
                                        />
                                   )}

                                   {/* Indemnite KM */}
                                   {helpers.values.categorie === CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM && (
                                        <>
                                             <MySelectSearchDataFromServerFormField
                                                  formikProps={helpers}
                                                  validationSchema={validationSchema}
                                                  label={<div className={"required"}>Adresse de départ</div>}
                                                  name={"adresseDepart"}
                                                  endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                  method={"GET"}
                                                  minimumLengthSearch={8}
                                                  placeholder={"Saisissez une adresse"}
                                                  classNameLabel={"col-lg-4"}
                                                  classNameField={"col-lg-8"}
                                                  onChangeCallback={val => {
                                                       calculateMontantIndemniteKmEntreDepartEtDestination(val as string, helpers.values.adresseArrive).then(dist => {
                                                            calculateMontantIndemniteKmTotal(dist, helpers.values.isAllerRetour!, helpers.values.nbTrajets)
                                                       })
                                                  }}
                                             />

                                             <MySelectSearchDataFromServerFormField
                                                  formikProps={helpers}
                                                  validationSchema={validationSchema}
                                                  label={<div className={"required"}>Adresse de destination</div>}
                                                  name={"adresseArrive"}
                                                  endpoint={CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT}
                                                  method={"GET"}
                                                  minimumLengthSearch={8}
                                                  placeholder={"Saisissez une adresse"}
                                                  classNameLabel={"col-lg-4"}
                                                  classNameField={"col-lg-8"}
                                                  onChangeCallback={val => {
                                                       calculateMontantIndemniteKmEntreDepartEtDestination(helpers.values.adresseDepart, val as string).then(dist => {
                                                            calculateMontantIndemniteKmTotal(dist, helpers.values.isAllerRetour!, helpers.values.nbTrajets)
                                                       })
                                                  }}
                                             />

                                             <div className="row">
                                                  <label className="col-lg-4 col-form-label fs-6 fw-bolder text-gray-900 required">Nombre de trajets</label>

                                                  <div className={"col-lg-8 d-flex gap-2"}>
                                                       <MyInputFormField
                                                            formikProps={helpers}
                                                            validationSchema={validationSchema}
                                                            name={"nbTrajets"}
                                                            type={"number"}
                                                            placeholder={"Nombre de trajets"}
                                                            classNameLayout={"me-2"}
                                                            onChangeCallback={val =>
                                                                 calculateMontantIndemniteKmTotal(
                                                                      detailsIndemniteKm.distanceEnKmEntreDepartEtDestination,
                                                                      helpers.values.isAllerRetour!,
                                                                      val
                                                                 )
                                                            }
                                                       />
                                                       <div className={"d-flex align-items-center"}>
                                                            <MyCheckboxFormField
                                                                 formikProps={helpers}
                                                                 validationSchema={validationSchema}
                                                                 name={"isAllerRetour"}
                                                                 onChangeCallback={val => {
                                                                      calculateMontantIndemniteKmTotal(
                                                                           detailsIndemniteKm.distanceEnKmEntreDepartEtDestination,
                                                                           val,
                                                                           helpers.values.nbTrajets
                                                                      )
                                                                 }}
                                                            />
                                                            <div style={{ marginLeft: "-1rem" }}>Aller-retour</div>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className={"p-4 border border-dashed text-gray-600"}>
                                                  <h4 className={"mb-4 text-gray-600"}>
                                                       Détails de l'indemnité kilométrique
                                                       <i className="fas fa-info-circle fs-3 float-end" />
                                                  </h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  <p>Distance totale: {detailsIndemniteKm.distanceTotal.toFixed(2)} Km</p>
                                                  <p>Montant TTC: {detailsIndemniteKm.montantTTC.toFixed(2)}€</p>
                                             </div>
                                        </>
                                   )}

                                   {/* Description */}
                                   <MyTextareaFormField
                                        formikProps={helpers}
                                        validationSchema={validationSchema}
                                        name={"description"}
                                        label={"Commentaire"}
                                        placeholder={"Description"}
                                        minRows={4}
                                        classNameLabel={"col-lg-4"}
                                        classNameField={"col-lg-8"}
                                   />

                                   <div className="separator separator-dashed" />
                                   <div className="text-center d-flex flex-sm-row flex-column justify-content-end">
                                        <button
                                             className={"btn btn-sm btn-primary me-2 mb-sm-0 mb-2"}
                                             type={"submit"}
                                             disabled={helpers.isSubmitting}
                                             onClick={() => setActionAfterSubmitForm("close")}
                                        >
                                             {!helpers.isSubmitting && (
                                                  <>
                                                       Finaliser l'ajout <i className={"fas fa-check-circle align-middle"} />
                                                  </>
                                             )}
                                             {helpers.isSubmitting && (
                                                  <span className="indicator-progress" style={{ display: "block" }}>
                                                       Ajout en cours
                                                       <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                  </span>
                                             )}
                                        </button>
                                   </div>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}
