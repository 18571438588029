import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { toast } from "react-toastify"
import MyTooltip from "@common-utils/MyTooltip"
import { useDashboardAlertesPeriodesEssaiExpirationListingListContext } from "./_DashboardAlertesPeriodesEssaiExpirationListingList"
import { useDashboardAlertesPeriodesEssaiExpirationListingContext } from "./DashboardAlertesPeriodesEssaiExpirationListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { IGetAlertesPeriodesEssaiExpirationItemDashboardResponseModel } from "./core/_models"
import moment from "moment"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useDashboardAlertesPeriodesEssaiExpirationListingContext()
     const listContext = useDashboardAlertesPeriodesEssaiExpirationListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const dateFinPeriodeEssai = item.consultantPeriodeEssai.dateFin2 || item.consultantPeriodeEssai.dateFin1

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.consultantPeriodeEssai.consultant?.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.consultantPeriodeEssai.consultant?.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.consultantPeriodeEssai.consultant?.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(
                                                       item.consultantPeriodeEssai.consultant?.id as number
                                                  )}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.consultantPeriodeEssai.consultant?.candidat?.user?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(
                                                                        item.consultantPeriodeEssai.consultant?.candidat?.user.avatarGoogleDriveId as string
                                                                   )
                                                                 : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"text-primary fw-bold fs-5"}>{item.consultantPeriodeEssai.consultant?.prenomNom}</span>
                                             </div>
                                             <div>
                                                  <span className={"text-muted"}>
                                                       {item.consultantPeriodeEssai.consultant?.clientPropal
                                                            ? item.consultantPeriodeEssai.consultant?.clientPropal?.client?.nom
                                                            : "Embauché sur profil"}
                                                  </span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        {moment().diff(dateFinPeriodeEssai) >= 0 ? (
                                             <span className={"badge badge-danger"}>Expiré le {dateFinPeriodeEssai?.format("DD/MM/YYYY")}</span>
                                        ) : (
                                             <span className={"badge badge-warning text-black"}>Expire le {dateFinPeriodeEssai?.format("DD/MM/YYYY")}</span>
                                        )}
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date publication */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-days fs-5 w-25px" />
                                                  Embauché le {item.consultantPeriodeEssai.consultant?.dateEmbauche?.format("dddd D MMMM YYYY")}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" /> {item.consultantPeriodeEssai.consultant?.personnel?.prenomNom}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-file-signature fs-5 w-25px" /> {item.consultantPeriodeEssai.consultant?.contratLabel}
                                             </div>
                                        </div>
                                        <div className="col text-end">
                                             <div>
                                                  <MyTooltip title={"Renouvellement"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-muted btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => toast.info("En maintenance ...")}
                                                       >
                                                            <i className="fas fa-clock fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>

                                             <div>
                                                  <MyTooltip title={"Rupture anticipée"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-muted btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => toast.info("En maintenance ...")}
                                                       >
                                                            <i className="fas fa-times-circle fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const DashboardAlertesPeriodesEssaiExpirationListingListItem = ({ item, index }: { item: IGetAlertesPeriodesEssaiExpirationItemDashboardResponseModel; index: number }) => {
     const context = useDashboardAlertesPeriodesEssaiExpirationListingContext()

     const isItemSelected: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.consultantPeriodeEssai.consultant?.id === item.consultantPeriodeEssai.consultant?.id
     const isItemLoading: boolean =
          context.itemInPreview !== null &&
          context.itemInPreview.selectedItem.consultantPeriodeEssai.consultant?.id === item.consultantPeriodeEssai.consultant?.id &&
          context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (
               !clickedElement.classList.contains("not-concerned-by-preview") &&
               context.itemInPreview?.selectedItem.consultantPeriodeEssai.consultant?.id !== item.consultantPeriodeEssai.consultant?.id
          ) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetAlertesPeriodesEssaiExpirationItemDashboardResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default DashboardAlertesPeriodesEssaiExpirationListingListItem
