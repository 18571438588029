import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react"
import { LayoutSplashScreen } from "@common-metronic/layout/core"
import { WithChildren } from "@common-metronic/helpers"
import { AuthModel } from "../common/models"
import axios from "axios"
import { CONST_API_BASE_URL_ENDPOINT } from "./endpoints"
import { IAuthenticatedUserModel } from "./models"
import useAuthHelpers from "./hooks/useAuthHelpers"

type AuthContextProps = {
     auth: AuthModel | undefined
     saveAuth: (auth: AuthModel | undefined) => void
     currentUser: IAuthenticatedUserModel | undefined
     setCurrentUser: Dispatch<SetStateAction<IAuthenticatedUserModel | undefined>>
     completionInfoProfilEnPourcentage: number
     logout: () => void
}

const initAuthContextPropsState = {
     auth: undefined,
     saveAuth: () => {},
     currentUser: undefined,
     setCurrentUser: () => {},
     completionInfoProfilEnPourcentage: 0,
     logout: () => {},
}

const AppContext = createContext<AuthContextProps>(initAuthContextPropsState)

const AuthProvider: FC<WithChildren> = ({ children }) => {
     const authHelpers = useAuthHelpers()

     const [auth, setAuth] = useState<AuthModel | undefined>(authHelpers.getAuth())
     const [currentUser, setCurrentUser] = useState<IAuthenticatedUserModel | undefined>()

     // % complétion info profil
     const completionInfoProfilEnPourcentage: number = useMemo(() => {
          if (!currentUser) return 0

          return 100
     }, [currentUser])

     const saveAuth = (auth: AuthModel | undefined) => {
          setAuth(auth)
          if (auth) {
               authHelpers.setAuth(auth)
          } else {
               authHelpers.removeAuth()
          }
     }

     const logout = () => {
          saveAuth(undefined)
          setCurrentUser(undefined)
     }

     return (
          <AppContext.Provider
               value={{
                    auth,
                    saveAuth,
                    currentUser,
                    setCurrentUser,
                    logout,
                    completionInfoProfilEnPourcentage,
               }}
          >
               {children}
          </AppContext.Provider>
     )
}

/* Shows the splash screen */
const AuthInit: FC<WithChildren> = ({ children }) => {
     const { auth, logout, setCurrentUser } = useAuth()
     const didRequest = useRef(false)
     const [showSplashScreen, setShowSplashScreen] = useState(true)

     // We should request user by authToken before rendering the application
     useEffect(() => {
          const requestUser = async (apiToken: string) => {
               try {
                    if (!didRequest.current) {
                         const { data } = await getUserByToken(apiToken)
                         if (data) {
                              setCurrentUser(data)
                         }
                    }
               } catch (error) {
                    if (!didRequest.current) {
                         logout()
                    }
               } finally {
                    setShowSplashScreen(false)
               }

               return () => (didRequest.current = true)
          }

          if (auth && auth.token) {
               requestUser(auth.impersonated_token || auth.token).then()
          } else {
               logout()
               setShowSplashScreen(false)
          }
          // eslint-disable-next-line
     }, [])

     return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

function useAuth() {
     return useContext(AppContext)
}

function getUserByToken(token: string) {
     return axios.get<IAuthenticatedUserModel>(`${CONST_API_BASE_URL_ENDPOINT}/user-info`, {
          headers: {
               "Authorization-api": `Bearer ${token}`,
               Accept: "application/json",
          },
     })
}

export { AuthProvider, AuthInit, useAuth }
