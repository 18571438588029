import { IClientBonCommandeModel, IClientBonLivraisonModel } from "@common-models/*"
import React, { useState } from "react"
import MyTooltip from "@common-utils/MyTooltip"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyModal from "@common-utils/MyModal"
import { toast } from "react-toastify"
import { deleteBonLivraisonRequest } from "../core/_requests"
import { usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext } from "./_PtfDetailsPeriodesBonsCommandeBonsLivraison"
import { useQueryClient } from "react-query"
import { IGetPeriodesBonsCommandeBonsLivraisonResponseModel } from "../core/_models"
import useSwal from "@common-hooks/useSwal"
import PtfPeriodeBonCommandeBonLivraisonEditForm from "../actions/_PtfPeriodeBonCommandeBonLivraisonEditForm"
import PtfPeriodeBonCommandeLivrableEditForm from "../actions/_PtfPeriodeBonCommandeLivrableEditForm"
import MyGoogleDriveFileViewerInModal from "../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"

const PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsLivraison = ({ bonCommande, ptf_type }: IPtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommandePropsModel) => {
     const context = usePtfDetailsPeriodesBonsCommandeBonsLivraisonContext()
     const queryClient = useQueryClient()
     const { swal } = useSwal()
     const [currentBonLivraisonInModal, setCurrentBonLivraisonInModal] = useState<{
          bl: IClientBonLivraisonModel
          index: number
          loading: boolean
     }>()
     const [editBonLivraisonInModal, setEditBonLivraisonInModal] = useState<IClientBonLivraisonModel>()

     function handleRemoveBonLivraison(id: number) {
          swal.fire({
               icon: "warning",
               text: `Vous-vous vraiment supprimer ${ptf_type === "TYPE_FORFAIT" ? "le livrable" : "le bon de livraison"}`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteBonLivraisonRequest(id)
                         .then(() => {
                              queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
                                   (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                                        if (prev?.data) {
                                             prev.data.map(item => {
                                                  item.clientPropalPeriodes?.map(item_ => {
                                                       item_.clientBonCommandes?.map(item__ => {
                                                            item__.clientBonLivraisons?.map(item___ => {
                                                                 if (item___.id === id) {
                                                                      item__.clientBonLivraisons = item__.clientBonLivraisons?.filter(item____ => item____.id !== id)
                                                                 }
                                                            })
                                                            return item__
                                                       })
                                                  })
                                             })
                                             return prev
                                        }
                                        return prev
                                   }
                              )
                              toast.info(ptf_type === "TYPE_FORFAIT" ? "Le livrable a bien été supprimé" : "Le bon de commande a bien été supprimé")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function handleSubmitEditBonCommandeCallback(val: IClientBonCommandeModel) {
          queryClient.setQueryData<IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined>(
               context.REACT_QUERY_KEY_GET_PERIODES_BC_BL,
               (prev: IGetPeriodesBonsCommandeBonsLivraisonResponseModel | undefined) => {
                    if (prev?.data) {
                         prev.data.map(item => {
                              item.clientPropalPeriodes?.map(item_ => {
                                   item_.clientBonCommandes!.map(item__ => {
                                        item__.clientBonLivraisons!.map((item___, index___) => {
                                             if (item___.id === editBonLivraisonInModal?.id) {
                                                  item__.clientBonLivraisons![index___] = {
                                                       ...item__.clientBonLivraisons![index___],
                                                       ...val,
                                                  }
                                             }
                                        })
                                        return item__
                                   })
                              })
                         })
                    }
                    return prev
               }
          )

          setEditBonLivraisonInModal(undefined)
     }

     return (
          <div className={`row ${bonCommande.clientBonLivraisons!.length > 0 ? "mt-4" : ""}`}>
               {bonCommande.clientBonLivraisons?.map((bl, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                         <div className={"p-4 border border-dashed border-warning mb-4"}>
                              <div className="d-flex justify-content-between">
                                   <div className={"mb-2"}>
                                        <div className={"fs-7 fw-bold text-warning d-flex align-items-center"}>
                                             {ptf_type === "TYPE_FORFAIT" ? <>{bl.livrableIntitule ? bl.livrableIntitule : `Livrable n°${index + 1}`}</> : <>BL n°{index + 1}</>}
                                        </div>
                                   </div>
                                   <div>
                                        <MyTooltip title={"Supprimer"}>
                                             <span className={"fas fa-trash text-warning fs-3 cursor-pointer me-2"} onClick={() => handleRemoveBonLivraison(bl.id as number)} />
                                        </MyTooltip>
                                        <MyTooltip title={"Modifier"}>
                                             <span className={"fas fa-edit text-warning fs-3 cursor-pointer"} onClick={() => setEditBonLivraisonInModal(bl)} />
                                        </MyTooltip>
                                   </div>
                              </div>
                              <div className="separator separator-dashed border-warning mb-4" />
                              <div className={"d-flex justify-content-between"}>
                                   <div>
                                        {ptf_type === "TYPE_FORFAIT" && (
                                             <>
                                                  <div>
                                                       <span className={"fw-bold"}>Lot: </span> {bl.livrableLot}
                                                  </div>
                                                  <div>
                                                       <span className={"fw-bold"}>Date de livraison: </span> {bl.dateDebut?.format("DD MMM YYYY")}
                                                  </div>
                                             </>
                                        )}
                                        {ptf_type === "TYPE_ASSISTANCE_TECHNIQUE" && (
                                             <>
                                                  <div>
                                                       <span className={"fw-bold"}>Début: </span> {bl.dateDebut?.format("D MMM YYYY")}
                                                  </div>
                                                  <div>
                                                       <span className={"fw-bold"}>Fin: </span> {bl.dateFin?.format("D MMM YYYY")}
                                                  </div>
                                             </>
                                        )}

                                        <div className={"mb-4"}>
                                             <span className={"fw-bold"}>État: </span> {bl.etatLabel}
                                        </div>
                                        <div>
                                             <span className={"fw-bold"}>Montant HT: </span> {bl.montant} €
                                        </div>
                                   </div>
                                   <div className="d-flex align-items-center" onClick={() => bl.googleDriveID && setCurrentBonLivraisonInModal({ bl, loading: true, index })}>
                                        {bl.googleDriveID ? (
                                             <img src={toAbsoluteUrl("/media/svg/files/pdf.svg")} className="w-30px cursor-pointer" alt="" />
                                        ) : (
                                             <span className="fas fa-question text-danger fs-3" />
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               ))}

               {/* Modal view Bon commande */}
               {currentBonLivraisonInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={currentBonLivraisonInModal.bl.googleDriveID as string}
                         googleDriveMimetype={currentBonLivraisonInModal.bl.googleDriveMimeType as string}
                         modalTitle={
                              <>
                                   {currentBonLivraisonInModal.bl.livrableIntitule
                                        ? currentBonLivraisonInModal.bl.livrableIntitule
                                        : `Livrable n°${currentBonLivraisonInModal.index + 1}`}
                              </>
                         }
                         handleCloseModal={() => setCurrentBonLivraisonInModal(undefined)}
                         modalSize={"xl"}
                    />
               )}

               {/* Modal edit BL */}
               {editBonLivraisonInModal && (
                    <MyModal
                         title={
                              <>
                                   Bon de livraison du {editBonLivraisonInModal.dateDebut?.format("D MMM YYYY")} au {editBonLivraisonInModal.dateFin?.format("D MMM YYYY")}
                              </>
                         }
                         show={true}
                         handleClose={() => setEditBonLivraisonInModal(undefined)}
                         size={"lg"}
                    >
                         {ptf_type === "TYPE_ASSISTANCE_TECHNIQUE" ? (
                              <PtfPeriodeBonCommandeBonLivraisonEditForm bl={editBonLivraisonInModal} handleSubmitCallback={handleSubmitEditBonCommandeCallback} />
                         ) : (
                              <PtfPeriodeBonCommandeLivrableEditForm bl={editBonLivraisonInModal} handleSubmitCallback={handleSubmitEditBonCommandeCallback} />
                         )}
                    </MyModal>
               )}
          </div>
     )
}

interface IPtfDetailsPeriodesBonsCommandeBonsLivraison_BonsCommandePropsModel {
     bonCommande: IClientBonCommandeModel
     ptf_type: "TYPE_FORFAIT" | "TYPE_ASSISTANCE_TECHNIQUE" | string
}

export default PtfDetailsPeriodesBonsCommandeBonsLivraison_BonsLivraison
