import React, { useState } from "react"
import MyCard from "@common-utils/MyCard"
import { useBonsLivraisonManquantsOfGivenMonthListingContext } from "./BonsLivraisonManquantsOfGivenMonthListing"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { Link } from "react-router-dom"
import { IBonsLivraisonManquantsOfGivenMonthItemResponseModel } from "./core/_models"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const BonsLivraisonManquantsOfGivenMonthListingPreview = () => {
     const globalContext = useBonsLivraisonManquantsOfGivenMonthListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.bc.id === selectedItemInPreview.bc.id) as IBonsLivraisonManquantsOfGivenMonthItemResponseModel
     const ptf = itemInPreview.bc.propalPeriode?.propal

     // States
     const [selectedTab, setSelectedTab] = useState<"BC" | "PTF">("BC")

     return (
          <MyCard>
               <MyCard.Header
                    isCollapseIconShown={false}
                    rightSideContent={
                         <>
                              <button
                                   type={"button"}
                                   className={`btn btn-sm ${selectedTab === "BC" ? "btn-dark" : "btn-outline btn-outline-dark"} me-2 px-3 py-2 d-flex align-items-center`}
                                   onClick={() => {
                                        if (selectedTab === "BC") return
                                        setSelectedTab("BC")
                                        globalContext.setItemInPreview(prev => prev && { ...prev, isSelectedItemLoading: true })
                                   }}
                              >
                                   BC <i className={"fas fa-file-alt ms-1 fs-6"} />
                              </button>
                              <button
                                   type={"button"}
                                   className={`btn btn-sm ${selectedTab === "PTF" ? "btn-dark" : "btn-outline btn-outline-dark"} px-3 py-2 d-flex align-items-center`}
                                   onClick={() => {
                                        if (selectedTab === "PTF") return
                                        setSelectedTab("PTF")
                                        globalContext.setItemInPreview(prev => prev && { ...prev, isSelectedItemLoading: true })
                                   }}
                              >
                                   PTF <i className={"fas fa-file-alt ms-1 fs-6"} />
                              </button>
                         </>
                    }
               >
                    {ptf?.client?.nom} |{" "}
                    <Link to={`/client/ptf/${ptf?.id}/details`} target={"_blank"}>
                         {ptf?.reference}
                    </Link>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyLoadingOverlay isActive={globalContext.itemInPreview?.isSelectedItemLoading as boolean}>
                         <>
                              {selectedTab === "BC" && (
                                   <iframe
                                        src={FileAndDriveHelper.getUrlOfDriveFilePreview(itemInPreview.bc.googleDriveID as string)}
                                        style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                                        onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                                   />
                              )}
                              {selectedTab === "PTF" && (
                                   <iframe
                                        src={FileAndDriveHelper.getUrlOfDriveFilePreview(itemInPreview.bc.propalPeriode?.googleDriveId as string)}
                                        style={{ width: "100%", minHeight: "82vh", overflowY: "scroll" }}
                                        onLoad={() => globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))}
                                   />
                              )}
                         </>
                    </MyLoadingOverlay>
               </MyCard.Body>
          </MyCard>
     )
}

export default BonsLivraisonManquantsOfGivenMonthListingPreview
