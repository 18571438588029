import MyCard from "@common-utils/MyCard"
import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF } from "../../gestion/routing/routing-endpoints"
import FormCreate from "./FormCreate"

const PersonnelCreate = () => {
     const breadcrumbs: Array<PageLink> = [
          {
               title: "Gestion",
          },
          {
               title: "Structure",
          },
          {
               title: "Staff",
               path: ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF,
          },
     ]

     return (
          <>
               <PageTitle breadcrumbs={breadcrumbs}>Nouveau</PageTitle>

               <div className="d-flex justify-content-center">
                    <div className={"w-100 w-lg-80 w-xl-60 w-xxl-55"}>
                         <MyCard classNames={""}>
                              <MyCard.Header>Ajouter un staff</MyCard.Header>
                              <MyCard.Body>
                                   <FormCreate />
                              </MyCard.Body>
                         </MyCard>
                    </div>
               </div>
          </>
     )
}

export { PersonnelCreate }
