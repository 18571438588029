import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import {
     IConsultantDeclarationMensuelleModel,
     IConsultantDeclarationMensuelleReviewModel,
     IConsultantDeclarationMensuelleSuiviModel,
     IHttpErrorResponseModel,
     IPersonnelAvecDelegationDroitsConsultantModel,
} from "@common-models/*"
import { ConsultantDeclarationDetailsNavigationHeader } from "./_ConsultantDeclarationDetails_NavigationHeader"
import ConsultantDeclarationDetailsDDC from "./_ConsultantDeclarationDetails_DDC"
import ConsultantDeclarationDetailsCra from "./_ConsultantDeclarationDetails_CRA"
import ConsultantDeclarationDetailsNDF from "./ndf/_ConsultantDeclarationDetails_NDF"
import ConsultantDeclarationDetailsTicketsRestaurant from "./tr/_ConsultantDeclarationDetails_TR"
import ConsultantDeclarationDetailsArrets from "./_ConsultantDeclarationDetails_arrets"
import MyAlert from "@common-utils/MyAlert"
import { toast } from "react-toastify"
import {
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROIT_REVIEW_DECLARATIONS,
} from "@common-constants/*"
import { useAuth } from "../../../../../AppContext"
import swal from "sweetalert2"
import {
     addReviewDeclarationRequestModel,
     addSuiviDeclarationDemandeRectificationParResponsable,
     addSuiviDeclarationDemandeRectificationParSiege,
     addSuiviDeclarationRejetDemandeRegulParRespRequest,
     addSuiviDeclarationValidationDemandeRegulParRespRequest,
     addSuiviDeclarationValidationResponsable,
     addSuiviDeclarationValidationSiege,
     cancelReviewDeclarationRequestModel,
} from "../core/_requests"
import ConsultantDeclarationDetails_Factures from "./factures/ConsultantDeclarationDetails_Factures"
import { useQuery } from "react-query"
import axios, { AxiosError } from "axios"
import { CONST_API_PERSONNEL_GET_DROITS_OF_CONSULTANTS_ENDPOINT } from "../../../../../endpoints"

const ConsultantDeclarationDetails = ({
     declaration,
     handleDemandeRectificationCallback = () => {},
     handleValidationCallback = () => {},
     handleReviewCallback = () => {},
     handleCancelReviewCallback = () => {},
     handleValidationDemandeRegulCallback = () => {},
     handleRejetDemandeRegulCallback = () => {},
     defaultStep = "arrets",
     nbJoursTravailles,
}: IConsultantDeclarationDetailsPropsModel) => {
     // Hooks
     const [navigationStep, setNavigationStep] = useState<"arrets" | "ddc" | "cra" | "factures" | "ndf" | "tr">(defaultStep)
     const { currentUser } = useAuth()

     // Get connected user rights on consultant
     const personnelRightsOnConsultantQuery = useQuery<IPersonnelAvecDelegationDroitsConsultantModel[], AxiosError>("PERSONNEL_RIGHTS_ON_CONSULTANT", async () => {
          return axios
               .post<IPersonnelAvecDelegationDroitsConsultantModel[]>(CONST_API_PERSONNEL_GET_DROITS_OF_CONSULTANTS_ENDPOINT(currentUser?.id!), {
                    consultants: [declaration.consultant?.id!],
               })
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Vars & consts
     const containerClassAccordingToStep = (() => {
          if (navigationStep === "arrets") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "factures") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "ddc") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "cra") return "w-100"
          if (navigationStep === "ndf") return "w-100 w-lg-90 w-xxl-65"
          if (navigationStep === "tr") return "w-100"

          return ""
     })()

     const connectedUserReview = declaration.reviews?.find(item => item.personnel?.id === currentUser?.id && item.deletedAt === null)
     const isActionReviewAvailable =
          personnelRightsOnConsultantQuery.data?.some(
               item => item.consultant?.id === declaration.consultant?.id && item.droits!.includes(CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROIT_REVIEW_DECLARATIONS)
          ) || false

     const isDeclarationAlreadyBeenReviewByConnectedUser = declaration.reviews?.some(item => item.personnel?.id === currentUser?.id && item.deletedAt === null) || false

     // Handles demande rectification resp ou siège
     function handleDemandeRectification() {
          const request =
               currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? addSuiviDeclarationDemandeRectificationParSiege : addSuiviDeclarationDemandeRectificationParResponsable

          swal.fire({
               icon: "warning",
               title: `Avant de procéder, merci de saisir un commentaire`,
               input: "textarea",
               inputPlaceholder: "Commentaire ...",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Envoyer la demande",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.info("Votre demande a bien été envoyée")
                              setNavigationStep("arrets")
                              handleDemandeRectificationCallback && handleDemandeRectificationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation responsable ou siège
     function handleValidation() {
          const request = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? addSuiviDeclarationValidationSiege : addSuiviDeclarationValidationResponsable

          swal.fire({
               icon: "info",
               title:
                    declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                         ? `Vous êtes sur le point de retourner la déclaration au siège`
                         : `Vous êtes sur le point de valider cette déclaration`,
               text:
                    declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                         ? "Cela signifie que la déclaration ne contient pas d'erreurs. Voulez-vous continuer?"
                         : `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder:
                    declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION ? "Précisez la raison de ce retour" : "Commentaire (optionnel)",
               inputAttributes: {
                    required: declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION ? "true" : "false",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION && value === ""
                              ? resolve("Merci de préciser la raison de ce retour")
                              : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success(
                                   declaration.etat?.type === CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION
                                        ? "La déclaration a été retournée au siège."
                                        : "Vous avez bien validé cette déclaration."
                              )
                              setNavigationStep("cra")
                              handleValidationCallback && handleValidationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation demande régul
     function handleValidationDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de valider la demande de régularisation de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return addSuiviDeclarationValidationDemandeRegulParRespRequest(declaration.id as number)
                         .then(r => {
                              toast.success("Vous avez bien validé cette demande.")
                              setNavigationStep("cra")
                              handleValidationDemandeRegulCallback && handleValidationDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles rejet demande régul
     function handleRejetDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de rejeter la demande de régularisation de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               input: "textarea",
               inputPlaceholder: "Merci de préciser la raison de ce rejet",
               inputAttributes: {
                    required: "true",
               },
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci de préciser la raison de ce rejet") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return addSuiviDeclarationRejetDemandeRegulParRespRequest(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success("Vous avez rejeté cette demande.")
                              setNavigationStep("cra")
                              handleRejetDemandeRegulCallback && handleRejetDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles review
     function handleReview() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de pré-valider la déclaration de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return addReviewDeclarationRequestModel(declaration.id as number)
                         .then(r => {
                              toast.success("La pré-validation a bien été prise en compte")
                              setNavigationStep("cra")
                              handleReviewCallback && handleReviewCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles cancel review
     function handleCancelReview() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point d'annuler la pré-validation la déclaration de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return cancelReviewDeclarationRequestModel(declaration.id as number, connectedUserReview?.id as number)
                         .then(r => {
                              toast.success("La pré-validation a bien été annulée")
                              setNavigationStep("cra")
                              handleCancelReviewCallback && handleCancelReviewCallback(connectedUserReview!)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function isActionDemandeRectifAvailable() {
          if (currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF") {
               return [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)
          } else {
               return [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION].includes(
                    declaration.etat?.type as string
               )
          }
     }

     function isActionValidationAvailable() {
          // Chargé de recrutement n'a pas le droit de valider
          if (currentUser?.roleGroup === "ROLE_GROUP_RECRUTEUR") return false

          if (currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF") {
               return [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)
          } else {
               return [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION].includes(
                    declaration.etat?.type as string
               )
          }
     }

     function isActionValiderOuRejeterDemandeRegulAvailable() {
          return (
               currentUser?.roleGroup !== "ROLE_GROUP_ADMINISTRATIF" && [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL].includes(declaration.etat?.type as string)
          )
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={containerClassAccordingToStep}>
                         <ConsultantDeclarationDetailsContext.Provider
                              value={{
                                   navigationStep,
                                   setNavigationStep,
                                   handleValidation,
                                   handleDemandeRectification,
                                   handleReview,
                                   handleCancelReview,
                                   handleValidationDemandeRegul,
                                   handleRejetDemandeRegul,
                                   declaration,
                                   isActionDemandeRectifAvailable: isActionDemandeRectifAvailable(),
                                   isActionValidationAvailable: isActionValidationAvailable(),
                                   isActionValiderOuRejeterDemandeRegulAvailable: isActionValiderOuRejeterDemandeRegulAvailable(),
                                   nbJoursTravailles,
                                   isActionReviewAvailable,
                                   isDeclarationAlreadyBeenReviewByConnectedUser,
                              }}
                         >
                              <>
                                   {declaration.etat?.commentaire && (
                                        <MyAlert type={"info"} customIcon={"fas fa-comment-dots"}>
                                             <b className={"text-dark"}>
                                                  {declaration.etat.personnel ? declaration.etat.personnel?.prenomNom : declaration.consultant?.prenomNom}:{" "}
                                             </b>{" "}
                                             {declaration.etat?.commentaire}
                                        </MyAlert>
                                   )}

                                   <ConsultantDeclarationDetailsNavigationHeader />

                                   <div className="separator separator-dashed mb-5" />

                                   <div className="px-15">
                                        {declaration.consultant?.contrat !== "CONTRAT_ST" && navigationStep === "arrets" && (
                                             <ConsultantDeclarationDetailsArrets
                                                  consultant_id={declaration.consultant!.id as number}
                                                  filtres={{
                                                       duOrAuWithThisMonthAndYear: declaration.mois!.parseZone().format("YYYY-MM-DD"),
                                                  }}
                                             />
                                        )}
                                        {declaration.consultant?.contrat !== "CONTRAT_ST" && navigationStep === "ddc" && (
                                             <ConsultantDeclarationDetailsDDC
                                                  consultant_id={declaration.consultant!.id as number}
                                                  filtres={{
                                                       duOrAuWithThisMonthAndYear: declaration.mois!.parseZone().format("YYYY-MM-DD"),
                                                  }}
                                             />
                                        )}
                                        {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(declaration.consultant?.statut!) &&
                                             navigationStep === "cra" && <ConsultantDeclarationDetailsCra />}
                                        {navigationStep === "ndf" && <ConsultantDeclarationDetailsNDF />}
                                        {navigationStep === "factures" && <ConsultantDeclarationDetails_Factures />}
                                        {declaration.consultant?.contrat !== "CONTRAT_ST" && navigationStep === "tr" && (
                                             <ConsultantDeclarationDetailsTicketsRestaurant declaration={declaration} />
                                        )}
                                   </div>
                              </>
                         </ConsultantDeclarationDetailsContext.Provider>
                    </div>
               </div>
          </>
     )
}

const ConsultantDeclarationDetailsContext = createContext<IConsultantDeclarationDetailsContextPropsModel>({} as IConsultantDeclarationDetailsContextPropsModel)
export const useConsultantDeclarationDetailsContext = () => useContext(ConsultantDeclarationDetailsContext)

interface IConsultantDeclarationDetailsContextPropsModel {
     declaration: IConsultantDeclarationMensuelleModel
     navigationStep: "arrets" | "ddc" | "cra" | "factures" | "ndf" | "tr"
     setNavigationStep: Dispatch<SetStateAction<"arrets" | "ddc" | "cra" | "factures" | "ndf" | "tr">>
     handleDemandeRectification: () => void
     handleValidation: () => void
     handleValidationDemandeRegul: () => void
     handleRejetDemandeRegul: () => void
     handleReview: () => void
     handleCancelReview: () => void
     isActionDemandeRectifAvailable: boolean
     isActionValidationAvailable: boolean
     isActionValiderOuRejeterDemandeRegulAvailable: boolean
     isActionReviewAvailable: boolean
     isDeclarationAlreadyBeenReviewByConnectedUser: boolean
     nbJoursTravailles?: number
}

interface IConsultantDeclarationDetailsPropsModel {
     declaration: IConsultantDeclarationMensuelleModel
     defaultStep?: "arrets" | "ddc" | "cra" | "factures" | "ndf" | "tr"
     handleDemandeRectificationCallback?: (val: IConsultantDeclarationMensuelleSuiviModel) => void
     handleValidationCallback?: (val: IConsultantDeclarationMensuelleSuiviModel) => void
     handleValidationDemandeRegulCallback?: (val: IConsultantDeclarationMensuelleSuiviModel) => void
     handleReviewCallback?: (val: IConsultantDeclarationMensuelleReviewModel) => void
     handleCancelReviewCallback?: (val: IConsultantDeclarationMensuelleReviewModel) => void
     handleRejetDemandeRegulCallback?: (val: IConsultantDeclarationMensuelleSuiviModel) => void
     nbJoursTravailles?: number
}

export default ConsultantDeclarationDetails
