import { toAbsoluteUrl } from "@common-metronic/helpers"
import { usePersonnelDetailsContext } from "./PersonnelDetails"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "../../../../common/components/MyLoadingOverlay"
import React, { useState } from "react"
import Navigation from "./_Navigation"
import clsx from "clsx"
import moment from "moment"

const SideBar = () => {
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     return (
          <>
               <div className="flex-column flex-lg-row-auto w-lg-350px w-xl-350px">
                    {/*begin::Card*/}
                    <div className="card mb-5 mb-xl-8">
                         {/*begin::Card body*/}
                         <div className="card-body">
                              <Navigation />

                              {/*begin::Summary*/}
                              {/*begin::User Info*/}
                              <div className="d-flex flex-center flex-column py-5">
                                   {/*begin::Avatar*/}
                                   <MyLoadingOverlay isActive={isAvatarLoading} classNames={"h-100px"} styles={{ borderRadius: "50%" }}>
                                        <div className="symbol symbol-100px symbol-circle mb-7">
                                             <img
                                                  src={
                                                       details?.personnel.avatarGoogleDriveId
                                                            ? FileAndDriveHelper.getThumbnailDriveUrl(details?.personnel.avatarGoogleDriveId)
                                                            : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                                  }
                                                  alt="Avatar"
                                                  onLoad={() => setIsAvatarLoading(false)}
                                             />
                                             <div
                                                  className={clsx(
                                                       "position-absolute translate-middle top-50 start-100 mb-2 bg-success rounded-circle border border-4 border-white h-15px w-15px",
                                                       {
                                                            "bg-success": !details?.personnelCompte?.dateFinAcces || moment().isBefore(details?.personnelCompte?.dateFinAcces),
                                                            "bg-danger": moment().isAfter(details?.personnelCompte?.dateFinAcces),
                                                       }
                                                  )}
                                             />
                                        </div>
                                   </MyLoadingOverlay>
                                   {/*end::Avatar*/}
                                   {/*begin::Name*/}
                                   <span className="fs-3 text-gray-800 fw-bolder mb-3">{details?.personnel.prenomNom}</span>
                                   {/*end::Name*/}
                                   {/*begin::Position*/}
                                   <div className="mb-9">
                                        {/*begin::Badge*/}
                                        <div className="badge badge-lg badge-light-primary d-inline">
                                             {details?.personnel.personnelPostes && details?.personnel.personnelPostes?.length > 0
                                                  ? details?.personnel.personnelPostes[0].poste?.nom
                                                  : "Non renseigné"}
                                        </div>
                                        {/*begin::Badge*/}
                                   </div>
                                   {/*end::Position*/}
                                   {/*begin::Info*/}
                                   {/*begin::Info heading*/}
                                   <div className="d-flex flex-wrap flex-center">
                                        {/*begin::Stats*/}
                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                             <div className="fs-4 fw-bolder text-gray-700">
                                                  <span className="w-75px">- k€</span>
                                             </div>
                                             <div className="fw-bold text-success">CA</div>
                                        </div>
                                        {/*end::Stats*/}
                                        {/*begin::Stats*/}
                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                                             <div className="fs-4 fw-bolder text-gray-700">
                                                  <span className="w-50px">- %</span>
                                             </div>
                                             <div className="fw-bold text-warning">MBP en %</div>
                                        </div>
                                        {/*end::Stats*/}
                                        {/*begin::Stats*/}
                                        <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                                             <div className="fs-4 fw-bolder text-gray-700">
                                                  <span className="w-50px">-</span>
                                             </div>
                                             <div className="fw-bold text-muted">Entrées</div>
                                        </div>
                                        {/*end::Stats*/}
                                   </div>
                                   {/*end::Info*/}
                              </div>
                              {/*end::User Info*/}
                              {/*end::Summary*/}
                              {/*begin::Details toggle*/}
                              <div className="d-flex flex-stack fs-4 py-3">
                                   <div className="fw-bolder">Details</div>
                              </div>
                              {/*end::Details toggle*/}
                              <div className="separator" />
                              {/*begin::Details content*/}
                              <div id="kt_user_view_details" className="collapse show">
                                   <div className="pb-5 fs-6">
                                        {/*begin::Details item*/}
                                        <div className="fw-bolder mt-5">Email</div>
                                        <div className="text-gray-600">
                                             <a href={`mailto:${details?.personnel.mailProfessionnel}`} className="text-gray-600 text-hover-primary">
                                                  {details?.personnel.mailProfessionnel}
                                             </a>
                                        </div>
                                        {/*begin::Details item*/}
                                        {/*begin::Details item*/}
                                        <div className="fw-bolder mt-5">Tél.</div>
                                        <div className="text-gray-600">
                                             <a
                                                  href={`tel:${
                                                       details?.personnel.telProfessionnelIndicatif ? details?.personnel.telProfessionnelIndicatif.phoneCodeWithPlusPrefix : ""
                                                  } ${details?.personnel.telProfessionnel}`}
                                                  className="text-gray-600 text-hover-primary"
                                             >
                                                  {details?.personnel.telProfessionnelIndicatif ? details?.personnel.telProfessionnelIndicatif.phoneCodeWithPlusPrefix : ""}{" "}
                                                  {details?.personnel.telProfessionnel}
                                             </a>
                                        </div>
                                        {/*begin::Details item*/}
                                        {/*begin::Details item*/}
                                        <div className="fw-bolder mt-5">Agence</div>
                                        <div className="text-gray-600">{details?.personnel.agence?.nom}</div>
                                        {/*begin::Details item*/}
                                        {/*begin::Details item*/}
                                        <div className="fw-bolder mt-5">Dernière connexion</div>
                                        <div className="text-gray-600">
                                             {details?.personnelCompte?.dateDerniereConnexion
                                                  ? details?.personnelCompte?.dateDerniereConnexion?.format("dddd D MMMM YYYY à H[h]m")
                                                  : "Jamais connecté"}
                                        </div>
                                        {/*begin::Details item*/}
                                        {/*begin::Details item*/}
                                        <div className="fw-bolder mt-5">LinkedIn</div>
                                        <div className="text-gray-600">
                                             {details?.personnel.linkedinUrl ? details?.personnel.linkedinUrl : <span className={"text-warning fw-light"}>Non renseigné</span>}
                                        </div>
                                        {/*begin::Details item*/}
                                   </div>
                              </div>
                              {/*end::Details content*/}
                         </div>
                         {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
               </div>
          </>
     )
}

export default SideBar
