// Etat badge for column état
import { IConsultantDeclarationMensuelleModel } from "@common-models/*"
import {
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
     CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF,
} from "@common-constants/*"
import React from "react"
import { useAuth } from "../../../../../../AppContext"
import { useConsultantsDeclarationsListingContext } from "../ConsultantsDeclarationsListing"
import { IGetConsultantsDeclarationsDataItemResponseModel } from "../core/_models"
import MyTooltip from "@common-utils/MyTooltip"

// Etat badge for cell column état
export const EtatBadge = ({ declaration }: { declaration: IConsultantDeclarationMensuelleModel | null }) => {
     // Hooks
     const { currentUser } = useAuth()

     // Vars & consts
     const doesConnectedUserHaveAccessToModuleAdmin = (() => {
          return currentUser?.authorizedAppModules?.find(item => item === CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF) !== undefined
     })()
     const isDeclarationAlreadyBeenReview = declaration?.reviews?.some(item => item.deletedAt === null) || false

     const PreValideBadge = declaration?.reviews ? (
          <MyTooltip
               title={
                    <>
                         <div className="fs-5">Pré-validations</div>
                         <div className="separator separator-dashed border-muted my-2" />

                         <div className={"d-flex flex-column gap-2"}>
                              {declaration?.reviews
                                   ?.filter(r => !r.deletedAt)
                                   .map((r, index) => (
                                        <div key={index}>
                                             Par <b>{r.personnel?.prenomNom}</b> {r.date?.format("dddd D MMMM YYYY à H[h]m")}
                                        </div>
                                   ))}
                         </div>
                    </>
               }
          >
               <span className={"badge badge-warning not-concerned-by-preview"}>Pré-validé</span>
          </MyTooltip>
     ) : (
          <></>
     )

     if (declaration?.etat) {
          // Badge according to access
          if (doesConnectedUserHaveAccessToModuleAdmin) {
               if (
                    [
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }
               if ([CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }
               if (
                    [
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,

                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }
               if (
                    [
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return (
                         <span className={"badge badge-success not-concerned-by-preview"}>
                              {declaration.consultant?.contrat === "CONTRAT_ST" ? "Facturée" : "Validée par le siège"}
                         </span>
                    )
               }
          } else {
               if (
                    [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }

               if ([CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(declaration.etat?.type as string)) {
                    return isDeclarationAlreadyBeenReview ? PreValideBadge : <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }
          }

          if (
               [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(
                    declaration.etat?.type as string
               )
          ) {
               return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
          }

          if (
               [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL].includes(
                    declaration.etat?.type as string
               )
          ) {
               if (isDeclarationAlreadyBeenReview) {
                    return isDeclarationAlreadyBeenReview ? PreValideBadge : <span className={"badge badge-warning not-concerned-by-preview"}>À contrôler</span>
               }
               if ([CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Envoyée au siège</span>
               }

               if (
                    [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validée par le siège</span>
               }
          }
     }

     return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
}

export const IconRelance = ({ item }: { item: IGetConsultantsDeclarationsDataItemResponseModel }) => {
     const context = useConsultantsDeclarationsListingContext()

     const ActionIcon = () => <span className={"fas fa-bell fs-1 text-warning text-hover-danger ms-4 not-concerned-by-preview"} onClick={() => context.handleRelance([item])} />

     return context.isRelancable(item) ? <ActionIcon /> : <></>
}
