import React, { useState } from "react"
import { useQueryClient } from "react-query"
import Swal from "sweetalert2"
import { toast } from "react-toastify"
import { IPersonnelDdcModel } from "@common-models/*"
import {
     CONST_CIVILITE_MME,
     CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE,
     CONST_PERSONNEL_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE,
     CONST_PERSONNEL_DDC_SUIVI_TYPE_ANNULATION_DEMANDE,
     CONST_PERSONNEL_DDC_SUIVI_TYPE_ENVOI_DEMANDE,
     CONST_PERSONNEL_DDC_SUIVI_TYPE_RECTIFICATION_AVANT_DECISION_RESPONSABLE,
     CONST_PERSONNEL_DDC_SUIVI_TYPE_REJET_DEMANDE,
} from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import { annulerConnectedUserDdcRequest } from "../../../mes-activites/ddc/core/_requests"
import GlobalHelper from "@common-helpers/GlobalHelper"
import { usePersonnelDdcListingContext } from "./PersonnelDdcListingForOne"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../../AppContext"
import MyGoogleDriveFileViewerInModal from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"
import useDateHelper from "@common-helpers/useDateHelper"

const PersonnelDdcListingItem = ({ item }: ConnectedUserDdcListingItemPropsModel) => {
     const { currentUser } = useAuth()
     const context = usePersonnelDdcListingContext()
     const { swal } = useSwal()
     const queryClient = useQueryClient()
     const [isJustificatifShownInModal, setIsJustificatifShownInModal] = useState<boolean>(false)
     const { countWeekdaysBetweenDates } = useDateHelper()

     const BadgeAccordingToEtat = ({ ddc }: { ddc: IPersonnelDdcModel }) => {
          return (
               <MyTooltip title={"Voir le suivi"}>
                    <div className={"d-inline cursor-pointer"} onClick={() => context.setDdcSuiviInModal(ddc)}>
                         {[CONST_PERSONNEL_DDC_SUIVI_TYPE_RECTIFICATION_AVANT_DECISION_RESPONSABLE, CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE].includes(ddc.etat?.type as string) && (
                              <span className="badge badge-light-warning">En cours</span>
                         )}
                         {ddc.etat?.type === CONST_PERSONNEL_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE && <span className="badge badge-light-success">Validé</span>}
                         {ddc.etat?.type === CONST_PERSONNEL_DDC_SUIVI_TYPE_REJET_DEMANDE && <span className="badge badge-light-danger">Rejeté</span>}
                         {ddc.etat?.type === CONST_PERSONNEL_DDC_SUIVI_TYPE_ANNULATION_DEMANDE && <span className="badge badge-secondary">Annulé</span>}
                    </div>
               </MyTooltip>
          )
     }

     function handleAnnulerDdc(ddc: IPersonnelDdcModel) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir annuler la demande couvrant du ${ddc.du
                    ?.parseZone()
                    .format("DD/MM/YYYY")} au ${ddc.au?.parseZone().format("DD/MM/YYYY")}?`,
               text: "Un mail sera envoyé pour informer de cette annulation.",
               input: "textarea",
               inputPlaceholder: "Précisez la raison de l'annulation",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Précisez la raison de l'annulation") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return annulerConnectedUserDdcRequest(ddc.id as number, commentaire)
                         .then(r => {
                              queryClient.setQueryData<IPersonnelDdcModel[] | undefined>(
                                   context.REACT_QUERY_KEY_GET_CONNECTED_USER_DDC_LISTING,
                                   (prev: IPersonnelDdcModel[] | undefined) => {
                                        if (prev) {
                                             const index = prev.findIndex(item => item.id === ddc.id)
                                             if (index >= 0) {
                                                  prev[index] = {
                                                       ...prev[index],
                                                       ...r.data,
                                                  }
                                             }
                                        }

                                        return prev
                                   }
                              )
                              toast.success("Votre demande a bien été annulée.")
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     const totalDemiJournees = item.demiJournees?.length || 0
     const totalJourneesCompletes = countWeekdaysBetweenDates(item.du!.parseZone(), item.au!.parseZone())
     const dureeAbsenceEnJours = totalJourneesCompletes - totalDemiJournees * 0.5

     return (
          <>
               <div className="d-flex align-items-center">
                    <div className={"w-65px text-center"}>
                         <BadgeAccordingToEtat ddc={item} />
                         {item.justificatifGoogleDriveID && (
                              <MyTooltip title={"Voir le justificatif"}>
                                   <div className="text-center mt-4 cursor-pointer" onClick={() => setIsJustificatifShownInModal(true)}>
                                        <i className={"fas fa-file-alt fs-1"} />
                                   </div>
                              </MyTooltip>
                         )}
                    </div>
                    <div className={"border-start ps-3 ms-2"}>
                         <span className={"text-primary fw-bolder d-block py-2 fs-5"}>{item.typeLabel}</span>
                         <span className="d-block fw-bold">
                              <MyTooltip
                                   title={
                                        <>
                                             <span>
                                                  <div className={"fw-bold text-dark"}>Période</div>
                                                  <div>Du {GlobalHelper.firstLetterUppercase(item.du?.parseZone().format("dddd D MMMM YYYY"))} </div>

                                                  <div>au {GlobalHelper.firstLetterUppercase(item.au?.parseZone().format("dddd D MMMM YYYY"))}</div>
                                                  {item.demiJournees && item.demiJournees.length > 0 && (
                                                       <>
                                                            <div className={"fw-bold mt-2 text-dark"}>
                                                                 Dont {item.demiJournees.length} demi journée{item.demiJournees.length > 1 && "s"}
                                                            </div>
                                                            {item.demiJournees.map(dj => (
                                                                 <div>{GlobalHelper.firstLetterUppercase(dj.parseZone().format("dddd D MMMM YYYY"))}</div>
                                                            ))}
                                                       </>
                                                  )}
                                             </span>
                                        </>
                                   }
                              >
                                   <span className="me-1 mb-1 cursor-default">
                                        {item.du?.parseZone().format("DD/MM/YYYY")} - {item.au?.parseZone().format("DD/MM/YYYY")}
                                   </span>
                              </MyTooltip>
                         </span>
                         <span className={"text-gray-600"}>
                              Durée: {dureeAbsenceEnJours} {dureeAbsenceEnJours > 1 ? "jours" : "jour"}{" "}
                         </span>
                         {context.editionMode &&
                              [
                                   CONST_PERSONNEL_DDC_SUIVI_TYPE_ENVOI_DEMANDE,
                                   CONST_PERSONNEL_DDC_SUIVI_TYPE_RECTIFICATION_AVANT_DECISION_RESPONSABLE,
                                   CONST_PERSONNEL_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE,
                              ].includes(item.etat!.type as string) && (
                                   <div className="d-flex text-dark fw-bold mt-4">
                                        <span className={"cursor-pointer text-hover-warning d-flex align-items-center me-4"} onClick={() => context.setDdcEditionInModal(item)}>
                                             <i className={"fas fa-edit me-2 fs-4 text-warning"} /> Editer
                                        </span>
                                        <span className={"cursor-pointer text-hover-danger d-flex align-items-center"} onClick={() => handleAnnulerDdc(item)}>
                                             <i className={"fas fa-times-circle me-2 fs-4 text-danger"} /> Annuler
                                        </span>
                                   </div>
                              )}
                    </div>
               </div>

               {/* Document in modal */}
               {isJustificatifShownInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={item.justificatifGoogleDriveID as string}
                         googleDriveMimetype={item.justificatifGoogleDriveMimeType as string}
                         modalTitle={<span>Justificatif</span>}
                         handleCloseModal={() => setIsJustificatifShownInModal(false)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface ConnectedUserDdcListingItemPropsModel {
     item: IPersonnelDdcModel
}

export default PersonnelDdcListingItem
