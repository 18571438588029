import React from "react"
import MyCard from "@common-utils/MyCard"
import { useDDdcListingContext } from "./ConsultantDdcListing"
import "./styles.scss"
import { IGetDemandesCongesResponseItemModel } from "../core/_models"
import MyGoogleDriveFileViewer from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewer"

/* Wrapper */
const DdcListingPreview = () => {
     const globalContext = useDDdcListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(
          item => item.consultantDdc.id === selectedItemInPreview.consultantDdc.id
     ) as IGetDemandesCongesResponseItemModel

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>Justificatif</MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyGoogleDriveFileViewer
                         googleDriveID={itemInPreview.consultantDdc.justificatifGoogleDriveID as string}
                         googleDriveMimetype={itemInPreview.consultantDdc.justificatifGoogleDriveMimeType as string}
                    />
               </MyCard.Body>
          </MyCard>
     )
}

export default DdcListingPreview
