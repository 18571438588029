import { IClientPropalCDCModel, IHttpErrorResponseModel } from "@common-models/*"
import React, { useRef, useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import { useQuery, useQueryClient } from "react-query"
import axios, { AxiosError } from "axios"
import { addCdcToPropalRequest, deleteCdcOfPropalRequest, getClientPropalCDCsRequest, replaceCdcOfPropalRequest } from "../core/_requests"
import { toast } from "react-toastify"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"
import useSwal from "@common-hooks/useSwal"
import MyTooltip from "@common-utils/MyTooltip"
import MyModal from "@common-utils/MyModal"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { CONST_API_CLIENT_PTF_CDC_GET_DOWNLOAD_CDC_ENDPOINT } from "../../../../endpoints"

const REACT_QUERY_KEY_GET_PTF_CDC = "REACT_QUERY_KEY_GET_PTF_CDC"
const PtfDetailsCdc = ({ ptf_ID }: IConsultantItemPropsModel) => {
     const queryClient = useQueryClient()
     const { swal } = useSwal()
     const refAddCdcInput = useRef<any>(null)
     const refReplaceCdcInput = useRef<any>(null)
     const [cdcInModal, setCdcInModal] = useState<{ cdc: IClientPropalCDCModel; index: number; loading: boolean }>()
     const [cdcToBeReplaced, setCdcToBeReplaced] = useState<IClientPropalCDCModel>()
     const cdcQuery = useQuery<IClientPropalCDCModel[], AxiosError>(REACT_QUERY_KEY_GET_PTF_CDC, async () => {
          return getClientPropalCDCsRequest(ptf_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Handles add CDC
     function handleAddCdc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Ajout en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               addCdcToPropalRequest(ptf_ID, file_binary)
                    .then(r => {
                         queryClient.setQueryData<IClientPropalCDCModel[] | undefined>(REACT_QUERY_KEY_GET_PTF_CDC, (prev: IClientPropalCDCModel[] | undefined) => {
                              if (prev) {
                                   return [...prev, r.data]
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le cahier des charges a bien été ajouté")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data.detail, { autoClose: false })
                    })
          }
     }

     // Handles delete CDC
     function handleRemoveCdc(cdc_id: number, index: number) {
          swal.fire({
               icon: "warning",
               text: `Confirmez-vous la suppression du CDC n°${index}?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return deleteCdcOfPropalRequest(cdc_id)
                         .then(() => {
                              queryClient.setQueryData<IClientPropalCDCModel[] | undefined>(REACT_QUERY_KEY_GET_PTF_CDC, (prev: IClientPropalCDCModel[] | undefined) => {
                                   if (prev) {
                                        return prev.filter(item => item.id !== cdc_id)
                                   }

                                   return prev
                              })
                              toast.success("Le cahier des charges a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles replace CDC
     function handleReplaceCdc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               replaceCdcOfPropalRequest(cdcToBeReplaced?.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<IClientPropalCDCModel[] | undefined>(REACT_QUERY_KEY_GET_PTF_CDC, (prev: IClientPropalCDCModel[] | undefined) => {
                              if (prev) {
                                   prev.map((item, index) => {
                                        if (item.id === cdcToBeReplaced?.id) {
                                             prev[index] = r.data
                                        }
                                   })
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le cahier des charges a bien été remplacé")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e?.response?.data.detail, { autoClose: false })
                    })
          }
     }

     // Handles download curriculum
     function handleDownloadCdc(cdc_id: number, mimeType: string, index: number) {
          toast.info("Téléchargement en cours ...", {
               ...CONST_DEFAULT_TOAST_OPTIONS,
               autoClose: false,
          })
          axios.get(CONST_API_CLIENT_PTF_CDC_GET_DOWNLOAD_CDC_ENDPOINT(cdc_id), {
               responseType: "blob",
          })
               .then(r => {
                    const fileName = `CDC n°${index}.${FileAndDriveHelper.getExtensionAccordingToMimetype(mimeType)}`
                    FileAndDriveHelper.downloadFile(r.data, fileName)

                    toast.dismiss()
                    toast.success("Le cahier des charges a bien été téléchargé")
               })
               .catch(() => {
                    toast.dismiss()
                    toast.error("Un problème est survenu lors du téléchargement du cahier des charges.", { autoClose: false })
               })
     }

     return (
          <>
               <div className="d-flex justify-content-center">
                    <input type="file" className={"d-none"} ref={refAddCdcInput} onChange={handleAddCdc} />
                    <button className={"btn btn-sm btn-outline btn-outline-dark d-flex align-items-center"} type={"button"} onClick={() => refAddCdcInput.current.click()}>
                         Ajouter <i className={"fas fa-plus-circle ms-1"} />
                    </button>
               </div>
               <div className="separator mt-2 mb-4"></div>
               {/* Is fetching */}
               {cdcQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* Is Error */}
               {!cdcQuery.isFetching && cdcQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{cdcQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => cdcQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* With results */}
               {!cdcQuery.isFetching && !cdcQuery.isError && cdcQuery.data && (
                    <div className={"row"}>
                         {cdcQuery.data.map((item, index) => {
                              const fileIconAccordingToMimeType = FileAndDriveHelper.getFontAwesomeClassAndColorAccordingToFileMimeType(item.googleDriveMimeType as string)
                              return (
                                   <div className="col-12 col-md-6 col-lg-4">
                                        <div className="d-flex justify-content-center text-center flex-column border border-dashed py-4">
                                             {/* FILE ICON with title */}
                                             <div className="cursor-pointer text-hover-primary" onClick={() => setCdcInModal({ cdc: item, index: index + 1, loading: true })}>
                                                  <span className={`${fileIconAccordingToMimeType} text-dark mb-1`} style={{ fontSize: "4rem" }} />
                                                  <div className={"fw-bold"}>CDC n°{index + 1}</div>
                                             </div>

                                             <div className="separator mt-2 mb-4" />

                                             {/* ACTIONS */}
                                             <div className="d-flex justify-content-center">
                                                  <MyTooltip title={"Voir"}>
                                                       <button
                                                            type="button"
                                                            className="btn btn-outline btn-outline-success d-flex align-items-center p-0 px-2 py-1 me-2"
                                                            onClick={() => setCdcInModal({ cdc: item, index: index + 1, loading: true })}
                                                       >
                                                            <span className="fas fa-eye fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                                  <MyTooltip title={"Remplacer"}>
                                                       <button
                                                            type="button"
                                                            className="btn btn-outline btn-outline-primary d-flex align-items-center p-0 px-2 py-1 me-2"
                                                            onClick={() => {
                                                                 setCdcToBeReplaced(item)
                                                                 refReplaceCdcInput.current.click()
                                                            }}
                                                       >
                                                            <span className="fas fa-sync fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                                  <MyTooltip title={"Télécharger"}>
                                                       <button
                                                            type={"button"}
                                                            className="btn btn-outline btn-outline-dark d-flex align-items-center p-0 px-2 py-1 me-2"
                                                            onClick={() => handleDownloadCdc(item.id as number, item.googleDriveMimeType as string, index + 1)}
                                                       >
                                                            <span className="fas fa-download fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                                  <MyTooltip title={"Supprimer"}>
                                                       <button
                                                            type="button"
                                                            className="btn btn-outline btn-outline-danger d-flex align-items-center p-0 px-2 py-1 me-2"
                                                            onClick={() => handleRemoveCdc(item.id as number, index + 1)}
                                                       >
                                                            <span className="fas fa-trash fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   </div>
                              )
                         })}
                    </div>
               )}

               {cdcInModal && (
                    <MyModal title={<span>CDC n°{cdcInModal.index}</span>} show={true} handleClose={() => setCdcInModal(undefined)} size={"lg"} noPadding>
                         <MyLoadingOverlay isActive={cdcInModal.loading}>
                              <iframe
                                   onLoad={() => setCdcInModal(prev => (prev ? { ...prev, loading: false } : undefined))}
                                   src={FileAndDriveHelper.getUrlOfDriveFilePreview(cdcInModal.cdc.googleDriveID as string)}
                                   style={{ height: "84vh", width: "100%" }}
                              />
                         </MyLoadingOverlay>
                    </MyModal>
               )}

               <input type="file" className={"d-none"} ref={refReplaceCdcInput} onChange={handleReplaceCdc} />
          </>
     )
}

interface IConsultantItemPropsModel {
     ptf_ID: number
}

export default PtfDetailsCdc
