import React, { useMemo, useState } from "react"
import * as Yup from "yup"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL, REGEXP_EMAIL } from "@common-constants/*"
import { CONST_API_UTILS_GET_TEMPLATES_ENDPOINT } from "../../../../endpoints"
import { Form, Formik, FormikHelpers } from "formik"
import MyModal from "@common-utils/MyModal"
import MyRadioField from "@common-utils/fields/MyRadioField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import GlobalHelper from "@common-helpers/GlobalHelper"
import MyEditorField from "@common-utils/fields/MyEditorField"
import MyTooltip from "@common-utils/MyTooltip"
import PersonnelTemplateCreateForm from "../../../personnel/mon-compte/templates/_PersonnelTemplateCreateForm"
import PersonnelTemplateEditForm from "../../../personnel/mon-compte/templates/_PersonnelTemplateEditForm"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import { deletePersonnelTemplate, getDetailsPersonnelTemplate } from "../../../personnel/mon-compte/templates/core/_request"
import { AxiosError } from "axios"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { ICandidatDocoPartagerParMailFormikModel, ICandidatDocoPartagerParMailFormPropsModel, ICandidatDocoPartagerParMailRequestModel } from "./core/_models"
import { IHttpErrorResponseModel } from "@common-models/*"
import { partagerDocoParMail } from "./core/_requests"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectCreatableField from "@common-utils/fields/MySelectCreatableField"

const CandidatDocoPartagerParMailForm = ({ candidatDoco, handleSubmitCallBack }: ICandidatDocoPartagerParMailFormPropsModel) => {
     const { swal } = useSwal()

     const [isAddingNewTemplate, setIsAddingNewTemplate] = useState<boolean>(false)
     const [templateInEdition, setTemplateInEdition] = useState<{ id: number; title?: string } | null>(null)

     const [forceEditorToRefreshContent, setForceEditorToRefreshContent] = useState<{ value: string; random: string }>()
     const [isFetchingContent, setIsFetchingTemplateContent] = useState<boolean>(false)

     const schema = Yup.object().shape({
          sansTemplateOuAvecTemplate: Yup.string().label(`"Template"`).required(),
          template: Yup.number()
               .label(`"Template"`)
               .when("sansTemplateOuAvecTemplate", {
                    is: "AVEC_TEMPLATE",
                    then: schema => schema.required(),
               }),
          destinataires: Yup.array().required().label(`"Destinataires"`),
          objetMail: Yup.string().label(`"Objet du mail"`).required(),
          corpsMail: Yup.string().label(`"Corps du mail"`).required(),
          typeEnvoi: Yup.string().label(`"Type d'envoi"`).required(),
          format: Yup.string().label(`"Format du DoCo"`).required(),
     })

     const initialValues: ICandidatDocoPartagerParMailFormikModel = {}

     function handleSubmit(values: ICandidatDocoPartagerParMailFormikModel, helpers: FormikHelpers<ICandidatDocoPartagerParMailFormikModel>) {
          helpers.setStatus(null)
          const payload: ICandidatDocoPartagerParMailRequestModel = {
               destinataires: values.destinataires as string[],
               objetMail: values.objetMail as string,
               corpsMail: values.corpsMail as string,
               typeEnvoi: values.typeEnvoi as "CONST_TYPE_ENVOI_SEPAREMENT" | "CONST_TYPE_ENVOI_CONJOINTEMENT",
               format: values.format as "CONST_FORMAT_DOCX" | "CONST_FORMAT_PDF",
          }
          partagerDocoParMail(candidatDoco.id, payload)
               .then(() => {
                    toast.success("Le DoCo a bien été partagé.")
                    handleSubmitCallBack()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Form Suivi
     const FormSuivi = useMemo(() => {
          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         const DestinatairesTypeEnvoiFormatDocoObjetEtCorpsMailFields = (
                              <>
                                   {/* objetMail field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Objet du mail</label>
                                        <div className="col-lg-8">
                                             <MyLoadingOverlay isActive={isFetchingContent} size={"sm"}>
                                                  <MyInputField
                                                       name={"objetMail"}
                                                       value={helpers.values.objetMail}
                                                       isInvalid={!!helpers.errors.objetMail}
                                                       placeholder={"Objet du mail"}
                                                       onChange={value => helpers.setFieldValue("objetMail", value)}
                                                  />
                                             </MyLoadingOverlay>
                                             {helpers.errors.objetMail && <div className="text-danger">{helpers.errors.objetMail}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Destinataires</label>

                                        <div className="col-lg-8">
                                             <MySelectCreatableField
                                                  name={"destinataires"}
                                                  options={[]}
                                                  value={helpers.values.destinataires}
                                                  onChange={val => helpers.setFieldValue("destinataires", val)}
                                                  isInvalid={!!helpers.errors.destinataires}
                                                  isValidNewOption={val => REGEXP_EMAIL.test(val)}
                                                  placeholder={"Saisissez les @ mails"}
                                             />
                                             {helpers.errors.destinataires && <div className={"text-danger"}>{helpers.errors.destinataires}</div>}
                                        </div>
                                   </div>

                                   {/* corpsMail field */}
                                   <div className={"mb-4"}>
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Corps du mail</label>
                                        <MyLoadingOverlay isActive={isFetchingContent}>
                                             <MyEditorField
                                                  value={helpers.values.corpsMail}
                                                  height={250}
                                                  onChange={val => helpers.setFieldValue("corpsMail", val)}
                                                  placeholder={"corpsMail"}
                                                  forceEditorToRefreshContent={forceEditorToRefreshContent}
                                             />
                                        </MyLoadingOverlay>
                                        <div className={"mb-2"}>{helpers.errors.corpsMail && <div className={"text-danger"}>{helpers.errors.corpsMail}</div>}</div>
                                   </div>

                                   {/* typeEnvoi field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             Type d'envoi{" "}
                                             <MyTooltip title={<span>Choisissez l'option "Séparément" si vous voulez envoyer un mail unique à chaque destinataire.</span>}>
                                                  <i className={"fas fa-info-circle text-dark"} />
                                             </MyTooltip>
                                        </label>
                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"typeEnvoi"}
                                                  options={[
                                                       { label: "Conjointement", value: "CONST_TYPE_ENVOI_SEPAREMENT" },
                                                       { label: "Séparément", value: "CONST_TYPE_ENVOI_CONJOINTEMENT" },
                                                  ]}
                                                  value={helpers.values.typeEnvoi}
                                                  isInvalid={!!helpers.errors.typeEnvoi}
                                                  onChange={value => helpers.setFieldValue("typeEnvoi", value)}
                                             />
                                             {helpers.errors.typeEnvoi && <div className="text-danger">{helpers.errors.typeEnvoi}</div>}
                                        </div>
                                   </div>

                                   {/* format field */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Format du DoCo</label>
                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"format"}
                                                  options={[
                                                       { label: "Word", value: "CONST_FORMAT_DOCX" },
                                                       { label: "PDF", value: "CONST_FORMAT_PDF" },
                                                  ]}
                                                  value={helpers.values.format}
                                                  isInvalid={!!helpers.errors.format}
                                                  onChange={value => helpers.setFieldValue("format", value)}
                                             />
                                             {helpers.errors.format && <div className="text-danger">{helpers.errors.format}</div>}
                                        </div>
                                   </div>
                              </>
                         )

                         const TemplateField = (
                              <div className="row mb-5">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Template</label>
                                   <div className="col-lg-8">
                                        <MySelectGetAllDataFromServer
                                             name={"template"}
                                             value={helpers.values.template}
                                             onChange={val => {
                                                  helpers.setFieldValue("template", val).then(() => {
                                                       // Hydrate content
                                                       if (val) {
                                                            setIsFetchingTemplateContent(true)
                                                            getDetailsPersonnelTemplate(val as number)
                                                                 .then(r => {
                                                                      setForceEditorToRefreshContent({ value: r.data.contenu as string, random: GlobalHelper.randomId() })
                                                                      helpers.setFieldValue("objetMail", r.data.intitule as string).then()
                                                                 })
                                                                 .catch((e: AxiosError) => toast.error(e?.response?.data.detail, { autoClose: false }))
                                                                 .finally(() => setIsFetchingTemplateContent(false))
                                                       }
                                                  })
                                             }}
                                             url={CONST_API_UTILS_GET_TEMPLATES_ENDPOINT}
                                             payload={{
                                                  includeTeam: true,
                                                  categorie: CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL,
                                             }}
                                             isInvalid={!!helpers.errors.template}
                                        />
                                        <div className="row mb-5">
                                             <div className="col">
                                                  {helpers.values.template && (
                                                       <div>
                                                            <MyTooltip title={"Supprimer le template sélectionné"}>
                                                                 <i
                                                                      className={"fas fa-trash text-danger cursor-pointer"}
                                                                      onClick={() =>
                                                                           deleteTemplate(helpers.values.template as number, () => helpers.setFieldValue("template", undefined))
                                                                      }
                                                                 />
                                                            </MyTooltip>
                                                            <MyTooltip title={"Editer le template sélectionné"}>
                                                                 <i
                                                                      className={"fas fa-edit text-warning ms-4 ms-sm-2 cursor-pointer"}
                                                                      onClick={() => setTemplateInEdition({ id: helpers.values.template as number })}
                                                                 />
                                                            </MyTooltip>
                                                       </div>
                                                  )}
                                             </div>
                                             <div className="col text-end">
                                                  <MyTooltip title={"Créer un nouveau template"}>
                                                       <span className={"text-success fw-bold cursor-pointer"} onClick={() => setIsAddingNewTemplate(true)}>
                                                            nouveau <i className={"fas fa-plus-circle text-success"} />
                                                       </span>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                        {helpers.errors.template && <div className="text-danger">{helpers.errors.template}</div>}
                                   </div>
                              </div>
                         )

                         return (
                              <Form noValidate autoComplete="off">
                                   {/* Field sansTemplateOuAvecTemplate */}
                                   <div className="row">
                                        <div className="col-6">
                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                  <span className="d-flex align-items-center me-2">
                                                       <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label bg-light-primary">
                                                                 <i className={"fas fa-file-lines fs-2 text-primary"} />
                                                            </span>
                                                       </span>
                                                       <span className="d-flex flex-column">
                                                            <span className="fw-bolder fs-6">Utiliser un template</span>
                                                            <span className="fs-7 text-muted">Pré-remplissez l'objet et le corps du mail avec un template (recommandé)</span>
                                                       </span>
                                                  </span>
                                                  <MyRadioField
                                                       name={"sansTemplateOuAvecTemplate"}
                                                       value={"AVEC_TEMPLATE"}
                                                       isChecked={helpers.values.sansTemplateOuAvecTemplate === "AVEC_TEMPLATE"}
                                                       isInvalid={!!helpers.errors.sansTemplateOuAvecTemplate}
                                                       onChange={val => helpers.setFieldValue("sansTemplateOuAvecTemplate", val)}
                                                  />
                                             </label>
                                        </div>
                                        <div className="col-6">
                                             <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                  <span className="d-flex align-items-center me-2">
                                                       <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label bg-light-success">
                                                                 <i className={"fas fa-file fs-2 text-success"} />
                                                            </span>
                                                       </span>
                                                       <span className="d-flex flex-column">
                                                            <span className="fw-bolder fs-6">Saisie vierge</span>
                                                            <span className="fs-7 text-muted">Rédigez l'objet et le crops du mail sans pré-saisie</span>
                                                       </span>
                                                  </span>
                                                  <MyRadioField
                                                       name={"sansTemplateOuAvecTemplate"}
                                                       value={"SANS_TEMPLATE"}
                                                       isChecked={helpers.values.sansTemplateOuAvecTemplate === "SANS_TEMPLATE"}
                                                       isInvalid={!!helpers.errors.sansTemplateOuAvecTemplate}
                                                       onChange={val => helpers.setFieldValue("sansTemplateOuAvecTemplate", val)}
                                                  />
                                             </label>
                                        </div>
                                   </div>

                                   {helpers.values.sansTemplateOuAvecTemplate && (
                                        <>
                                             <div className={"border p-4 mb-2"}>
                                                  {helpers.values.sansTemplateOuAvecTemplate === "AVEC_TEMPLATE" ? (
                                                       <>
                                                            {/* Field template */}
                                                            {TemplateField}

                                                            {/* other fieldS */}
                                                            {helpers.values.template && DestinatairesTypeEnvoiFormatDocoObjetEtCorpsMailFields}
                                                       </>
                                                  ) : (
                                                       <>{DestinatairesTypeEnvoiFormatDocoObjetEtCorpsMailFields}</>
                                                  )}
                                             </div>
                                             {/* Submit button */}
                                             <div className={"text-end"}>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? "Partager" : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </>
                                   )}
                              </Form>
                         )
                    }}
               </Formik>
          )
     }, [isFetchingContent])

     // Template create
     const CreateTemplateFormInModal = () => {
          return (
               <MyModal title={<span>Création d'un template "Partage de DoCo"</span>} show={true} handleClose={() => setIsAddingNewTemplate(false)} fullscreen>
                    <PersonnelTemplateCreateForm
                         categorie={CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL}
                         handleOnSubmitCallBack={() => setIsAddingNewTemplate(false)}
                    />
               </MyModal>
          )
     }

     // Template edit
     const EditTemplateFormInModal = () => {
          return (
               <MyModal title={<span>Edition du template</span>} show={true} handleClose={() => setTemplateInEdition(null)} fullscreen>
                    <PersonnelTemplateEditForm template_ID={templateInEdition!.id} handleOnSubmitCallBack={() => setTemplateInEdition(null)} />
               </MyModal>
          )
     }

     // Template delete
     function deleteTemplate(id: number, callBack: () => void) {
          // Sweet alert confirmation
          return swal.fire({
               icon: "warning",
               title: "Vous êtes sur le point de supprimer le template sélectionné",
               text: "Voulez-vous continuer?",
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return deletePersonnelTemplate(id)
                         .then(() => {
                              toast.info("Le template a bien été supprimé")
                              callBack()
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          })
     }

     return (
          <>
               {FormSuivi}

               {isAddingNewTemplate && <CreateTemplateFormInModal />}
               {templateInEdition && <EditTemplateFormInModal />}
          </>
     )
}

export default CandidatDocoPartagerParMailForm
