import { IGetDocumentsConsultant } from "../core/_models"
import React, { useRef, useState } from "react"
import { ICandidatDocumentModel, IConsultantDocumentModel, IConsultantMissionModel, IConsultantModel } from "@common-models/*"
import { REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS } from "./_ConsultantDetails_Documents"
import {
     CONST_CANDIDAT_DOCUMENT_TYPE_DOCO,
     CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT,
     CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
     CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROIT_GESTION_CONTRAT_TRAVAIL,
} from "@common-constants/*"
import MyTooltip from "@common-utils/MyTooltip"
import { useQueryClient } from "react-query"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyModal from "@common-utils/MyModal"
import { useConsultantDocumentActions, useConsultantMissionDocActions } from "../../core/_hooks"
import ConsultantAddAvenantContratForm from "./helpers/_ConsultantAddAvenantContrat"
import { useCandidatDocumentActions } from "../../../../recrutement/candidats/core/_hooks"
import ConsultantEditContratDetailsForm from "./helpers/_ConsultantEditContratDetails"
import ConsultantEditMissionDetailsForm from "./helpers/_ConsultantEditMissionDetails"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import MyGoogleDriveFileViewerInModal from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"

const ConsultantDetailsDocumentsPro = ({ documents }: IConsultantDetailsDocumentsProPropsModel) => {
     const context = useConsultantDetailsContext()

     const queryClient = useQueryClient()
     const consultantDocumentActions = useConsultantDocumentActions(context.detailsQuery.data?.consultant?.id as number)
     const candidatDocumentActions = useCandidatDocumentActions(context.detailsQuery.data?.consultant.candidat?.id as number)
     const consultantMissionDocActions = useConsultantMissionDocActions()

     const [isAddingAvenantContrat, setIsAddingAvenantContrat] = useState<boolean>(false)
     const [documentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMime: string
          modalTitle: string
          isLoading: boolean
     }>()

     /* Container containing missions (ODM) */
     const MissionsContainer = ({ children }: { children: JSX.Element }) => {
          return (
               <div className={"p-4 border border-dashed mb-4"}>
                    <div className="d-flex justify-content-between d-flex align-items-center mb-4">
                         <h4>{context.detailsQuery.data?.consultant.contrat === "CONTRAT_ST" ? "Missions" : "Ordres de mission"}</h4>
                    </div>
                    <div className="separator separator-dashed mb-6" />
                    {children}
               </div>
          )
     }

     /* Container containing contrats */
     const ContratsContainer = ({ children }: { children: JSX.Element }) => {
          const refInputAddDocument = useRef<HTMLInputElement>(null)

          function handleAddAvenantCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.contrats) prev.documentsPro.contrats = [val, ...prev.documentsPro.contrats]
                    }
                    return prev
               })
               setIsAddingAvenantContrat(false)
          }

          return (
               <div className={"p-4 border border-dashed mb-4"}>
                    <div className="d-flex justify-content-between d-flex align-items-center mb-4">
                         <h4>Contrats</h4>
                         <MyTooltip title={"Ajouter"}>
                              <button type={"button"} className={"btn btn-sm btn-outline btn-outline-success p-0 px-2 py-1 me-2"} onClick={() => setIsAddingAvenantContrat(true)}>
                                   <i className={"fas fa-plus-circle fs-5"} />
                              </button>
                         </MyTooltip>
                         <input type={"file"} className={"d-none"} ref={refInputAddDocument} onChange={e => () => {}} />
                    </div>
                    <div className="separator separator-dashed mb-6" />
                    {children}

                    {/* Add avenant contrat */}
                    {isAddingAvenantContrat && (
                         <MyModal title={<span>Ajout d'un avenant au contrat</span>} show={true} handleClose={() => setIsAddingAvenantContrat(false)} size={"lg"}>
                              <ConsultantAddAvenantContratForm consultant={context.detailsQuery.data?.consultant as IConsultantModel} callback={handleAddAvenantCallback} />
                         </MyModal>
                    )}
               </div>
          )
     }

     /* Container containing Docos */
     const DocosContainer = ({ children }: { children: JSX.Element }) => {
          const refInputAddDocument = useRef<HTMLInputElement>(null)

          function handleAddDocumentCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         prev.documentsPro.docos = [...(prev.documentsPro.docos || []), val]
                    }
                    return prev
               })
          }

          return (
               <div className={"p-4 border border-dashed mb-4"}>
                    <div className="d-flex justify-content-between d-flex align-items-center mb-4">
                         <h4>DoCos</h4>
                         <MyTooltip title={"Ajouter"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-success p-0 px-2 py-1 me-2"}
                                   onClick={() => refInputAddDocument.current?.click()}
                              >
                                   <i className={"fas fa-plus-circle fs-5"} />
                              </button>
                         </MyTooltip>
                         <input
                              type={"file"}
                              className={"d-none"}
                              ref={refInputAddDocument}
                              onChange={e => candidatDocumentActions.add(e, CONST_CANDIDAT_DOCUMENT_TYPE_DOCO, handleAddDocumentCallback)}
                         />
                    </div>
                    <div className="separator separator-dashed mb-6" />
                    {children}
               </div>
          )
     }

     /* DoCo item */
     const DoCoItem = ({ document, classNames = "" }: { document: ICandidatDocumentModel; classNames?: string }) => {
          const refInputReplaceDocument = useRef<HTMLInputElement>(null)

          function handleDeleteDocumentCallback() {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (document.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO) prev.documentsPro.docos = (prev.documentsPro.docos || []).filter(item => item.id !== document.id)
                    }
                    return prev
               })
          }

          function handleReplaceDocumentCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (document.type === CONST_CANDIDAT_DOCUMENT_TYPE_DOCO && prev.documentsPro.docos) {
                              prev.documentsPro.docos.map(item => {
                                   if (item.id === val.id) {
                                        item.googleDriveID = val.googleDriveID
                                        return item
                                   }
                              })
                         }
                    }
                    return prev
               })
          }

          return (
               <>
                    <div className={`d-flex ${classNames}`}>
                         <MyTooltip title={<span>Déposé le {document.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                              <div
                                   className="symbol symbol-35px cursor-pointer me-4 pe-1 border-end-dashed rounded-0 border-gray-300"
                                   onClick={() =>
                                        setActiveDriveDocumentInModal({
                                             modalTitle: `${document.typeLabel}`,
                                             driveID: document.googleDriveID as string,
                                             driveMime: document.googleDriveMimeType as string,
                                             isLoading: true,
                                        })
                                   }
                              >
                                   <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-light-show" alt="" />
                                   <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-dark-show" alt="" />
                              </div>
                         </MyTooltip>
                         <div className={"d-flex align-items-center"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                                   onClick={() =>
                                        setActiveDriveDocumentInModal({
                                             modalTitle: `${document.typeLabel}`,
                                             driveID: document.googleDriveID as string,
                                             driveMime: document.googleDriveMimeType as string,
                                             isLoading: true,
                                        })
                                   }
                              >
                                   Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                              </button>
                              <MyTooltip title={"Remplacer"}>
                                   <button
                                        type={"button"}
                                        className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                        onClick={() => refInputReplaceDocument.current?.click()}
                                   >
                                        <i className={"fas fa-sync fs-5"} />
                                   </button>
                              </MyTooltip>
                              <input
                                   type={"file"}
                                   className={"d-none"}
                                   ref={refInputReplaceDocument}
                                   onChange={e => candidatDocumentActions.replace(e, document.id as number, handleReplaceDocumentCallback)}
                              />
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-danger p-0 px-2 py-1"}
                                   onClick={() => candidatDocumentActions.flagAsDeleted(document.id as number, handleDeleteDocumentCallback)}
                              >
                                   <i className={"fas fa-trash fs-5"} />
                              </button>
                         </div>
                    </div>
               </>
          )
     }

     /* Contrat item */
     const ContratItem = ({ document, classNames = "" }: { document: IConsultantDocumentModel; classNames?: string }) => {
          const [isEditingContratDetailsInModal, setIsEditingContratDetailsInModal] = useState<boolean>()
          const refInputAddVersionSignee = useRef<HTMLInputElement>(null)
          const refInputReplaceDocumentNonSigne = useRef<HTMLInputElement>(null)
          const refInputReplaceDocumentSigne = useRef<HTMLInputElement>(null)
          const contratSigne = document.fileSigne

          function handleAddDocumentSigneCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.contrats) {
                              prev.documentsPro.contrats.map(item => {
                                   if (item.id === document.id) {
                                        item.fileSigne = val
                                        return item
                                   }
                              })
                         }
                    }
                    return prev
               })
          }

          function handleReplaceDocumentCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.contrats) {
                              prev.documentsPro.contrats.map(item => {
                                   if (item.id === val.id) {
                                        item.googleDriveID = val.googleDriveID
                                   }

                                   if (item.fileSigne && item.fileSigne.id === val.id) {
                                        item.fileSigne.googleDriveID = val.googleDriveID
                                   }

                                   return item
                              })
                         }
                    }
                    return prev
               })
          }

          function handleEditDetailsCallback(val: IConsultantDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.contrats) {
                              prev.documentsPro.contrats.map(item => {
                                   if (item.id === document.id) {
                                        item.dateDebutValidite = val.dateDebutValidite
                                   }

                                   return item
                              })
                         }
                    }
                    return prev
               })
          }

          return (
               <>
                    <div className={"p-4 border border-dashed mb-4"}>
                         <div className={"d-flex justify-content-between"}>
                              <div className="fs-6">
                                   {document.type === CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT && (
                                        <>{context.detailsQuery.data?.consultant.contrat !== "CONTRAT_ST" ? "Contrat de travail" : "Contrat"}</>
                                   )}

                                   {document.type === CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT && "Avenant au contrat"}
                              </div>
                              <MyTooltip title={"Éditer"}>
                                   <div
                                        className="fas fa-edit text-warning d-flex align-items-center fs-3 cursor-pointer btn-active-light-primary"
                                        onClick={() => setIsEditingContratDetailsInModal(true)}
                                   />
                              </MyTooltip>
                         </div>
                         <div className={"text-muted fs-9 mb-1"}>Valable depuis le {document.dateDebutValidite?.format("DD/MM/YYYY")}</div>
                         <div className="separator separator-dashed mb-6" />

                         {/* Version non signée  */}
                         <div className={`d-flex ${classNames}`}>
                              <MyTooltip title={<span>Déposé le {document.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                                   <div
                                        className={"fas fa-file text-dark border-end-dashed rounded-0 border-gray-300 d-flex align-items-center me-4 cursor-pointer"}
                                        style={{ fontSize: "2.5rem", paddingRight: "1.15rem" }}
                                        onClick={() =>
                                             setActiveDriveDocumentInModal({
                                                  modalTitle: `${document.typeLabel}`,
                                                  driveID: document.googleDriveID as string,
                                                  driveMime: document.googleDriveMimeType as string,
                                                  isLoading: true,
                                             })
                                        }
                                   />
                              </MyTooltip>
                              <div className={"d-flex align-items-center"}>
                                   <button
                                        type={"button"}
                                        className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                                        onClick={() =>
                                             setActiveDriveDocumentInModal({
                                                  modalTitle: `${document.typeLabel}`,
                                                  driveID: document.googleDriveID as string,
                                                  driveMime: document.googleDriveMimeType as string,
                                                  isLoading: true,
                                             })
                                        }
                                   >
                                        Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                                   </button>
                                   <MyTooltip title={"Remplacer"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                             onClick={() => refInputReplaceDocumentNonSigne.current?.click()}
                                        >
                                             <i className={"fas fa-sync fs-5"} />
                                        </button>
                                   </MyTooltip>
                                   <input
                                        type={"file"}
                                        className={"d-none"}
                                        ref={refInputReplaceDocumentNonSigne}
                                        onChange={e => consultantDocumentActions.replace(e, document.id as number, handleReplaceDocumentCallback)}
                                   />
                              </div>
                         </div>

                         {/* Version signée  */}
                         {contratSigne && (
                              <div className={`d-flex mt-2 ${classNames}`}>
                                   <MyTooltip title={<span>Déposé le {contratSigne.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                                        <div
                                             className={
                                                  "fas fa-file-signature text-dark border-end-dashed rounded-0 border-gray-300 d-flex align-items-center me-4 pe-1 cursor-pointer"
                                             }
                                             style={{ fontSize: "2.5rem" }}
                                             onClick={() =>
                                                  setActiveDriveDocumentInModal({
                                                       modalTitle: `${contratSigne.typeLabel}`,
                                                       driveID: contratSigne.googleDriveID as string,
                                                       driveMime: contratSigne.googleDriveMimeType as string,
                                                       isLoading: true,
                                                  })
                                             }
                                        />
                                   </MyTooltip>
                                   <div className={"d-flex align-items-center"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                                             onClick={() =>
                                                  setActiveDriveDocumentInModal({
                                                       modalTitle: `${contratSigne.typeLabel}`,
                                                       driveID: contratSigne.googleDriveID as string,
                                                       driveMime: contratSigne.googleDriveMimeType as string,
                                                       isLoading: true,
                                                  })
                                             }
                                        >
                                             Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                                        </button>
                                        <MyTooltip title={"Remplacer"}>
                                             <button
                                                  type={"button"}
                                                  className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                                  onClick={() => refInputReplaceDocumentSigne.current?.click()}
                                             >
                                                  <i className={"fas fa-sync fs-5"} />
                                             </button>
                                        </MyTooltip>
                                        <input
                                             type={"file"}
                                             className={"d-none"}
                                             ref={refInputReplaceDocumentSigne}
                                             onChange={e => consultantDocumentActions.replace(e, contratSigne.id as number, handleReplaceDocumentCallback)}
                                        />
                                   </div>
                              </div>
                         )}

                         {/* Notification si contrat signé missing */}
                         {!document.fileSigne && (
                              <div className={"p-2 border border-danger border-dashed mt-4"}>
                                   <MyTooltip title={"Veuillez joindre le document signé"}>
                                        <>
                                             <div className="fas fa-exclamation-triangle text-danger d-flex justify-content-center" style={{ fontSize: "1.8rem" }} />
                                             <input
                                                  type={"file"}
                                                  className={"d-none"}
                                                  ref={refInputAddVersionSignee}
                                                  onChange={e => consultantDocumentActions.addVersionSignee(e, document.id as number, handleAddDocumentSigneCallback)}
                                             />
                                        </>
                                   </MyTooltip>

                                   <div className={"d-flex justify-content-center mt-2"}>
                                        <button
                                             type={"button"}
                                             className={"btn btn-sm btn-outline btn-outline-danger p-0 px-2 py-1 d-flex align-items-center"}
                                             onClick={() => refInputAddVersionSignee.current?.click()}
                                        >
                                             Joindre le document signé <i className={"fas fa-plus-circle ms-1"} />
                                        </button>
                                   </div>
                              </div>
                         )}
                         <div></div>
                    </div>

                    {/* Edition détails contrat */}
                    {isEditingContratDetailsInModal && (
                         <MyModal title={<span>Edition des détails du contrat</span>} show={true} handleClose={() => setIsEditingContratDetailsInModal(false)} size={"lg"}>
                              <ConsultantEditContratDetailsForm document={document} callback={handleEditDetailsCallback} />
                         </MyModal>
                    )}
               </>
          )
     }

     /* Mission item */
     const MissionItem = ({ mission, classNames = "" }: { mission: IConsultantMissionModel; classNames?: string }) => {
          const [isEditingDetailsInModal, setIsEditingDetailsInModal] = useState<boolean>()
          const refInputAddVersionSignee = useRef<HTMLInputElement>(null)
          const refInputReplaceDocumentNonSigne = useRef<HTMLInputElement>(null)
          const refInputReplaceDocumentSigne = useRef<HTMLInputElement>(null)
          const documentSigne = mission.fileSigne

          function handleAddDocumentSigneCallback(val: IConsultantMissionModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.missions) {
                              prev.documentsPro.missions.map(item => {
                                   if (item.id === mission.id) {
                                        item.fileSigne = val
                                        return item
                                   }
                              })
                         }
                    }
                    return prev
               })
          }

          function handleReplaceDocumentCallback(val: IConsultantMissionModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.missions) {
                              prev.documentsPro.missions.map(item => {
                                   if (item.id === val.id) {
                                        item.googleDriveID = val.googleDriveID
                                   }

                                   if (item.fileSigne && item.fileSigne.id === val.id) {
                                        item.fileSigne.googleDriveID = val.googleDriveID
                                   }

                                   return item
                              })
                         }
                    }
                    return prev
               })
          }

          function handleEditDetailsCallback(val: IConsultantMissionModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (prev.documentsPro.missions) {
                              prev.documentsPro.missions.map(item => {
                                   if (item.id === mission.id) {
                                        item.dateDebutValidite = val.dateDebutValidite
                                        item.dateFinValidite = val.dateFinValidite
                                   }

                                   return item
                              })
                         }
                    }
                    return prev
               })
          }

          return (
               <>
                    <div className={"p-4 border border-dashed mb-4"}>
                         <div className={"d-flex justify-content-between"}>
                              <div className="fs-6">{mission.propal?.client?.nom}</div>
                              <MyTooltip title={"Éditer"}>
                                   <div
                                        className="fas fa-edit text-warning d-flex align-items-center fs-3 cursor-pointer btn-active-light-primary"
                                        onClick={() => setIsEditingDetailsInModal(true)}
                                   />
                              </MyTooltip>
                         </div>
                         <div className={"text-muted fs-9 mb-1"}>
                              {mission.dateDebutValidite?.format("DD/MM/YYYY")} - {mission.dateFinValidite?.format("DD/MM/YYYY")}
                         </div>

                         {context.detailsQuery.data?.consultant.contrat !== "CONTRAT_ST" && (
                              <>
                                   <div className="separator separator-dashed mb-6" />

                                   {/* Version non signée  */}
                                   <div className={`d-flex ${classNames}`}>
                                        <MyTooltip title={<span>Déposé le {mission.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                                             <div
                                                  className={"fas fa-file text-dark border-end-dashed rounded-0 border-gray-300 d-flex align-items-center me-4 cursor-pointer"}
                                                  style={{ fontSize: "2.5rem", paddingRight: "1.15rem" }}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            modalTitle: `Ordre de mission du ${mission.dateDebutValidite?.format("DD/MM/YYYY")} - ${mission.dateFinValidite?.format(
                                                                 "DD/MM/YYYY"
                                                            )}`,
                                                            driveID: mission.googleDriveID as string,
                                                            driveMime: mission.googleDriveMimeType as string,
                                                            isLoading: true,
                                                       })
                                                  }
                                             />
                                        </MyTooltip>
                                        <div className={"d-flex align-items-center"}>
                                             <button
                                                  type={"button"}
                                                  className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                                                  onClick={() =>
                                                       setActiveDriveDocumentInModal({
                                                            modalTitle: `Ordre de mission du ${mission.dateDebutValidite?.format("DD/MM/YYYY")} - ${mission.dateFinValidite?.format(
                                                                 "DD/MM/YYYY"
                                                            )}`,
                                                            driveID: mission.googleDriveID as string,
                                                            driveMime: mission.googleDriveMimeType as string,
                                                            isLoading: true,
                                                       })
                                                  }
                                             >
                                                  Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                                             </button>
                                             <MyTooltip title={"Remplacer"}>
                                                  <button
                                                       type={"button"}
                                                       className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                                       onClick={() => refInputReplaceDocumentNonSigne.current?.click()}
                                                  >
                                                       <i className={"fas fa-sync fs-5"} />
                                                  </button>
                                             </MyTooltip>
                                             <input
                                                  type={"file"}
                                                  className={"d-none"}
                                                  ref={refInputReplaceDocumentNonSigne}
                                                  onChange={e => consultantMissionDocActions.replace(e, mission.id as number, handleReplaceDocumentCallback)}
                                             />
                                        </div>
                                   </div>

                                   {/* Version signée  */}
                                   {documentSigne && (
                                        <div className={`d-flex mt-2 ${classNames}`}>
                                             <MyTooltip title={<span>Déposé le {documentSigne.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                                                  <div
                                                       className={
                                                            "fas fa-file-signature text-dark border-end-dashed rounded-0 border-gray-300 d-flex align-items-center me-4 pe-1 cursor-pointer"
                                                       }
                                                       style={{ fontSize: "2.5rem" }}
                                                       onClick={() =>
                                                            setActiveDriveDocumentInModal({
                                                                 modalTitle: `Ordre de mission du ${mission.dateDebutValidite?.format(
                                                                      "DD/MM/YYYY"
                                                                 )} - ${mission.dateFinValidite?.format("DD/MM/YYYY")}`,
                                                                 driveID: documentSigne.googleDriveID as string,
                                                                 driveMime: documentSigne.googleDriveMimeType as string,
                                                                 isLoading: true,
                                                            })
                                                       }
                                                  />
                                             </MyTooltip>
                                             <div className={"d-flex align-items-center"}>
                                                  <button
                                                       type={"button"}
                                                       className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                                                       onClick={() =>
                                                            setActiveDriveDocumentInModal({
                                                                 modalTitle: `Ordre de mission du ${mission.dateDebutValidite?.format(
                                                                      "DD/MM/YYYY"
                                                                 )} - ${mission.dateFinValidite?.format("DD/MM/YYYY")}`,
                                                                 driveID: documentSigne.googleDriveID as string,
                                                                 driveMime: documentSigne.googleDriveMimeType as string,
                                                                 isLoading: true,
                                                            })
                                                       }
                                                  >
                                                       Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                                                  </button>
                                                  <MyTooltip title={"Remplacer"}>
                                                       <button
                                                            type={"button"}
                                                            className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                                            onClick={() => refInputReplaceDocumentSigne.current?.click()}
                                                       >
                                                            <i className={"fas fa-sync fs-5"} />
                                                       </button>
                                                  </MyTooltip>
                                                  <input
                                                       type={"file"}
                                                       className={"d-none"}
                                                       ref={refInputReplaceDocumentSigne}
                                                       onChange={e => consultantMissionDocActions.replace(e, documentSigne.id as number, handleReplaceDocumentCallback)}
                                                  />
                                             </div>
                                        </div>
                                   )}

                                   {/* Notification si contrat signé missing */}
                                   {!mission.fileSigne && (
                                        <div className={"p-2 border border-danger border-dashed mt-4"}>
                                             <MyTooltip title={"Veuillez joindre le document signé"}>
                                                  <>
                                                       <div className="fas fa-exclamation-triangle text-danger d-flex justify-content-center" style={{ fontSize: "1.8rem" }} />
                                                       <input
                                                            type={"file"}
                                                            className={"d-none"}
                                                            ref={refInputAddVersionSignee}
                                                            onChange={e => consultantMissionDocActions.addVersionSignee(e, mission.id as number, handleAddDocumentSigneCallback)}
                                                       />
                                                  </>
                                             </MyTooltip>

                                             <div className={"d-flex justify-content-center mt-2"}>
                                                  <button
                                                       type={"button"}
                                                       className={"btn btn-sm btn-outline btn-outline-danger p-0 px-2 py-1 d-flex align-items-center"}
                                                       onClick={() => refInputAddVersionSignee.current?.click()}
                                                  >
                                                       Joindre le document signé <i className={"fas fa-plus-circle ms-1"} />
                                                  </button>
                                             </div>
                                        </div>
                                   )}
                              </>
                         )}
                    </div>

                    {/* Edition détails */}
                    {isEditingDetailsInModal && (
                         <MyModal title={<span>Edition de la mission</span>} show={true} handleClose={() => setIsEditingDetailsInModal(false)} size={"lg"}>
                              <ConsultantEditMissionDetailsForm mission={mission} callback={handleEditDetailsCallback} />
                         </MyModal>
                    )}
               </>
          )
     }

     return (
          <>
               {/* Missions */}
               <MissionsContainer>
                    <div className="row">
                         {documents.documentsPro.missions?.map((item, index) => {
                              return (
                                   <div className="col-sm-6 col-xxl-4 mb-4" key={index}>
                                        <MissionItem mission={item} />
                                   </div>
                              )
                         })}
                    </div>
               </MissionsContainer>

               {/* Contrats */}
               {(!context.detailsQuery.data?.isConnectedUserWithDelegationAdministrative ||
                    (context.detailsQuery.data?.isConnectedUserWithDelegationAdministrative &&
                         context.detailsQuery.data?.connectedUserWithDelegationAdministrativeDroits.includes(
                              CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROIT_GESTION_CONTRAT_TRAVAIL
                         ))) && (
                    <ContratsContainer>
                         <div className="row">
                              {documents.documentsPro.contrats?.map((item, index) => {
                                   return (
                                        <div className="col-sm-6 col-xxl-4 mb-4" key={index}>
                                             <ContratItem document={item} />
                                        </div>
                                   )
                              })}
                         </div>
                    </ContratsContainer>
               )}

               {/* DoCos */}
               <DocosContainer>
                    <div className="row">
                         {documents.documentsPro.docos?.map((item, index) => {
                              return (
                                   <div className="col-sm-6 col-xxl-4 mb-4" key={index}>
                                        <DoCoItem document={item} key={index} />
                                   </div>
                              )
                         })}
                    </div>
               </DocosContainer>

               {/* Document in modal */}
               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<span>{documentInModal.modalTitle}</span>}
                         handleCloseModal={() => setActiveDriveDocumentInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConsultantDetailsDocumentsProPropsModel {
     documents: IGetDocumentsConsultant
}

export default ConsultantDetailsDocumentsPro
