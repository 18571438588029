import clsx from "clsx"
import { useAuth } from "../../AppContext"
import { ThemeModeSwitcher, useThemeMode } from "@common-metronic/partials"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { HeaderUserMenu } from "./header-menus/HeaderUserMenu"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import React, { useState } from "react"
import MyLoadingOverlay from "../../../common/components/MyLoadingOverlay"
import { toast } from "react-toastify"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ACTIVE_USERNAMES_ENDPOINT } from "../../endpoints"
import useAuthHelper from "../../hooks/useAuthHelpers"

const itemClass = "ms-1 ms-lg-3"
const userAvatarClass = "symbol-35px symbol-md-40px"

const Navbar = () => {
     const { mode } = useThemeMode()
     const { currentUser, auth } = useAuth()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const { impersonate, quitImpersonate } = useAuthHelper()

     const NavItemTools = () => {
          return (
               <div className={clsx("app-navbar-item", itemClass)}>
                    <div>
                         <MyTooltip title={<>Outils</>} placement={"bottom"}>
                              <button
                                   className={"btn btn-icon btn-active-light-primary text-muted  menu-dropdown"}
                                   type="button"
                                   data-bs-toggle="dropdown"
                                   data-bs-auto-close="true"
                                   aria-expanded="false"
                              >
                                   <i className={"fas fa-tools cursor-pointer fs-2"} />
                              </button>
                         </MyTooltip>
                         <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px dropdown-menu">
                              <div
                                   className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-6 py-7"
                                   style={{ backgroundImage: `url(${toAbsoluteUrl("/media/misc/pattern-1.jpg")})` }}
                              >
                                   <h2 className="text-white fw-bold">Boite à outils</h2>
                              </div>
                              <div className="row g-0">
                                   <div className="col-6">
                                        <Link to={"/tools/calculator"} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
                                             <span className="fas fa-calculator text-dark mb-2" style={{ fontSize: "2.5rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Calculatrice</span>
                                             <span className="fs-7 text-gray-400">Cost tool & PE</span>
                                        </Link>
                                   </div>
                                   <div className="col-6">
                                        <Link
                                             to={"/tools/signature-mail"}
                                             className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
                                             onClick={e => {
                                                  e.preventDefault()
                                                  toast.info("Temporairement indisponible")
                                             }}
                                        >
                                             <span className="fas fa-signature text-dark mb-2" style={{ fontSize: "2.6rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Signature</span>
                                             <span className="fs-7 text-gray-400">Mail</span>
                                        </Link>
                                   </div>
                                   <div className="col-12">
                                        <a
                                             href={"https://pdf-utils.cts-corporate.com"}
                                             target={"_blank"}
                                             className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
                                        >
                                             <span className="fas fa-file-pdf text-dark mb-2" style={{ fontSize: "2.6rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">PDF Utils</span>
                                             <span className="fs-7 text-gray-400">Traitement des fichiers PDF</span>
                                        </a>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          )
     }

     const NavItemAnnuaire = () => {
          return (
               <div className={clsx("app-navbar-item", itemClass)}>
                    <div>
                         <MyTooltip title={<>Annuaire</>} placement={"bottom"}>
                              <button
                                   className={"btn btn-icon btn-active-light-primary text-muted  menu-dropdown"}
                                   type="button"
                                   data-bs-toggle="dropdown"
                                   data-bs-auto-close="true"
                                   aria-expanded="false"
                              >
                                   <i className={"fas fa-address-book cursor-pointer fs-2"} />
                              </button>
                         </MyTooltip>
                         <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px dropdown-menu">
                              <div
                                   className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-6 py-7"
                                   style={{ backgroundImage: `url(${toAbsoluteUrl("/media/misc/pattern-1.jpg")})` }}
                              >
                                   <h2 className="text-white fw-bold">Annuaire</h2>
                              </div>
                              <div className="row g-0">
                                   <div className="col-6">
                                        <Link to={"/personnel/annuaire"} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
                                             <span className="fas fa-list text-dark mb-2" style={{ fontSize: "2.5rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Liste</span>
                                        </Link>
                                   </div>
                                   <div className="col-6">
                                        <Link to={"personnel/organigramme"} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom">
                                             <span className="fas fa-sitemap text-dark mb-2" style={{ fontSize: "2.6rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Organigramme</span>
                                        </Link>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          )
     }

     const NavItemGoogleApps = () => {
          return (
               <div className={clsx("app-navbar-item", itemClass)}>
                    <div>
                         <MyTooltip title={<>Google Apps</>} placement={"bottom"}>
                              <button
                                   className={"btn btn-icon btn-active-light-primary text-muted  menu-dropdown"}
                                   type="button"
                                   data-bs-toggle="dropdown"
                                   data-bs-auto-close="true"
                                   aria-expanded="false"
                              >
                                   <i className={"fa-brands fa-google cursor-pointer fs-2"} />
                              </button>
                         </MyTooltip>

                         <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px dropdown-menu">
                              <div
                                   className="d-flex flex-column flex-center bgi-no-repeat rounded-top px-6 py-7"
                                   style={{
                                        backgroundImage: `url(${toAbsoluteUrl("/media/misc/google.jpeg")})`,
                                        backgroundPosition: "center center",
                                        backgroundSize: "cover",
                                   }}
                              >
                                   <h2 className="text-secondary fw-bold">Google Apps</h2>
                              </div>
                              <div className="row g-0">
                                   <div className="col-6">
                                        <Link
                                             to={"https://meet.google.com/"}
                                             className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
                                             target={"_blank"}
                                        >
                                             <span className="fas fa-video text-dark mb-2" style={{ fontSize: "2.5rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Meet</span>
                                             <span className="fs-7 text-gray-400">Visio</span>
                                        </Link>
                                   </div>
                                   <div className="col-6">
                                        <Link
                                             to={"https://drive.google.com/drive/shared-drives"}
                                             className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom"
                                             target={"_blank"}
                                        >
                                             <span className="fa-brands fa-google-drive text-dark mb-2" style={{ fontSize: "2.6rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Drive</span>
                                             <span className="fs-7 text-gray-400">Stockage de fichiers</span>
                                        </Link>
                                   </div>
                              </div>

                              <div className="row g-0">
                                   <div className="col-6">
                                        <Link
                                             to={"https://calendar.google.com"}
                                             className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom"
                                             target={"_blank"}
                                        >
                                             <span className="fas fa-calendar text-dark mb-2" style={{ fontSize: "2.5rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Calendar</span>
                                             <span className="fs-7 text-gray-400">Agenda</span>
                                        </Link>
                                   </div>
                                   <div className="col-6">
                                        <Link to={"https://chat.google.com"} className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom" target={"_blank"}>
                                             <span className="fas fa-comments text-dark mb-2" style={{ fontSize: "2.6rem" }} />
                                             <span className="fs-5 fw-bold text-gray-800 mb-0">Chat</span>
                                             <span className="fs-7 text-gray-400">Chat interne</span>
                                        </Link>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          )
     }

     const NavItemSwitchUser = () => {
          return (
               <div className={clsx("app-navbar-item", itemClass)}>
                    <MyTooltip
                         title={
                              auth?.impersonated_token ? (
                                   <>
                                        Quit impersonation as {currentUser?.prenomNom} ( {currentUser?.id} )
                                   </>
                              ) : (
                                   <>Switch user</>
                              )
                         }
                         placement={"bottom"}
                    >
                         <button
                              className={"btn btn-icon btn-active-light-primary text-muted menu-dropdown"}
                              type="button"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="outside"
                              aria-expanded="false"
                              onClick={() => auth?.impersonated_token && quitImpersonate()}
                         >
                              <i className={`fas fa-user-${auth?.impersonated_token ? "xmark" : "cog"} cursor-pointer fs-2`} />
                         </button>
                    </MyTooltip>

                    {!auth?.impersonated_token && (
                         <div className="menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px dropdown-menu dropdown-menu-end p-4">
                              <h5>Switch user</h5>
                              <MySelectGetAllDataFromServer
                                   url={CONST_API_UTILS_GET_ACTIVE_USERNAMES_ENDPOINT}
                                   name={""}
                                   value={""}
                                   onChange={(val, opt) => {
                                        impersonate(opt.label)
                                   }}
                                   isInvalid={false}
                              />
                         </div>
                    )}
               </div>
          )
     }

     return (
          <div className="app-navbar flex-shrink-0">
               {(auth?.impersonated_token || currentUser?.id === 38) && <NavItemSwitchUser />}

               <NavItemAnnuaire />

               <NavItemTools />

               <NavItemGoogleApps />
               <div className={clsx("app-navbar-item", itemClass)}>
                    <MyTooltip title={<span>{currentUser!.nbCandidatsDansListeTraitement} candidats dans ma liste de traitement</span>} placement={"bottom"}>
                         <Link
                              to={"/recrutement/candidats/search/results?areCandidatesInListeDeTraitement=1"}
                              className={"btn btn-icon btn-active-light-primary text-muted position-relative"}
                              onClick={e => {
                                   if (currentUser!.nbCandidatsDansListeTraitement === 0) e.preventDefault()
                              }}
                              reloadDocument
                         >
                              <i className={"fas fa-inbox cursor-pointer fs-2"} />
                              {currentUser?.nbCandidatsDansListeTraitement !== undefined && currentUser.nbCandidatsDansListeTraitement > 0 && (
                                   <span
                                        className="position-absolute translate-middle  badge badge-circle badge-warning h-15px w-15px cursor-pointer"
                                        style={{ top: "10px", left: "10px" }}
                                   >
                                        {currentUser!.nbCandidatsDansListeTraitement}
                                   </span>
                              )}
                         </Link>
                    </MyTooltip>
               </div>

               <div className={clsx("app-navbar-item", itemClass)}>
                    <ThemeModeSwitcher toggleBtnClass={clsx("btn-active-light-primary btn-custom")} />
               </div>

               <div className={clsx("app-navbar-item", itemClass)}>
                    <div
                         className={clsx("cursor-pointer symbol", userAvatarClass)}
                         data-kt-menu-trigger="{default: 'hover'}"
                         data-kt-menu-attach="parent"
                         data-kt-menu-placement="bottom-end"
                    >
                         <MyLoadingOverlay isActive={isAvatarLoading} classNames={clsx("cursor-pointer symbol", userAvatarClass)}>
                              <img
                                   src={
                                        currentUser?.avatarGoogleDriveID
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(currentUser.avatarGoogleDriveID)
                                             : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                   }
                                   onLoad={() => {
                                        setIsAvatarLoading(false)
                                   }}
                                   alt="Avatar"
                              />
                         </MyLoadingOverlay>
                    </div>
                    <HeaderUserMenu />
               </div>
          </div>
     )
}

export { Navbar }
