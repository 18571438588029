import { IConsultantSuiviDelegationAdministrativeFormikModel, IConsultantSuiviModificationDelegationDroitsItemRequestModel } from "./core/_models"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel, IPersonnelAvecDelegationDroitsConsultantModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROITS_OPTIONS_FOR_SELECT2 } from "@common-constants/*"
import {
     CONST_API_BUSINESS_CONSULTANT_SUPPRIMER_DELEGATION_ADMINISTRATIVE_ENDPOINT,
     CONST_API_BUSINESS_CONSULTANTS_PERSONNELS_DELEGATION_DROITS_ENDPOINT,
     CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT,
} from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { consultantSuiviSetDelegationDroitsRequest } from "./core/_requests"
import { useQuery } from "react-query"
import * as Yup from "yup"
import swal from "sweetalert2"
import MySelectGetAllDataFromServerFormField from "@common-components/fields/MySelectGetAllDataFromServerFormField"
import MySelectFormField from "@common-components/fields/MySelectFormField"
import MyCard from "@common-utils/MyCard"

const ConsultantSuiviModificationPersonnelDelegationDroits = ({ consultants, onSubmitCallback = () => {}, annulerCallback = () => {} }: IPropsModel) => {
     const delegationQuery = useQuery<IPersonnelAvecDelegationDroitsConsultantModel[], AxiosError>("RQK_GET_PERSONNELS_AVEC_DROITS", async () => {
          return axios
               .post(CONST_API_BUSINESS_CONSULTANTS_PERSONNELS_DELEGATION_DROITS_ENDPOINT, {
                    consultant_IDS: consultants.map(item => item.id),
               })
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Handle submit
     function handleSubmit(values: IConsultantSuiviDelegationAdministrativeFormikModel, helpers: FormikHelpers<IConsultantSuiviDelegationAdministrativeFormikModel>) {
          helpers.setStatus(null)

          const payload: IConsultantSuiviModificationDelegationDroitsItemRequestModel[] = values.rows.flatMap(row =>
               row.responsables.map(resp => ({
                    consultant: row.consultant,
                    responsable: resp.responsable,
                    droits: resp.droits,
               }))
          )

          consultantSuiviSetDelegationDroitsRequest(payload)
               .then(() => {
                    helpers.setSubmitting(false)
                    onSubmitCallback()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Handle suppression
     function handleDelete(consultant_ID: number, personnel_ID: number) {
          swal.fire({
               icon: "warning",
               text: `Veuillez confirmer la suppression`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return axios
                         .post(CONST_API_BUSINESS_CONSULTANT_SUPPRIMER_DELEGATION_ADMINISTRATIVE_ENDPOINT, {
                              consultant_ID,
                              personnel_ID,
                         })
                         .then(() => {
                              toast.success("La suppression a bien été effectuée")
                              delegationQuery.refetch()
                         })
                         .catch((e: AxiosError) => {
                              const error: IHttpErrorResponseModel = e.response?.data
                              toast.error(error?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Form schema
     const validationSchema = Yup.object().shape({
          rows: Yup.array()
               .of(
                    Yup.object().shape({
                         consultant: Yup.number().label(`"Consultant"`).required(),
                         responsables: Yup.array()
                              .of(
                                   Yup.object().shape({
                                        responsable: Yup.number().label(`"Responsable"`).required(),
                                        droits: Yup.array().of(Yup.string()).min(1).label(`"Droits"`).required(),
                                   })
                              )
                              .min(1)
                              .label(`"Responsables"`),
                    })
               )
               .min(1)
               .label(`"Rows"`),
     })

     const initialValues: IConsultantSuiviDelegationAdministrativeFormikModel = {
          rows: delegationQuery.data
               ? Object.values(
                      delegationQuery.data.reduce(
                           (acc, item) => {
                                const consultantId = item.consultant?.id
                                const personnelId = item.personnel?.id

                                if (!consultantId) {
                                     return acc // Skip invalid entries
                                }

                                // Add or update the consultant in the accumulator
                                if (!acc[consultantId]) {
                                     acc[consultantId] = {
                                          consultant: consultantId,
                                          responsables: [],
                                     }
                                }

                                // Add the personnel and droits to the responsables array
                                if (personnelId) {
                                     acc[consultantId].responsables.push({
                                          responsable: personnelId,
                                          droits: item.droits || [],
                                     })
                                }

                                return acc
                           },
                           {} as Record<number, IConsultantSuiviDelegationAdministrativeFormikModel["rows"][0]>
                      )
                 )
               : [], // Fallback when no data
     }

     const consultantsOptionsList: { label: any; value: any }[] = delegationQuery.data
          ? delegationQuery.data.map(item => ({
                 label: item.consultant?.prenomNom,
                 value: item.consultant?.id,
            }))
          : []

     const responsablesOptionsList: { label: any; value: any }[] = delegationQuery.data
          ? delegationQuery.data.map(item => ({
                 label: item.personnel?.prenomNom,
                 value: item.personnel?.id,
            }))
          : []

     return (
          <>
               {delegationQuery.isFetching && (
                    <div className="text-center p-5">
                         <MySimpleSpinner size="xl" />
                    </div>
               )}

               {!delegationQuery.isFetching && delegationQuery.isError && (
                    <div className="alert alert-danger alert-dismissible fade show mb-4" role="alert">
                         <span className="me-2">{delegationQuery.error?.response?.data?.detail}</span>
                         <button className="btn btn-sm btn-danger" onClick={() => delegationQuery.refetch()}>
                              Recharger
                         </button>
                    </div>
               )}

               {!delegationQuery.isFetching && !delegationQuery.isError && delegationQuery.data && (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                         {helpers => (
                              <Form noValidate autoComplete="off" className="needs-validation">
                                   {helpers.status && <div className="alert alert-danger mb-4">{helpers.status}</div>}

                                   <FieldArray name="rows">
                                        {() => (
                                             <div className={"d-flex flex-column gap-5"}>
                                                  {helpers.values.rows.map((row_item, row_index) => (
                                                       <MyCard classNames={"border"} key={row_index}>
                                                            <MyCard.Header isCollapseIconShown>
                                                                 {consultantsOptionsList.find(item => item.value === helpers.values.rows[row_index].consultant)?.label}
                                                            </MyCard.Header>
                                                            <MyCard.Body>
                                                                 {row_item.responsables.map((resp_item, index_resp) => (
                                                                      <div className="mb-3" key={index_resp}>
                                                                           <div className="d-flex justify-content-between align-items-center mb-2">
                                                                                <span className="fw-bold text-primary">Responsable délégué {index_resp + 1}</span>
                                                                                <button
                                                                                     type="button"
                                                                                     className="btn btn-outline btn-outline-danger btn-sm"
                                                                                     onClick={() => handleDelete(row_item.consultant!, resp_item.responsable)}
                                                                                >
                                                                                     Supprimer
                                                                                </button>
                                                                           </div>

                                                                           <MySelectGetAllDataFromServerFormField
                                                                                formikProps={helpers}
                                                                                validationSchema={validationSchema}
                                                                                name={`rows[${row_index}].responsables[${index_resp}].responsable`}
                                                                                endpoint={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                                                defaultOptions={responsablesOptionsList}
                                                                                method={"GET"}
                                                                                placeholder={"Responsable"}
                                                                                classNameLayout={"mb-2"}
                                                                           />

                                                                           <MySelectFormField
                                                                                formikProps={helpers}
                                                                                validationSchema={validationSchema}
                                                                                name={`rows[${row_index}].responsables[${index_resp}].droits`}
                                                                                placeholder={"Droits"}
                                                                                isMulti
                                                                                options={CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROITS_OPTIONS_FOR_SELECT2}
                                                                           />
                                                                      </div>
                                                                 ))}
                                                            </MyCard.Body>
                                                       </MyCard>
                                                  ))}
                                             </div>
                                        )}
                                   </FieldArray>

                                   {/* Submit button */}
                                   <div className="d-flex justify-content-end mt-3">
                                        <button className="btn btn-outline-secondary btn-sm me-2" type="button" onClick={annulerCallback} disabled={helpers.isSubmitting}>
                                             Annuler
                                        </button>
                                        <button className="btn btn-primary btn-sm d-inline-flex align-items-center" type="submit" disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Appliquer" : <MySimpleSpinner size="sm" />}
                                        </button>
                                   </div>
                              </Form>
                         )}
                    </Formik>
               )}
          </>
     )
}

interface IPropsModel {
     consultants: IConsultantModel[]
     annulerCallback?: () => void
     onSubmitCallback?: () => void
}

export default ConsultantSuiviModificationPersonnelDelegationDroits
