import { useQueryClient } from "react-query"
import { useFournisseurListingContext } from "../../FournisseurListing"
import { useThemeMode } from "@common-metronic/partials"
import { IFournisseurEditInfoByStaffModel, IItemResponseModel, IResponseModel } from "../../core/_models"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import * as Yup from "yup"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_IMAGE_PNG } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { setFournisseurSimpleDetails } from "../../../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import MyInputField from "@common-utils/fields/MyInputField"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_PAYS_ENDPOINT } from "../../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"

const FormEditMode = ({ toggleToViewMode }: { toggleToViewMode: () => void }) => {
     const queryClient = useQueryClient()
     const globalContext = useFournisseurListingContext()
     const { mode } = useThemeMode()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel
     const fournisseur = itemInPreview.fournisseur

     const [isLogoLoading, setIsLogoLoading] = useState<boolean>(true)
     const [logoPreview, setLogoPreview] = useState<any>(
          fournisseur?.logoGoogleDriveId
               ? FileAndDriveHelper.getThumbnailDriveUrl(fournisseur?.logoGoogleDriveId)
               : toAbsoluteUrl(mode == "dark" ? "/media/svg/files/blank-image-dark.svg" : "/media/svg/files/blank-image.svg")
     )

     // Schema
     const schema = Yup.object().shape({
          raisonSociale: Yup.string().required().nullable().label(`"Raison sociale"`),
          siret: Yup.string().nullable().label(`"SIRET"`),
          capitalSocial: Yup.string().nullable().label(`"Capital social"`),
          adresse: Yup.string().nullable().label(`"Adresse"`),
          ville: Yup.string().nullable().label(`"ville"`),
          codePostal: Yup.string().nullable().label(`"Code postal"`),
          pays: Yup.number().nullable().label(`"Pays"`),
          logo: Yup.mixed()
               .nullable()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               }),
     })

     // Init values
     const initialValues: IFournisseurEditInfoByStaffModel = {
          raisonSociale: fournisseur?.raisonSociale,
          siret: fournisseur?.siret,
          capitalSocial: fournisseur?.capitalSociale,
          adresse: fournisseur?.adresse,
          ville: fournisseur?.ville,
          codePostal: fournisseur?.codePostal,
          pays: fournisseur?.pays?.id,
     }

     // Handle submit
     function handleSubmit(values: IFournisseurEditInfoByStaffModel, helpers: FormikHelpers<IFournisseurEditInfoByStaffModel>) {
          helpers.setStatus(null)

          // Request
          setFournisseurSimpleDetails(itemInPreview.fournisseur.id!, values)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    queryClient.setQueryData<IResponseModel | undefined>(globalContext.REACT_QUERY_KEY_GET_LISTING, (prev: IResponseModel | undefined) => {
                         if (prev?.data) {
                              prev?.data.map(item => {
                                   if (item.fournisseur.id === itemInPreview.fournisseur.id) {
                                        item.fournisseur = {
                                             ...item.fournisseur,
                                             ...r.data,
                                        }
                                        return item
                                   }
                              })
                         }

                         return prev
                    })

                    toggleToViewMode()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Field logo */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Logo</label>
                                        <div className="col-lg-8">
                                             <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${logoPreview})` }}>
                                                  <MyLoadingOverlay isActive={isLogoLoading}>
                                                       <img
                                                            className="image-input-wrapper w-125px h-125px"
                                                            src={logoPreview}
                                                            onLoad={() => setIsLogoLoading(false)}
                                                            alt={"logo"}
                                                       ></img>
                                                  </MyLoadingOverlay>

                                                  <label
                                                       className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                       data-kt-image-input-action="change"
                                                       data-bs-original-title="Change logo"
                                                  >
                                                       <i className="bi bi-pencil-fill fs-7"></i>
                                                       <input
                                                            type="file"
                                                            name="logo"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={e => {
                                                                 const file = e.target.files![0]
                                                                 helpers.setFieldValue("logo", file)
                                                                 const reader = new FileReader()
                                                                 reader.onloadend = () => {
                                                                      // Set the image preview URL
                                                                      setLogoPreview(reader.result as string)
                                                                 }
                                                                 reader.readAsDataURL(file)
                                                            }}
                                                       />
                                                  </label>
                                             </div>
                                        </div>
                                   </div>
                                   {helpers.errors.logo && <div className={"text-danger"}>{helpers.errors.logo}</div>}

                                   {/* Nom commercial */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom commercial</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"raisonSociale"}
                                                  value={helpers.values.raisonSociale}
                                                  onChange={val => helpers.setFieldValue("raisonSociale", val)}
                                                  placeholder={"Raison sociale"}
                                                  isInvalid={!!helpers.errors.raisonSociale}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.raisonSociale && <div className={"text-danger"}>{helpers.errors.raisonSociale}</div>}</div>
                                        </div>
                                   </div>

                                   {/* SIRET */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">SIRET</label>

                                        <div className="col-lg-8">
                                             <MyInptuMaskField
                                                  name={"siret"}
                                                  mask={"999 999 999 99999"}
                                                  value={helpers.values.siret}
                                                  placeholder={"SIRET"}
                                                  inputMode={"numeric"}
                                                  onChange={e => helpers.setFieldValue("siret", e.target.value)}
                                                  isInvalid={!!helpers.errors.siret}
                                             />

                                             <div className={"mb-2"}>{helpers.errors.siret && <div className={"text-danger"}>{helpers.errors.siret}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Capital Social */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Capital Social</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"capitalSocial"}
                                                  value={helpers.values.capitalSocial}
                                                  placeholder={"Capital social"}
                                                  inputMode={"numeric"}
                                                  onChange={val => helpers.setFieldValue("capitalSocial", val)}
                                                  isInvalid={!!helpers.errors.capitalSocial}
                                             />

                                             <div className={"mb-2"}>{helpers.errors.capitalSocial && <div className={"text-danger"}>{helpers.errors.capitalSocial}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Adresse */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Adresse</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"adresse"}
                                                  value={helpers.values.adresse}
                                                  onChange={val => helpers.setFieldValue("adresse", val)}
                                                  placeholder={"Adresse"}
                                                  isInvalid={!!helpers.errors.adresse}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.adresse && <div className={"text-danger"}>{helpers.errors.adresse}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Ville */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Ville</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"ville"}
                                                  value={helpers.values.ville}
                                                  onChange={val => helpers.setFieldValue("ville", val)}
                                                  placeholder={"Ville"}
                                                  isInvalid={!!helpers.errors.ville}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.ville && <div className={"text-danger"}>{helpers.errors.ville}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Code postal */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Code postal</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"codePostal"}
                                                  value={helpers.values.codePostal}
                                                  onChange={val => helpers.setFieldValue("codePostal", val)}
                                                  placeholder={"Code postal"}
                                                  isInvalid={!!helpers.errors.codePostal}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.codePostal && <div className={"text-danger"}>{helpers.errors.codePostal}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Pays */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Pays</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"pays"}
                                                  value={helpers.values.pays}
                                                  url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                  onChange={val => helpers.setFieldValue("pays", val)}
                                                  defaultOptions={
                                                       fournisseur?.pays
                                                            ? [
                                                                   {
                                                                        value: fournisseur?.pays.id as number,
                                                                        label: fournisseur?.pays.nomFr as string,
                                                                   },
                                                              ]
                                                            : undefined
                                                  }
                                                  placeholder={"Pays"}
                                                  isInvalid={!!helpers.errors.pays}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.pays && <div className={"text-danger"}>{helpers.errors.pays}</div>}</div>
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5" />

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end gap-5"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-active-secondary d-inline-flex align-items-center"}
                                             type={"button"}
                                             onClick={toggleToViewMode}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

export default FormEditMode
