import React from "react"
import MyCard from "@common-utils/MyCard"
import { useListingContext } from "./ConsultantsMaladiesListingMultiple"
import "./styles.scss"
import MyGoogleDriveFileViewer from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewer"
import { IGetConsultantsMaladiesItemResponseModel } from "./core/_models"

/* Wrapper */
const ListPreview = () => {
     const globalContext = useListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.arret.id === selectedItemInPreview.arret.id) as IGetConsultantsMaladiesItemResponseModel

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>Justificatif</MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    <MyGoogleDriveFileViewer
                         googleDriveID={itemInPreview.arret.justificatifGoogleDriveID as string}
                         googleDriveMimetype={itemInPreview.arret.justificatifGoogleDriveMimeType as string}
                    />
               </MyCard.Body>
          </MyCard>
     )
}

export default ListPreview
