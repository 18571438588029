import { IConsultantModel } from "@common-models/*"
import {
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AFFECTATION_MISSION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_MACHINE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_PRIME,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_REMARQUE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUTER_PERSONNEL_DELEGATION_DROITS,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_COEFF,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE_FACTURATION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_ENMISSION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_HORSEFFECTIF,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_INTERCONTRAT,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_HEURESSUPP,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MACHINE,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MANAGER,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MISSION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_PERSONNEL_DELEGATION_DROITS,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_POSITION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_STATUT,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_TJM,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_SALAIRE_BRUT,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_TJMST,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PROLONGEMENT_MISSION,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING,
     CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SUPPRESSION_MACHINE,
} from "@common-constants/*"
import MyCard from "@common-utils/MyCard"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_CONSULTANT_ALLOWED_SUIVIS_ENDPOINT } from "../../../../endpoints"
import React, { useState } from "react"
import moment from "moment"
import ConsultantSuiviSalarieChangementEtatToIntercontrat from "./_ConsultantSuiviSalarieChangementEtatToIntercontrat"
import ConsultantSuiviSalarieRepriseMission from "./_ConsultantSuiviSalarieRepriseMission"
import ConsultantSuiviProlongementMissionSalarie from "./prolongementMission/_ConsultantSuiviProlongementMissionSalarie"
import ConsultantSuiviProlongementMissionNonSalarie from "./prolongementMission/_ConsultantSuiviProlongementMissionNonSalarie"
import ConsultantSuiviSalarieEmbaucheSurProfilAffectationMission from "./_ConsultantSuiviSalarieEmbaucheSurProfilAffectationMission"
import ConsultantSuiviChangementSalaire from "./_ConsultantSuiviChangementSalaire"
import ConsultantSuiviChangementTauxAchat from "./_ConsultantSuiviChangementTauxAchat"
import ConsultantSuiviChangementCoeff from "./_ConsultantSuiviChangementCoeff"
import ConsultantSuiviChangementPosition from "./_ConsultantSuiviChangementPosition"
import ConsultantSuiviChangementHeuresSupp from "./_ConsultantSuiviChangementHeuresSupp"
import ConsultantSuiviChangementStatut from "./_ConsultantSuiviChangementStatut"
import ConsultantSuiviChangementEntiteRattachement from "./_ConsultantSuiviChangementEntiteRattachement"
import ConsultantSuiviChangementEntiteFacturation from "./_ConsultantSuiviChangementEntiteFacturation"
import ConsultantSuiviPrime from "./_ConsultantSuiviPrime"
import ConsultantSuiviChangementManager from "./_ConsultantSuiviChangementManager"
import ConsultantSuiviCommentaire from "./_ConsultantSuiviCommentaire"
import ConsultantSuiviAjoutOuChangementMachine from "./_ConsultantSuiviAjoutOuChangementMachine"
import ConsultantSuiviSuppressionMachine from "./_ConsultantSuiviSupressionMachine"
import ConsultantSuiviChangementMissionSalarie from "./_ConsultantSuiviChangementMissionSalarie"
import ConsultantSuiviChangementMissionSousTraitant from "./_ConsultantSuiviChangementMissionSousTraitant"
import ConsultantSuiviChangementTjm from "./_ConsultantSuiviEvolutionTjm"
import ConsultantSuiviReporting from "./_ConsultantSuiviReporting"
import ConsultantSuiviSortieDesEffectifs from "./_ConsultantSuiviSortieDesEffectifs"
import ConsultantSuiviModificationPersonnelDelegationDroits from "./_ConsultantSuiviModificationPersonnelDelegationDroits"
import ConsultantSuiviAjoutPersonnelDelegationDroits from "./_ConsultantSuiviAjoutPersonnelDelegationDroits"

const ConsultantSuiviSelectEvent = ({
     consultants,
     setModalSizeCallback = () => {},
     onSubmitCallback = () => {},
     setModalNoPaddingCallback = () => {},
     setModalTitle = () => {},
}: IPropsModel) => {
     const [selectedEvent, setSelectedEvent] = useState<string | null>(null)
     const [isNext, setIsNext] = useState<boolean>(false)

     function handleSuivant() {
          if (selectedEvent !== null) setIsNext(true)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_HORSEFFECTIF) setModalTitle(<span>{consultants[0].prenomNom}: Sortie des effectifs</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING) setModalTitle(<span>{consultants[0].prenomNom}: Suivi de mission</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_TJM) setModalTitle(<span>{consultants[0].prenomNom}: Changement de TJM</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MISSION) setModalTitle(<span>{consultants[0].prenomNom}: Changement de mission</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SUPPRESSION_MACHINE) setModalTitle(<span>{consultants[0].prenomNom}: Suppression de machine</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_MACHINE) setModalTitle(<span>{consultants[0].prenomNom}: Ajout de machine</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MACHINE) setModalTitle(<span>{consultants[0].prenomNom}: Changement de machine</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_REMARQUE) setModalTitle(<span>{consultants[0].prenomNom}: Commentaire</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_INTERCONTRAT) setModalTitle(<span>{consultants[0].prenomNom}: Intercontrat</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_ENMISSION) setModalTitle(<span>{consultants[0].prenomNom}: Reprise de mission</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AFFECTATION_MISSION) setModalTitle(<span>{consultants[0].prenomNom}: Affectation de mission</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PROLONGEMENT_MISSION) setModalTitle(<span>{consultants[0].prenomNom}: Prolongement de mission</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_SALAIRE_BRUT) setModalTitle(<span>{consultants[0].prenomNom}: Evolution de salaire</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_TJMST) setModalTitle(<span>{consultants[0].prenomNom}: Evolution du taux d'achat</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_PRIME) setModalTitle(<span>{consultants[0].prenomNom}: Prime</span>)
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_COEFF) {
               setModalTitle(consultants.length > 1 ? <>Changement de coeff.</> : <>{consultants[0].prenomNom}: Changement de coeff.</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_POSITION) {
               setModalTitle(consultants.length > 1 ? <>Changement de position</> : <>{consultants[0].prenomNom}: Changement de position</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_HEURESSUPP) {
               setModalTitle(consultants.length > 1 ? <>Changement d'heures supp.</> : <>{consultants[0].prenomNom}: Changement d'heures supp.</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_STATUT) {
               setModalTitle(consultants.length > 1 ? <>Changement de statut</> : <>{consultants[0].prenomNom}: Changement de statut</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE) {
               setModalTitle(consultants.length > 1 ? <>Changement d'entité de rattachement</> : <>{consultants[0].prenomNom}: Changement d'entité de rattachement</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE_FACTURATION) {
               setModalTitle(consultants.length > 1 ? <>Changement d'entité de facturation</> : <>{consultants[0].prenomNom}: Changement d'entité de facturation</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MANAGER) {
               setModalTitle(consultants.length > 1 ? <>Changement de manager</> : <>{consultants[0].prenomNom}: Changement de manager</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_PERSONNEL_DELEGATION_DROITS) {
               setModalTitle(consultants.length > 1 ? <>Modification de délégation de droits</> : <>{consultants[0].prenomNom}: Modification de délégation de droits</>)
          }
          if (selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUTER_PERSONNEL_DELEGATION_DROITS) {
               setModalTitle(consultants.length > 1 ? <>Ajout délégation de droits</> : <>{consultants[0].prenomNom}: Ajout délégation de droits</>)
          }
     }

     return (
          <>
               {/* CHOOSE EVENT */}
               {(selectedEvent === null || !isNext) && (
                    <>
                         <MyCard classNames={"mb-5"} defaultIsCollapsed={true}>
                              <MyCard.Header isCollapseIconShown>Consultants concernés ( {consultants.length} )</MyCard.Header>
                              <MyCard.Body>
                                   <>
                                        {consultants.map((item, index) => (
                                             <div key={index}>{item.prenomNom}</div>
                                        ))}
                                   </>
                              </MyCard.Body>
                         </MyCard>

                         <form>
                              <div id={"choose-event"}>
                                   {/* Event */}
                                   <div className="row mb-4">
                                        <div className="col-lg-12">
                                             <MySelectGetAllDataFromServer
                                                  name={"clients"}
                                                  url={CONST_API_UTILS_GET_CONSULTANT_ALLOWED_SUIVIS_ENDPOINT}
                                                  value={selectedEvent}
                                                  onChange={val => setSelectedEvent(val)}
                                                  payload={{
                                                       date: moment().format("YYYY-MM-DD"),
                                                       consultant_IDs: consultants.map(item => item.id),
                                                  }}
                                                  method={"POST"}
                                                  isInvalid={false}
                                                  placeholder={"Choisissez"}
                                             />
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button
                                             className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                             type={"submit"}
                                             onClick={handleSuivant}
                                             disabled={!selectedEvent}
                                        >
                                             Suivant
                                        </button>
                                   </div>
                              </div>
                         </form>
                    </>
               )}

               {/* Form according to event */}
               {isNext && (
                    <>
                         {/* Sortie des effectifs */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_HORSEFFECTIF && (
                              <ConsultantSuiviSortieDesEffectifs
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}

                         {/* Reporting */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING && (
                              <ConsultantSuiviReporting
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                                   setModalNoPaddingCallback={setModalNoPaddingCallback}
                                   setModalSizeCallback={setModalSizeCallback}
                              />
                         )}

                         {/* Changement de TJM */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_TJM && (
                              <ConsultantSuiviChangementTjm
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                                   setModalNoPaddingCallback={setModalNoPaddingCallback}
                                   setModalSizeCallback={setModalSizeCallback}
                              />
                         )}
                         {/* Suppression machine */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SUPPRESSION_MACHINE && (
                              <ConsultantSuiviSuppressionMachine
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Changement / Ajout machine */}
                         {[CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_MACHINE, CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MACHINE].includes(selectedEvent || "") && (
                              <ConsultantSuiviAjoutOuChangementMachine
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Commentaire */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_REMARQUE && (
                              <ConsultantSuiviCommentaire
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Changement manager */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MANAGER && (
                              <ConsultantSuiviChangementManager
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Set perosnnel delegation droits */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_PERSONNEL_DELEGATION_DROITS && (
                              <ConsultantSuiviModificationPersonnelDelegationDroits
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Ajout personnel delegation droits */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUTER_PERSONNEL_DELEGATION_DROITS && (
                              <ConsultantSuiviAjoutPersonnelDelegationDroits
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* Prime */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_PRIME && (
                              <ConsultantSuiviPrime
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT STATUT*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_STATUT && (
                              <ConsultantSuiviChangementStatut
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT ENTITE FACTURATION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE_FACTURATION && (
                              <ConsultantSuiviChangementEntiteFacturation
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT ENTITE RATTACHEMENT*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE && (
                              <ConsultantSuiviChangementEntiteRattachement
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT HEURES SUPP.*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_HEURESSUPP && (
                              <ConsultantSuiviChangementHeuresSupp
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT POSITION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_POSITION && (
                              <ConsultantSuiviChangementPosition
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT COEFF.*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_COEFF && (
                              <ConsultantSuiviChangementCoeff
                                   consultants={consultants}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT TAUX ACHAT */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_TJMST && (
                              <ConsultantSuiviChangementTauxAchat
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* CHANGEMENT SALAIRE */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_SALAIRE_BRUT && (
                              <ConsultantSuiviChangementSalaire
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* INTERCONTRAT */}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_INTERCONTRAT && (
                              <ConsultantSuiviSalarieChangementEtatToIntercontrat
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                              />
                         )}
                         {/* REPRISE MISSION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_ENMISSION && (
                              <ConsultantSuiviSalarieRepriseMission
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                                   setModalNoPaddingCallback={setModalNoPaddingCallback}
                                   setModalSizeCallback={setModalSizeCallback}
                              />
                         )}
                         {/* REPRISE MISSION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AFFECTATION_MISSION && (
                              <ConsultantSuiviSalarieEmbaucheSurProfilAffectationMission
                                   consultant={consultants[0]}
                                   annulerCallback={() => {
                                        setSelectedEvent(null)
                                        setIsNext(false)
                                        setModalTitle(<span>Choisissez un suivi</span>)
                                        setModalSizeCallback("lg")
                                   }}
                                   onSubmitCallback={onSubmitCallback}
                                   setModalNoPaddingCallback={setModalNoPaddingCallback}
                                   setModalSizeCallback={setModalSizeCallback}
                              />
                         )}
                         {/* PROLONGEMENT MISSION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PROLONGEMENT_MISSION && (
                              <>
                                   {consultants[0].contrat !== "CONTRAT_ST" ? (
                                        <ConsultantSuiviProlongementMissionSalarie
                                             consultant_ID={consultants[0].id as number}
                                             annulerCallback={() => {
                                                  setSelectedEvent(null)
                                                  setIsNext(false)
                                                  setModalTitle(<span>Choisissez un suivi</span>)
                                                  setModalSizeCallback("lg")
                                             }}
                                             onSubmitCallback={onSubmitCallback}
                                             setModalNoPaddingCallback={setModalNoPaddingCallback}
                                             setModalSizeCallback={setModalSizeCallback}
                                        />
                                   ) : (
                                        <ConsultantSuiviProlongementMissionNonSalarie
                                             consultant_ID={consultants[0].id as number}
                                             annulerCallback={() => {
                                                  setSelectedEvent(null)
                                                  setIsNext(false)
                                                  setModalTitle(<span>Choisissez un suivi</span>)
                                                  setModalSizeCallback("lg")
                                             }}
                                             onSubmitCallback={onSubmitCallback}
                                        />
                                   )}
                              </>
                         )}
                         {/* CHANGEMENT MISSION*/}
                         {selectedEvent === CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MISSION && (
                              <>
                                   {consultants[0].contrat !== "CONTRAT_ST" ? (
                                        <ConsultantSuiviChangementMissionSalarie
                                             consultant={consultants[0]}
                                             annulerCallback={() => {
                                                  setSelectedEvent(null)
                                                  setIsNext(false)
                                                  setModalTitle(<span>Choisissez un suivi</span>)
                                                  setModalSizeCallback("lg")
                                             }}
                                             onSubmitCallback={onSubmitCallback}
                                             setModalNoPaddingCallback={setModalNoPaddingCallback}
                                             setModalSizeCallback={setModalSizeCallback}
                                        />
                                   ) : (
                                        <ConsultantSuiviChangementMissionSousTraitant
                                             consultant={consultants[0]}
                                             annulerCallback={() => {
                                                  setSelectedEvent(null)
                                                  setIsNext(false)
                                                  setModalTitle(<span>Choisissez un suivi</span>)
                                                  setModalSizeCallback("lg")
                                             }}
                                             onSubmitCallback={onSubmitCallback}
                                        />
                                   )}
                              </>
                         )}
                    </>
               )}
          </>
     )
}

interface IPropsModel {
     consultants: IConsultantModel[]
     onSubmitCallback?: () => void
     setModalSizeCallback?: (size: "lg" | "xl" | "fullscreen") => void
     setModalNoPaddingCallback?: (noPadding: boolean) => void
     setModalTitle?: (title: JSX.Element) => void
}

export default ConsultantSuiviSelectEvent
