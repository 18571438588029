import React, { createContext, useContext, useRef } from "react"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyPagination from "@common-utils/MyPagination"
import { IConsultantsListingListContextPropsModel } from "./core/_models"
import { useConsultantsListingContext } from "./ConsultantsListing"
import { IGetConsultantsListingItemResponseModel } from "../core/_models"
import ConsultantsListingListItem from "./_ConsultantsListingListItem"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import BulkSelectionContainerForListings from "../../../../utils/BulkSelectionContainerForListings"
import MyTooltip from "@common-utils/MyTooltip"
import { toast } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS } from "@common-constants/*"

const ConsultantsListingListContext = createContext<IConsultantsListingListContextPropsModel>({} as IConsultantsListingListContextPropsModel)
export const useConsultantsListingListContext = () => useContext(ConsultantsListingListContext)
const ConsultantListingList = () => {
     const context = useConsultantsListingContext()
     const selectedItemRef = useRef<any>(null)

     // BULK SELECTION: handling the "check all elements" button click
     function handleCheckAllElementsInPageCallBack() {
          const items = context.listingQuery.data!.data.map(item => item.consultant)
          context.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               checkedElementsInPage: items,
          }))
          toast.info("Tous les éléments de cette page ont été cochés.", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
     }

     // BULK SELECTION: handling the "check all elements across all pages" button click
     function handleCheckAllElementsAcrossAllPagesCallBack() {
          context.setIsBulkSelectionEnabled(prev => ({
               ...prev,
               areAllElementsInAllPagesChecked: true,
          }))
          toast.info("Tous les éléments correspondant à votre recherche ont été cochés.", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
     }

     // BULK SELECTION: handling the "Quit" button click
     function handleQuitBulkSelectionModeCallBack() {
          context.setIsBulkSelectionEnabled({
               enabled: false,
               checkedElementsInPage: [],
               areAllElementsInAllPagesChecked: false,
          })
     }

     const CardHeaderRightSide = () => {
          return (
               <>
                    {context.listingQuery.isFetching ? (
                         <MySimpleSpinner size={"sm"} />
                    ) : (
                         <>
                              {context.listingQuery.data && context.listingQuery.data.totalElementsInPage > 0 && (
                                   <div className={"d-flex align-items-center"}>
                                        {/* Pagination component*/}
                                        <MyPagination
                                             page={context.filters.page as number}
                                             pageStart={context.listingQuery.data.pageStart}
                                             pageEnd={context.listingQuery.data.pageEnd}
                                             totalPages={context.listingQuery.data.totalPages}
                                             totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                             onPreviousPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) - 1,
                                                  }))
                                                  context.setItemInPreview(null)
                                             }}
                                             onNextPageCallBack={() => {
                                                  context.setFilters(prev => ({
                                                       ...prev,
                                                       page: (prev.page as number) + 1,
                                                  }))
                                                  context.setItemInPreview(null)
                                             }}
                                        />
                                   </div>
                              )}
                         </>
                    )}
               </>
          )
     }

     return (
          <ConsultantsListingListContext.Provider value={{ selectedItemRef }}>
               <MyCard>
                    <MyCard.Header isCollapseIconShown={false} rightSideContent={<CardHeaderRightSide />}>
                         Consultants
                    </MyCard.Header>

                    <MyCard.Body noPadding={true}>
                         <>
                              {/* Fetching */}
                              {context.listingQuery.isFetching ? (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner classNames={"ms-2"} />
                                   </div>
                              ) : (
                                   <>
                                        {/* In case of error */}
                                        {context.listingQuery.error ? (
                                             <MyAlert type={"danger"}>{context.listingQuery.error.response?.data.detail}</MyAlert>
                                        ) : (
                                             <>
                                                  {/* CASE: no data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length === 0 && (
                                                       <div className="text-center text-muted p-4 fs-4">Aucun résultat</div>
                                                  )}

                                                  {/* CASE: If some data */}
                                                  {context.listingQuery.data && context.listingQuery.data.data.length > 0 && (
                                                       <>
                                                            {/* Mass selection actions component */}
                                                            {context.isBulkSelectionEnabled.enabled && (
                                                                 <BulkSelectionContainerForListings
                                                                      areAllElementsInAllPagesChecked={context.isBulkSelectionEnabled.areAllElementsInAllPagesChecked}
                                                                      checkedElementIdsInPage={context.isBulkSelectionEnabled.checkedElementsInPage}
                                                                      totalElementsInPage={context.listingQuery.data.totalElementsInPage}
                                                                      totalElementsInAllPages={context.listingQuery.data.totalElementsInAllPages}
                                                                      allowCheckAllElementInAllPages={false}
                                                                      handleCheckAllElementsInPageCallBack={handleCheckAllElementsInPageCallBack}
                                                                      handleCheckAllElementsAcrossAllPagesCallBack={handleCheckAllElementsAcrossAllPagesCallBack}
                                                                      handleQuitBulkSelectionModeCallBack={handleQuitBulkSelectionModeCallBack}
                                                                      AllElementsCheckedAcrossAllPagesActionsChildren={
                                                                           <>
                                                                                <MyTooltip title={"Ajouter un suivi"}>
                                                                                     <button
                                                                                          type={"button"}
                                                                                          className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                                                                          onClick={() =>
                                                                                               context.setSelectedConsultantsForSuivi(prev => ({
                                                                                                    ...prev,
                                                                                                    consultants: context.isBulkSelectionEnabled.checkedElementsInPage,
                                                                                               }))
                                                                                          }
                                                                                     >
                                                                                          <i className="fas fa-calendar-plus fs-4" />
                                                                                     </button>
                                                                                </MyTooltip>
                                                                           </>
                                                                      }
                                                                      AllOrFewElementsCheckedInCurrentPageActionsChildren={
                                                                           <>
                                                                                <MyTooltip title={"Ajouter un suivi"}>
                                                                                     <button
                                                                                          type={"button"}
                                                                                          className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                                                                          onClick={() =>
                                                                                               context.setSelectedConsultantsForSuivi(prev => ({
                                                                                                    ...prev,
                                                                                                    consultants: context.isBulkSelectionEnabled.checkedElementsInPage,
                                                                                               }))
                                                                                          }
                                                                                     >
                                                                                          <i className="fas fa-calendar-plus fs-4" />
                                                                                     </button>
                                                                                </MyTooltip>
                                                                           </>
                                                                      }
                                                                 />
                                                            )}
                                                            {/* List elements */}
                                                            <div
                                                                 // className={"hover-scroll-overlay-y"}
                                                                 style={{
                                                                      maxHeight: context.isBulkSelectionEnabled.enabled ? "59vh" : "68vh",
                                                                      overflowY: "auto",
                                                                 }}
                                                            >
                                                                 {context.listingQuery.data.data.map((item: IGetConsultantsListingItemResponseModel, index) => (
                                                                      <MyLoadingOverlay
                                                                           isActive={!!context.consultantsBeingUpdated.find(item_ => item_.id === item.consultant.id)}
                                                                           key={index}
                                                                      >
                                                                           <div className="border border-bottom" key={item.consultant.id}>
                                                                                <ConsultantsListingListItem item={item} index={index} />
                                                                           </div>
                                                                      </MyLoadingOverlay>
                                                                 ))}
                                                            </div>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </>
                    </MyCard.Body>
               </MyCard>
          </ConsultantsListingListContext.Provider>
     )
}

export default ConsultantListingList
