import React, { createContext, useContext, useState } from "react"
import { useSearchResultsContext } from "../CandidatsRechercheResults"
import MyCard from "@common-utils/MyCard"
import "../styles.scss"
import MyLoadingOverlay from "../../../../../../../common/components/MyLoadingOverlay"
import { CONST_CANDIDAT_ETAT_EMBAUCHE } from "@common-constants/*"
import { ActionsRapidesButton, AjouterUnSuiviButton, CurriculumIframeWithUtils, ItemNavigationArrows, RecruterButton } from "./helpers/_CandidatsRechercheResultsPreviewHelpers"
import { CandidatsRechercheResultsPreviewHelpersDocos } from "./helpers/_CandidatsRechercheResultsPreviewHelpersDocos"
import { ISearchResultsPreviewContextPropsModel, ISearchResultsResponseModel } from "../core/_models"
import { CandidatsRechercheResultsPreviewHelpersComments } from "./helpers/_CandidatsRechercheResultsPreviewHelpersComments"
import { CandidatsRechercheResultsPreviewHelpersCandidatures } from "./helpers/_CandidatsRechercheResultsPreviewHelpersCandidatures"
import { CandidatsRechercheResultsPreviewHelpersDetails } from "./helpers/_CandidatsRechercheResultsPreviewHelpersDetails"
import { ICandidatModel } from "@common-models/*"
import { useQueryClient } from "react-query"

/* Some actions like: ajout de suivi, actions rapides, programmer une visio & recrutement */
const CardFooter = () => {
     const globalContext = useSearchResultsContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)

     return (
          <>
               {/* Actions (suivi: répondeur, DMS, entretien ... + add to vivier */}
               <AjouterUnSuiviButton />
               {/* Actions rapides (déjà contacté par ... , non retenu pour ...) */}
               <ActionsRapidesButton />
               {/* Programmer visio */}
               <button
                    className={"btn btn-sm btn-success p-0 px-3 py-2 py-sm-2 px-sm-3 me-6"}
                    onClick={() => candidateInPreview && globalContext.setSchedulingEntretienMeet(candidateInPreview)}
               >
                    <span className={"d-none d-sm-inline-flex"}>Programmer une Visio</span> <i className={"fas fa-video fs-6"} />
               </button>
               {/* Recruter */}
               {candidateInPreview?.candidat !== CONST_CANDIDAT_ETAT_EMBAUCHE && <RecruterButton />}
          </>
     )
}

/* Wrapper */
const PreviewContext = createContext<ISearchResultsPreviewContextPropsModel>({} as ISearchResultsPreviewContextPropsModel)
export const useSearchResultsPreviewContext = () => useContext(PreviewContext)
const CandidatsRechercheResultsPreview = () => {
     const queryClient = useQueryClient()
     const globalContext = useSearchResultsContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const candidateInPreview = globalContext.useListingQuery.data?.data.find(item => item.candidat.id === selectedItemInPreview?.candidat.id)
     const [activeTab, setActiveTab] = useState<"cv" | "doco" | "details" | "dms" | "commentaires" | "candidatures">("cv")
     const [isPreviewBodyLoading, setIsPreviewBodyLoading] = useState<boolean>(true)

     function handleSubmitModifyCandidatDetailsCallBack(modifiedCandidat: ICandidatModel) {
          queryClient.setQueryData<ISearchResultsResponseModel | undefined>(
               [globalContext.REACT_QUERY_KEY_SEARCH_RESULTS, globalContext.filters],
               (prev: ISearchResultsResponseModel | undefined) => {
                    if (prev?.data) {
                         const index = prev.data.findIndex(item_ => item_.candidat.id === candidateInPreview?.candidat?.id)
                         prev.data[index] = {
                              ...prev.data[index],
                              candidat: { ...prev.data[index].candidat, ...modifiedCandidat },
                         }

                         return prev
                    }

                    return prev
               }
          )
     }

     return (
          <PreviewContext.Provider
               value={{
                    isPreviewBodyLoading,
                    setIsPreviewBodyLoading,
               }}
          >
               <MyCard>
                    <MyCard.Header rightSideContent={<ItemNavigationArrows />} isCollapseIconShown={false}>
                         <div className="d-flex align-items-center">
                              <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                              <span className={"me-2"}>{candidateInPreview?.candidat.prenomNom}</span>
                         </div>
                    </MyCard.Header>
                    <MyCard.Body noPadding classNames={"d-flex"}>
                         <div style={{ width: "95%" }}>
                              <MyLoadingOverlay isActive={globalContext.itemInPreview!.isSelectedItemLoading || globalContext.useListingQuery.isFetching || isPreviewBodyLoading}>
                                   <>
                                        {activeTab === "cv" && <CurriculumIframeWithUtils />}
                                        {activeTab === "doco" && <CandidatsRechercheResultsPreviewHelpersDocos />}
                                        {activeTab === "details" && (
                                             <CandidatsRechercheResultsPreviewHelpersDetails handleSubmitCallBack={handleSubmitModifyCandidatDetailsCallBack} />
                                        )}
                                        {activeTab === "commentaires" && <CandidatsRechercheResultsPreviewHelpersComments />}
                                        {activeTab === "candidatures" && candidateInPreview?.candidat?.candidatures && candidateInPreview?.candidat?.candidatures?.length > 0 && (
                                             <CandidatsRechercheResultsPreviewHelpersCandidatures />
                                        )}
                                   </>
                              </MyLoadingOverlay>
                         </div>

                         <div className="d-flex m-0 p-0" style={{ width: "5%" }}>
                              <ul className="nav nav-tabs nav-tabs-custom">
                                   <li
                                        className={`nav-item d-flex align-items-center justify-content-center w-100 cursor-pointer ${
                                             activeTab === "cv" ? "bg-dark border-bottom border-dark" : "bg-hover-light-primary border-bottom border-secondary"
                                        }`}
                                        onClick={() => {
                                             setIsPreviewBodyLoading(true)
                                             setActiveTab("cv")
                                        }}
                                   >
                                        <span className={`fw-bold ${activeTab === "cv" ? "text-inverse-dark" : ""}`}>CV</span>
                                   </li>
                                   <li
                                        className={`nav-item d-flex align-items-center justify-content-center w-100 cursor-pointer ${
                                             activeTab === "doco" ? "bg-dark border-bottom border-dark" : "bg-hover-light-primary border-bottom border-secondary"
                                        }`}
                                        onClick={() => {
                                             setIsPreviewBodyLoading(true)
                                             setActiveTab("doco")
                                        }}
                                   >
                                        <span className={`fw-bold ${activeTab === "doco" ? "text-inverse-dark" : ""}`}>DoCo</span>
                                   </li>
                                   <li
                                        className={`nav-item d-flex align-items-center justify-content-center w-100 cursor-pointer ${
                                             activeTab === "details" ? "bg-dark border-bottom border-dark" : "bg-hover-light-primary border-bottom border-secondary"
                                        }`}
                                        onClick={() => {
                                             setIsPreviewBodyLoading(true)
                                             setActiveTab("details")
                                        }}
                                   >
                                        <span className={`fw-bold ${activeTab === "details" ? "text-inverse-dark" : ""}`}>Détails</span>
                                   </li>
                                   <li
                                        className={`nav-item d-flex align-items-center justify-content-center w-100 cursor-pointer ${
                                             activeTab === "commentaires" ? "bg-dark border-bottom border-dark" : "bg-hover-light-primary border-bottom border-secondary"
                                        }`}
                                        onClick={() => {
                                             setIsPreviewBodyLoading(true)
                                             setActiveTab("commentaires")
                                        }}
                                   >
                                        <span className={`fw-bold ${activeTab === "commentaires" ? "text-inverse-dark" : ""}`}>Commentaires</span>
                                   </li>
                                   {candidateInPreview?.candidat?.candidatures && candidateInPreview?.candidat?.candidatures?.length > 0 && (
                                        <li
                                             className={`nav-item d-flex align-items-center justify-content-center w-100 cursor-pointer ${
                                                  activeTab === "candidatures" ? "bg-dark border-bottom border-dark" : "bg-hover-light-primary"
                                             }`}
                                             onClick={() => {
                                                  setIsPreviewBodyLoading(true)
                                                  setActiveTab("candidatures")
                                             }}
                                        >
                                             <span className={`fw-bold ${activeTab === "candidatures" ? "text-inverse-dark" : ""}`}>Candidatures</span>
                                        </li>
                                   )}
                              </ul>
                         </div>
                    </MyCard.Body>

                    <MyCard.Footer classNames={"d-flex justify-content-center"}>
                         <CardFooter />
                    </MyCard.Footer>
               </MyCard>
          </PreviewContext.Provider>
     )
}

export default CandidatsRechercheResultsPreview
