import React, { ReactNode, useCallback, useMemo, useRef, useState } from "react"
import Select from "react-select"
import axios, { AxiosError, AxiosResponse } from "axios"
import { FormikProps } from "formik/dist/types"
import clsx from "clsx"
import { ObjectSchema } from "yup"
import useSelectReact, { SelectOptionsType, SelectOptionType, SelectOptionValueType } from "./useSelectReact"
import useFormik from "./useFormik"
import { debounce } from "lodash"

const MySelectSearchDataFromServerFormField = ({
     name,
     placeholder,
     payload = {},
     isClearable = true,
     isMulti = false,
     minimumLengthSearch,
     defaultOptions = [],
     method,
     label,
     autoFocus,
     formikProps,
     classNameLayout,
     classNameLabel,
     classNameField,
     endpoint,
     validationSchema,
     onChangeCallback = () => {},
}: IPropsModel) => {
     const { formatGroupLabel, styles } = useSelectReact()
     const { isFieldValid, isFieldOnError, getFieldErrorMessage, isFieldRequired } = useFormik()

     const [isLoading, setIsLoading] = useState<boolean>(false)
     // This will be used to display the options obtained from the Response. This options will be replaced for every new Response.
     const [options, setOptions] = useState<SelectOptionsType>(defaultOptions)
     // CacheOptions will store "forever" the options obtained from the Response. The outcome of a new Response will append the new options to the cached options.
     const [cacheOptions, setCacheOptions] = useState<SelectOptionsType>(defaultOptions) /*  */
     const [noOptionsMessage, setNoOptionsMessage] = useState<string>(`Saisissez au moins ${minimumLengthSearch} caractères pour lancer la recherche`)

     const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

     // 🔹 Debounced API Call (only fetches when user stops typing for 2 seconds)
     const fetchOptions = useMemo(() => {
          return debounce((q: string) => {
               if (q.length < minimumLengthSearch) {
                    setOptions([]) // Clear options immediately when input length is too short.
                    return
               }

               setIsLoading(true)
               setNoOptionsMessage("Chargement en cours ...") // Show loading message.

               let result
               // Adjust payload values if necessary.
               if (method === "GET") {
                    for (const key in payload) {
                         if (payload[key] === true) payload[key] = 1
                         if (payload[key] === false) payload[key] = 0
                    }

                    result = axios.get<Array<{ label: string; value: string | number }>>(endpoint, {
                         params: { q, ...payload },
                    })
               } else {
                    result = axios.post<Array<{ label: string; value: string | number }>>(endpoint, payload)
               }

               result
                    .then((r: AxiosResponse) => {
                         setOptions(r.data) // Set the new options from the API response.
                         setCacheOptions(prev => {
                              r.data.forEach(item => {
                                   if ("options" in item) {
                                        item.options.forEach(subItem => {
                                             if (!prev.some(e => e.value === subItem.value)) {
                                                  prev.push(subItem)
                                             }
                                        })
                                   } else {
                                        if (!prev.some(e => e.value === item.value)) {
                                             prev.push(item)
                                        }
                                   }
                              })
                              return [...prev]
                         })
                    })
                    .catch((e: AxiosError) => {
                         setNoOptionsMessage(e.response?.status === 400 ? e.response.data.detail : "Une erreur est survenue")
                         setOptions([])
                    })
                    .finally(() => {
                         setIsLoading(false)
                    })

               setNoOptionsMessage("Aucun résultat")
          }, 700)
     }, [endpoint, method, payload, minimumLengthSearch])

     // onChange: sets the field's value of the targeted field
     function onChange(val: any) {
          if (val === null) {
               formikProps?.setFieldValue(name, null)
          } else {
               if (Array.isArray(val)) {
                    formikProps?.setFieldValue(
                         name,
                         val.map(i => i.value)
                    )
                    onChangeCallback(
                         val.map(i => i.value),
                         val.map(i => i)
                    )
               } else {
                    formikProps?.setFieldValue(name, val?.value)
                    onChangeCallback(val?.value, val)
               }
          }
     }

     // Handle input change and trigger debounced request
     const handleInputChange = (q: string) => {
          // Handle immediate message update
          if (q.length < minimumLengthSearch) {
               setNoOptionsMessage(`Saisissez au moins ${minimumLengthSearch - q.length} caractères pour lancer la recherche`)
          } else {
               setNoOptionsMessage("Chargement en cours ...") // Show loading message when input length is sufficient
          }

          // Clear any previously set debounced timeout
          if (debounceTimeout.current) {
               clearTimeout(debounceTimeout.current)
          }

          // Set a new timeout for debounced call
          debounceTimeout.current = setTimeout(() => {
               fetchOptions(q) // Trigger debounced function to make the API call
          }, 700)
     }

     const selectValue = useCallback(() => {
          if (!formikProps?.getFieldProps(name).value) return []
          if (Array.isArray(formikProps?.getFieldProps(name).value)) {
               return cacheOptions.filter(opt =>
                    "options" in opt
                         ? opt.options.some(item => formikProps?.getFieldProps(name).value.includes(item.value))
                         : formikProps?.getFieldProps(name).value.includes(opt.value)
               )
          } else {
               return cacheOptions.find(opt => opt.value === formikProps?.getFieldProps(name).value)
          }
     }, [formikProps?.getFieldProps(name).value, cacheOptions])

     return (
          <div className={clsx("row", classNameLayout)}>
               <div className={clsx("col-12 d-flex align-items-center", classNameLabel)}>
                    {label && (
                         <label
                              className={clsx("fs-6 fw-bolder text-gray-900", {
                                   required: validationSchema && isFieldRequired(validationSchema, name),
                              })}
                         >
                              {label}
                         </label>
                    )}
               </div>
               <div
                    className={clsx("col-12", classNameField, {
                         "mt-1": label,
                    })}
               >
                    <Select
                         menuPortalTarget={document.body}
                         isClearable={isClearable}
                         placeholder={placeholder}
                         name={name}
                         formatGroupLabel={formatGroupLabel}
                         styles={styles(!!(formikProps && isFieldOnError(formikProps, name)), !!(formikProps && isFieldValid(formikProps, name)))}
                         value={selectValue()}
                         options={options}
                         loadingMessage={() => "Chargement en cours ..."}
                         onInputChange={handleInputChange} // Handle input change here
                         onChange={onChange}
                         isLoading={isLoading}
                         isMulti={isMulti}
                         noOptionsMessage={() => noOptionsMessage}
                         onMenuClose={() => formikProps?.getFieldHelpers(name).setTouched(true)}
                         autoFocus={autoFocus}
                    />
                    {formikProps && isFieldOnError(formikProps, name) && <div className="text-danger">{getFieldErrorMessage(formikProps, name)}</div>}
               </div>
          </div>
     )
}

interface IPropsModel {
     label?: ReactNode
     name: string
     formikProps: FormikProps<any> | null
     endpoint: string
     method: "POST" | "GET"
     placeholder: string
     minimumLengthSearch: number
     isClearable?: boolean
     payload?: Record<string, any>
     isMulti?: boolean
     defaultOptions?: SelectOptionsType
     classNameLayout?: string
     classNameLabel?: string
     classNameField?: string
     autoFocus?: boolean
     validationSchema?: ObjectSchema<any>
     onChangeCallback?: (val: SelectOptionValueType | Array<SelectOptionValueType>, option: SelectOptionType | Array<SelectOptionType>) => void
}

export default MySelectSearchDataFromServerFormField
