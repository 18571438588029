import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import MyTooltip from "@common-utils/MyTooltip"
import { useDdcListingListContext } from "./_DdcListingList"
import { useDDdcListingContext } from "./ConsultantDdcListing"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { Link } from "react-router-dom"
import { IGetDemandesCongesResponseItemModel } from "../core/_models"
import { useDdcActions } from "./core/_hooks"
import { CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE, CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE, CONST_DDC_SUIVI_TYPE_REJET_DEMANDE } from "@common-constants/*"
import { useThemeMode } from "@common-metronic/partials"
import GlobalHelper from "@common-helpers/GlobalHelper"
import useDateHelper from "@common-helpers/useDateHelper"
import moment from "moment"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const { mode } = useThemeMode()
     const globalContext = useDDdcListingContext()
     const listContext = useDdcListingListContext()
     const ddcActions = useDdcActions()
     const { countWeekdaysBetweenDates } = useDateHelper()

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     const totalDemiJournees = item.consultantDdc.demiJournees?.length || 0
     const totalJourneesCompletes = countWeekdaysBetweenDates(item.consultantDdc.du as moment.Moment, item.consultantDdc.au as moment.Moment)
     const dureeAbsenceEnJours = totalJourneesCompletes - totalDemiJournees * 0.5

     const BadgeEtat = () => {
          if (item.consultantDdc.etat?.type === "TYPE_ANNULATION_DEMANDE")
               return (
                    <MyTooltip title={<>{item.consultantDdc.etat.commentaire}</>}>
                         <div className="badge badge-secondary cursor-default">
                              Annulée <span className={"fas fa-times-circle ms-1"} />
                         </div>
                    </MyTooltip>
               )
          if (item.consultantDdc.etat?.type === "TYPE_REJET_DEMANDE")
               return (
                    <MyTooltip title={<>{item.consultantDdc.etat.commentaire}</>}>
                         <div className="badge badge-danger cursor-default">
                              Rejetée <span className={"fas fa-times-circle ms-1"} />
                         </div>
                    </MyTooltip>
               )
          if (item.consultantDdc.etat?.type === "TYPE_ENVOI_DEMANDE" || item.consultantDdc.etat?.type === "TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM")
               return (
                    <div className="badge badge-warning cursor-default">
                         En attente <span className={"fas fa-clock ms-1"} />
                    </div>
               )
          if (item.consultantDdc.etat?.type === "TYPE_ACCEPTATION_DEMANDE")
               return (
                    <div className="badge badge-success cursor-default">
                         Validée <span className={"fas fa-check-circle ms-1"} />
                    </div>
               )

          return <div className={"badge badge-danger cursor-default"}>???</div>
     }

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.consultantDdc.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.consultantDdc.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.consultantDdc.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     function validationCallback() {
          globalContext.setItemInPreview(null)
          globalContext.listingQuery.refetch().then()
     }

     function rejetCallback() {
          globalContext.listingQuery.refetch().then()
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between align-items-center"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.consultantDdc?.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   <div className={"d-flex align-items-center"}>
                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.consultantDdc.consultant?.candidat?.user?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(
                                                                        item.consultantDdc?.consultant?.candidat?.user.avatarGoogleDriveId as string
                                                                   )
                                                                 : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <Link
                                                       to={`/business/consultants/${item.consultantDdc?.consultant?.id}/details`}
                                                       target={"_blank"}
                                                       className={"text-primary fw-bold fs-5"}
                                                  >
                                                       {item.consultantDdc?.consultant?.prenomNom}
                                                  </Link>
                                             </div>
                                             <div>
                                                  <span className={"text-gray-600"}>
                                                       <MyTooltip
                                                            title={
                                                                 <>
                                                                      <span>
                                                                           <div className={"fw-bold text-dark"}>Période</div>
                                                                           <div>Du {GlobalHelper.firstLetterUppercase(item.consultantDdc.du?.format("dddd D MMMM YYYY"))} </div>

                                                                           <div>au {GlobalHelper.firstLetterUppercase(item.consultantDdc.au?.format("dddd D MMMM YYYY"))}</div>
                                                                           {item.consultantDdc.demiJournees && item.consultantDdc.demiJournees.length > 0 && (
                                                                                <>
                                                                                     <div className={"fw-bold mt-2 text-dark"}>
                                                                                          Dont {item.consultantDdc.demiJournees.length} demi journée
                                                                                          {item.consultantDdc.demiJournees.length > 1 && "s"}
                                                                                     </div>
                                                                                     {item.consultantDdc.demiJournees.map(dj => (
                                                                                          <div>{GlobalHelper.firstLetterUppercase(dj.format("dddd D MMMM YYYY"))}</div>
                                                                                     ))}
                                                                                </>
                                                                           )}
                                                                      </span>
                                                                 </>
                                                            }
                                                       >
                                                            <span className={"text-gray-600 cursor-default"}>
                                                                 {item.consultantDdc.du?.format("DD/MM/YYYY")} - {item.consultantDdc.au?.format("DD/MM/YYYY")}{" "}
                                                                 <span className={"text-gray-600 "}>
                                                                      ( {dureeAbsenceEnJours} {dureeAbsenceEnJours > 1 ? "jours" : "jour"} )
                                                                 </span>
                                                            </span>
                                                       </MyTooltip>
                                                  </span>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex flex-column align-items-end">
                                        <div className={"badge badge-dark mb-1"}>{item.consultantDdc.typeLabel}</div>
                                        <BadgeEtat />
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date de la demande */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-day fs-5 w-25px" />
                                                  Demande du {item.consultantDdc.createdAt?.format("dddd D MMMM YYYY")}
                                             </div>

                                             {/* Durée */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-days fs-5 w-25px" />
                                                  Du {item.consultantDdc.du?.format("dddd D MMM YYYY")} au {item.consultantDdc.au?.format("dddd D MMM YYYY")}{" "}
                                                  <span className={"text-gray-600 ms-2"}>
                                                       ( {dureeAbsenceEnJours} {dureeAbsenceEnJours > 1 ? "jours" : "jour"} )
                                                  </span>
                                             </div>

                                             {/* Demi journées */}
                                             {item.consultantDdc.demiJournees && (
                                                  <div className={`d-flex align-items-center mb-2`}>
                                                       <span className="w-25px" />
                                                       Demi journée{item.consultantDdc.demiJournees.length > 1 && "s"}:{" "}
                                                       {item.consultantDdc.demiJournees.map(dj => dj.format("DD/MM/YYYY")).join(", ")}
                                                  </div>
                                             )}

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" /> {item.consultantDdc?.consultant?.personnel?.prenomNom}
                                             </div>

                                             {/* Client */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-building fs-5 w-25px" /> {item.consultantDdc?.consultant?.clientPropal?.client?.nom}
                                             </div>

                                             {/* Congés pris */}
                                             <div className={`d-flex align-items-center mb-2 text-muted`}>
                                                  <span className="fas fa-hourglass-half fs-5 w-25px" /> Congés pris: -
                                             </div>

                                             {/* Congés disponibles */}
                                             <div className={`d-flex align-items-center mb-2 text-muted`}>
                                                  <span className="fas fa-hourglass-start fs-5 w-25px" /> Congés disponibles: -
                                             </div>

                                             {/* Solde */}
                                             <div className={`d-flex align-items-center mb-2 text-muted`}>
                                                  <span className="fas fa-hourglass-end fs-5 w-25px" /> Solde: -
                                             </div>
                                        </div>
                                        {![CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE, CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE, CONST_DDC_SUIVI_TYPE_REJET_DEMANDE].includes(
                                             item.consultantDdc.etat?.type as string
                                        ) && (
                                             <div className="col text-end">
                                                  <div>
                                                       <MyTooltip title={"Valider cette demande"}>
                                                            <button
                                                                 type={"button"}
                                                                 className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                                                 onClick={() => ddcActions.validation(item.consultantDdc.id as number, validationCallback)}
                                                            >
                                                                 <i className="fas fa-thumbs-up fs-4" />
                                                            </button>
                                                       </MyTooltip>
                                                  </div>

                                                  <div>
                                                       <MyTooltip title={"Rejeter cette demande"}>
                                                            <button
                                                                 type={"button"}
                                                                 className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-2`}
                                                                 onClick={() => ddcActions.rejet(item.consultantDdc.id as number, rejetCallback)}
                                                            >
                                                                 <i className="fas fa-thumbs-down fs-4" />
                                                            </button>
                                                       </MyTooltip>
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const DdcListingListItem = ({ item, index }: { item: IGetDemandesCongesResponseItemModel; index: number }) => {
     const context = useDDdcListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.consultantDdc?.id === item.consultantDdc?.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem?.consultantDdc.id === item.consultantDdc?.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem?.consultantDdc.id !== item.consultantDdc?.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview({
                    ...getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages),
                    isSelectedItemLoading: false,
               })
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetDemandesCongesResponseItemModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default DdcListingListItem
