import * as Yup from "yup"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "@common-utils/MyAlert"
import React, { useState } from "react"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyCard from "@common-utils/MyCard"
import { useDemandeEmbaucheDetailsContext } from "../DemandeEmbaucheDetails"
import { IGetDemandeEmbaucheInfoClientResponseModel, ISetDemandeEmbaucheInfoClientSalarieFormikValuesModel, ISetDemandeEmbaucheInfoClientSalarieRequestModel } from "./core/_models"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { setDemandeEmbaucheInfoClientSalarie } from "./core/_requests"
import { IClientPropalModel, IHttpErrorResponseModel } from "@common-models/*"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import {
     CONST_API_UTILS_GET_BASES_FACTU_ENDPOINT,
     CONST_API_UTILS_GET_MACHINES_ENDPOINT,
     CONST_API_UTILS_GET_PAYS_ENDPOINT,
     CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT,
     CONST_API_UTILS_GET_VILLES_URL_ENDPOINT,
} from "../../../../../endpoints"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { getClientPropalSimpleDetailsRequest } from "../../../../client/ptf/core/_requests"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyModal from "@common-utils/MyModal"
import AddMachineForm from "../../../../utils/_AddMachineForm"

const DemandeEmbaucheDetailsInfoClientSalarie = ({ data }: { data: IGetDemandeEmbaucheInfoClientResponseModel }) => {
     const context = useDemandeEmbaucheDetailsContext()
     const [propalSimpleInfo, setPropalSimpleInfo] = useState<{ loading: boolean; data: IClientPropalModel | null }>({
          loading: false,
          data: data.consultant.clientPropal || null,
     })
     const [isAddingMachine, setIsAddingMachine] = useState<boolean>(false)

     // Form schema
     const schema = Yup.object().shape({
          clientPropal: Yup.string().label(`"PTF"`).nullable().required(),
          isPeriodeOfferteAuClient: Yup.boolean().label(`"Période offerte"`).nullable().required(),
          periodeOfferteDateDebut: Yup.string()
               .label(`"Date de début"`)
               .nullable()
               .when("isPeriodeOfferteAuClient", {
                    is: true,
                    then: schema => schema.required(),
               }),
          periodeOfferteDateFin: Yup.string()
               .label(`"Date de début"`)
               .nullable()
               .when("isPeriodeOfferteAuClient", {
                    is: true,
                    then: schema => schema.required(),
               }),
          missionDateDebut: Yup.string().label(`"Date de début"`).nullable().required(),
          missionDateFin: Yup.string().label(`"Date de fin"`).nullable().required(),
          missionBaseFacturationAnnuelle: Yup.string().label(`"Base de facturation"`).nullable().required(),
          missionAdresse: Yup.string().label(`"Adresse"`).nullable().required(),
          missionPays: Yup.number().label(`"Pays"`).nullable().required(),
          missionVille: Yup.string()
               .label(`"Ville"`)
               .nullable()
               .when("missionPays", {
                    is: CONST_PAYS_ID_FRANCE,
                    otherwise: schema => schema.required(),
               }),
          missionVilleFrance: Yup.number()
               .label(`"Ville"`)
               .nullable()
               .when("missionPays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          missionCodePostal: Yup.string().label(`"Code postal"`).nullable().required(),
          isMachineIncluded: Yup.boolean().label(`"Machine?"`).nullable().required(),
          machine: Yup.number()
               .label(`"Machine"`)
               .nullable()
               .when("isMachineIncluded", {
                    is: true,
                    then: schema => schema.required(),
               }),
     })

     // Form initial values
     const initialValues: ISetDemandeEmbaucheInfoClientSalarieFormikValuesModel = {
          clientPropal: data.consultant.clientPropal?.id,
          isPeriodeOfferteAuClient: data.demandeEmbauche.periodeFacturationOfferte === true,
          periodeOfferteDateDebut: data.demandeEmbauche.periodeFacturationOfferteDateDebut?.format("YYYY-MM-DD"),
          periodeOfferteDateFin: data.demandeEmbauche.periodeFacturationOfferteDateFin?.format("YYYY-MM-DD"),
          missionDateDebut: data.mission?.dateDebutValidite?.format("YYYY-MM-DD"),
          missionDateFin: data.mission?.dateFinValidite?.format("YYYY-MM-DD"),
          missionBaseFacturationAnnuelle: data.consultant.baseFacturationAnnuelle?.id,
          missionAdresse: data.mission?.travailAdresse,
          missionPays: data.mission?.travailPays?.id,
          missionVille: data.mission?.travailVille,
          missionVilleFrance: data.mission?.travailvillle?.id,
          missionCodePostal: data.mission?.travailCodePostal,
          isMachineIncluded: !!data.consultant.machine,
          machine: data.consultant.machine?.id,
     }

     // Handles submit
     function handleSubmit(values: ISetDemandeEmbaucheInfoClientSalarieFormikValuesModel, helpers: FormikHelpers<ISetDemandeEmbaucheInfoClientSalarieFormikValuesModel>) {
          helpers.setStatus(null)

          const payload: ISetDemandeEmbaucheInfoClientSalarieRequestModel = {
               clientPropal: values.clientPropal as number,
               isPeriodeOfferteAuClient: values.isPeriodeOfferteAuClient,
               periodeOfferteDateDebut: values.periodeOfferteDateDebut,
               periodeOfferteDateFin: values.periodeOfferteDateFin,
               missionDateDebut: values.missionDateDebut as string,
               missionDateFin: values.missionDateFin as string,
               missionBaseFacturationAnnuelle: values.missionBaseFacturationAnnuelle as number,
               missionAdresse: values.missionAdresse as string,
               missionPays: values.missionPays as number,
               missionVille: values.missionVille,
               missionVilleFrance: values.missionVilleFrance,
               missionCodePostal: values.missionCodePostal as string,
               isMachineIncluded: values.isMachineIncluded,
               machine: values.machine,
          }

          setDemandeEmbaucheInfoClientSalarie(data.consultant.id as number, payload)
               .then(() => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setNavigationStep("odm")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Handles change PTF
     function handleChangeClientPropal(ptf_id: number | undefined | null) {
          if (ptf_id) {
               setPropalSimpleInfo(prev => ({ ...prev, loading: true }))
               getClientPropalSimpleDetailsRequest(ptf_id)
                    .then(r => {
                         setPropalSimpleInfo({ loading: false, data: r.data })
                    })
                    .catch(() => {
                         setPropalSimpleInfo(prev => ({ ...prev, loading: false }))
                         toast.error("Un problème est survenu lors de la récupération des informations de la PTF sélectionnée.", { autoClose: false })
                    })
          }
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <MyCard classNames={"w-100 w-lg-75 w-xl-60"}>
                                   <MyCard.Body>
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             <div className="d-flex justify-content-between align-items-center mb-2">
                                                  <h3>Informations client</h3>
                                             </div>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* PTF */}
                                             <div className="row mb-4">
                                                  <MyAlert type={"primary"} classNames={"mb-4"}>
                                                       Assurez-vous que la PTF soit bien validée afin qu'elle puisse apparaître dans la liste
                                                  </MyAlert>
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez la PTF</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"clientPropal"}
                                                            value={helpers.values.clientPropal}
                                                            defaultOptions={
                                                                 data.consultant.clientPropal
                                                                      ? [
                                                                             {
                                                                                  value: data.consultant.clientPropal.id as number,
                                                                                  label: data.consultant.clientPropal.referencePlusPlus as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            isInvalid={!!helpers.errors.clientPropal}
                                                            onChange={val => {
                                                                 helpers.setFieldValue("clientPropal", val).then(() => {
                                                                      handleChangeClientPropal(val as number)
                                                                 })
                                                            }}
                                                            url={CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT}
                                                            isClearable={false}
                                                       />
                                                       {helpers.errors.clientPropal && <div className={"text-danger"}>{helpers.errors.clientPropal}</div>}
                                                  </div>
                                             </div>

                                             {propalSimpleInfo.loading && (
                                                  <div className={"text-center p-5"}>
                                                       <MySimpleSpinner size={"md"} />{" "}
                                                  </div>
                                             )}

                                             {propalSimpleInfo.data && !propalSimpleInfo.loading && (
                                                  <>
                                                       {/* Détails PTF (loaded) */}
                                                       <div className={"p-4 border border-dashed text-gray-600 mb-4"}>
                                                            <h4 className={"mb-4 text-gray-600"}>
                                                                 Détails de la {propalSimpleInfo.data.reference}
                                                                 <i className="fas fa-info-circle fs-3 float-end" />
                                                            </h4>
                                                            <div className="separator separator-dashed mb-6" />

                                                            <p>Client: {propalSimpleInfo.data.client?.nom}</p>
                                                            <p>Responsable client: {propalSimpleInfo.data.clientResponsable?.prenomNom}</p>
                                                            {propalSimpleInfo.data.type === "TYPE_ASSISTANCE_TECHNIQUE" && <p>TJM: {propalSimpleInfo.data.tjm}€</p>}
                                                            <p>Type: {propalSimpleInfo.data.typeLabel}</p>
                                                       </div>

                                                       {/* Avez-vous offert une période au client? */}
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6">Allez-vous offrir une période au client?</label>

                                                            <div className="col-lg-8 d-flex align-items-center">
                                                                 <MyCheckBoxField
                                                                      name={"isPeriodeOfferteAuClient"}
                                                                      value={helpers.values.isPeriodeOfferteAuClient}
                                                                      isInvalid={!!helpers.errors.isPeriodeOfferteAuClient}
                                                                      onChange={val => helpers.setFieldValue("isPeriodeOfferteAuClient", val)}
                                                                 />
                                                                 {helpers.errors.isPeriodeOfferteAuClient && (
                                                                      <div className={"text-danger"}>{helpers.errors.isPeriodeOfferteAuClient}</div>
                                                                 )}
                                                            </div>
                                                       </div>

                                                       {helpers.values.isPeriodeOfferteAuClient && (
                                                            <div className={"p-4 border border-dashed mb-4"}>
                                                                 <h4 className={"mb-4"}>Période offerte au client</h4>
                                                                 <div className="separator separator-dashed mb-6" />

                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                                      <div className="col-lg-8">
                                                                           <div className="d-flex">
                                                                                <MyDateDayMonthYearField
                                                                                     name={"periodeOfferteDateDebut"}
                                                                                     value={helpers.values.periodeOfferteDateDebut}
                                                                                     isInvalid={!!helpers.errors.periodeOfferteDateDebut}
                                                                                     minDate={data.consultant.dateEmbauche?.format("YYYY-MM-DD")}
                                                                                     maxDate={helpers.values.periodeOfferteDateFin}
                                                                                     onChange={val => helpers.setFieldValue("periodeOfferteDateDebut", val)}
                                                                                     placeholder={"Date de début"}
                                                                                     classNames={"rounded-0 rounded-start"}
                                                                                />
                                                                                <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                                <MyDateDayMonthYearField
                                                                                     name={"periodeOfferteDateFin"}
                                                                                     value={helpers.values.periodeOfferteDateFin}
                                                                                     minDate={helpers.values.periodeOfferteDateDebut}
                                                                                     isInvalid={!!helpers.errors.periodeOfferteDateFin}
                                                                                     onChange={val => helpers.setFieldValue("periodeOfferteDateFin", val)}
                                                                                     placeholder={"Date de fin"}
                                                                                     classNames={"rounded-0 rounded-end"}
                                                                                />
                                                                           </div>
                                                                           {(helpers.errors.periodeOfferteDateDebut || helpers.errors.periodeOfferteDateFin) && (
                                                                                <div className={"text-danger"}>Période requise ou incorrecte</div>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       )}

                                                       {/* MISSION */}
                                                       <div className={"p-4 border border-dashed mb-4"}>
                                                            <h4 className={"mb-4"}>MISSION</h4>
                                                            <div className="separator separator-dashed mb-6" />

                                                            {/* Période mission */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                                 <div className="col-lg-8">
                                                                      <div className="d-flex">
                                                                           <MyDateDayMonthYearField
                                                                                name={"missionDateDebut"}
                                                                                value={helpers.values.missionDateDebut}
                                                                                isInvalid={!!helpers.errors.missionDateDebut}
                                                                                minDate={data.consultant.dateEmbauche?.format("YYYY-MM-DD")}
                                                                                maxDate={helpers.values.missionDateFin}
                                                                                onChange={val => helpers.setFieldValue("missionDateDebut", val)}
                                                                                placeholder={"Date de début"}
                                                                                classNames={"rounded-0 rounded-start"}
                                                                           />
                                                                           <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                           <MyDateDayMonthYearField
                                                                                name={"missionDateFin"}
                                                                                value={helpers.values.missionDateFin}
                                                                                minDate={helpers.values.missionDateDebut}
                                                                                isInvalid={!!helpers.errors.missionDateFin}
                                                                                onChange={val => helpers.setFieldValue("missionDateFin", val)}
                                                                                placeholder={"Date de fin"}
                                                                                classNames={"rounded-0 rounded-end"}
                                                                           />
                                                                      </div>
                                                                      {(helpers.errors.missionDateDebut || helpers.errors.missionDateFin) && (
                                                                           <div className={"text-danger"}>Période requise ou incorrecte</div>
                                                                      )}
                                                                 </div>
                                                            </div>

                                                            {/* Base de facturation */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Base de facturation annuelle</label>

                                                                 <div className="col-lg-8">
                                                                      <MySelectGetAllDataFromServer
                                                                           name={"missionBaseFacturationAnnuelle"}
                                                                           value={helpers.values.missionBaseFacturationAnnuelle}
                                                                           defaultOptions={
                                                                                data.consultant.baseFacturationAnnuelle
                                                                                     ? [
                                                                                            {
                                                                                                 value: data.consultant.baseFacturationAnnuelle.id as number,
                                                                                                 label: data.consultant.baseFacturationAnnuelle.nbJoursPlusPlus!.toString(),
                                                                                            },
                                                                                       ]
                                                                                     : []
                                                                           }
                                                                           url={CONST_API_UTILS_GET_BASES_FACTU_ENDPOINT}
                                                                           isInvalid={!!helpers.errors.missionBaseFacturationAnnuelle}
                                                                           onChange={val => helpers.setFieldValue("missionBaseFacturationAnnuelle", val)}
                                                                      />
                                                                      {helpers.errors.missionBaseFacturationAnnuelle && (
                                                                           <div className={"text-danger"}>{helpers.errors.missionBaseFacturationAnnuelle}</div>
                                                                      )}
                                                                 </div>
                                                            </div>

                                                            {/* Lieu de travail */}
                                                            <h5 className={"mt-8 mb-4"}>Lieu de travail</h5>
                                                            <div className="separator separator-dashed mb-6" />

                                                            {/* Adresse */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Pays</label>

                                                                 <div className="col-lg-8">
                                                                      <MySelectGetAllDataFromServer
                                                                           name={"missionPays"}
                                                                           value={helpers.values.missionPays}
                                                                           defaultOptions={
                                                                                data.mission?.travailPays
                                                                                     ? [
                                                                                            {
                                                                                                 value: data.mission.travailPays.id as number,
                                                                                                 label: data.mission.travailPays.nomFr as string,
                                                                                            },
                                                                                       ]
                                                                                     : []
                                                                           }
                                                                           url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                                           isInvalid={!!helpers.errors.missionPays}
                                                                           onChange={val => helpers.setFieldValue("missionPays", val)}
                                                                      />
                                                                      {helpers.errors.missionPays && <div className={"text-danger"}>{helpers.errors.missionPays}</div>}
                                                                 </div>
                                                            </div>

                                                            {/* Adresse */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Adresse</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"missionAdresse"}
                                                                           value={helpers.values.missionAdresse}
                                                                           isInvalid={!!helpers.errors.missionAdresse}
                                                                           onChange={val => helpers.setFieldValue("missionAdresse", val)}
                                                                           placeholder={"Adresse"}
                                                                      />
                                                                      {helpers.errors.missionAdresse && <div className={"text-danger"}>{helpers.errors.missionAdresse}</div>}
                                                                 </div>
                                                            </div>

                                                            {/* Ville */}
                                                            {helpers.values.missionPays === CONST_PAYS_ID_FRANCE ? (
                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                      <div className="col-lg-8">
                                                                           <MySelectSearchDataFromServer
                                                                                name={"missionVilleFrance"}
                                                                                value={helpers.values.missionVilleFrance}
                                                                                url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                                method={"GET"}
                                                                                minimumLengthSearch={2}
                                                                                placeholder={"Choisissez la ville"}
                                                                                defaultOptions={
                                                                                     data.mission?.travailvillle
                                                                                          ? [
                                                                                                 {
                                                                                                      value: data.mission.travailvillle.id as number,
                                                                                                      label: data.mission.travailvillle.nom as string,
                                                                                                 },
                                                                                            ]
                                                                                          : []
                                                                                }
                                                                                isInvalid={!!helpers.errors.missionVilleFrance}
                                                                                onChange={val => helpers.setFieldValue("missionVilleFrance", val)}
                                                                           />
                                                                           {helpers.errors.missionVilleFrance && (
                                                                                <div className={"text-danger"}>{helpers.errors.missionVilleFrance}</div>
                                                                           )}
                                                                      </div>
                                                                 </div>
                                                            ) : (
                                                                 <div className="row mb-4">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                      <div className="col-lg-8">
                                                                           <MyInputField
                                                                                name={"missionVille"}
                                                                                value={helpers.values.missionVille}
                                                                                isInvalid={!!helpers.errors.missionVille}
                                                                                onChange={val => helpers.setFieldValue("missionVille", val)}
                                                                                placeholder={"Ville"}
                                                                           />
                                                                           {helpers.errors.missionVille && <div className={"text-danger"}>{helpers.errors.missionVille}</div>}
                                                                      </div>
                                                                 </div>
                                                            )}

                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Code postal</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"missionCodePostal"}
                                                                           value={helpers.values.missionCodePostal}
                                                                           inputMode={"numeric"}
                                                                           isInvalid={!!helpers.errors.missionCodePostal}
                                                                           onChange={val => helpers.setFieldValue("missionCodePostal", val)}
                                                                           placeholder={"Code postal"}
                                                                      />
                                                                      {helpers.errors.missionCodePostal && <div className={"text-danger"}>{helpers.errors.missionCodePostal}</div>}
                                                                 </div>
                                                            </div>

                                                            {/* Voulez-vous inclure une machine? */}
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous inclure une machine?</label>

                                                                 <div className="col-lg-8 d-flex align-items-center">
                                                                      <MyCheckBoxField
                                                                           name={"isMachineIncluded"}
                                                                           value={helpers.values.isMachineIncluded}
                                                                           isInvalid={!!helpers.errors.isMachineIncluded}
                                                                           onChange={val => helpers.setFieldValue("isMachineIncluded", val)}
                                                                      />
                                                                      {helpers.errors.isMachineIncluded && <div className={"text-danger"}>{helpers.errors.isMachineIncluded}</div>}
                                                                 </div>
                                                            </div>

                                                            {helpers.values.isMachineIncluded && (
                                                                 <>
                                                                      {/* Machine */}
                                                                      <div className="row mb-4">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez une machine</label>

                                                                           <div className="col-lg-8">
                                                                                <MySelectGetAllDataFromServer
                                                                                     name={"machine"}
                                                                                     value={helpers.values.machine}
                                                                                     defaultOptions={
                                                                                          data.consultant.machine
                                                                                               ? [
                                                                                                      {
                                                                                                           value: data.consultant.machine.id as number,
                                                                                                           label: data.consultant.machine.nomPlusPlus as string,
                                                                                                      },
                                                                                                 ]
                                                                                               : []
                                                                                     }
                                                                                     isInvalid={!!helpers.errors.machine}
                                                                                     onChange={val => helpers.setFieldValue("machine", val)}
                                                                                     url={CONST_API_UTILS_GET_MACHINES_ENDPOINT}
                                                                                />
                                                                                <div className="d-flex justify-content-between flex-row-reverse mt-1">
                                                                                     {helpers.errors.machine && <div className={"text-danger"}>{helpers.errors.machine}</div>}
                                                                                     <div className="text-primary fw-bold cursor-pointer" onClick={() => setIsAddingMachine(true)}>
                                                                                          Ajouter <i className={"fas fa-plus-circle text-primary"} />
                                                                                     </div>
                                                                                </div>
                                                                           </div>
                                                                      </div>
                                                                 </>
                                                            )}
                                                       </div>
                                                  </>
                                             )}

                                             <div className="separator bg-primary my-5" />

                                             {/* Submit button */}
                                             <div className={"d-flex justify-content-between"}>
                                                  <button
                                                       className={"btn btn-sm btn-warning d-inline-flex align-items-center"}
                                                       type={"button"}
                                                       disabled={helpers.isSubmitting}
                                                       onClick={() => context.setNavigationStep("contract")}
                                                  >
                                                       <span className={"fas fa-arrow-left fs-4 me-1"}></span> Précédant
                                                  </button>
                                                  <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? (
                                                            <>
                                                                 Sauvegarder et suivant <span className={"fas fa-arrow-right fs-4 ms-1"}></span>
                                                            </>
                                                       ) : (
                                                            <MySimpleSpinner size={"sm"} />
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   </MyCard.Body>
                              </MyCard>
                         )
                    }}
               </Formik>

               {/* Modal add machine */}
               {isAddingMachine && (
                    <MyModal title={<>Nouvelle machine</>} show={true} handleClose={() => setIsAddingMachine(false)}>
                         <AddMachineForm callback={() => setIsAddingMachine(false)} />
                    </MyModal>
               )}
          </>
     )
}

export default DemandeEmbaucheDetailsInfoClientSalarie
