import { toAbsoluteUrl } from "@common-metronic/helpers"
import React, { useState } from "react"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { usePersonnelDetailsContext } from "../PersonnelDetails"
import MyAlert from "@common-utils/MyAlert"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { personnelSetNfcRequest } from "../../core/_requests"
import { IGetPersonnelDetailsResponseModel } from "../../core/_models"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import MyModal from "@common-utils/MyModal"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { useQueryClient } from "react-query"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_AVAILABLE_NFC_ENDPOINT } from "../../../../endpoints"
import swal from "sweetalert2"

const Nfc = () => {
     const queryClient = useQueryClient()
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data
     const [isNfcLoading, setIsNfcLoading] = useState<boolean>(true)
     const [isSettingNfc, setIsSettingNfc] = useState<boolean>(false)

     const FormSetNfcInModal = () => {
          interface IBingNfcRequestModel {
               nfc?: number
          }

          // Form schema
          const schema = Yup.object().shape({
               nfc: Yup.number().required().label(`"NFC"`),
          })

          // Init values
          const initialValues: IBingNfcRequestModel = {
               nfc: details?.personnel.nfc?.id,
          }

          // Handle submit
          function handleSubmit(values: IBingNfcRequestModel, helpers: FormikHelpers<IBingNfcRequestModel>) {
               helpers.setStatus(null)

               personnelSetNfcRequest(details?.personnel.id as number, values.nfc as number)
                    .then(r => {
                         toast.success(details?.personnel.nfc ? "La carte NFC a bien été modifiée." : "La carte NFC a bien été liée.")

                         queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                              context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                              (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                                   if (prev) {
                                        prev.personnel = {
                                             ...prev.personnel,
                                             nfc: r.data.nfc,
                                        }
                                   }
                                   return prev
                              }
                         )
                         setIsSettingNfc(false)
                    })
                    .catch((e: AxiosError) => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               <>
                    <MyModal show={true} handleClose={() => setIsSettingNfc(false)} title={<>Choisissez une carte</>}>
                         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                              {helpers => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {helpers.status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {helpers.status}
                                                  </MyAlert>
                                             )}

                                             <MySelectGetAllDataFromServer
                                                  name={"nfc"}
                                                  value={helpers.values.nfc}
                                                  url={CONST_API_UTILS_GET_AVAILABLE_NFC_ENDPOINT}
                                                  defaultOptions={
                                                       details?.personnel.nfc
                                                            ? [
                                                                   {
                                                                        value: details?.personnel.nfc.id as number,
                                                                        label: `Carte n°${details?.personnel.nfc.referenceExterne}`,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  method={"GET"}
                                                  payload={{ excluded_ids: [details?.personnel.nfc?.id] }}
                                                  isInvalid={!!helpers.errors.nfc}
                                                  onChange={val => helpers.setFieldValue("nfc", val)}
                                                  placeholder={"Choisissez une carte"}
                                             />
                                             {helpers.errors.nfc && <div className={"text-danger"}>{helpers.errors.nfc}</div>}

                                             <div className="d-flex justify-content-end mt-5">
                                                  <button
                                                       type={"button"}
                                                       className={"btn btn-outline btn-active-secondary btn-sm me-2"}
                                                       onClick={() => setIsSettingNfc(false)}
                                                       disabled={helpers.isSubmitting}
                                                  >
                                                       Annuler
                                                  </button>
                                                  <button type={"submit"} className={"btn btn-primary btn-sm"} disabled={helpers.isSubmitting}>
                                                       {!helpers.isSubmitting ? <>Lier</> : <MySimpleSpinner size={"sm"} />}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </MyModal>
               </>
          )
     }

     function retirerNfc() {
          swal.fire({
               icon: "warning",
               text: `Voulez-vous vraiment retirer la carte NFC de ${details?.personnel.prenomNom} ?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return personnelSetNfcRequest(details?.personnel.id as number, null)
                         .then(() => {
                              queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                                   (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                                        if (prev) {
                                             prev.personnel = {
                                                  ...prev.personnel,
                                                  nfc: null,
                                             }
                                        }
                                        return prev
                                   }
                              )
                              toast.success("La carte NFC a bien été retirée.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className="bg-body p-5">
                    {details?.personnel.nfc && (
                         <>
                              <h5 className={"text-center"}>Carte n°{details?.personnel?.nfc?.referenceExterne}</h5>
                              <div className="d-flex justify-content-center mb-2 rounded">
                                   <MyLoadingOverlay isActive={isNfcLoading} styles={{ height: "250px", width: "400px" }}>
                                        <div className={"position-relative"}>
                                             <img
                                                  className={"rounded shadow-sm"}
                                                  src={toAbsoluteUrl("/media/misc/nfc/nfc_blank.svg")}
                                                  alt="NFC"
                                                  style={{ height: "250px", width: "400px" }}
                                                  onLoad={() => setIsNfcLoading(false)}
                                             />
                                             <img
                                                  className={"position-absolute"}
                                                  src={toAbsoluteUrl(`/media/misc/nfc/qr-codes/${details?.personnel.nfc.referenceInterne}.svg`)}
                                                  alt="NFC"
                                                  style={{ height: "110px", width: "100px", right: 18, top: 70 }}
                                                  onLoad={() => setIsNfcLoading(false)}
                                             />
                                             <span className={"position-absolute text-black"} style={{ left: 5, bottom: 5, fontSize: "0.6rem" }}>
                                                  {details.personnel.nfc.referenceExterne}
                                             </span>
                                        </div>
                                   </MyLoadingOverlay>
                              </div>
                              <div className="d-flex justify-content-center">
                                   <button className={"btn btn-sm btn-primary me-2"} onClick={() => setIsSettingNfc(true)}>
                                        Changer
                                   </button>
                                   <button className={"btn btn-sm btn-danger"} onClick={retirerNfc}>
                                        Retirer
                                   </button>
                              </div>
                         </>
                    )}

                    {!details?.personnel.nfc && (
                         <>
                              <MyAlert type={"primary"}>Aucune carte NFC n'est liée à {details?.personnel.prenomNom}.</MyAlert>
                              <div className={"d-flex justify-content-center mt-4"}>
                                   <button type={"button"} className={"btn btn-sm btn-success py-2 px-3"} onClick={() => setIsSettingNfc(true)}>
                                        <i className={"fad fa-link-simple fs-3"} /> <br />
                                        Lier une carte NFC
                                   </button>
                              </div>
                         </>
                    )}
               </div>

               {isSettingNfc && <FormSetNfcInModal />}
          </>
     )
}

export default Nfc
