import React, { useRef, useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import MyTooltip from "@common-utils/MyTooltip"
import { IGetAnnoncesResponseItemModel } from "./core/_models"
import { useAnnoncesListingContext } from "./AnnoncesListing"
import { useAnnoncesListingListContext } from "./_AnnoncesListingList"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import {
     CONST_ANNONCE_ETAT_ACTIVE,
     CONST_ANNONCE_ETAT_ARCHIVED,
     CONST_ENTITE_CODE_AIRFRAME,
     CONST_ENTITE_CODE_AIRSYS,
     CONST_ENTITE_CODE_CTS,
     CONST_ENTITE_CODE_GS,
     CONST_ENTITE_CODE_IT,
     CONST_ENTITE_CODE_NATESYS,
     CONST_ENTITE_CODE_NEO,
     CONST_ENTITE_CODE_NORD,
     CONST_ENTITE_CODE_SENSE,
     CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_PASVUPARMOI,
} from "@common-constants/*"
import moment from "moment"
import { Link, useNavigate } from "react-router-dom"
import { generateTalentplugUrlEditionAnnonceRequest } from "../core/_requests"
import { toast } from "react-toastify"
import { OffreModel } from "@common-models/*"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const navigate = useNavigate()
     const globalContext = useAnnoncesListingContext()
     const listContext = useAnnoncesListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const checkboxInputRef = useRef<any>(null)

     function handleOnCheckItem() {
          const isItemChecked = globalContext.isBulkSelectionEnabled.checkedElementsInPage.find((item_: OffreModel) => item_.id === item.annonce.id)
          if (!isItemChecked) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.annonce],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter((item_: OffreModel) => item_.id !== item.annonce.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     const ActionsIcons = () => {
          const publishedAt = moment(item.annonce.publishedAt)
          const dateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

          return (
               <>
                    {/* Si non différé */}
                    {publishedAt.diff(dateToday, "days") <= 0 && item.annonce.etat !== CONST_ANNONCE_ETAT_ARCHIVED && (
                         <>
                              <div>
                                   <MyTooltip title={"Republier"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => globalContext.setAnnoncesToRepublish([item.annonce])}
                                        >
                                             <i className="fas fa-globe fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                              <div>
                                   <MyTooltip title={"Archiver"}>
                                        <button
                                             type={"button"}
                                             className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-2`}
                                             onClick={() => globalContext.setAnnoncesToArchiver([item.annonce])}
                                        >
                                             <i className="fas fa-box-archive fs-4" />
                                        </button>
                                   </MyTooltip>
                              </div>
                         </>
                    )}
                    <div>
                         <MyTooltip title={"Changer de responsable"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-primary btn-sm btn-icon rounded-circle mb-2`}
                                   onClick={() => globalContext.setAnnoncesChangementResponsable([item.annonce])}
                              >
                                   <i className="fas fa-user fs-4" />
                              </button>
                         </MyTooltip>
                    </div>
                    <div>
                         <MyTooltip title={"Editer sur le site partenaire"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-warning btn-sm btn-icon rounded-circle mb-2`}
                                   onClick={handleGenerateUrlEditionTalentplug}
                              >
                                   <i className="fas fa-edit fs-4" />
                              </button>
                         </MyTooltip>
                    </div>
                    <div>
                         <MyTooltip title={"Cloner"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle`}
                                   onClick={() => navigate(`/recrutement/annonces/${item.annonce.id}/clone`)}
                              >
                                   <i className="fas fa-clone fs-4" />
                              </button>
                         </MyTooltip>
                    </div>
               </>
          )
     }

     const AnnonceEtatLabel = () => {
          const publishedAt = moment(item.annonce.publishedAt)
          const dateToday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

          if (item.annonce.etat === CONST_ANNONCE_ETAT_ACTIVE) {
               if (dateToday.diff(publishedAt, "days") > 15) {
                    return <span className="badge badge-danger">Active {">"} 15 jrs</span>
               } else if (publishedAt.diff(dateToday, "days") <= 0 && publishedAt.diff(dateToday, "days") > -1) {
                    // En cours si la date de publication n'a pas dépassé un jour
                    return <span className="badge badge-primary">En cours</span>
               } else if (publishedAt.diff(dateToday, "days") > 0) {
                    return <span className="badge badge-info">Différé</span>
               } else {
                    return <span className="badge badge-success">Active</span>
               }
          } else {
               return <span className={"badge badge-dark"}>{item.annonce.etatLabel}</span>
          }
     }

     const avatarSrcAccordingToEntity = (() => {
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_AIRFRAME) return toAbsoluteUrl("/media/logos/cts/logo-airframe.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_AIRSYS) return toAbsoluteUrl("/media/logos/cts/logo-airsys.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_CTS) return toAbsoluteUrl("/media/logos/cts/logo-cts.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_GS) return toAbsoluteUrl("/media/logos/cts/logo-gs.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_IT) return toAbsoluteUrl("/media/logos/cts/logo-it.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_NATESYS) return toAbsoluteUrl("/media/logos/cts/logo-natesys.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_NEO) return toAbsoluteUrl("/media/logos/cts/logo-neo.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_NORD) return toAbsoluteUrl("/media/logos/cts/logo-nord.png")
          if (item.annonce.entite?.code === CONST_ENTITE_CODE_SENSE) return toAbsoluteUrl("/media/svg/files/blank-image.svg")

          return toAbsoluteUrl("/media/svg/files/blank-image.svg")
     })()

     function handleGenerateUrlEditionTalentplug(e: any) {
          e.target.disabled = true
          toast.info("Redirection en cours, veuillez patienter ...", { autoClose: false })
          generateTalentplugUrlEditionAnnonceRequest(item.annonce.id as number)
               .then(r => {
                    if (r.data.redirectUrl) {
                         window.location.href = r.data.redirectUrl
                    } else {
                         toast.error("Impossible d'éditer cette offre.")
                    }
               })
               .catch(e => {
                    toast.dismiss()
                    toast.error(e?.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, middle & left side*/}
                              <div className={"d-flex justify-content-between"}>
                                   <div className={"d-flex align-items-center"}>
                                        {/* checkbox */}
                                        {globalContext.isBulkSelectionEnabled.enabled && (
                                             <div
                                                  className={
                                                       "form-check form-check-custom form-check-solid form-check-sm pe-6 ps-2 pt-4 pb-4 not-concerned-by-preview cursor-pointer"
                                                  }
                                             >
                                                  <input
                                                       type="checkbox"
                                                       className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                       style={{ height: "2rem", width: "2rem" }}
                                                       checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.find(
                                                            (item_: OffreModel) => item.annonce.id === item_.id
                                                       )}
                                                       onChange={() => handleOnCheckItem()}
                                                       ref={checkboxInputRef}
                                                  />
                                             </div>
                                        )}

                                        {/* Left */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <img
                                                  src={avatarSrcAccordingToEntity}
                                                  onLoad={() => setIsAvatarLoading(false)}
                                                  style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                  alt="Avatar"
                                             />
                                        </MyLoadingOverlay>

                                        {/* Middle */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"fw-bold fs-5"}>{item.annonce.intitule}</span>
                                             </div>
                                             <div>Publiée le {item.annonce.publishedAt?.format("dddd D MMMM YYYY")}</div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        <AnnonceEtatLabel />
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date publication */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-briefcase fs-5 w-25px" />
                                                  {item.annonce.businessUnitMetier?.nom}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" />
                                                  {item.annonce.personnel?.prenomNom}
                                             </div>

                                             {/* Localisation */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-location-dot fs-5 w-25px" />
                                                  {item.annonce.ville ? item.annonce?.ville.nom : <span className={"text-muted"}>Localisation inconnue</span>}
                                             </div>

                                             {/* Référence interne */}
                                             {item.annonce.reference && (
                                                  <div className={`d-flex align-items-center mb-2`}>
                                                       <span className="fas fa-search fs-5 w-25px" />
                                                       {item.annonce.reference} (interne)
                                                  </div>
                                             )}

                                             {/* Référence interne */}
                                             {item.annonce.reference_talentplug && (
                                                  <div className={`d-flex align-items-center mb-2`}>
                                                       <span className="fas fa-search fs-5 w-25px" />
                                                       {item.annonce.reference_talentplug} (externe)
                                                  </div>
                                             )}
                                        </div>
                                        <div className="col text-end">
                                             <ActionsIcons />
                                        </div>
                                   </div>
                                   <div className={"separator border-primary"} />

                                   <div className={"d-flex justify-content-center p-4"}>
                                        {item.totalCandidatures === 0 && "Aucune candidature"}
                                        {item.totalCandidatures > 0 && (
                                             <>
                                                  <Link
                                                       to={"/recrutement/candidats/search/results"}
                                                       state={{ annonce_ids: [item.annonce.id] }}
                                                       className={"btn btn-sm btn-light-dark me-4 px-2 py-1"}
                                                  >
                                                       {item.totalCandidatures === 1 && `1 candidature au total`}
                                                       {item.totalCandidatures > 1 && `${item.totalCandidatures} candidatures au total`}
                                                  </Link>
                                                  {item.totalUnseenCandidaturesByConnectedUser > 0 && (
                                                       <Link
                                                            to={"/recrutement/candidats/search/results"}
                                                            state={{
                                                                 annonce_ids: [item.annonce.id],
                                                                 seenOrNot: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_PASVUPARMOI,
                                                            }}
                                                            className={"btn btn-sm btn-light-warning px-2 py-1 text-dark"}
                                                       >
                                                            {item.totalUnseenCandidaturesByConnectedUser === 1 && `1 candidature non vue`}
                                                            {item.totalUnseenCandidaturesByConnectedUser > 1 &&
                                                                 `${item.totalUnseenCandidaturesByConnectedUser} candidatures non consultées`}
                                                       </Link>
                                                  )}
                                             </>
                                        )}
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const AnnoncesListingListItem = ({ item, index }: { item: IGetAnnoncesResponseItemModel; index: number }) => {
     const context = useAnnoncesListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.annonce.id === item.annonce.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.annonce.id === item.annonce.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.annonce.id !== item.annonce.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetAnnoncesResponseItemModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default AnnoncesListingListItem
