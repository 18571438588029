import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import React, { useState } from "react"
import MyAlert from "@common-utils/MyAlert"
import { useFournisseurListingContext } from "../FournisseurListing"
import { IItemResponseModel } from "../core/_models"
import { IConsultantModel } from "@common-models/*"
import { useThemeMode } from "@common-metronic/partials"
import { Link } from "react-router-dom"

const Consultants = () => {
     const globalContext = useFournisseurListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.fournisseur?.id === selectedItemInPreview.fournisseur?.id) as IItemResponseModel

     return (
          <>
               <div className="d-flex justify-content-between">
                    <h4>Consultants</h4>
               </div>

               <div className="separator separator-dashed mt-3 mb-5" />

               {itemInPreview.fournisseur.consultantSTs!.length === 0 && <MyAlert type={"primary"}>Aucun consultant de disponible.</MyAlert>}
               {itemInPreview.fournisseur.consultantSTs!.length > 0 && (
                    <div className="d-flex flex-column gap-5">
                         {itemInPreview.fournisseur.consultantSTs!.map((st, key) => (
                              <Item consultant={st.consultant!} key={key} />
                         ))}
                    </div>
               )}
          </>
     )
}

const Item = ({ consultant }: { consultant: IConsultantModel }) => {
     const { mode } = useThemeMode()

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!consultant.candidat?.user?.avatarGoogleDriveId)

     return (
          <div className={`p-4 d-flex align-items-center bg-light rounded-2`}>
               {/* Avatar */}
               <div>
                    <div className={"d-flex justify-content-center"}>
                         <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                              {/* Avatar */}
                              <img
                                   src={
                                        consultant?.candidat?.user?.avatarGoogleDriveId
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(consultant?.candidat?.user?.avatarGoogleDriveId)
                                             : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                   }
                                   onLoad={() => setIsAvatarLoading(false)}
                                   style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                   alt="Avatar"
                              />
                         </MyLoadingOverlay>
                    </div>
               </div>
               <div className={"flex-grow-1 ms-4"}>
                    <div className={"d-flex align-items-center"}>
                         <div>
                              <span className={"fs-5 fw-bold me-2 d-flex align-items-center"}>
                                   <Link to={`/business/consultants/${consultant.id}/details`} className={"text-dark text-hover-primary"}>
                                        {consultant?.prenomNom}
                                   </Link>
                              </span>
                         </div>
                         <div className={"ms-auto"}>
                              <div className={`badge badge-dark`}>{consultant.metier?.nom}</div>
                         </div>
                    </div>
                    <div className={"d-flex align-items-center"}>
                         <a href={`mailto:${consultant.mailPro}`}>{consultant.mailPro}</a>
                         <div className={"ms-auto mt-1"}>
                              <div className={`badge badge-primary`}>
                                   <span>{consultant.entite?.nom}</span>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default Consultants
