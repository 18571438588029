import { IConsultantSuiviChangementMissionSalarieRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IClientPropalModel, IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_MIME_APPLICATION_DOCX, CONST_PAYS_ID_FRANCE } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import { consultantSuiviChangementMissionSalarieRequest } from "./core/_requests"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import * as Yup from "yup"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_PAYS_ENDPOINT, CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT, CONST_API_UTILS_GET_VILLES_URL_ENDPOINT } from "../../../../endpoints"
import { getClientPropalSimpleDetailsRequest } from "../../../client/ptf/core/_requests"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectSearchDataFromServer from "@common-utils/fields/MySelectSearchDataFromServer"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"

const ConsultantSuiviChangementMissionSalarie = ({
     consultant,
     annulerCallback = () => {},
     onSubmitCallback = () => {},
     setModalNoPaddingCallback = () => {},
     setModalSizeCallback = () => {},
}: IPropsModel) => {
     const [propalSimpleInfo, setPropalSimpleInfo] = useState<{ loading: boolean; data: IClientPropalModel | null }>({
          loading: false,
          data: null,
     })
     const [ordreMission, setOrdreMission] = useState<{
          loading: boolean
          googleDriveID: string
     }>()

     // Form schema
     const schema = Yup.object().shape({
          clientPropal: Yup.string().label(`"PTF"`).required(),
          ordreMissionUploadedOrGenerated: Yup.string().required().label(`"Génération ou upload"`),
          ordreMission: Yup.mixed()
               .test("fileFormat", "Seul le format Word est autorisé.", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_APPLICATION_DOCX].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               })
               .label(`"Ordre de mission"`)
               .when("ordreMissionUploadedOrGenerated", {
                    is: "UPLOADED",
                    then: schema => schema.required(),
               }),
          dateDebut: Yup.string().required().label(`"Date de changement"`),
          dateFin: Yup.string().required().label(`"Date de fin"`),
          adresse: Yup.string().required().label(`"Adresse postale"`),
          ville: Yup.string()

               .label(`"Ville"`)
               .when("pays", {
                    is: val => val && val !== CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          villeFrance: Yup.number()

               .label(`"Ville"`)
               .when("pays", {
                    is: CONST_PAYS_ID_FRANCE,
                    then: schema => schema.required(),
               }),
          codePostal: Yup.number().required().label(`"Code postal"`),
          pays: Yup.number().required().label(`"Pays"`),
     })

     // Form initial values
     const initialValues: IConsultantSuiviChangementMissionSalarieRequestModel = {}

     // Handle submit
     function handleSubmit(values: IConsultantSuiviChangementMissionSalarieRequestModel, helpers: FormikHelpers<IConsultantSuiviChangementMissionSalarieRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviChangementMissionSalarieRequest(consultant.id as number, values)
               .then(r => {
                    if (values.ordreMissionUploadedOrGenerated === "UPLOADED") {
                         onSubmitCallback()
                         toast.success(`${consultant.prenomNom} a bien changé sa mission.`)
                    } else {
                         setModalNoPaddingCallback(true)
                         setModalSizeCallback("fullscreen")
                         setOrdreMission({ loading: true, googleDriveID: r.data.ordreDeMissionGoogleDriveID })
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Handles change PTF
     function handleChangeClientPropal(ptf_id: number | undefined | null) {
          if (ptf_id) {
               setPropalSimpleInfo(prev => ({ ...prev, loading: true }))
               getClientPropalSimpleDetailsRequest(ptf_id)
                    .then(r => {
                         setPropalSimpleInfo({ loading: false, data: r.data })
                    })
                    .catch(() => {
                         setPropalSimpleInfo(prev => ({ ...prev, loading: false }))
                         toast.error("Un problème est survenu lors de la récupération des informations de la PTF sélectionnée.", { autoClose: false })
                    })
          }
     }

     if (ordreMission) {
          return (
               <>
                    <div className="text-center mt-2">
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary"}
                              onClick={() => {
                                   onSubmitCallback()
                                   toast.success(`${consultant.prenomNom} a bien changé sa mission.`)
                              }}
                         >
                              TERMINER <i className={"fas fa-check-circle"} />
                         </button>
                    </div>
                    <MyLoadingOverlay isActive={ordreMission.loading}>
                         <iframe
                              onLoad={() => setOrdreMission(prev => prev && { ...prev, loading: false })}
                              src={FileAndDriveHelper.getUrlOfDriveFileEditMode(ordreMission.googleDriveID)}
                              className={"vh-100 w-100"}
                         />
                    </MyLoadingOverlay>
               </>
          )
     } else {
          return (
               <>
                    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
                         {helpers => {
                              console.log(helpers.errors)
                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}

                                        <div className="row mb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Date de changement</label>

                                             <div className="col-lg-8">
                                                  <MyDateDayMonthYearField
                                                       name={"dateDebut"}
                                                       value={helpers.values.dateDebut}
                                                       isInvalid={!!helpers.errors.dateDebut}
                                                       maxDate={helpers.values.dateFin}
                                                       onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                       placeholder={"Date de changement"}
                                                       classNames={"rounded-0 rounded-start"}
                                                  />
                                                  {helpers.errors.dateDebut && <div className={"text-danger"}>{helpers.errors.dateDebut}</div>}
                                             </div>
                                        </div>

                                        <div className="separator separator-dashed mb-6" />

                                        {/* PTF */}
                                        <div className="border border-dashed p-4 mb-4">
                                             <h4 className={"mb-4"}>N° d'affaire</h4>
                                             <div className="separator separator-dashed mb-6" />
                                             <MyAlert type={"primary"} classNames={"mb-4"}>
                                                  Assurez-vous d'avoir bien créé et validé votre PTF afin qu'elle puisse appraître dans a liste.
                                             </MyAlert>
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez la PTF</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"clientPropal"}
                                                            value={helpers.values.clientPropal}
                                                            isInvalid={!!helpers.errors.clientPropal}
                                                            onChange={val => {
                                                                 helpers.setFieldValue("clientPropal", val).then(() => {
                                                                      handleChangeClientPropal(val as number)
                                                                 })
                                                            }}
                                                            url={CONST_API_UTILS_GET_PTFS_NON_MERE_ENDPOINT}
                                                            placeholder={"Choisissez la PTF"}
                                                            isClearable={false}
                                                       />
                                                       {helpers.errors.clientPropal && <div className={"text-danger"}>{helpers.errors.clientPropal}</div>}
                                                  </div>
                                             </div>

                                             {propalSimpleInfo.loading && (
                                                  <div className={"text-center p-5"}>
                                                       <MySimpleSpinner size={"md"} />{" "}
                                                  </div>
                                             )}

                                             {/* Détails PTF (loaded) */}
                                             {helpers.values.clientPropal && propalSimpleInfo.data && !propalSimpleInfo.loading && (
                                                  <div className={"p-4 border border-dashed text-gray-600 mb-4"}>
                                                       <h4 className={"mb-4 text-gray-600"}>
                                                            Détails de la {propalSimpleInfo.data.reference}
                                                            <i className="fas fa-info-circle fs-3 float-end" />
                                                       </h4>
                                                       <div className="separator separator-dashed mb-6" />

                                                       <p>Client: {propalSimpleInfo.data.client?.nom}</p>
                                                       <p>Responsable client: {propalSimpleInfo.data.clientResponsable?.prenomNom}</p>
                                                       {propalSimpleInfo.data.type === "TYPE_ASSISTANCE_TECHNIQUE" && <p>TJM: {propalSimpleInfo.data.tjm}€</p>}
                                                       <p>Type: {propalSimpleInfo.data.typeLabel}</p>
                                                  </div>
                                             )}
                                        </div>

                                        {/* ODM */}
                                        <div className="border border-dashed p-4 mb-4">
                                             <h4 className={"mb-4"}>ODM</h4>
                                             <div className="separator separator-dashed mb-6" />
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MyRadioWithLabelAndDescriptionField
                                                            name={"ordreMissionUploadedOrGenerated"}
                                                            value={"UPLOADED"}
                                                            isChecked={helpers.values.ordreMissionUploadedOrGenerated === "UPLOADED"}
                                                            label={"Saisie manuelle"}
                                                            description={"Joignez votre propre document"}
                                                            iconFontAwesomeClassName={"upload"}
                                                            color={"primary"}
                                                            isInvalid={!!helpers.errors.ordreMissionUploadedOrGenerated}
                                                            onChange={val => helpers.setFieldValue("ordreMissionUploadedOrGenerated", val)}
                                                       />
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyRadioWithLabelAndDescriptionField
                                                            name={"ordreMissionUploadedOrGenerated"}
                                                            value={"GENERATED"}
                                                            isChecked={helpers.values.ordreMissionUploadedOrGenerated === "GENERATED"}
                                                            label={
                                                                 <span>
                                                                      Génération automatique <br /> ( recommandé )
                                                                 </span>
                                                            }
                                                            description={"Générer un ODM pré-rempli éditable en ligne"}
                                                            iconFontAwesomeClassName={"bolt"}
                                                            color={"success"}
                                                            isInvalid={!!helpers.errors.ordreMissionUploadedOrGenerated}
                                                            onChange={val => helpers.setFieldValue("ordreMissionUploadedOrGenerated", val)}
                                                       />
                                                  </div>
                                             </div>

                                             {/* ODM */}
                                             {helpers.values.ordreMissionUploadedOrGenerated === "UPLOADED" && (
                                                  <div className="row mt-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ordre de mission</label>

                                                       <div className="col-lg-8">
                                                            <MyFileField
                                                                 name={"ordreMission"}
                                                                 onChange={val => helpers.setFieldValue("ordreMission", val)}
                                                                 isInvalid={!!helpers.errors.ordreMission}
                                                                 accept={`${CONST_MIME_APPLICATION_DOCX}`}
                                                            />
                                                            <div className={"mb-2"}>
                                                                 {helpers.errors.ordreMission && <div className={"text-danger"}>{helpers.errors.ordreMission}</div>}
                                                            </div>
                                                       </div>
                                                  </div>
                                             )}

                                             {helpers.values.ordreMissionUploadedOrGenerated === "GENERATED" && (
                                                  <MyAlert type={"primary"} classNames={"mt-4"}>
                                                       <b className={"text-dark"}>Vous avez opter pour une génération automatique</b>. Une fois que vous aurez validé le formulaire,
                                                       un Ordre De Mission sera généré en se basant sur les informations saisies, et vous pourrez ensuite le modifier en ligne.
                                                  </MyAlert>
                                             )}
                                        </div>

                                        <div className={"mb-2"}>
                                             {helpers.errors.ordreMissionUploadedOrGenerated && (
                                                  <div className={"text-danger"}>{helpers.errors.ordreMissionUploadedOrGenerated}</div>
                                             )}
                                        </div>

                                        {/* MISSION */}
                                        <div className={"p-4 border border-dashed mb-4"}>
                                             <h4 className={"mb-4"}>MISSION</h4>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* Période mission */}
                                             <div className="row mb-2">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                  <div className="col-lg-8">
                                                       <div className="d-flex">
                                                            <MyDateDayMonthYearField
                                                                 name={"dateDebut"}
                                                                 value={helpers.values.dateDebut}
                                                                 isInvalid={!!helpers.errors.dateDebut}
                                                                 maxDate={helpers.values.dateFin}
                                                                 onChange={val => helpers.setFieldValue("dateDebut", val)}
                                                                 placeholder={"Date de changement"}
                                                                 classNames={"rounded-0 rounded-start"}
                                                                 isDisabled={true}
                                                            />
                                                            <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                            <MyDateDayMonthYearField
                                                                 name={"dateFin"}
                                                                 value={helpers.values.dateFin}
                                                                 minDate={helpers.values.dateDebut}
                                                                 isInvalid={!!helpers.errors.dateFin}
                                                                 onChange={val => helpers.setFieldValue("dateFin", val)}
                                                                 placeholder={"Date de fin"}
                                                                 classNames={"rounded-0 rounded-end"}
                                                            />
                                                       </div>
                                                       {(helpers.errors.dateDebut || helpers.errors.dateFin) && <div className={"text-danger"}>Période requise ou incorrecte</div>}
                                                  </div>
                                             </div>

                                             {/* Lieu de travail */}
                                             <h5 className={"mt-8 mb-4"}>Lieu de travail</h5>
                                             <div className="separator separator-dashed mb-6" />

                                             {/* Pays */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Pays</label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            name={"pays"}
                                                            value={helpers.values.pays}
                                                            url={CONST_API_UTILS_GET_PAYS_ENDPOINT}
                                                            isInvalid={!!helpers.errors.pays}
                                                            onChange={val => helpers.setFieldValue("pays", val)}
                                                       />
                                                       {helpers.errors.pays && <div className={"text-danger"}>{helpers.errors.pays}</div>}
                                                  </div>
                                             </div>

                                             {helpers.values.pays && (
                                                  <>
                                                       {/* Adresse */}
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Adresse</label>

                                                            <div className="col-lg-8">
                                                                 <MyInputField
                                                                      name={"adresse"}
                                                                      value={helpers.values.adresse}
                                                                      isInvalid={!!helpers.errors.adresse}
                                                                      onChange={val => helpers.setFieldValue("adresse", val)}
                                                                      placeholder={"Adresse"}
                                                                 />
                                                                 {helpers.errors.adresse && <div className={"text-danger"}>{helpers.errors.adresse}</div>}
                                                            </div>
                                                       </div>

                                                       {/* Ville */}
                                                       {helpers.values.pays === CONST_PAYS_ID_FRANCE ? (
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                 <div className="col-lg-8">
                                                                      <MySelectSearchDataFromServer
                                                                           name={"villeFrance"}
                                                                           value={helpers.values.villeFrance}
                                                                           url={CONST_API_UTILS_GET_VILLES_URL_ENDPOINT}
                                                                           method={"GET"}
                                                                           minimumLengthSearch={2}
                                                                           placeholder={"Choisissez la ville"}
                                                                           isInvalid={!!helpers.errors.villeFrance}
                                                                           onChange={val => helpers.setFieldValue("villeFrance", val)}
                                                                      />
                                                                      {helpers.errors.villeFrance && <div className={"text-danger"}>{helpers.errors.villeFrance}</div>}
                                                                 </div>
                                                            </div>
                                                       ) : (
                                                            <div className="row mb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Ville</label>

                                                                 <div className="col-lg-8">
                                                                      <MyInputField
                                                                           name={"ville"}
                                                                           value={helpers.values.ville}
                                                                           isInvalid={!!helpers.errors.ville}
                                                                           onChange={val => helpers.setFieldValue("ville", val)}
                                                                           placeholder={"Ville"}
                                                                      />
                                                                      {helpers.errors.ville && <div className={"text-danger"}>{helpers.errors.ville}</div>}
                                                                 </div>
                                                            </div>
                                                       )}

                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Code postal</label>

                                                            <div className="col-lg-8">
                                                                 <MyInputField
                                                                      name={"codePostal"}
                                                                      value={helpers.values.codePostal}
                                                                      inputMode={"numeric"}
                                                                      isInvalid={!!helpers.errors.codePostal}
                                                                      onChange={val => helpers.setFieldValue("codePostal", val)}
                                                                      placeholder={"Code postal"}
                                                                 />
                                                                 {helpers.errors.codePostal && <div className={"text-danger"}>{helpers.errors.codePostal}</div>}
                                                            </div>
                                                       </div>
                                                  </>
                                             )}
                                        </div>

                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  onClick={annulerCallback}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  Annuler
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? <>Valider</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </>
          )
     }
}

interface IPropsModel {
     consultant: IConsultantModel
     annulerCallback?: () => void
     onSubmitCallback?: () => void
     setModalNoPaddingCallback?: (noPadding: boolean) => void
     setModalSizeCallback?: (size: "lg" | "xl" | "fullscreen") => void
}

export default ConsultantSuiviChangementMissionSalarie
