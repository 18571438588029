import { IGetDocumentsConsultant } from "../core/_models"
import React, { useRef, useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { IFournisseurDocumentModel } from "@common-models/*"
import MyTooltip from "@common-utils/MyTooltip"
import {
     CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO,
     CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS,
     CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE,
     CONST_FOURNISSEUR_DOCUMENT_RIB,
     CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF,
} from "@common-constants/*"
import { useQueryClient } from "react-query"
import { REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS } from "./_ConsultantDetails_Documents"
import { useFournisseurDocumentActions } from "../../../../fournisseur/core/_hooks"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import MyGoogleDriveFileViewerInModal from "../../../../../components/MyDocumentViewer/MyGoogleDriveFileViewerInModal"

const ConsultantDetailsDocumentsFournisseur = ({ documents }: IConsultantDetailsDocumentsFournisseurPropsModel) => {
     const context = useConsultantDetailsContext()

     const queryClient = useQueryClient()
     const fournisseurDocumentActions = useFournisseurDocumentActions(context.detailsQuery.data?.st?.fournisseur?.id as number)

     const [documentInModal, setActiveDriveDocumentInModal] = useState<{
          driveID: string
          driveMime: string
          modalTitle: string
          isLoading: boolean
     }>()

     /* Container containing fournisseir documents */
     const DocumentsFournisseurContainer = ({ type, containerTitle, children }: { type: string; containerTitle: string; children: JSX.Element }) => {
          const refInputAddDocument = useRef<HTMLInputElement>(null)

          function handleAddDocumentCallback(val: IFournisseurDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (type === CONST_FOURNISSEUR_DOCUMENT_RIB && prev.documentsFournisseur.rib) {
                              prev.documentsFournisseur.rib = [val, ...prev.documentsFournisseur.rib]
                         }
                         if (type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS && prev.documentsFournisseur.extraitsKbis) {
                              prev.documentsFournisseur.extraitsKbis = [val, ...prev.documentsFournisseur.extraitsKbis]
                         }
                         if (type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO && prev.documentsFournisseur.assurancesPro) {
                              prev.documentsFournisseur.assurancesPro = [val, ...prev.documentsFournisseur.assurancesPro]
                         }
                         if (type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF && prev.documentsFournisseur.vigilancesUrssaf) {
                              prev.documentsFournisseur.vigilancesUrssaf = [val, ...prev.documentsFournisseur.vigilancesUrssaf]
                         }
                         if (type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE && prev.documentsFournisseur.regularitesFiscale) {
                              prev.documentsFournisseur.regularitesFiscale = [val, ...prev.documentsFournisseur.regularitesFiscale]
                         }
                    }
                    return prev
               })
          }

          return (
               <div className={"p-4 border border-dashed mb-4"}>
                    <div className="d-flex justify-content-between d-flex align-items-center mb-4">
                         <h4>{containerTitle}</h4>
                         <MyTooltip title={"Ajouter"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-success p-0 px-2 py-1 me-2"}
                                   onClick={() => refInputAddDocument.current?.click()}
                              >
                                   <i className={"fas fa-plus-circle fs-5"} />
                              </button>
                         </MyTooltip>
                         <input type={"file"} className={"d-none"} ref={refInputAddDocument} onChange={e => fournisseurDocumentActions.add(e, type, handleAddDocumentCallback)} />
                    </div>
                    <div className="separator separator-dashed mb-6" />
                    <div className="row">
                         <div className="col-sm-6 col-xxl-4 mb-4">{children}</div>
                    </div>
               </div>
          )
     }

     /* Fournisseur document */
     const DocumentFournisseurItem = ({ fournisseurDocument, classNames = "" }: { fournisseurDocument: IFournisseurDocumentModel; classNames?: string }) => {
          const refInputReplaceDocument = useRef<HTMLInputElement>(null)

          function handleDeleteDocumentCallback() {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_RIB && prev.documentsFournisseur.rib) {
                              prev.documentsFournisseur.rib = prev.documentsFournisseur.rib.filter(item => item.id === fournisseurDocument.id)
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS && prev.documentsFournisseur.extraitsKbis) {
                              prev.documentsFournisseur.extraitsKbis = prev.documentsFournisseur.extraitsKbis.filter(item => item.id === fournisseurDocument.id)
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO && prev.documentsFournisseur.assurancesPro) {
                              prev.documentsFournisseur.assurancesPro = prev.documentsFournisseur.assurancesPro.filter(item => item.id === fournisseurDocument.id)
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF && prev.documentsFournisseur.vigilancesUrssaf) {
                              prev.documentsFournisseur.vigilancesUrssaf = prev.documentsFournisseur.vigilancesUrssaf.filter(item => item.id === fournisseurDocument.id)
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE && prev.documentsFournisseur.regularitesFiscale) {
                              prev.documentsFournisseur.regularitesFiscale = prev.documentsFournisseur.regularitesFiscale.filter(item => item.id === fournisseurDocument.id)
                         }
                    }
                    return prev
               })
          }

          function handleReplaceDocumentCallback(val: IFournisseurDocumentModel) {
               queryClient.setQueryData<IGetDocumentsConsultant | undefined>(REACT_QUERY_KEY_GET_CONSULTANT_DOCUMENTS, (prev: IGetDocumentsConsultant | undefined) => {
                    if (prev) {
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_RIB && prev.documentsFournisseur.rib) {
                              prev.documentsFournisseur.rib.map(item => {
                                   if (item.id === fournisseurDocument.id) {
                                        item.googleDriveId = val.googleDriveId
                                        return item
                                   }
                              })
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS && prev.documentsFournisseur.extraitsKbis) {
                              prev.documentsFournisseur.extraitsKbis.map(item => {
                                   if (item.id === fournisseurDocument.id) {
                                        item.googleDriveId = val.googleDriveId
                                        return item
                                   }
                              })
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO && prev.documentsFournisseur.assurancesPro) {
                              prev.documentsFournisseur.assurancesPro.map(item => {
                                   if (item.id === fournisseurDocument.id) {
                                        item.googleDriveId = val.googleDriveId
                                        return item
                                   }
                              })
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF && prev.documentsFournisseur.vigilancesUrssaf) {
                              prev.documentsFournisseur.vigilancesUrssaf.map(item => {
                                   if (item.id === fournisseurDocument.id) {
                                        item.googleDriveId = val.googleDriveId
                                        return item
                                   }
                              })
                         }
                         if (fournisseurDocument.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE && prev.documentsFournisseur.regularitesFiscale) {
                              prev.documentsFournisseur.regularitesFiscale.map(item => {
                                   if (item.id === fournisseurDocument.id) {
                                        item.googleDriveId = val.googleDriveId
                                        return item
                                   }
                              })
                         }
                    }
                    return prev
               })
          }

          return (
               <div className={`d-flex ${classNames}`}>
                    <MyTooltip title={<span>Déposé le {fournisseurDocument.createdAt?.format("dddd D MMMM YYYY")}</span>}>
                         <div
                              className="symbol symbol-35px cursor-pointer me-4 pe-1 border-end-dashed rounded-0 border-gray-300"
                              onClick={() =>
                                   setActiveDriveDocumentInModal({
                                        modalTitle: `${fournisseurDocument.typeLabel}`,
                                        driveID: fournisseurDocument.googleDriveId as string,
                                        driveMime: fournisseurDocument.googleDriveMimeType as string,
                                        isLoading: true,
                                   })
                              }
                         >
                              <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-light-show" alt="" />
                              <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-dark-show" alt="" />
                         </div>
                    </MyTooltip>
                    <div className={"d-flex align-items-center"}>
                         <button
                              type={"button"}
                              className={"btn btn-sm btn-primary p-0 px-2 py-1 me-2 d-flex align-items-center"}
                              onClick={() =>
                                   setActiveDriveDocumentInModal({
                                        modalTitle: `${fournisseurDocument.typeLabel}`,
                                        driveID: fournisseurDocument.googleDriveId as string,
                                        driveMime: fournisseurDocument.googleDriveMimeType as string,
                                        isLoading: true,
                                   })
                              }
                         >
                              Consulter <i className={"fas fa-eye fs-5 ms-1"} />
                         </button>
                         <MyTooltip title={"Remplacer"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-warning p-0 px-2 py-1 me-2"}
                                   onClick={() => refInputReplaceDocument.current?.click()}
                              >
                                   <i className={"fas fa-sync fs-5"} />
                              </button>
                         </MyTooltip>
                         <input
                              type={"file"}
                              className={"d-none"}
                              ref={refInputReplaceDocument}
                              onChange={e => fournisseurDocumentActions.replace(e, fournisseurDocument.id as number, handleReplaceDocumentCallback)}
                         />
                         <MyTooltip title={"Supprimer"}>
                              <button
                                   type={"button"}
                                   className={"btn btn-sm btn-outline btn-outline-danger p-0 px-2 py-1"}
                                   onClick={() => fournisseurDocumentActions.flagAsDeleted(fournisseurDocument.id as number, handleDeleteDocumentCallback)}
                              >
                                   <i className={"fas fa-trash fs-5"} />
                              </button>
                         </MyTooltip>
                    </div>
               </div>
          )
     }

     return (
          <>
               <div className="row">
                    {/* RIB */}
                    <div className="col-sm-6">
                         <DocumentsFournisseurContainer containerTitle={"RIB"} type={CONST_FOURNISSEUR_DOCUMENT_RIB}>
                              <>
                                   {documents.documentsFournisseur.rib?.map((item, index) => {
                                        return <DocumentFournisseurItem fournisseurDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsFournisseurContainer>
                    </div>

                    {/* Extrait K-bis */}
                    <div className="col-sm-6">
                         <DocumentsFournisseurContainer containerTitle={"Extrait K-bis"} type={CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS}>
                              <>
                                   {documents.documentsFournisseur.extraitsKbis?.map((item, index) => {
                                        return <DocumentFournisseurItem fournisseurDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsFournisseurContainer>
                    </div>
               </div>

               <div className="row">
                    {/* Assurance RC Pro */}
                    <div className="col-sm-6">
                         <DocumentsFournisseurContainer containerTitle={"Assurance RC Pro."} type={CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO}>
                              <>
                                   {documents.documentsFournisseur.assurancesPro?.map((item, index) => {
                                        return <DocumentFournisseurItem fournisseurDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsFournisseurContainer>
                    </div>

                    {/* Vigilance URSSAF */}
                    <div className="col-sm-6">
                         <DocumentsFournisseurContainer containerTitle={"Vigilance URSSAF"} type={CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF}>
                              <>
                                   {documents.documentsFournisseur.vigilancesUrssaf?.map((item, index) => {
                                        return <DocumentFournisseurItem fournisseurDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsFournisseurContainer>
                    </div>
               </div>

               <div className="row">
                    {/* Régularité fiscale */}
                    <div className="col-sm-6">
                         <DocumentsFournisseurContainer containerTitle={"Régularité fiscale"} type={CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE}>
                              <>
                                   {documents.documentsFournisseur.regularitesFiscale?.map((item, index) => {
                                        return <DocumentFournisseurItem fournisseurDocument={item} key={index} classNames={index > 0 ? "mt-4" : ""} />
                                   })}
                              </>
                         </DocumentsFournisseurContainer>
                    </div>
               </div>

               {/* Document in modal */}
               {documentInModal && (
                    <MyGoogleDriveFileViewerInModal
                         googleDriveID={documentInModal.driveID as string}
                         googleDriveMimetype={documentInModal.driveMime as string}
                         modalTitle={<span>{documentInModal.modalTitle}</span>}
                         handleCloseModal={() => setActiveDriveDocumentInModal(undefined)}
                         modalSize={"lg"}
                    />
               )}
          </>
     )
}

interface IConsultantDetailsDocumentsFournisseurPropsModel {
     documents: IGetDocumentsConsultant
}

export default ConsultantDetailsDocumentsFournisseur
