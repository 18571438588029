import React, { useEffect } from "react"
import MyCard from "@common-utils/MyCard"
import { usePersonnelListingContext } from "./PersonnelListing"
import { useAuth } from "../../../AppContext"
import { IGetPersonnelListingItemResponseModel } from "./core/_models"
import { Link } from "react-router-dom"
import MyGoogleDriveFileViewer from "../../../components/MyDocumentViewer/MyGoogleDriveFileViewer"
import MyAlert from "@common-utils/MyAlert"

/* Wrapper */
const Preview = () => {
     const { currentUser } = useAuth()
     const globalContext = usePersonnelListingContext()
     const selectedItemInPreview = globalContext.itemInPreview!.selectedItem
     const itemInPreview = globalContext.listingQuery.data?.data.find(item => item.personnel.id === selectedItemInPreview?.personnel.id) as IGetPersonnelListingItemResponseModel

     useEffect(() => {
          globalContext.setItemInPreview(prev => (prev ? { ...prev, isSelectedItemLoading: false } : prev))
     }, [itemInPreview])

     const contrat = itemInPreview?.personnel.personnelDocuments?.find(doc => doc.type === "TYPE_CONTRAT")

     return (
          <MyCard>
               <MyCard.Header isCollapseIconShown={false}>
                    <div className="d-flex align-items-center">
                         <span className={"fas fa-eye-slash fs-4 text-dark p-2 cursor-pointer"} onClick={() => globalContext.setItemInPreview(null)} />
                         <span className={"me-2"}>
                              <Link to={"#"} className={"fw-bold text-hover-dark fs-5"}>
                                   {itemInPreview?.personnel.prenomNom}
                              </Link>
                         </span>
                    </div>
               </MyCard.Header>
               <MyCard.Body noPadding styles={{ maxHeight: "75.1vh", overflowY: "scroll" }}>
                    {contrat ? (
                         <MyGoogleDriveFileViewer googleDriveID={contrat.googleDriveID as string} googleDriveMimetype={contrat.googleDriveMimeType as string} />
                    ) : (
                         <MyAlert type={"primary"}>Aucun contrat ajouté à ce jour.</MyAlert>
                    )}
               </MyCard.Body>
          </MyCard>
     )
}

export default Preview
