import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import React, { createContext, useContext, useState } from "react"
import { IConsultantDetailsContextModel, IGetDetailsConsultant } from "./core/_models"
import { getConsultantDetails } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import MyCard from "@common-utils/MyCard"
import ConsultantDetailsInfoBase from "./infoBase/_ConsultantDetails_InfoBase"
import ConsultantDetailsDocuments from "./documents/_ConsultantDetails_Documents"
import ConsultantDetails_SimulateurRentabilite from "./simulateurRentabilite/_ConsultantDetails_SimulateurRentabilite"
import ConsultantDetailsDeclarationsActivite from "./declarations/_ConsultantDetails_Declarations"
import ConsultantDetailsHeader from "./header/_ConsultantDetailsHeader"
import ConsultantDetailsDDC from "./ddc/_ConsultantDetails_DDC"
import ConsultantDetailsPE from "./indicateurs/_ConsultantDetails_Indicateurs"
import ConsultantDetailsIndicateurs from "./indicateurs/_ConsultantDetails_Indicateurs"
import ConsultantsSuivisListing from "../suivi/listing/ConsultantsSuivisListing"
import MyModal from "@common-utils/MyModal"
import ConsultantSuiviSelectEvent from "../suivi/_ConsultantSuiviSelectEvent"
import GlobalHelper from "@common-helpers/GlobalHelper"
import moment from "moment"
import EnableDisableAccount from "./header/_EnableDisableAccount"

const ConsultantDetailsContext = createContext<IConsultantDetailsContextModel>({} as IConsultantDetailsContextModel)
export const useConsultantDetailsContext = () => useContext(ConsultantDetailsContext)

export const REACT_QUERY_KEY_GET_CONSULTANT_DETAILS = "REACT_QUERY_KEY_GET_CONSULTANT_DETAILS"
const ConsultantDetails = () => {
     const consultant_ID = parseInt(useParams<{ consultant_id: string }>().consultant_id as string)
     const [selectedNavigationItem, setSelectedNavigationItem] = useState<
          "INFO_BASE" | "DOCUMENTS" | "PE" | "DDC" | "DECLARATIONS" | "SIMULATEUR_RENTABILITE" | "INDICATEURS" | "SUIVIS"
     >("INFO_BASE")

     const [isAddingSuiviOnModal, setIsAddingSuiviOnModal] = useState<{
          visible: boolean
          modalSize: "lg" | "xl" | "fullscreen"
          modalNoPadding: boolean
          modalTitle?: JSX.Element
     }>({
          visible: false,
          modalSize: "lg",
          modalNoPadding: false,
     })

     const [rerenderSuiviKey, setRerenderSuiviKey] = useState<string>(GlobalHelper.randomId())

     const detailsQuery = useQuery<IGetDetailsConsultant, AxiosError>(REACT_QUERY_KEY_GET_CONSULTANT_DETAILS, async () => {
          return getConsultantDetails(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Navigation title on the left corner of the card
     const NavigationTitle = () => {
          return (
               <span>
                    {selectedNavigationItem === "INFO_BASE" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-info-circle fs-2 me-2"} /> Informations de base
                         </div>
                    )}
                    {selectedNavigationItem === "DOCUMENTS" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-folder-open fs-2 me-2"} /> Documents
                         </div>
                    )}
                    {selectedNavigationItem === "DDC" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-umbrella-beach fs-2 me-2"} /> Demandes de congés
                         </div>
                    )}
                    {selectedNavigationItem === "PE" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-hourglass-half fs-2 me-2"} /> Périodes d'essai
                         </div>
                    )}
                    {selectedNavigationItem === "DECLARATIONS" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-calendar-days fs-2 me-2"} /> Déclarations d'activité
                         </div>
                    )}
                    {selectedNavigationItem === "SIMULATEUR_RENTABILITE" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-calculator fs-2 me-2"} /> Simulation de rentabilité
                         </div>
                    )}
                    {selectedNavigationItem === "INDICATEURS" && (
                         <div className={"d-flex align-items-center"}>
                              <span className={"fas fa-chart-pie fs-2 me-2"} /> Indicateurs
                         </div>
                    )}
                    {selectedNavigationItem === "SUIVIS" && (
                         <div className={"d-flex align-items-between"}>
                              <div>
                                   <span className={"fas fa-history fs-2 me-2"} /> Suivi
                              </div>
                         </div>
                    )}
               </span>
          )
     }

     const NavigationRightSide = () => {
          if (selectedNavigationItem === "SUIVIS") {
               return (
                    <i
                         className="fas fa-plus-circle text-dark fs-2 text-hover-primary cursor-pointer"
                         onClick={() => setIsAddingSuiviOnModal(prev => ({ ...prev, visible: true }))}
                    />
               )
          }

          return <></>
     }

     return (
          <>
               <ConsultantDetailsContext.Provider
                    value={{
                         detailsQuery,
                         selectedNavigationItem,
                         setSelectedNavigationItem,
                         REACT_QUERY_KEY_GET_CONSULTANT_DETAILS,
                    }}
               >
                    <>
                         <div className={"d-flex justify-content-center"}>
                              <div className={"w-100 w-lg-75 w-xxl-65"}>
                                   {detailsQuery.isFetching && (
                                        <div className={"text-center p-5"}>
                                             <MySimpleSpinner size={"xl"} />
                                        </div>
                                   )}

                                   {!detailsQuery.isFetching && detailsQuery.isError && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             <>
                                                  <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                                  <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                                       Recharger
                                                  </button>
                                             </>
                                        </MyAlert>
                                   )}

                                   {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                                        <>
                                             {/* Alert sortie des effectifs */}
                                             {detailsQuery.data.consultant.etat === "ETAT_HORS_EFFECTIF" && (
                                                  <MyAlert type={"secondary"} classNames={"mb-4"}>
                                                       {detailsQuery.data.consultant.dateSortie?.parseZone().isAfter(moment()) ? (
                                                            <div>
                                                                 Ce collaborateur sortira des effectifs le{" "}
                                                                 {detailsQuery.data.consultant.dateSortie?.parseZone().format("dddd D MMMM YYYY")}
                                                            </div>
                                                       ) : (
                                                            <div>
                                                                 Ce collaborateur est sorti des effectifs le{" "}
                                                                 {detailsQuery.data.consultant.dateSortie?.parseZone().format("dddd D MMMM YYYY")}
                                                            </div>
                                                       )}
                                                  </MyAlert>
                                             )}

                                             <EnableDisableAccount />

                                             <ConsultantDetailsHeader />

                                             {/* Informations de base */}
                                             <MyCard classNames={"mb-4"}>
                                                  <MyCard.Header
                                                       classNames={"d-flex justify-content-center d-sm-inline-grid justify-content-sm-between"}
                                                       rightSideContent={<NavigationRightSide />}
                                                  >
                                                       <NavigationTitle />
                                                  </MyCard.Header>
                                                  <MyCard.Body noPadding>
                                                       {selectedNavigationItem === "INFO_BASE" && <ConsultantDetailsInfoBase consultantInfoPerso={detailsQuery.data} />}
                                                       {selectedNavigationItem === "DOCUMENTS" && <ConsultantDetailsDocuments />}
                                                       {selectedNavigationItem === "SIMULATEUR_RENTABILITE" && (
                                                            <ConsultantDetails_SimulateurRentabilite consultantDetails={detailsQuery.data} />
                                                       )}
                                                       {selectedNavigationItem === "DECLARATIONS" && <ConsultantDetailsDeclarationsActivite />}

                                                       {selectedNavigationItem === "DDC" && <ConsultantDetailsDDC />}
                                                       {selectedNavigationItem === "PE" && <ConsultantDetailsPE />}
                                                       {selectedNavigationItem === "INDICATEURS" && <ConsultantDetailsIndicateurs />}
                                                       {selectedNavigationItem === "SUIVIS" && (
                                                            <ConsultantsSuivisListing
                                                                 consultants={[consultant_ID]}
                                                                 pageLength={undefined}
                                                                 isSearchBarVisible={false}
                                                                 isPaginationVisible={false}
                                                                 isTitleVisible={false}
                                                                 key={rerenderSuiviKey}
                                                            />
                                                       )}
                                                  </MyCard.Body>
                                             </MyCard>
                                        </>
                                   )}
                              </div>
                         </div>
                    </>
               </ConsultantDetailsContext.Provider>

               {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                    <>
                         {/* Modal to add suivi */}
                         {isAddingSuiviOnModal.visible && (
                              <MyModal
                                   title={isAddingSuiviOnModal.modalTitle === undefined ? <>Choisissez un suivi</> : isAddingSuiviOnModal.modalTitle}
                                   show={isAddingSuiviOnModal.visible}
                                   handleClose={() => setIsAddingSuiviOnModal({ visible: false, modalSize: "lg", modalNoPadding: false })}
                                   size={["lg", "xl"].includes(isAddingSuiviOnModal.modalSize) ? (isAddingSuiviOnModal.modalSize as "lg" | "xl") : "lg"}
                                   fullscreen={isAddingSuiviOnModal.modalSize === "fullscreen" ? true : undefined}
                                   noPadding={isAddingSuiviOnModal.modalNoPadding}
                              >
                                   <ConsultantSuiviSelectEvent
                                        consultants={[detailsQuery.data.consultant]}
                                        setModalSizeCallback={modalSize => setIsAddingSuiviOnModal(prev => ({ ...prev, modalSize }))}
                                        setModalNoPaddingCallback={val => setIsAddingSuiviOnModal(prev => ({ ...prev, modalNoPadding: val }))}
                                        setModalTitle={modalTitle => setIsAddingSuiviOnModal(prev => ({ ...prev, modalTitle }))}
                                        onSubmitCallback={() => {
                                             setIsAddingSuiviOnModal({ visible: false, modalSize: "lg", modalNoPadding: false })
                                             setRerenderSuiviKey(GlobalHelper.randomId())
                                        }}
                                   />
                              </MyModal>
                         )}
                    </>
               )}
          </>
     )
}

export default ConsultantDetails
