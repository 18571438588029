import { CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL, CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "@common-constants/*"
import { IProlongementPtfRequestModel } from "../core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { IClientPropalPeriodeModel, IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { getReferenceAndDateDebutNextPeriodeOfPropalRequest, prolongementPropalRequest } from "../core/_requests"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MyFileField from "@common-utils/fields/MyFileField"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { useQuery } from "react-query"
import moment from "moment/moment"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MySelectField from "@common-utils/fields/MySelectField"

interface IPtfProlongementFormPropsModel {
     ptf_ID: number
     handleSubmitCallback?: (periode: IClientPropalPeriodeModel) => void
}

const PtfProlongementForm = ({ ptf_ID, handleSubmitCallback = () => {} }: IPtfProlongementFormPropsModel) => {
     const [uploadedOrGeneratedPropal, setUploadedOrGeneratedPropal] = useState<{
          googleDriveID: string
          loading: boolean
     }>()

     // Query
     const refAndDateDebutNextPeriodeQuery = useQuery<{ reference: string; dateDebut: moment.Moment }, AxiosError>("REACT_QUERY_KEY_GET_REF_DATE_DEBUT_NEXT_PERIODE", () => {
          return getReferenceAndDateDebutNextPeriodeOfPropalRequest(ptf_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Formik handle submit
     function handleSubmit(values: IProlongementPtfRequestModel, helpers: FormikHelpers<IProlongementPtfRequestModel>) {
          helpers.setStatus(null)

          const payload: IProlongementPtfRequestModel = {
               ...values,
               ptfPeriodeReference: refAndDateDebutNextPeriodeQuery.data!.reference,
          }
          prolongementPropalRequest(ptf_ID, payload)
               .then(r => {
                    setUploadedOrGeneratedPropal({ googleDriveID: r.data.googleDriveId as string, loading: true })
                    toast.success(
                         values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "GENERATION"
                              ? "Votre proposition prolongée a bien été générée, vous pouvez commencer à l'éditer en ligne."
                              : "La proposition prolongée a bien envoyée. Vous pouvez continuer à l'éditer dans l'encadré ou fermer si vous avez terminé."
                    )
                    handleSubmitCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     // Fetching ref
     if (refAndDateDebutNextPeriodeQuery.isFetching) {
          return (
               <div className={"text-center p-5"}>
                    <MySimpleSpinner size={"xl"} />
               </div>
          )
     }

     // On error while fetching
     if (!refAndDateDebutNextPeriodeQuery.isFetching && refAndDateDebutNextPeriodeQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         <span className={"me-2"}>{refAndDateDebutNextPeriodeQuery.error?.response?.data?.detail}</span>
                         <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => refAndDateDebutNextPeriodeQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     // Once form submited. The next version of ODM appears and can be edited
     if (uploadedOrGeneratedPropal) {
          return (
               <MyLoadingOverlay isActive={uploadedOrGeneratedPropal.loading}>
                    <iframe
                         onLoad={() => setUploadedOrGeneratedPropal(prev => (prev ? { ...prev, loading: false } : undefined))}
                         src={FileAndDriveHelper.getUrlOfDriveFileEditMode(uploadedOrGeneratedPropal.googleDriveID)}
                         className={"vh-100 w-100"}
                    />
               </MyLoadingOverlay>
          )
     }

     if (!refAndDateDebutNextPeriodeQuery.isFetching && !refAndDateDebutNextPeriodeQuery.isError && refAndDateDebutNextPeriodeQuery.data) {
          // Form initial values
          const initialValues: IProlongementPtfRequestModel = {
               ptfPeriodeDateDebut: refAndDateDebutNextPeriodeQuery?.data?.dateDebut?.format("YYYY-MM-DD"),
          }

          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <h5 className={"mb-4"}>Choisissez parmi les deux options</h5>
                                   <div className={"mb-2"}>
                                        <MyRadioWithLabelAndDescriptionField
                                             name={"ptfPeriodeDocumentGeneratedOrSaisieManuelle"}
                                             value={"SAISIE"}
                                             isChecked={helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "SAISIE"}
                                             label={"Saisie manuelle"}
                                             description={"Joignez votre propre PTF prolongée depuis votre ordinateur"}
                                             iconFontAwesomeClassName={"upload"}
                                             color={"primary"}
                                             isInvalid={!!helpers.errors.ptfPeriodeDocumentGeneratedOrSaisieManuelle}
                                             onChange={val => helpers.setFieldValue("ptfPeriodeDocumentGeneratedOrSaisieManuelle", val)}
                                        />
                                   </div>
                                   <MyRadioWithLabelAndDescriptionField
                                        name={"ptfPeriodeDocumentGeneratedOrSaisieManuelle"}
                                        value={"GENERATION"}
                                        isChecked={helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "GENERATION"}
                                        label={"Génération automatique (recommandé)"}
                                        description={"Générer la PTF prolongée à partir du dernier document PTF de la dernière période"}
                                        iconFontAwesomeClassName={"bolt"}
                                        color={"success"}
                                        isInvalid={!!helpers.errors.ptfPeriodeDocumentGeneratedOrSaisieManuelle}
                                        onChange={val => helpers.setFieldValue("ptfPeriodeDocumentGeneratedOrSaisieManuelle", val)}
                                   />

                                   <div className="separator mb-5"></div>

                                   {helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle && (
                                        <>
                                             {/* Document */}
                                             {helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "SAISIE" && (
                                                  <>
                                                       <MyAlert type={"primary"} classNames={"mb-4"}>
                                                            <div>
                                                                 Assurer vous de bien utiliser la référence{" "}
                                                                 <span className={"fw-bold text-dark"}>{refAndDateDebutNextPeriodeQuery.data.reference}</span> dans le document que
                                                                 vous allez joindre.
                                                            </div>
                                                       </MyAlert>
                                                       <div className="row mb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez un document</label>

                                                            <div className="col-lg-8">
                                                                 <MyFileField
                                                                      name={"ptfPeriodeDocument"}
                                                                      isInvalid={!!helpers.errors.ptfPeriodeDocument}
                                                                      onChange={val => helpers.setFieldValue("ptfPeriodeDocument", val)}
                                                                 />
                                                                 {helpers.errors.ptfPeriodeDocument && <div className={"text-danger"}>{helpers.errors.ptfPeriodeDocument}</div>}
                                                            </div>
                                                       </div>
                                                  </>
                                             )}

                                             {helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "GENERATION" && (
                                                  <MyAlert type={"primary"} classNames={"mb-4"}>
                                                       <div>
                                                            Après avoir cliqué sur le bouton "Générer", un document word s'affichera, vous permettant ainsi de le modifier. Assurez
                                                            tout de même d'utiliser la référence:{" "}
                                                            <span className={"fw-bold text-dark"}>{refAndDateDebutNextPeriodeQuery.data.reference}</span>.
                                                       </div>
                                                  </MyAlert>
                                             )}

                                             {/* Période */}
                                             <div className="row mb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                  <div className="col-lg-8">
                                                       <div className="d-flex">
                                                            <MyDateDayMonthYearField
                                                                 name={"ptfPeriodeDateDebut"}
                                                                 value={helpers.values.ptfPeriodeDateDebut}
                                                                 isInvalid={!!helpers.errors.ptfPeriodeDateDebut}
                                                                 maxDate={helpers.values.ptfPeriodeDateFin}
                                                                 onChange={val => helpers.setFieldValue("ptfPeriodeDateDebut", val)}
                                                                 placeholder={"Date de début"}
                                                                 classNames={"rounded-0 rounded-start"}
                                                            />
                                                            <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                            <MyDateDayMonthYearField
                                                                 name={"ptfPeriodeDateFin"}
                                                                 value={helpers.values.ptfPeriodeDateFin}
                                                                 minDate={helpers.values.ptfPeriodeDateDebut}
                                                                 isInvalid={!!helpers.errors.ptfPeriodeDateFin}
                                                                 onChange={val => helpers.setFieldValue("ptfPeriodeDateFin", val)}
                                                                 placeholder={"Date de fin"}
                                                                 classNames={"rounded-0 rounded-end"}
                                                            />
                                                       </div>
                                                       {helpers.errors.ptfPeriodeDateDebut && <div className={"text-danger"}>{helpers.errors.ptfPeriodeDateDebut}</div>}
                                                       {helpers.errors.ptfPeriodeDateFin && <div className={"text-danger"}>{helpers.errors.ptfPeriodeDateFin}</div>}
                                                  </div>
                                             </div>

                                             <div className={"p-4 border border-dashed mb-4"}>
                                                  <h4 className={"mb-4"}>Bon de commande / Accord client</h4>
                                                  <div className="separator separator-dashed mb-6" />

                                                  {/* Type */}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Type</label>

                                                       <div className="col-lg-8">
                                                            <MySelectField
                                                                 name={"bonCommandeType"}
                                                                 options={[
                                                                      {
                                                                           value: CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION,
                                                                           label: "Mail de confirmation / accord client",
                                                                      },
                                                                      {
                                                                           value: CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL,
                                                                           label: "Bon de commande original",
                                                                      },
                                                                 ]}
                                                                 value={helpers.values.bonCommandeType}
                                                                 isInvalid={!!helpers.errors.bonCommandeType}
                                                                 onChange={val => helpers.setFieldValue("bonCommandeType", val)}
                                                            />
                                                            {helpers.errors.bonCommandeType && <div className={"text-danger"}>{helpers.errors.bonCommandeType}</div>}
                                                       </div>
                                                  </div>

                                                  {/* Période */}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Période</label>

                                                       <div className="col-lg-8">
                                                            <div className="d-flex">
                                                                 <MyDateDayMonthYearField
                                                                      name={"bonCommandeDateDebut"}
                                                                      value={helpers.values.bonCommandeDateDebut}
                                                                      isInvalid={!!helpers.errors.bonCommandeDateDebut}
                                                                      minDate={helpers.values.ptfPeriodeDateDebut}
                                                                      maxDate={helpers.values.bonCommandeDateFin}
                                                                      onChange={val => helpers.setFieldValue("bonCommandeDateDebut", val)}
                                                                      placeholder={"Date de début"}
                                                                      classNames={"rounded-0 rounded-start"}
                                                                 />
                                                                 <span className="input-group-text fas fa-ellipsis d-flex align-items-center rounded-0" />
                                                                 <MyDateDayMonthYearField
                                                                      name={"bonCommandeDateFin"}
                                                                      value={helpers.values.bonCommandeDateFin}
                                                                      minDate={helpers.values.bonCommandeDateDebut}
                                                                      maxDate={helpers.values.ptfPeriodeDateFin}
                                                                      isInvalid={!!helpers.errors.bonCommandeDateFin}
                                                                      onChange={val => helpers.setFieldValue("bonCommandeDateFin", val)}
                                                                      placeholder={"Date de fin"}
                                                                      classNames={"rounded-0 rounded-end"}
                                                                 />
                                                            </div>
                                                            {helpers.errors.bonCommandeDateDebut && <div className={"text-danger"}>{helpers.errors.bonCommandeDateDebut}</div>}
                                                            {helpers.errors.bonCommandeDateFin && <div className={"text-danger"}>{helpers.errors.bonCommandeDateFin}</div>}
                                                       </div>
                                                  </div>

                                                  {/* Document BC */}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">BC / Accord client</label>
                                                       <div className="col-lg-8">
                                                            <MyFileField
                                                                 name={"bonCommandeDocument"}
                                                                 isInvalid={!!helpers.errors.bonCommandeDocument}
                                                                 onChange={val => helpers.setFieldValue("bonCommandeDocument", val)}
                                                            />
                                                            {helpers.errors.bonCommandeDocument && <div className={"text-danger"}>{helpers.errors.bonCommandeDocument}</div>}
                                                       </div>
                                                  </div>

                                                  {/* CDC  */}
                                                  <div className="row mb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">Cahier des charges</label>
                                                       <div className="col-lg-8">
                                                            <MyFileField
                                                                 name={"documentCdc"}
                                                                 isInvalid={!!helpers.errors.documentCdc}
                                                                 onChange={val => helpers.setFieldValue("documentCdc", val)}
                                                            />
                                                            {helpers.errors.documentCdc && <div className={"text-danger"}>{helpers.errors.documentCdc}</div>}
                                                       </div>
                                                  </div>
                                             </div>
                                        </>
                                   )}

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        {helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle && (
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? (
                                                       <>
                                                            {helpers.values.ptfPeriodeDocumentGeneratedOrSaisieManuelle === "GENERATION" ? (
                                                                 <>
                                                                      Générer <i className={"fas fa-bolt fs-4 ms-1"} />
                                                                 </>
                                                            ) : (
                                                                 <>
                                                                      Envoyer <i className={"fas fa-upload fs-4 ms-1"} />
                                                                 </>
                                                            )}
                                                       </>
                                                  ) : (
                                                       <MySimpleSpinner size={"sm"} />
                                                  )}
                                             </button>
                                        )}
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          )
     }

     return <></>
}

export default PtfProlongementForm
