import React, { useState } from "react"
import { IGetDetailsConsultant } from "../core/_models"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { KTSVG, toAbsoluteUrl } from "@common-metronic/helpers"
import { useConsultantDetailsContext } from "../ConsultantDetails"
import MyCard from "@common-utils/MyCard"
import MyTooltip from "@common-utils/MyTooltip"
import { useThemeMode } from "@common-metronic/partials"

const ConsultantDetailsHeader = () => {
     const { mode } = useThemeMode()
     const context = useConsultantDetailsContext()
     const data = context.detailsQuery.data as IGetDetailsConsultant

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(false)

     const user = context.detailsQuery.data?.consultant.candidat?.user

     return (
          <MyCard classNames={"mb-5 mb-xl-5"}>
               <MyCard.Body classNames={"pt-9 pb-0"}>
                    <div className="d-flex flex-md-row flex-column text-center mb-3 justify-content-md-start justify-content-center">
                         <div className="me-md-7 mb-4">
                              <MyLoadingOverlay isActive={isAvatarLoading} classNames={"symbol symbol-110px symbol-fixed position-relative"}>
                                   <>
                                        <img
                                             src={
                                                  user?.avatarGoogleDriveId
                                                       ? FileAndDriveHelper.getThumbnailDriveUrl(user.avatarGoogleDriveId as string)
                                                       : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                             }
                                             onLoad={() => setIsAvatarLoading(false)}
                                             alt={"Avatar"}
                                        />
                                        <div className="position-absolute translate-middle bottom-0 start-100 mb-2 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                                   </>
                              </MyLoadingOverlay>
                         </div>

                         <div className="flex-md-grow-1">
                              <div className="d-flex justify-content-center justify-content-md-between align-items-start flex-wrap mb-3">
                                   <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center mb-2">
                                             <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1" style={{ cursor: "default" }}>
                                                  {data.consultant.prenomNom}
                                             </span>
                                             <span style={{ cursor: "default" }}>
                                                  <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-1 svg-icon-primary" />
                                             </span>
                                        </div>

                                        <div className="d-flex flex-wrap fw-bold fs-6">
                                             <a
                                                  href={`tel:${data.consultant.telProPays?.phoneCodeWithPlusPrefix} ${data.consultant.telPro}`}
                                                  className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                             >
                                                  <i className={"fas fa-phone me-1"} /> {data.consultant.telProPays?.phoneCodeWithPlusPrefix} {data.consultant.telPro}
                                             </a>
                                             <a href={`mailto:${data.consultant.mailPro}`} className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                  <KTSVG path="/media/icons/duotune/communication/com011.svg" className="svg-icon-4 me-1" />
                                                  {data.consultant.mailPro}
                                             </a>
                                        </div>
                                   </div>
                              </div>

                              <div className="d-flex flex-wrap flex-stack justify-content-md-start justify-content-center">
                                   <div className="d-flex align-items-center w-200px w-sm-300px flex-column">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                             <span className="fw-bold fs-6 text-gray-400">Remplissage du profil</span>
                                             <span className="fw-bolder fs-6">100%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                                             <div
                                                  className="bg-success rounded h-5px"
                                                  role="progressbar"
                                                  style={{
                                                       width: `100%`,
                                                  }}
                                             ></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="d-flex justify-content-center overflow-auto">
                         <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                              <MyTooltip title={"Informations de base"}>
                                   <li className="nav-item">
                                        <span
                                             className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "INFO_BASE" && "active"}`}
                                             onClick={() => context.setSelectedNavigationItem("INFO_BASE")}
                                        >
                                             <i className={"fas fa-info-circle"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </li>
                              </MyTooltip>

                              <MyTooltip title={"Documents"}>
                                   <li className="nav-item">
                                        <span
                                             className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "DOCUMENTS" && "active"}`}
                                             onClick={() => context.setSelectedNavigationItem("DOCUMENTS")}
                                        >
                                             <i className={"fas fa-folder-open"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </li>
                              </MyTooltip>

                              {data.consultant.contrat !== "CONTRAT_ST" && (
                                   <>
                                        <MyTooltip title={"Demandes de congés"}>
                                             <li className="nav-item">
                                                  <span
                                                       className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "DDC" && "active"}`}
                                                       onClick={() => context.setSelectedNavigationItem("DDC")}
                                                  >
                                                       <i className={"fas fa-umbrella-beach"} style={{ fontSize: "2rem" }}></i>
                                                  </span>
                                             </li>
                                        </MyTooltip>
                                        <MyTooltip title={"Périodes d'essai"}>
                                             <li className="nav-item">
                                                  <span
                                                       className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "PE" && "active"}`}
                                                       onClick={() => context.setSelectedNavigationItem("PE")}
                                                  >
                                                       <i className={"fas fa-hourglass-half"} style={{ fontSize: "2rem" }}></i>
                                                  </span>
                                             </li>
                                        </MyTooltip>
                                   </>
                              )}

                              <MyTooltip title={"Déclarations d'activité"}>
                                   <li className="nav-item">
                                        <span
                                             className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "DECLARATIONS" && "active"}`}
                                             onClick={() => context.setSelectedNavigationItem("DECLARATIONS")}
                                        >
                                             <i className={"fas fa-calendar-days"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </li>
                              </MyTooltip>

                              {data.consultant.contrat !== "CONTRAT_ST" && !context.detailsQuery.data?.isConnectedUserWithDelegationAdministrative && (
                                   <MyTooltip title={"Simulatation de rentabilité"}>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary cursor-pointer ${
                                                       context.selectedNavigationItem === "SIMULATEUR_RENTABILITE" && "active"
                                                  }`}
                                                  onClick={() => context.setSelectedNavigationItem("SIMULATEUR_RENTABILITE")}
                                             >
                                                  <i className={"fas fa-calculator"} style={{ fontSize: "2rem" }}></i>
                                             </span>
                                        </li>
                                   </MyTooltip>
                              )}

                              {!context.detailsQuery.data?.isConnectedUserWithDelegationAdministrative && (
                                   <MyTooltip title={"Indicateurs"}>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "INDICATEURS" && "active"}`}
                                                  onClick={() => context.setSelectedNavigationItem("INDICATEURS")}
                                             >
                                                  <i className={"fas fa-chart-pie"} style={{ fontSize: "2rem" }}></i>
                                             </span>
                                        </li>
                                   </MyTooltip>
                              )}

                              <MyTooltip title={"Suivi"}>
                                   <li className="nav-item">
                                        <span
                                             className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "SUIVIS" && "active"}`}
                                             onClick={() => context.setSelectedNavigationItem("SUIVIS")}
                                        >
                                             <i className={"fas fa-history"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </li>
                              </MyTooltip>
                         </ul>
                    </div>
               </MyCard.Body>
          </MyCard>
     )
}

export default ConsultantDetailsHeader
