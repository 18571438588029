import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { IGetViviersResponseItemModel } from "./core/_models"
import { useViviersListingContext } from "./ViviersListing"
import { useViviersListingListContext } from "./_ViviersListingList"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import MyTooltip from "@common-utils/MyTooltip"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = useViviersListingContext()
     const listContext = useViviersListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.vivier.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.vivier.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.vivier.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.vivier.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   {/* Avatar, titre */}
                                   <div className={"d-flex align-items-center"}>
                                        {/* Avatar */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={toAbsoluteUrl("/media/logos/cts/logo-cts.png")}
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Titre */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <span className={"text-primary fw-bold fs-5"}>{item.vivier.intitule}</span>
                                             </div>
                                             <div className={"text-muted"}>{item.vivier.description}</div>
                                        </div>
                                   </div>

                                   {/* Contrat, état & statut */}
                                   <div className="d-flex align-items-center">
                                        <MyTooltip title={<>Je m'abonne</>}>
                                             <span className="fas fa-bell fs-2 cursor-pointer text-muted not-concerned-by-preview" onClick={() => toast.info("Coming soon ...")} />
                                        </MyTooltip>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             {/* Date publication */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar fs-5 w-25px" />
                                                  Publiée le {item.vivier.date?.format("dddd D MMMM YYYY")}
                                             </div>

                                             {/* Responsable */}
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" />
                                                  {item.vivier.personnel?.prenomNom}
                                             </div>
                                        </div>
                                        <div className="col text-end">
                                             <MyTooltip title={"S'ABONNER"}>
                                                  <button
                                                       type={"button"}
                                                       className={`btn btn-outline btn-outline-muted btn-sm btn-icon rounded-circle mb-2`}
                                                       onClick={() => toast.info("coming soon ...")}
                                                  >
                                                       <i className="fas fa-bell fs-4" />
                                                  </button>
                                             </MyTooltip>
                                        </div>
                                   </div>
                                   <div className={"separator border-primary"} />

                                   <div className={"d-flex justify-content-center p-4"}>
                                        {item.totalCandidats > 0 ? (
                                             <Link
                                                  to={"/recrutement/candidats/search/results"}
                                                  state={{ vivier_ids: [item.vivier.id] }}
                                                  className={"btn btn-sm btn-light-dark me-4 px-2 py-1 fs-6"}
                                             >
                                                  {item.totalCandidats} {item.totalCandidats > 1 ? "candidats" : "candidat"}
                                             </Link>
                                        ) : (
                                             <span className={"text-muted"}>Aucun candidat</span>
                                        )}
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const ViviersListingListItem = ({ item, index }: { item: IGetViviersResponseItemModel; index: number }) => {
     const context = useViviersListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.vivier.id === item.vivier.id
     const isItemLoading: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.vivier.id === item.vivier.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.vivier.id !== item.vivier.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetViviersResponseItemModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default ViviersListingListItem
