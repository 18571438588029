import React, { useCallback, useMemo, useRef, useState } from "react"
import Select from "react-select"
import axios, { AxiosError, AxiosResponse } from "axios"
import { debounce } from "lodash"
import useSelectReact from "@common-hooks/useSelectReact"

type Props = {
     url: string
     name: string
     value: string | number | Array<string | number> | undefined | null
     onChange: (value: string | number) => void
     minimumLengthSearch: number
     isInvalid: boolean
     isClearable?: boolean
     placeholder?: string
     method?: "POST" | "GET"
     payload?: {}
     isMulti?: boolean
     defaultOptions?: Array<{ label: string; value: string | number }>
     className?: string
}

const MySelectSearchDataFromServer: React.FC<Props> = ({
     url,
     name,
     value,
     onChange,
     isClearable = true,
     placeholder = "Tapez puis choisissez",
     payload = {},
     isMulti = false,
     minimumLengthSearch,
     defaultOptions = [],
     method = "POST",
     className = "",
     isInvalid,
}) => {
     const { formatGroupLabel, styles } = useSelectReact()

     const [isLoading, setIsLoading] = useState<boolean>(false)
     const [options, setOptions] = useState<any>(defaultOptions)
     const [cacheOptions, setCacheOptions] = useState<any>(defaultOptions)
     const [noOptionsMessage, setNoOptionsMessage] = useState<string>(`Saisissez au moins ${minimumLengthSearch} caractères pour lancer la recherche`)

     const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

     // 🔹 Debounced API Call (only fetches when user stops typing for 2 seconds)
     const fetchOptions = useMemo(() => {
          return debounce((q: string) => {
               if (q.length < minimumLengthSearch) {
                    setOptions([]) // Clear options immediately when input length is too short.
                    return
               }

               setIsLoading(true)
               setNoOptionsMessage("Chargement en cours ...") // Show loading message.

               let result
               // Adjust payload values if necessary.
               if (method === "GET") {
                    for (const key in payload) {
                         if (payload[key] === true) payload[key] = 1
                         if (payload[key] === false) payload[key] = 0
                    }

                    result = axios.get<Array<{ label: string; value: string | number }>>(url, {
                         params: { q, ...payload },
                    })
               } else {
                    result = axios.post<Array<{ label: string; value: string | number }>>(url, payload)
               }

               result
                    .then((r: AxiosResponse) => {
                         setOptions(r.data) // Set the new options from the API response.
                         setCacheOptions(prev => {
                              r.data.forEach(item => {
                                   if ("options" in item) {
                                        item.options.forEach(subItem => {
                                             if (!prev.some(e => e.value === subItem.value)) {
                                                  prev.push(subItem)
                                             }
                                        })
                                   } else {
                                        if (!prev.some(e => e.value === item.value)) {
                                             prev.push(item)
                                        }
                                   }
                              })
                              return [...prev]
                         })
                    })
                    .catch((e: AxiosError) => {
                         setNoOptionsMessage(e.response?.status === 400 ? e.response.data.detail : "Une erreur est survenue")
                         setOptions([])
                    })
                    .finally(() => {
                         setIsLoading(false)
                    })

               setNoOptionsMessage("Aucun résultat")
          }, 700)
     }, [url, method, payload, minimumLengthSearch])

     // Handle input change and trigger debounced request
     const handleInputChange = (q: string) => {
          // Handle immediate message update
          if (q.length < minimumLengthSearch) {
               setNoOptionsMessage(`Saisissez au moins ${minimumLengthSearch - q.length} caractères pour lancer la recherche`)
          } else {
               setNoOptionsMessage("Chargement en cours ...") // Show loading message when input length is sufficient
          }

          // Clear any previously set debounced timeout
          if (debounceTimeout.current) {
               clearTimeout(debounceTimeout.current)
          }

          // Set a new timeout for debounced call
          debounceTimeout.current = setTimeout(() => {
               fetchOptions(q) // Trigger debounced function to make the API call
          }, 700)
     }

     const selectValue = useCallback(() => {
          if (!value) return []
          if (Array.isArray(value)) {
               return cacheOptions.filter(opt => ("options" in opt ? opt.options.some(item => value.includes(item.value)) : value.includes(opt.value)))
          } else {
               return cacheOptions.find(opt => opt.value === value)
          }
     }, [value, cacheOptions])

     return (
          <Select
               menuPortalTarget={document.body}
               isClearable={isClearable}
               placeholder={placeholder}
               name={name}
               formatGroupLabel={formatGroupLabel}
               styles={styles(isInvalid)}
               value={selectValue()}
               options={options}
               loadingMessage={() => "Chargement en cours ..."}
               onInputChange={handleInputChange} // Handle input change here
               onChange={(val: any) => {
                    onChange(Array.isArray(val) ? val.map(i => i.value) : val?.value)
               }}
               isLoading={isLoading}
               isMulti={isMulti}
               noOptionsMessage={() => noOptionsMessage}
               className={className}
          />
     )
}

export default MySelectSearchDataFromServer
