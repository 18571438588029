import { Form, Formik, FormikHelpers } from "formik"
import { IHttpErrorResponseModel } from "@common-models/*"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import {
     CONST_CIVILITE_MR,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_KPI_LIST_OPTIONS_FOR_SELECT2,
     CONST_PAYS_ID_FRANCE,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BASE_DOCUMENTAIRE,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_ANNONCE,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_CANDIDAT,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_VIVIER,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_TDB,
     CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_OPTIONS_FOR_SELECT2,
     CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ECOLE,
     CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT,
     CONST_PERSONNEL_STATUTS_OPTIONS_FOR_SELECT2,
     KPI_NB_ANNONCE_ARCHIVE,
     KPI_NB_ANNONCE_CREEE,
     KPI_NB_ANNONCE_REPUBLIE,
     KPI_NB_CV_SAISI,
     KPI_NB_ENTRETIEN_CLIENT_SAISI,
     KPI_NB_ENTRETIEN_CTS_SAISI,
     KPI_NB_ENTRETIEN_TELEPHONIQUE_SAISI,
     KPI_NB_MESSAGE_REPONDEUR_SAISI,
} from "@common-constants/*"
import * as Yup from "yup"
import MyAlert from "@common-utils/MyAlert"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import MyInputField from "@common-utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "@common-utils/fields/MyInputFieldMask"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import MySelectField from "@common-utils/fields/MySelectField"
import { IPersonnelCreateRequestModel } from "../core/_models"
import { personnelCreateRequest } from "../core/_requests"
import { useNavigate } from "react-router-dom"
import { ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF } from "../../gestion/routing/routing-endpoints"
import {
     CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT,
     CONST_API_UTILS_GET_AGENCES_ENDPOINT,
     CONST_API_UTILS_GET_AVAILABLE_NFC_ENDPOINT,
     CONST_API_UTILS_GET_ENTITES_ENDPOINT,
     CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT,
     CONST_API_UTILS_GET_METIERS_PERSONNEL_INTERNE_URL_ENDPOINT,
} from "../../../endpoints"
import moment from "moment/moment"

const FormCreate = () => {
     const navigate = useNavigate()
     const schema = Yup.object().shape({
          civilite: Yup.string().label(`"Civilité"`).required(),
          nom: Yup.string().label(`"Nom"`).required(),
          prenom: Yup.string().label(`"Prénom"`).required(),
          emailPro: Yup.string().email().label(`"Email"`).required(),
          telProIndicatif: Yup.number().label(`"Indicatif tél."`).required(),
          telPro: Yup.string().label(`"Indicatif tél."`).required(),
          statut: Yup.string().label(`"Statut"`).required(),
          agence: Yup.number().label(`"Agence"`).required(),
          poste: Yup.number().label(`"Poste"`).required(),
          entite: Yup.number().label(`"Entite"`).required(),
          responsable: Yup.number().label(`"Responsable"`).required(),
          dateActivationCompte: Yup.string().label(`"Date d'activation du compte"`).required(),
          authorizedAppAccessPages: Yup.array().label(`"Modules autorisées"`).required(),
          authorizedKpi: Yup.array().label(`"KPIs"`),
          team: Yup.array().label(`"Team"`),
          nfc: Yup.number().label(`"NFC"`),
          linkedinUrl: Yup.string().label(`"LinkedIn"`),
          heuresTravail: Yup.number().label(`"Heures travail"`).required(),
          heuresSupp: Yup.number().label(`"Heures supp"`).required(),
          dateEntree: Yup.string().label(`"Date entrée"`).required(),
          optionLignesCra: Yup.array().label(`"Lignes CRA"`),
          gwsGroupMailTeam: Yup.string().email().label(`"Group GWS Team"`).required(),
          gwsGroupMailResponsables: Yup.string().email().label(`"Group GWS Resp."`).required(),
     })

     const initialValues: IPersonnelCreateRequestModel = {
          civilite: CONST_CIVILITE_MR,
          telProIndicatif: CONST_PAYS_ID_FRANCE,
          authorizedAppAccessPages: [
               CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_TDB,
               CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_ANNONCE,
               CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_CANDIDAT,
               CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_RECRUTEMENT_VIVIER,
               CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BASE_DOCUMENTAIRE,
          ],
          authorizedKpi: [
               KPI_NB_CV_SAISI,
               KPI_NB_MESSAGE_REPONDEUR_SAISI,
               KPI_NB_ENTRETIEN_TELEPHONIQUE_SAISI,
               KPI_NB_ENTRETIEN_CTS_SAISI,
               KPI_NB_ENTRETIEN_CLIENT_SAISI,
               KPI_NB_ANNONCE_ARCHIVE,
               KPI_NB_ANNONCE_CREEE,
               KPI_NB_ANNONCE_REPUBLIE,
          ],
          dateEntree: moment().format("YYYY-MM-DD"),
          dateActivationCompte: moment().format("YYYY-MM-DD"),
          heuresTravail: 7,
          heuresSupp: 0,
     }

     function handleSubmit(values: IPersonnelCreateRequestModel, helpers: FormikHelpers<IPersonnelCreateRequestModel>) {
          helpers.setStatus(null)

          personnelCreateRequest(values)
               .then(() => {
                    toast.success("Le staff a bien été ajouté")
                    navigate(ROUTING_ABSOLUTE_PATH_GESTION_STRUCTURE_STAFF)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })
               })
               .finally(() => {
                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Civilité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"civilite"}
                                                  options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(c => c.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                  value={helpers.values.civilite}
                                                  isInvalid={!!helpers.errors.civilite}
                                                  onChange={val => helpers.setFieldValue("civilite", val)}
                                             />
                                             {helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Prénom</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  onChange={val =>
                                                       helpers.setFieldValue("prenom", val).then(() => {
                                                            helpers.setFieldValue(
                                                                 "gwsGroupMailTeam",
                                                                 `${val.toLowerCase()}.${helpers.values.nom?.toLowerCase()}.team@cts-corporate.com`
                                                            )
                                                            helpers.setFieldValue(
                                                                 "gwsGroupMailResponsables",
                                                                 `${val.toLowerCase}.${helpers.values.nom?.toLowerCase()}.responsables@cts-corporate.com`
                                                            )
                                                            helpers.setFieldValue("emailPro", `${val.toLowerCase()}.${helpers.values.nom?.toLowerCase()}@cts-corporate.com`)
                                                       })
                                                  }
                                                  value={helpers.values.prenom}
                                                  isInvalid={!!helpers.errors.prenom}
                                                  placeholder={"Prénom"}
                                             />
                                             {helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}
                                        </div>
                                   </div>

                                   {/* Nom */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Nom</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  onChange={val =>
                                                       helpers.setFieldValue("nom", val).then(() => {
                                                            helpers.setFieldValue(
                                                                 "gwsGroupMailTeam",
                                                                 `${helpers.values.prenom?.toLowerCase()}.${val.toLowerCase()}.team@cts-corporate.com`
                                                            )
                                                            helpers.setFieldValue(
                                                                 "gwsGroupMailResponsables",
                                                                 `${helpers.values.prenom?.toLowerCase()}.${val.toLowerCase()}.responsables@cts-corporate.com`
                                                            )
                                                            helpers.setFieldValue("emailPro", `${helpers.values.prenom?.toLowerCase()}.${val.toLowerCase()}@cts-corporate.com`)
                                                       })
                                                  }
                                                  value={helpers.values.nom}
                                                  isInvalid={!!helpers.errors.nom}
                                                  placeholder={"Nom"}
                                             />
                                             {helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}
                                        </div>
                                   </div>

                                   <div className="separator separator-dashed my-4"></div>

                                   {/* Email pro*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email pro.</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"emailPro"}
                                                  value={helpers.values.emailPro}
                                                  isInvalid={!!helpers.errors.emailPro}
                                                  onChange={val => helpers.setFieldValue("emailPro", val)}
                                                  placeholder={"Email pro."}
                                             />
                                             {helpers.errors.emailPro && <div className={"text-danger"}>{helpers.errors.emailPro}</div>}
                                        </div>
                                   </div>

                                   {/* Field Telindicatif + Tél*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone pro.</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MySelectGetAllDataFromServer
                                                            name={"telProIndicatif"}
                                                            value={helpers.values.telProIndicatif}
                                                            url={CONST_API_UTILS_GET_INDICATIF_TEL_ENDPOINT}
                                                            placeholder={"Choisissez un indicatif"}
                                                            isInvalid={!!helpers.errors.telProIndicatif}
                                                            onChange={val => helpers.setFieldValue("telProIndicatif", val)}
                                                            defaultOptions={[
                                                                 {
                                                                      label: "+33 - France",
                                                                      value: CONST_PAYS_ID_FRANCE,
                                                                 },
                                                            ]}
                                                       />
                                                       {helpers.errors.telProIndicatif && <div className={"text-danger"}>{helpers.errors.telProIndicatif}</div>}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInptuMaskField
                                                            name={"telPro"}
                                                            value={helpers.values.telPro}
                                                            isInvalid={!!helpers.errors.telPro}
                                                            mask={"99 9999999999999999999999999"}
                                                            placeholder={"Téléphone"}
                                                            inputMode={"numeric"}
                                                            onChange={e => helpers.setFieldValue("telPro", e.target.value)}
                                                       />
                                                       {helpers.errors.telPro && <div className={"text-danger"}>{helpers.errors.telPro}</div>}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   {/* Statut */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Statut</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"statut"}
                                                  value={helpers.values.statut}
                                                  options={CONST_PERSONNEL_STATUTS_OPTIONS_FOR_SELECT2}
                                                  isInvalid={!!helpers.errors.statut}
                                                  onChange={val => helpers.setFieldValue("statut", val)}
                                             />
                                             {helpers.errors.statut && <div className={"text-danger"}>{helpers.errors.statut}</div>}
                                        </div>
                                   </div>

                                   {/* Métier */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Métier</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"poste"}
                                                  value={helpers.values.poste}
                                                  isInvalid={!!helpers.errors.poste}
                                                  onChange={val => helpers.setFieldValue("poste", val)}
                                                  method={"GET"}
                                                  url={CONST_API_UTILS_GET_METIERS_PERSONNEL_INTERNE_URL_ENDPOINT}
                                             />
                                             {helpers.errors.poste && <div className={"text-danger"}>{helpers.errors.poste}</div>}
                                        </div>
                                   </div>

                                   {/* Agence */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Agence</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"agence"}
                                                  value={helpers.values.agence}
                                                  isInvalid={!!helpers.errors.agence}
                                                  onChange={val => helpers.setFieldValue("agence", val)}
                                                  url={CONST_API_UTILS_GET_AGENCES_ENDPOINT}
                                             />
                                             {helpers.errors.agence && <div className={"text-danger"}>{helpers.errors.agence}</div>}
                                        </div>
                                   </div>

                                   {/* Entité */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"entite"}
                                                  value={helpers.values.entite}
                                                  isInvalid={!!helpers.errors.entite}
                                                  onChange={val => helpers.setFieldValue("entite", val)}
                                                  url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                             />
                                             {helpers.errors.entite && <div className={"text-danger"}>{helpers.errors.entite}</div>}
                                        </div>
                                   </div>

                                   {/*Date entrée*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Date entrée</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateEntree"}
                                                  onChange={date => helpers.setFieldValue("dateEntree", date)}
                                                  value={helpers.values.dateEntree}
                                                  isInvalid={!!helpers.errors.dateEntree}
                                                  placeholder={"Date d'entrée"}
                                             />
                                             {helpers.errors.dateEntree && <div className={"text-danger"}>{helpers.errors.dateEntree}</div>}
                                        </div>
                                   </div>

                                   {/* Heures travail */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Heures travail</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"heuresTravail"}
                                                  onChange={date => helpers.setFieldValue("heuresTravail", date)}
                                                  value={helpers.values.heuresTravail}
                                                  isInvalid={!!helpers.errors.heuresTravail}
                                                  placeholder={"Heures travail"}
                                                  type={"number"}
                                                  inputMode={"numeric"}
                                             />
                                             {helpers.errors.heuresTravail && <div className={"text-danger"}>{helpers.errors.heuresTravail}</div>}
                                        </div>
                                   </div>

                                   {/* Heures travail */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Heures supp.</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"heuresSupp"}
                                                  onChange={date => helpers.setFieldValue("heuresSupp", date)}
                                                  value={helpers.values.heuresSupp}
                                                  isInvalid={!!helpers.errors.heuresSupp}
                                                  placeholder={"Heures supp."}
                                                  type={"number"}
                                                  inputMode={"numeric"}
                                             />
                                             {helpers.errors.heuresSupp && <div className={"text-danger"}>{helpers.errors.heuresSupp}</div>}
                                        </div>
                                   </div>

                                   {/* Options CRA */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Options lignes CRA</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"optionLignesCra"}
                                                  options={[
                                                       {
                                                            label: "TT",
                                                            value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT,
                                                       },
                                                       {
                                                            label: "École",
                                                            value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ECOLE,
                                                       },
                                                  ]}
                                                  value={helpers.values.optionLignesCra}
                                                  isInvalid={!!helpers.errors.optionLignesCra}
                                                  onChange={val => helpers.setFieldValue("optionLignesCra", val)}
                                                  isMulti
                                                  isSearchable
                                                  isClearable
                                             />
                                             {helpers.errors.optionLignesCra && <div className={"text-danger"}>{helpers.errors.optionLignesCra}</div>}
                                        </div>
                                   </div>

                                   {/* Responsable */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Responsable direct</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"responsable"}
                                                  value={helpers.values.responsable}
                                                  method={"GET"}
                                                  isInvalid={!!helpers.errors.responsable}
                                                  onChange={val => helpers.setFieldValue("responsable", val)}
                                                  url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                             />
                                             {helpers.errors.responsable && <div className={"text-danger"}>{helpers.errors.responsable}</div>}
                                        </div>
                                   </div>

                                   {/* Team */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Team</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"team"}
                                                  value={helpers.values.team}
                                                  method={"GET"}
                                                  isInvalid={!!helpers.errors.team}
                                                  onChange={val => helpers.setFieldValue("team", val)}
                                                  url={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                                  isMulti
                                             />
                                             {helpers.errors.team && <div className={"text-danger"}>{helpers.errors.team}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">LinkedIn URL</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"linkedinUrl"}
                                                  value={helpers.values.linkedinUrl}
                                                  isInvalid={!!helpers.errors.linkedinUrl}
                                                  onChange={val => helpers.setFieldValue("linkedinUrl", val)}
                                                  placeholder={"LinkedIn"}
                                             />
                                             {helpers.errors.linkedinUrl && <div className={"text-danger"}>{helpers.errors.linkedinUrl}</div>}
                                        </div>
                                   </div>

                                   <div className="separator separator-dashed my-4"></div>

                                   {/*Date d'activation du compte*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Date d'activation du compte</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <MyDateDayMonthYearField
                                                  name={"dateActivationCompte"}
                                                  onChange={date => helpers.setFieldValue("dateActivationCompte", date)}
                                                  value={helpers.values.dateActivationCompte}
                                                  isInvalid={!!helpers.errors.dateActivationCompte}
                                                  placeholder={"Date d'activation"}
                                             />
                                             {helpers.errors.dateActivationCompte && <div className={"text-danger"}>{helpers.errors.dateActivationCompte}</div>}
                                        </div>
                                   </div>

                                   {/* Modules autorisés */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Modules</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"authorizedAppAccessPages"}
                                                  options={CONST_PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_OPTIONS_FOR_SELECT2}
                                                  value={helpers.values.authorizedAppAccessPages}
                                                  isInvalid={!!helpers.errors.authorizedAppAccessPages}
                                                  onChange={val => helpers.setFieldValue("authorizedAppAccessPages", val)}
                                                  isMulti
                                                  isSearchable
                                                  isClearable
                                             />
                                             {helpers.errors.authorizedAppAccessPages && <div className={"text-danger"}>{helpers.errors.authorizedAppAccessPages}</div>}
                                        </div>
                                   </div>

                                   {/* KPI */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">KPI</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  name={"authorizedKpi"}
                                                  options={CONST_KPI_LIST_OPTIONS_FOR_SELECT2}
                                                  value={helpers.values.authorizedKpi}
                                                  isInvalid={!!helpers.errors.authorizedKpi}
                                                  onChange={val => helpers.setFieldValue("authorizedKpi", val)}
                                                  isMulti
                                                  isSearchable
                                                  isClearable
                                             />
                                             {helpers.errors.authorizedKpi && <div className={"text-danger"}>{helpers.errors.authorizedKpi}</div>}
                                        </div>
                                   </div>

                                   {/* NFC */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Carte NFC</label>

                                        <div className="col-lg-8">
                                             <MySelectGetAllDataFromServer
                                                  name={"nfc"}
                                                  value={helpers.values.nfc}
                                                  url={CONST_API_UTILS_GET_AVAILABLE_NFC_ENDPOINT}
                                                  method={"GET"}
                                                  isInvalid={!!helpers.errors.nfc}
                                                  onChange={val => helpers.setFieldValue("nfc", val)}
                                                  placeholder={"Choisissez une carte"}
                                             />
                                             {helpers.errors.nfc && <div className={"text-danger"}>{helpers.errors.nfc}</div>}
                                        </div>
                                   </div>

                                   {/* GWS group Team*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">GWS group Team</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"gwsGroupMailTeam"}
                                                  value={helpers.values.gwsGroupMailTeam}
                                                  isInvalid={!!helpers.errors.gwsGroupMailTeam}
                                                  onChange={val => helpers.setFieldValue("gwsGroupMailTeam", val)}
                                                  placeholder={"GWS group Team"}
                                             />
                                             {helpers.errors.gwsGroupMailTeam && <div className={"text-danger"}>{helpers.errors.gwsGroupMailTeam}</div>}
                                        </div>
                                   </div>

                                   {/* GWS group Resp.*/}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">GWS group Resp.</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"gwsGroupMailResponsables"}
                                                  value={helpers.values.gwsGroupMailResponsables}
                                                  isInvalid={!!helpers.errors.gwsGroupMailResponsables}
                                                  onChange={val => helpers.setFieldValue("gwsGroupMailResponsables", val)}
                                                  placeholder={"GWS group Resp."}
                                             />
                                             {helpers.errors.gwsGroupMailResponsables && <div className={"text-danger"}>{helpers.errors.gwsGroupMailResponsables}</div>}
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5"></div>

                                   {/* Submit button */}
                                   <div className={"text-end"}>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

export default FormCreate
