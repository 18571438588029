import { useQueryClient } from "react-query"
import { usePersonnelDetailsContext } from "../../PersonnelDetails"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { IPersonnelModel } from "@common-models/*"
import MyLoadingOverlay from "../../../../../../common/components/MyLoadingOverlay"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import swal from "sweetalert2"
import { personnelRemovePersonnelsFromTeamRequest } from "../../../core/_requests"
import { IGetPersonnelDetailsResponseModel } from "../../../core/_models"

const Team = () => {
     const queryClient = useQueryClient()
     const context = usePersonnelDetailsContext()
     const details = context.detailsQuery.data

     return (
          <>
               <div className={"row gy-5"}>
                    {details?.team?.map((item, index) => (
                         <div className="col-4" key={index}>
                              <div className={"d-flex align-items-center"}>
                                   <TeamItem item={item} />
                              </div>
                         </div>
                    ))}
               </div>
          </>
     )
}

const TeamItem = ({ item }: { item: IPersonnelModel }) => {
     const context = usePersonnelDetailsContext()
     const queryClient = useQueryClient()
     const details = context.detailsQuery.data
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [isHovered, setIsHovered] = useState<boolean>(false)

     function removePersonnelFromTeam() {
          swal.fire({
               icon: "warning",
               text: `Voulez-vous vraiment retirer ${item.prenomNom} de l'équipe?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return personnelRemovePersonnelsFromTeamRequest(details?.personnel.id as number, [item.id as number])
                         .then(r => {
                              queryClient.setQueryData<IGetPersonnelDetailsResponseModel | undefined>(
                                   context.REACT_QUERY_KEY_GET_PERSONNEL_DETAILS,
                                   (prev: IGetPersonnelDetailsResponseModel | undefined) => {
                                        if (prev) {
                                             prev = r.data
                                        }
                                        return prev
                                   }
                              )
                              toast.success("Le personnel a bien été retiré de l'équipe.")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className={"d-flex align-items-center me-3"} onMouseEnter={() => setIsHovered(prev => true)} onMouseOut={() => setIsHovered(false)}>
                    <MyLoadingOverlay isActive={isAvatarLoading}>
                         {isHovered ? (
                              <i className={"fad fa-times-circle w-40px h-40px cursor-pointer text-danger"} style={{ fontSize: "3rem" }} onClick={removePersonnelFromTeam} />
                         ) : (
                              <img
                                   className="rounded-circle w-40px h-40px"
                                   src={
                                        item?.avatarGoogleDriveId
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(item?.avatarGoogleDriveId)
                                             : toAbsoluteUrl("/media/svg/avatars/blank.svg")
                                   }
                                   onLoad={() => setIsAvatarLoading(false)}
                                   alt={"Avatar"}
                              />
                         )}
                    </MyLoadingOverlay>
               </div>
               <div>
                    <Link to={`/gestion/structure/staff/${item.id}/details`} className={"fw-bold text-gray-700 text-hover-primary"}>
                         {item.prenomNom}
                    </Link>
                    <div>{item.personnelPostes && item.personnelPostes.length > 0 && item.personnelPostes[0].poste?.nom}</div>
               </div>
          </>
     )
}

export default Team
