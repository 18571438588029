import { IConsultantSuiviAjoutPersonnelDelegationDroitsRequestModel } from "./core/_models"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { IConsultantModel, IHttpErrorResponseModel } from "@common-models/*"
import { CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR, CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROITS_OPTIONS_FOR_SELECT2 } from "@common-constants/*"
import MyAlert from "@common-utils/MyAlert"
import { CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT } from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"
import { consultantSuiviAjoutPersonnelDelegationDroitsRequest } from "./core/_requests"
import * as Yup from "yup"
import MyCard from "@common-utils/MyCard"
import MySelectGetAllDataFromServerFormField from "@common-components/fields/MySelectGetAllDataFromServerFormField"
import MySelectFormField from "@common-components/fields/MySelectFormField"

const ConsultantSuiviAjoutPersonnelDelegationDroits = ({ consultants, onSubmitCallback = () => {}, annulerCallback = () => {} }: IPropsModel) => {
     // Handle submit
     function handleSubmit(values: IConsultantSuiviAjoutPersonnelDelegationDroitsRequestModel, helpers: FormikHelpers<IConsultantSuiviAjoutPersonnelDelegationDroitsRequestModel>) {
          helpers.setStatus(null)

          consultantSuiviAjoutPersonnelDelegationDroitsRequest(
               consultants.map(item => item.id!),
               values
          )
               .then(r => {
                    helpers.setSubmitting(false)
                    onSubmitCallback()
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Form schema
     const validationSchema = Yup.object().shape({
          rows: Yup.array()
               .of(
                    Yup.object().shape({
                         consultant: Yup.number().label(`"Consultant"`).required(),
                         responsables: Yup.array()
                              .of(
                                   Yup.object().shape({
                                        responsable: Yup.number().label(`"Responsable"`).required(),
                                        droits: Yup.array().of(Yup.string()).min(1).label(`"Droits"`).required(),
                                   })
                              )
                              .min(1)
                              .label(`"Responsables"`),
                    })
               )
               .min(1)
               .label(`"Rows"`),
     })

     const initialValues: IConsultantSuiviAjoutPersonnelDelegationDroitsRequestModel = {}

     return (
          <>
               <MyCard classNames={"mb-5 border"} defaultIsCollapsed={true}>
                    <MyCard.Header isCollapseIconShown>Consultants concernés ( {consultants.length} )</MyCard.Header>
                    <MyCard.Body>
                         <>
                              {consultants.map((item, index) => (
                                   <div key={index}>{item.prenomNom}</div>
                              ))}
                         </>
                    </MyCard.Body>
               </MyCard>

               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <MySelectGetAllDataFromServerFormField
                                        formikProps={helpers}
                                        validationSchema={validationSchema}
                                        name={`responsables`}
                                        label={"Délégués"}
                                        endpoint={CONST_API_UTILS_GET_ACTIVE_PERSONNELS_ENDPOINT}
                                        method={"GET"}
                                        placeholder={"Délégués"}
                                        classNameLayout={"mb-2"}
                                        isMulti
                                        classNameLabel={"col-lg-2"}
                                        classNameField={"col-lg-10"}
                                   />

                                   <MySelectFormField
                                        formikProps={helpers}
                                        validationSchema={validationSchema}
                                        label={"Droits"}
                                        name={`droits`}
                                        placeholder={"Droits"}
                                        isMulti
                                        options={CONST_PERSONNEL_AVEC_DELEGATION_DROITS_CONSULTANT__DROITS_OPTIONS_FOR_SELECT2}
                                        classNameLabel={"col-lg-2"}
                                        classNameField={"col-lg-10"}
                                   />

                                   {/* Submit button */}
                                   <div className="d-flex justify-content-end mt-3">
                                        <button className="btn btn-outline-secondary btn-sm me-2" type="button" onClick={annulerCallback} disabled={helpers.isSubmitting}>
                                             Annuler
                                        </button>
                                        <button className="btn btn-primary btn-sm d-inline-flex align-items-center" type="submit" disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? "Appliquer" : <MySimpleSpinner size="sm" />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     consultants: IConsultantModel[]
     annulerCallback?: () => void
     onSubmitCallback?: () => void
}

export default ConsultantSuiviAjoutPersonnelDelegationDroits
