import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "../../../../common/components/MyLoadingOverlay"
import { usePersonnelListingContext } from "./PersonnelListing"
import { usePersonnelListingListContext } from "./_List"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { toast } from "react-toastify"
import MyTooltip from "@common-utils/MyTooltip"
import { IGetPersonnelListingItemResponseModel } from "./core/_models"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { Link } from "react-router-dom"
import { useThemeMode } from "@common-metronic/partials"

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const globalContext = usePersonnelListingContext()
     const listContext = usePersonnelListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const { mode } = useThemeMode()

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.personnel.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.personnel.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.personnel.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.personnel.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   {/* Avatar, titre */}
                                   <div className={"d-flex align-items-center"}>
                                        {/* Avatar */}
                                        <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                             <div className={"d-flex"}>
                                                  <img
                                                       src={
                                                            item.personnel?.avatarGoogleDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item.personnel?.avatarGoogleDriveId)
                                                                 : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </div>
                                        </MyLoadingOverlay>

                                        {/* Titre */}
                                        <div className={"ms-4"}>
                                             <div className={"d-flex"}>
                                                  <Link
                                                       to={`/gestion/structure/staff/${item.personnel.id}/details?nom=${item.personnel.prenomNom}`}
                                                       className={"fw-bold text-hover-dark fs-5"}
                                                  >
                                                       {item.personnel.prenomNom}
                                                  </Link>
                                             </div>
                                             <div className={"text-muted"}>
                                                  {item?.personnel.personnelPostes && item?.personnel.personnelPostes?.length > 0
                                                       ? item?.personnel.personnelPostes[0]?.poste?.nom
                                                       : "Poste non renseigné"}
                                             </div>
                                        </div>
                                   </div>

                                   {/* Right side */}
                                   <div className="d-flex align-items-center">
                                        <span className={"badge badge-dark me-1"}>{item.personnel.mainEntite?.nom}</span>
                                        <span className={"badge badge-dark"}>
                                             {!item?.personnel?.statut && "Statut non renseigné"}
                                             {item?.personnel?.statut && item?.personnel?.statut === "STATUT_NON_SALARIE" ? "Non salarié" : item.personnel.statutLabel}
                                        </span>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <>
                                   <div className={"d-flex align-items-center px-4 pb-4"}>
                                        <div className={"col-10"}>
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-envelope fs-5 w-25px" />
                                                  <a href={`mailto:${item.personnel.mailProfessionnel}`}>{item.personnel.mailProfessionnel}</a>
                                             </div>

                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-phone fs-5 w-25px" />
                                                  <a href={`tel:${item.personnel.telProfessionnelIndicatif?.phoneCodeWithPlusPrefix} ${item.personnel.telProfessionnel}`}>
                                                       {item.personnel.telProfessionnelIndicatif?.phoneCodeWithPlusPrefix} {item.personnel.telProfessionnel}
                                                  </a>
                                             </div>

                                             {item.responsable && (
                                                  <div className={`d-flex align-items-center mb-2`}>
                                                       <span className="fas fa-user fs-5 w-25px" />
                                                       Sous la responsabilité de {item.responsable?.prenomNom}
                                                  </div>
                                             )}

                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-building fs-5 w-25px" />
                                                  {item.personnel.agence?.nom}
                                             </div>

                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-calendar-check fs-5 w-25px" />
                                                  {item.personnel.dateEntree?.format("[Entré le] dddd D MMMM YYYY")}
                                             </div>

                                             {item.personnel.dateSortie && (
                                                  <div className={`d-flex align-items-center mb-2`}>
                                                       <span className="fas fa-calendar-xmark fs-5 w-25px" />
                                                       {item.personnel.dateSortie?.format("[Sorti le] dddd D MMMM YYYY")}
                                                  </div>
                                             )}
                                        </div>
                                        <div className="col text-end">
                                             {!item.personnelCompte?.dateFinAcces ? (
                                                  <MyTooltip title={"Désactiver compte"} placement={"top"}>
                                                       <div>
                                                            <button
                                                                 type={"button"}
                                                                 className={`btn btn-outline btn-outline-danger btn-sm btn-icon rounded-circle mb-2`}
                                                                 onClick={() => toast.info("coming soon ...")}
                                                            >
                                                                 <i className="fas fa-power-off fs-4" />
                                                            </button>
                                                       </div>
                                                  </MyTooltip>
                                             ) : (
                                                  <MyTooltip title={"Activer compte"} placement={"top"}>
                                                       <div>
                                                            <button
                                                                 type={"button"}
                                                                 className={`btn btn-outline btn-outline-success btn-sm btn-icon rounded-circle mb-2`}
                                                                 onClick={() => toast.info("coming soon ...")}
                                                            >
                                                                 <i className="fas fa-power-off fs-4" />
                                                            </button>
                                                       </div>
                                                  </MyTooltip>
                                             )}
                                             {!item.personnel.dateSortie && (
                                                  <MyTooltip title={"Sortir des effectifs"} placement={"bottom"}>
                                                       <button
                                                            type={"button"}
                                                            className={`btn btn-outline btn-outline-warning btn-sm btn-icon rounded-circle mb-2`}
                                                            onClick={() => toast.info("coming soon ...")}
                                                       >
                                                            <i className="fas fa-door-open fs-4" />
                                                       </button>
                                                  </MyTooltip>
                                             )}
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const ListingItemWrapper = ({ item, index }: { item: IGetPersonnelListingItemResponseModel; index: number }) => {
     const context = usePersonnelListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.personnel.id === item.personnel.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.personnel.id === item.personnel.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.personnel.id !== item.personnel.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

interface IListItemPropsModel {
     item: IGetPersonnelListingItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

export default ListingItemWrapper
