import { IConsultantModel } from "@common-models/*"
import MyLoadingOverlay from "../../../../../common/components/MyLoadingOverlay"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import { Link } from "react-router-dom"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import MyAlert from "@common-utils/MyAlert"
import { useThemeMode } from "@common-metronic/partials"

const ConsultantItem = ({ consultant }: IConsultantItemPropsModel) => {
     const { mode } = useThemeMode()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!consultant.candidat?.user?.avatarGoogleDriveId)

     return (
          <div className={`p-4 d-flex align-items-center mb-4 bg-light`}>
               {/* Avatar */}
               <div>
                    <div className={"d-flex justify-content-center"}>
                         <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                              {/* Avatar */}
                              <img
                                   src={
                                        consultant.candidat?.user?.avatarGoogleDriveId
                                             ? FileAndDriveHelper.getThumbnailDriveUrl(consultant.candidat.user.avatarGoogleDriveId)
                                             : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                   }
                                   onLoad={() => setIsAvatarLoading(false)}
                                   style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                   alt="Avatar"
                              />
                         </MyLoadingOverlay>
                    </div>
               </div>
               <div className={"flex-grow-1 ms-4"}>
                    <div className={"d-flex align-items-center"}>
                         <div>
                              <span className={"fs-5 fw-bold me-2 d-flex align-items-center"}>
                                   <Link to={`/business/consultants/${consultant.id}/details`} target={"_blank"}>
                                        {consultant.prenomNom}
                                   </Link>
                              </span>
                         </div>
                         <div className={"ms-auto"}>
                              <div className={`badge badge-dark`}>{consultant.contratLabel}</div>
                         </div>
                    </div>
                    <div className={"d-flex align-items-center mt-1"}>
                         <div>{consultant.metier?.nom}</div>
                         <div className={"ms-auto"}>
                              <div className={"badge badge-primary"}>{consultant.statutLabel}</div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

const PtfDetailsConsultantsAttachesToday = ({ consultants }: IPtfDetailsConsultantsAttachesTodayPropsModel) => {
     return (
          <>
               {consultants.length > 0 ? (
                    consultants.map((consultant, index) => <ConsultantItem consultant={consultant} key={index} />)
               ) : (
                    <MyAlert type={"primary"}>Actuellement, aucun consultant n'est associé à cette PTF.</MyAlert>
               )}
          </>
     )
}

interface IConsultantItemPropsModel {
     consultant: IConsultantModel
}

interface IPtfDetailsConsultantsAttachesTodayPropsModel {
     consultants: IConsultantModel[]
}

export default PtfDetailsConsultantsAttachesToday
